import { sanitizeString, unifyString } from '@afleya/common';
import { MaterialCategoryEntity } from '@afleya/material-schemas';
import { materialsInputsDatabase } from 'services/offline/dexieDB';
import isUndefined from 'lodash/isUndefined';
import { LocalMaterialInput } from 'services/materialInputs/types';
import { distance } from 'fastest-levenshtein';

interface GetNicknamesProps {
  uniqueNickname: Set<string>;
  searchTerms: string;
  newSelectedDaughterCategories: MaterialCategoryEntity[];
  projectId: string;
  skip: number;
  take: number;
}

export const listMaterialInputsSearchEngine = async ({
  uniqueNickname,
  searchTerms,
  newSelectedDaughterCategories,
  projectId,
  skip,
  take,
}: GetNicknamesProps): Promise<LocalMaterialInput[]> => {
  const unifiedSearchTerms = sanitizeString(searchTerms)
    .split(' ')
    .map(searchTerm => unifyString(searchTerm));

  let nicknames: LocalMaterialInput[] = [];
  let maxDistance = 0;

  while (nicknames.length < take && maxDistance <= 1) {
    const limit = take - nicknames.length;
    const newNicknames = await fetchNicknames({
      uniqueNickname,
      unifiedSearchTerms,
      newSelectedDaughterCategories,
      projectId,
      skip,
      limit,
      maxDistance,
    });

    nicknames = nicknames.concat(
      newNicknames.filter(
        newNickname =>
          !nicknames.some(nickname => nickname.id === newNickname.id),
      ),
    );

    maxDistance++;
  }

  return nicknames;
};

interface fetchNicknamesProps {
  uniqueNickname: Set<string>;
  unifiedSearchTerms: string[];
  newSelectedDaughterCategories: MaterialCategoryEntity[];
  projectId: string;
  skip: number;
  limit: number;
  maxDistance: number;
}

const fetchNicknames = async ({
  uniqueNickname,
  unifiedSearchTerms,
  newSelectedDaughterCategories,
  projectId,
  skip,
  limit,
  maxDistance,
}: fetchNicknamesProps): Promise<LocalMaterialInput[]> => {
  return await materialsInputsDatabase.materialInputs
    .orderBy('nickname')
    .filter(materialInput => {
      const nickname = materialInput.nickname;
      const nicknameParts = nickname
        .split(' ')
        .map(nicknamePart => sanitizeString(unifyString(nicknamePart.trim())));

      if (
        !uniqueNickname.has(nickname) &&
        unifiedSearchTerms.every(term =>
          maxDistance === 0
            ? unifyString(nickname).includes(term)
            : nicknameParts.some(
                nicknamePart => distance(nicknamePart, term) <= maxDistance,
              ),
        ) &&
        newSelectedDaughterCategories.some(
          category => category.id === materialInput.materialCategoryId,
        ) &&
        !isUndefined(materialInput) &&
        materialInput.projectId === projectId
      ) {
        uniqueNickname.add(nickname);

        return true;
      }

      return false;
    })
    .offset(skip)
    .limit(limit)
    .toArray();
};
