import { FooterButton, FormContainer } from 'components/Layout';
import { PageContent } from 'components/PageContent';
import PageFooter from 'components/PageFooter';
import { FormattedMessage } from 'react-intl';
import { CircularProgress } from '@mui/material';
import { useAsyncFn } from 'react-use';
import {
  duplicateBuildingPartFormAnswersSchema,
  DuplicateBuildingPartFormAnswersSchema,
} from 'services/buildingPart/validationSchema';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { duplicateBuildingPartMultipleTime } from 'services/offline/requests/buildingAndBuildingPart/duplicateBuildingPartMultipleTime';
import { DuplicateBuildingPartFields } from './Fields';

interface Props {
  targetBuildingId: string;
  projectId: string;
  buildingId: string;
  buildingPartId: string;
  numberOfDuplicateBuildingParts: number;
  targetBuildingPartsId: string[];
  setDuplicateState: (duplicateState: boolean) => void;
}

export const DuplicateBuildingPartForm = ({
  targetBuildingId,
  projectId,
  buildingPartId,
  numberOfDuplicateBuildingParts,
  targetBuildingPartsId,
  setDuplicateState,
}: Props): JSX.Element => {
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<DuplicateBuildingPartFormAnswersSchema>({
    mode: 'onChange',
    resolver: zodResolver(duplicateBuildingPartFormAnswersSchema),
  });

  const [{ loading: submitLoading }, onSubmit] = useAsyncFn(
    async (formAnswers: DuplicateBuildingPartFormAnswersSchema) => {
      await Promise.all(
        targetBuildingPartsId.map(
          async targetBuildingPartId =>
            await duplicateBuildingPartMultipleTime({
              projectId,
              sourceBuildingPartId: buildingPartId,
              targetBuildingId,
              targetBuildingPartId,
              buildingPartNames: formAnswers.buildingPartNames,
            }),
        ),
      );
      setDuplicateState(true);
    },
    [
      targetBuildingPartsId,
      buildingPartId,
      projectId,
      setDuplicateState,
      targetBuildingId,
    ],
  );

  return (
    <>
      <PageContent>
        <FormContainer
          id="duplicate-buildingPart-form"
          noValidate
          autoComplete="off"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmit={handleSubmit(onSubmit)}
        >
          <DuplicateBuildingPartFields
            control={control}
            buildingPartId={buildingPartId}
            numberOfDuplicateBuildingParts={numberOfDuplicateBuildingParts}
          />
        </FormContainer>
      </PageContent>
      <PageFooter>
        <FooterButton
          form="duplicate-buildingPart-form"
          type="submit"
          color="primary"
          variant="contained"
          disabled={
            !isValid || submitLoading || targetBuildingPartsId.length === 0
          }
        >
          {submitLoading ? (
            <CircularProgress size={20} />
          ) : (
            <FormattedMessage
              id={'input-module-duplicate-building-part.submitButton'}
            />
          )}
        </FooterButton>
      </PageFooter>
    </>
  );
};
