import { CameraAlt, LocationOn } from '@mui/icons-material';
import { Button, Stack } from '@mui/material';
import SuccessDrawer from 'components/SuccessDrawer';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { navigateToModuleInputCategories } from 'services/navigation/navigateToModuleInputCategories';
import { DivButton, DivContainer } from './InputModuleSuccessDrawer.style';

interface Props {
  open?: boolean;
  onAddIdenticalClick: () => void;
  projectId: string;
  buildingId: string;
  buildingPartId: string;
  openCamera: () => void;
  openPlan: () => void;
}

export const InputModuleSuccessDrawer = ({
  open,
  onAddIdenticalClick,
  projectId,
  buildingId,
  buildingPartId,
  openCamera,
  openPlan,
}: Props): JSX.Element => {
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <SuccessDrawer
      open={open}
      title={intl.formatMessage({
        id: 'input-module-input-page.successDrawerMessage',
      })}
    >
      <DivContainer>
        <DivButton>
          <Button
            data-test="addPhoto"
            color="primary"
            variant="contained"
            onClick={openCamera}
          >
            <CameraAlt fontSize="large" />
          </Button>
        </DivButton>
        <DivButton>
          <Button
            data-test="addOnMap"
            color="primary"
            variant="contained"
            onClick={openPlan}
          >
            <LocationOn fontSize="large" />
          </Button>
        </DivButton>
      </DivContainer>
      <Stack direction="row" spacing={1} justifyContent="center">
        <Button
          data-test="addAnotherMaterialInput"
          id="add-another-material-button"
          color="secondary"
          variant="contained"
          onClick={() =>
            navigateToModuleInputCategories({
              projectId,
              buildingId,
              buildingPartId,
              navigate,
            })
          }
        >
          <FormattedMessage id="input-module-input-page.addAnotherMaterial" />
        </Button>
        <Button
          data-test="addSameMaterialInput"
          id="add-identical-material-button"
          color="primary"
          variant="contained"
          onClick={onAddIdenticalClick}
        >
          <FormattedMessage id="input-module-input-page.addIdenticalMaterial" />
        </Button>
      </Stack>
    </SuccessDrawer>
  );
};
