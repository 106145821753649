import { LocalMaterialInput } from 'services/materialInputs/types';
import { materialsInputsDatabase } from '../../dexieDB';

interface ListMaterialsInputArgs {
  projectId: string;
  buildingId?: string;
  buildingPartId?: string;
}
export const listMaterialInputs = async (
  params: ListMaterialsInputArgs,
): Promise<LocalMaterialInput[]> => {
  return await materialsInputsDatabase.materialInputs
    .where(getFilters(params))
    .sortBy('nickname');
};

const getFilters = ({
  projectId,
  buildingId,
  buildingPartId,
}: ListMaterialsInputArgs) => {
  if (buildingPartId !== undefined) {
    return { buildingPartId };
  }
  if (buildingId !== undefined) {
    return { buildingId };
  }

  return { projectId };
};
