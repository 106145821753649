import PageHeader from 'components/PageHeader';
import { useIntl } from 'react-intl';
import { routes } from 'routes';

interface ImageRecognitionPageHeaderProps {
  locationTitle: string;
  projectId: string;
  buildingId: string;
  buildingPartId: string;
}

export const ImageRecognitionPageHeader = ({
  locationTitle,
  projectId,
  buildingId,
  buildingPartId,
}: ImageRecognitionPageHeaderProps): JSX.Element => {
  const intl = useIntl();

  return (
    <PageHeader
      title={intl.formatMessage({
        id: 'input-module-image-recognition-page.title',
      })}
      locationTitle={locationTitle}
      backLinkTitle={intl.formatMessage({
        id: 'input-module-categories-page.title',
      })}
      backLinkPath={routes.INPUT_MODULE_CATEGORIES.replace(
        ':projectId',
        projectId,
      )
        .replace(':buildingId', buildingId)
        .replace(':buildingPartId', buildingPartId)}
    ></PageHeader>
  );
};
