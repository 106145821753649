import { Dialog, DialogTitle } from '@mui/material';
import { useAsync } from 'react-use';
import { FormattedMessage } from 'react-intl';
import LoadingBox from 'components/LoadingBox';
import { MaterialInputForRecap } from 'services/offline/materialInputsForRecap';
import { getMaterialInput } from 'services/offline/requests/materialInput/getMaterialInput';
import { LocalMaterialInput } from 'services/materialInputs/types';
import { listCharacteristicInputs } from 'services/offline/requests/materialInput/listCharacteristicInputs';
import { listCharacteristics } from 'services/offline/requests/material/listCharacteristics';
import { listCharacteristicValues } from 'services/offline/requests/material/listCharacteristicValues';
import { DialogTransition } from 'components/DialogTransition';
import { InputModuleSummaryEditForm } from './InputModuleSummaryEditForm';

interface Props {
  materialInputForRecap: MaterialInputForRecap;
  open: boolean;
  onClose: () => void;
  onClickPhoto: () => void;
  onEditMaterialInput: (
    newEditMaterialInput: LocalMaterialInput,
  ) => Promise<void>;
}

export const InputModuleSummaryEditDialog = ({
  materialInputForRecap,
  open,
  onClose,
  onClickPhoto,
  onEditMaterialInput,
}: Props): JSX.Element => {
  const { loading, value } = useAsync(async () => {
    const materialInput = await getMaterialInput(materialInputForRecap.id);
    const characteristicInputs = await listCharacteristicInputs(
      materialInputForRecap.id,
    );
    const characteristicValues = await listCharacteristicValues(
      materialInput.materialCategoryId,
    );
    const characteristicNames = await listCharacteristics(
      materialInput.materialCategoryId,
    );

    return {
      materialInput,
      characteristicInputs,
      characteristicValues,
      characteristicNames,
    };
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={DialogTransition}
      fullScreen
    >
      <DialogTitle>
        <FormattedMessage
          id="input-module-summary.dialog-edit.title"
          values={{ nickname: materialInputForRecap.nickname }}
        />
      </DialogTitle>
      {loading || value === undefined ? (
        <LoadingBox />
      ) : (
        <InputModuleSummaryEditForm
          materialInput={value.materialInput}
          onClose={onClose}
          onClickPhoto={onClickPhoto}
          onEditMaterialInput={onEditMaterialInput}
          characteristicNames={value.characteristicNames}
          characteristicValues={value.characteristicValues}
          characteristicInputs={value.characteristicInputs}
        />
      )}
    </Dialog>
  );
};
