import { listMaterialInputIconsContract } from '@afleya/material-inputs-contracts';
import { MaterialInputIconEntity } from '@afleya/project-schemas';
import { getAxiosRequest } from '@swarmion/serverless-contracts';
import { getJwtToken } from 'services/cognito';
import client from '../../client';

interface Props {
  projectId: string;
  skip: number;
  take: number;
  buildingPartId?: string;
  considerChildBuildingParts?: boolean;
}

export const listProjectMaterialInputIcons = async ({
  projectId,
  skip,
  take,
  buildingPartId,
  considerChildBuildingParts,
}: Props): Promise<MaterialInputIconEntity[]> => {
  const jwtToken = await getJwtToken();

  const { data: icons } = await getAxiosRequest(
    listMaterialInputIconsContract,
    client,
    {
      headers: {
        authorization: `Bearer ${jwtToken}`,
      },
      pathParameters: {
        projectId,
      },
      queryStringParameters: {
        skip: skip.toString(),
        take: take.toString(),
        buildingPartId,
        considerChildBuildingParts:
          considerChildBuildingParts === true ? 'true' : 'false',
      },
    },
  );

  return icons;
};
