import { Image } from 'components/Image';
import { FormattedMessage } from 'react-intl';
import { getMaterialInputPicture } from 'services/offline/requests/materialInput/getMaterialInputPicture';
import { deleteLocalMaterialInputPicture } from 'services/offline/requests/materialInput/deleteLocalMaterialInputPicture';
import { ImageTypes } from '@afleya/common';
import {
  ButtonPicture,
  DivPhotoDetail,
  StackFooter,
  StackPictureDetail,
} from './PictureDetail.style';

interface Props {
  onClosePictureDetail: () => void;
  removeMaterialInputPictureId: (pictureId: string) => void;
  projectId: string;
  materialInputId: string;
  pictureId: string;
}

const PictureDetail = ({
  onClosePictureDetail,
  removeMaterialInputPictureId,
  projectId,
  materialInputId,
  pictureId,
}: Props): JSX.Element => {
  const onDelete = async () => {
    await deleteLocalMaterialInputPicture(pictureId);
    removeMaterialInputPictureId(pictureId);
    onClosePictureDetail();
  };

  return (
    <StackPictureDetail spacing={2}>
      <DivPhotoDetail>
        <Image
          projectId={projectId}
          hostId={materialInputId}
          imageId={pictureId}
          errorLoadingId="photo.errorLoading"
          dialogContentIds={[
            'photo.deleteDialogTitle',
            'photo.deleteDialogContent',
          ]}
          getImage={getMaterialInputPicture}
          imageType={ImageTypes.materialInputPicture}
        />
      </DivPhotoDetail>
      <StackFooter direction="row" spacing={2}>
        <ButtonPicture
          variant="contained"
          color="secondary"
          onClick={() => {
            void onDelete();
          }}
        >
          <FormattedMessage id="picture-detail.delete" />
        </ButtonPicture>
        <ButtonPicture
          variant="contained"
          color="primary"
          onClick={onClosePictureDetail}
        >
          <FormattedMessage id="picture-detail.next" />
        </ButtonPicture>
      </StackFooter>
    </StackPictureDetail>
  );
};

export default PictureDetail;
