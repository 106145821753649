import Dexie from 'dexie';

export class SyncableDexie extends Dexie {
  getNbUnsynchronizedItem = async (): Promise<number> => {
    const remoteNode = await this._syncNodes.where({ type: 'remote' }).first();
    if (!remoteNode) {
      return 0;
    }

    const nbUnsyncronizedItem = (
      await this._changes
        .where(':id')
        .above(remoteNode.myRevision)
        .primaryKeys()
    ).length;

    return nbUnsyncronizedItem;
  };
}
