import { InputUnit } from '@afleya/common';
import { roundDimension } from 'components/UnitSelector';
import { MaterialInputFormEntity } from './MaterialInputFormEntity';
import { LocalMaterialInput } from './types';

export const getEditMaterialInputFormDefaultValues = (
  materialInput: LocalMaterialInput,
): MaterialInputFormEntity => {
  const {
    nickname,
    locationDescription,
    amount,
    materialToDeduce,
    hasAsbestos,
    hasLead,
    hasOtherPollution,
    age,
    stateNewPercentage,
    stateGoodPercentage,
    stateMediumPercentage,
    stateObsoletePercentage,
    stateComments,
    assemblyTypeId,
    comments,
    dimensions,
    reusePercentage,
    materialEvaluationMethod,
    weightPerFunctionalUnit,
    inputUnit,
  } = materialInput;

  return {
    nickname,
    locationDescription,
    amount: amount.toString(),
    childBuildingPartId: null,
    stateNewPercentage,
    stateGoodPercentage,
    stateMediumPercentage,
    stateObsoletePercentage,
    stateComments: stateComments ?? '',
    assemblyTypeId: assemblyTypeId ?? undefined,
    age,
    comments: comments ?? '',
    materialToDeduce: Boolean(materialToDeduce),
    hasAsbestos: Boolean(hasAsbestos),
    hasLead: Boolean(hasLead),
    hasOtherPollution: Boolean(hasOtherPollution),
    evaluationMethod: materialEvaluationMethod,
    length: getDimension(dimensions['length'], inputUnit),
    width: getDimension(dimensions['width'], inputUnit),
    height: getDimension(dimensions['height'], inputUnit),
    reusePercentage,
    weightPerFunctionalUnit,
  };
};

const getDimension = (dimension: unknown, inputUnit: InputUnit): string => {
  if (typeof dimension === 'number') {
    if (inputUnit === InputUnit.METER) {
      return roundDimension(dimension / 100).toString();
    } else {
      return roundDimension(dimension).toString();
    }
  }

  return '';
};
