import { MaterialEvaluationMethod } from '@afleya/common';
import { Divider, Stack } from '@mui/material';
import { Control, UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { MaterialInputFormEntity } from 'services/materialInputs';
import { FormattedMessage } from 'react-intl';
import { Fragment } from 'react';
import { MaterialToDeduce } from './MaterialToDeduce';
import { PollutionCheckbox } from './PollutionCheckbox';

interface Props {
  control: Control<MaterialInputFormEntity>;
  getValues: UseFormGetValues<MaterialInputFormEntity>;
  setValue: UseFormSetValue<MaterialInputFormEntity>;
  setDdChecked: (b: boolean) => void;
}

export const CheckboxFields = ({
  control,
  getValues,
  setValue,
  setDdChecked,
}: Props): JSX.Element => {
  const evaluationMethod = getValues('evaluationMethod');
  const displayMaterialToDeduce =
    evaluationMethod === MaterialEvaluationMethod.SURFACE ||
    evaluationMethod === MaterialEvaluationMethod.VOLUME;

  return (
    <Fragment>
      <FormattedMessage id="material-input-form.legend.pollution" />
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        divider={<Divider orientation="vertical" flexItem />}
        spacing={3}
      >
        <Stack direction="row" spacing={2}>
          <PollutionCheckbox
            control={control}
            fieldName="hasAsbestos"
            getValues={getValues}
            setValue={setValue}
            setDdChecked={setDdChecked}
          />
          <PollutionCheckbox
            control={control}
            fieldName="hasLead"
            getValues={getValues}
            setValue={setValue}
            setDdChecked={setDdChecked}
          />
          <PollutionCheckbox
            control={control}
            fieldName="hasOtherPollution"
            getValues={getValues}
            setValue={setValue}
            setDdChecked={setDdChecked}
          />
        </Stack>
        {displayMaterialToDeduce && <MaterialToDeduce control={control} />}
      </Stack>
    </Fragment>
  );
};
