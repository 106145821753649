// contracts/httpApi/asyncOperation/getAsyncOperationContract.ts
import { headersSchema } from "@afleya/common";
import { asyncOperationSchema } from "@afleya/project-schemas";
import {
  ApiGatewayContract,
  HttpStatusCodes
} from "@swarmion/serverless-contracts";
var pathParametersSchema = {
  type: "object",
  properties: {
    projectId: { type: "string", format: "uuid" },
    asyncOperationId: { type: "string", format: "uuid" }
  },
  required: ["projectId", "asyncOperationId"],
  additionalProperties: false
};
var getAsyncOperationContract = new ApiGatewayContract({
  id: "getAsyncOperationContract",
  path: "/projects/{projectId}/async-operation/{asyncOperationId}",
  method: "GET",
  integrationType: "httpApi",
  authorizerType: "cognito",
  pathParametersSchema,
  headersSchema,
  outputSchemas: {
    [HttpStatusCodes.OK]: asyncOperationSchema
  }
});

// contracts/httpApi/buildingPastOperation/createBuildingPastOperations.ts
import { OperationTypes } from "@afleya/common";
import { ApiGatewayContract as ApiGatewayContract2 } from "@swarmion/serverless-contracts";
var pathParametersSchema2 = {
  type: "object",
  properties: {
    projectId: { type: "string" },
    buildingId: { type: "string" }
  },
  required: ["projectId", "buildingId"],
  additionalProperties: false
};
var headersSchema2 = {
  type: "object",
  properties: {
    authorization: { type: "string" }
  },
  required: ["authorization"]
};
var bodySchema = {
  type: "array",
  items: {
    enum: Object.values(OperationTypes)
  }
};
var createBuildingPastOperationsContract = new ApiGatewayContract2({
  id: "createBuildingPastOperationsContract",
  path: "/project/{projectId}/building/{buildingId}/building-past-operations",
  method: "POST",
  integrationType: "httpApi",
  authorizerType: "cognito",
  pathParametersSchema: pathParametersSchema2,
  headersSchema: headersSchema2,
  bodySchema
});

// contracts/httpApi/buildingPastOperation/getBuildingPastOperations.ts
import { BuildingPastOperationSchema } from "@afleya/project-schemas";
import {
  ApiGatewayContract as ApiGatewayContract3,
  HttpStatusCodes as HttpStatusCodes2
} from "@swarmion/serverless-contracts";
var pathParametersSchema3 = {
  type: "object",
  properties: {
    projectId: { type: "string" },
    buildingId: { type: "string" }
  },
  required: ["projectId", "buildingId"],
  additionalProperties: false
};
var headersSchema3 = {
  type: "object",
  properties: {
    authorization: { type: "string" }
  },
  required: ["authorization"]
};
var outputSchema = {
  type: "array",
  items: BuildingPastOperationSchema
};
var getBuildingPastOperationsContract = new ApiGatewayContract3({
  id: "getBuildingPastOperationsContract",
  path: "/project/{projectId}/building/{buildingId}/building-past-operations",
  method: "GET",
  integrationType: "httpApi",
  authorizerType: "cognito",
  pathParametersSchema: pathParametersSchema3,
  headersSchema: headersSchema3,
  outputSchemas: { [HttpStatusCodes2.OK]: outputSchema }
});

// contracts/httpApi/createProjectOwnerOrganizationContract.ts
import {
  ApiGatewayContract as ApiGatewayContract4,
  HttpStatusCodes as HttpStatusCodes3
} from "@swarmion/serverless-contracts";
import { headersSchema as headersSchema4 } from "@afleya/common";
import {
  organizationEntitySchema,
  organizationInformationsSchema
} from "@afleya/project-schemas";
var createProjectOwnerOrganizationContract = new ApiGatewayContract4({
  id: "createProjectOwnerOrganizationContract",
  path: "/projects/project-owner-organizations",
  method: "POST",
  integrationType: "httpApi",
  authorizerType: "cognito",
  headersSchema: headersSchema4,
  bodySchema: organizationInformationsSchema,
  outputSchemas: { [HttpStatusCodes3.OK]: organizationEntitySchema }
});

// contracts/httpApi/createUserContract.ts
import { ApiGatewayContract as ApiGatewayContract5 } from "@swarmion/serverless-contracts";
var headersSchema5 = {
  type: "object",
  properties: {
    authorization: { type: "string" }
  },
  required: ["authorization"]
};
var bodySchema2 = {
  type: "object",
  properties: {
    email: { type: "string" }
  },
  required: ["email"]
};
var createUserContract = new ApiGatewayContract5({
  id: "createUserContract",
  path: "/projects/users",
  method: "POST",
  integrationType: "httpApi",
  authorizerType: "cognito",
  headersSchema: headersSchema5,
  bodySchema: bodySchema2
});

// contracts/httpApi/deleteBuildingContract.ts
import { ApiGatewayContract as ApiGatewayContract6 } from "@swarmion/serverless-contracts";
var pathParametersSchema4 = {
  type: "object",
  properties: {
    projectId: { type: "string" },
    buildingId: { type: "string" }
  },
  required: ["projectId", "buildingId"],
  additionalProperties: false
};
var headersSchema6 = {
  type: "object",
  properties: {
    authorization: { type: "string" }
  },
  required: ["authorization"]
};
var deleteBuildingContract = new ApiGatewayContract6({
  id: "deleteBuildingContract",
  path: "/projects/{projectId}/buildings/{buildingId}",
  method: "DELETE",
  integrationType: "httpApi",
  authorizerType: "cognito",
  pathParametersSchema: pathParametersSchema4,
  headersSchema: headersSchema6
});

// contracts/httpApi/deleteProjectContract.ts
import { ApiGatewayContract as ApiGatewayContract7 } from "@swarmion/serverless-contracts";
var pathParametersSchema5 = {
  type: "object",
  properties: {
    projectId: { type: "string" }
  },
  required: ["projectId"],
  additionalProperties: false
};
var headersSchema7 = {
  type: "object",
  properties: {
    authorization: { type: "string" }
  },
  required: ["authorization"]
};
var deleteProjectContract = new ApiGatewayContract7({
  id: "deleteProjectContract",
  path: "/projects/{projectId}",
  method: "DELETE",
  integrationType: "httpApi",
  authorizerType: "cognito",
  pathParametersSchema: pathParametersSchema5,
  headersSchema: headersSchema7
});

// contracts/httpApi/deleteScenarioContract.ts
import { headersSchema as headersSchema8 } from "@afleya/common";
import { ApiGatewayContract as ApiGatewayContract8 } from "@swarmion/serverless-contracts";
var pathParametersSchema6 = {
  type: "object",
  properties: {
    projectId: { type: "string", format: "uuid" },
    scenarioId: { type: "string", format: "uuid" }
  },
  required: ["projectId", "scenarioId"],
  additionalProperties: false
};
var deleteScenarioContract = new ApiGatewayContract8({
  id: "deleteScenarioContract",
  path: "/scenario/{projectId}/{scenarioId}",
  method: "DELETE",
  integrationType: "httpApi",
  authorizerType: "cognito",
  headersSchema: headersSchema8,
  pathParametersSchema: pathParametersSchema6
});

// contracts/httpApi/deleteUserContract.ts
import { ApiGatewayContract as ApiGatewayContract9 } from "@swarmion/serverless-contracts";
var pathParametersSchema7 = {
  type: "object",
  properties: {
    userId: { type: "string" }
  },
  required: ["userId"],
  additionalProperties: false
};
var headersSchema9 = {
  type: "object",
  properties: {
    authorization: { type: "string" }
  },
  required: ["authorization"]
};
var deleteUserContract = new ApiGatewayContract9({
  id: "deleteUserContract",
  path: "/projects/users/{userId}",
  method: "DELETE",
  integrationType: "httpApi",
  authorizerType: "cognito",
  pathParametersSchema: pathParametersSchema7,
  headersSchema: headersSchema9
});

// contracts/httpApi/getEnvironmentalIndicatorsContract.ts
import { headersSchema as headersSchema10 } from "@afleya/common";
import { environmentalIndicatorsSchema } from "@afleya/project-schemas";
import {
  ApiGatewayContract as ApiGatewayContract10,
  HttpStatusCodes as HttpStatusCodes4
} from "@swarmion/serverless-contracts";
var pathParametersSchema8 = {
  type: "object",
  properties: {
    projectId: { type: "string" },
    scenarioId: { type: "string" }
  },
  required: ["projectId", "scenarioId"],
  additionalProperties: false
};
var getEnvironmentalIndicatorsContract = new ApiGatewayContract10({
  id: "getEnvironmentalIndicatorsContract",
  path: "/projects/{projectId}/scenario/{scenarioId}/environmental-indicators",
  method: "GET",
  integrationType: "httpApi",
  authorizerType: "cognito",
  headersSchema: headersSchema10,
  pathParametersSchema: pathParametersSchema8,
  outputSchemas: { [HttpStatusCodes4.OK]: environmentalIndicatorsSchema }
});

// contracts/httpApi/getOrganizationInformationsBySiretContract.ts
import { headersSchema as headersSchema11 } from "@afleya/common";
import { organizationInformationsSchema as organizationInformationsSchema2 } from "@afleya/project-schemas";
import {
  ApiGatewayContract as ApiGatewayContract11,
  HttpStatusCodes as HttpStatusCodes5
} from "@swarmion/serverless-contracts";
var pathParametersSchema9 = {
  type: "object",
  properties: {
    siret: { type: "string" }
  },
  required: ["siret"],
  additionalProperties: false
};
var getOrganizationInformationsBySiretContract = new ApiGatewayContract11({
  id: "getOrganizationInformationsBySiretContract",
  path: "/projects/organization-informations/{siret}",
  method: "GET",
  integrationType: "httpApi",
  authorizerType: "cognito",
  headersSchema: headersSchema11,
  pathParametersSchema: pathParametersSchema9,
  outputSchemas: { [HttpStatusCodes5.OK]: organizationInformationsSchema2 }
});

// contracts/httpApi/getPemAndWasteByTreatmentCategoriesContract.ts
import {
  ApiGatewayContract as ApiGatewayContract12,
  HttpStatusCodes as HttpStatusCodes6
} from "@swarmion/serverless-contracts";
import { PemAndWasteByTreatmentCategoriesSchema } from "@afleya/project-schemas";
var pathParametersSchema10 = {
  type: "object",
  properties: {
    projectId: { type: "string" },
    scenarioId: { type: "string" }
  },
  required: ["projectId", "scenarioId"],
  additionalProperties: false
};
var headersSchema12 = {
  type: "object",
  properties: {
    authorization: { type: "string" }
  },
  required: ["authorization"]
};
var getPemAndWasteByTreatmentCategoriesContract = new ApiGatewayContract12({
  id: "getPemAndWasteByTreatmentCategoriesContract",
  path: "/project/{projectId}/scenario/{scenarioId}/pem-and-waste-by-treatment",
  method: "GET",
  integrationType: "httpApi",
  authorizerType: "cognito",
  pathParametersSchema: pathParametersSchema10,
  headersSchema: headersSchema12,
  outputSchemas: {
    [HttpStatusCodes6.OK]: PemAndWasteByTreatmentCategoriesSchema
  }
});

// contracts/httpApi/getProjectBuildingPartsContract.ts
import { buildingPartEntitySchema } from "@afleya/project-schemas";
import {
  ApiGatewayContract as ApiGatewayContract13,
  HttpStatusCodes as HttpStatusCodes7
} from "@swarmion/serverless-contracts";
var pathParametersSchema11 = {
  type: "object",
  properties: {
    projectId: { type: "string" }
  },
  required: ["projectId"],
  additionalProperties: false
};
var headersSchema13 = {
  type: "object",
  properties: {
    authorization: { type: "string" }
  },
  required: ["authorization"]
};
var outputSchema2 = {
  type: "array",
  items: buildingPartEntitySchema
};
var getProjectBuildingPartsContract = new ApiGatewayContract13({
  id: "getProjectBuildingPartsContract",
  path: "/projects/{projectId}/buildingParts",
  method: "GET",
  integrationType: "httpApi",
  authorizerType: "cognito",
  pathParametersSchema: pathParametersSchema11,
  headersSchema: headersSchema13,
  outputSchemas: { [HttpStatusCodes7.OK]: outputSchema2 }
});

// contracts/httpApi/getProjectCoordinatesContract.ts
import {
  ApiGatewayContract as ApiGatewayContract14,
  HttpStatusCodes as HttpStatusCodes8
} from "@swarmion/serverless-contracts";
import { projectCoordinatesSchema } from "@afleya/project-schemas";
var pathParametersSchema12 = {
  type: "object",
  properties: {
    projectId: { type: "string" }
  },
  required: ["projectId"],
  additionalProperties: false
};
var headersSchema14 = {
  type: "object",
  properties: {
    authorization: { type: "string" }
  },
  required: ["authorization"]
};
var getProjectCoordinatesContract = new ApiGatewayContract14({
  id: "getProjectCoordinatesContract",
  path: "/projects/{projectId}/coordinates",
  method: "GET",
  integrationType: "httpApi",
  authorizerType: "cognito",
  pathParametersSchema: pathParametersSchema12,
  headersSchema: headersSchema14,
  outputSchemas: { [HttpStatusCodes8.OK]: projectCoordinatesSchema }
});

// contracts/httpApi/getReportsDownloadRightContract.ts
import { headersSchema as headersSchema15 } from "@afleya/common";
import {
  ApiGatewayContract as ApiGatewayContract15,
  HttpStatusCodes as HttpStatusCodes9
} from "@swarmion/serverless-contracts";
var pathParametersSchema13 = {
  type: "object",
  properties: {
    projectId: { type: "string" },
    scenarioId: { type: "string" }
  },
  required: ["projectId", "scenarioId"],
  additionalProperties: false
};
var getReportsDownloadRightContract = new ApiGatewayContract15({
  id: "getReportsDownloadRightContract",
  path: "/scenario/{projectId}/{scenarioId}/download-right",
  method: "GET",
  integrationType: "httpApi",
  authorizerType: "cognito",
  headersSchema: headersSchema15,
  pathParametersSchema: pathParametersSchema13,
  outputSchemas: { [HttpStatusCodes9.OK]: { type: "boolean" } }
});

// contracts/httpApi/getUserProfile.ts
import { userProfileSchema } from "@afleya/project-schemas";
import {
  ApiGatewayContract as ApiGatewayContract16,
  HttpStatusCodes as HttpStatusCodes10
} from "@swarmion/serverless-contracts";
var headersSchema16 = {
  type: "object",
  properties: {
    authorization: { type: "string" }
  },
  required: ["authorization"]
};
var getUserProfileContract = new ApiGatewayContract16({
  id: "getUserProfileContract",
  path: "/projects/user-profile",
  method: "GET",
  integrationType: "httpApi",
  authorizerType: "cognito",
  headersSchema: headersSchema16,
  outputSchemas: { [HttpStatusCodes10.OK]: userProfileSchema }
});

// contracts/httpApi/listOrganizationUsersContract.ts
import { UserEntitySchema } from "@afleya/project-schemas";
import {
  ApiGatewayContract as ApiGatewayContract17,
  HttpStatusCodes as HttpStatusCodes11
} from "@swarmion/serverless-contracts";
var headersSchema17 = {
  type: "object",
  properties: {
    authorization: { type: "string" }
  },
  required: ["authorization"]
};
var outputSchema3 = {
  type: "array",
  items: UserEntitySchema
};
var listOrganizationUsersContract = new ApiGatewayContract17({
  id: "listOrganizationUsersContract",
  path: "/projects/organization-users",
  method: "GET",
  integrationType: "httpApi",
  authorizerType: "cognito",
  headersSchema: headersSchema17,
  outputSchemas: { [HttpStatusCodes11.OK]: outputSchema3 }
});

// contracts/httpApi/listProjectOwnerOrganizationsContract.ts
import { headersSchema as headersSchema18 } from "@afleya/common";
import { organizationEntitySchema as organizationEntitySchema2 } from "@afleya/project-schemas";
import {
  ApiGatewayContract as ApiGatewayContract18,
  HttpStatusCodes as HttpStatusCodes12
} from "@swarmion/serverless-contracts";
var outputSchema4 = {
  type: "array",
  items: organizationEntitySchema2
};
var listProjectOwnerOrganizationsContract = new ApiGatewayContract18({
  id: "listProjectOwnerOrganizationsContract",
  path: "/projects/project-owner-organizations",
  method: "GET",
  integrationType: "httpApi",
  authorizerType: "cognito",
  headersSchema: headersSchema18,
  outputSchemas: { [HttpStatusCodes12.OK]: outputSchema4 }
});

// contracts/httpApi/updateAllScenarioPEMsContract.ts
import { headersSchema as headersSchema19 } from "@afleya/common";
import { scenarioPEMSchema } from "@afleya/project-schemas";
import {
  ApiGatewayContract as ApiGatewayContract19,
  HttpStatusCodes as HttpStatusCodes13
} from "@swarmion/serverless-contracts";
var pathParametersSchema14 = {
  type: "object",
  properties: {
    projectId: { type: "string", format: "uuid" },
    scenarioId: { type: "string", format: "uuid" }
  },
  required: ["projectId", "scenarioId"],
  additionalProperties: false
};
var bodySchema3 = {
  type: "object",
  properties: {
    isProcessed: { type: "boolean" },
    scenarioMaterialIds: { type: "array", items: { type: "string" } }
  },
  required: ["isProcessed", "scenarioMaterialIds"],
  additionalProperties: false
};
var outputSchema5 = {
  type: "array",
  items: scenarioPEMSchema
};
var updateAllScenarioPEMsContract = new ApiGatewayContract19({
  id: "updateAllScenarioPEMsContract",
  path: "/projects/{projectId}/scenario/{scenarioId}/scenario-pem",
  method: "PUT",
  integrationType: "httpApi",
  authorizerType: "cognito",
  headersSchema: headersSchema19,
  pathParametersSchema: pathParametersSchema14,
  bodySchema: bodySchema3,
  outputSchemas: { [HttpStatusCodes13.OK]: outputSchema5 }
});

// contracts/httpApi/updateProjectReferenceScenarioContract.ts
import { headersSchema as headersSchema20 } from "@afleya/common";
import { ProjectEntitySchema } from "@afleya/project-schemas";
import {
  ApiGatewayContract as ApiGatewayContract20,
  HttpStatusCodes as HttpStatusCodes14
} from "@swarmion/serverless-contracts";
var pathParametersSchema15 = {
  type: "object",
  properties: {
    projectId: { type: "string" }
  },
  required: ["projectId"]
};
var bodySchema4 = {
  type: "object",
  properties: {
    referenceScenarioId: { type: ["string", "null"], format: "uuid" }
  },
  required: ["referenceScenarioId"]
};
var updateProjectReferenceScenarioContract = new ApiGatewayContract20({
  id: "updateProjectReferenceScenarioContract",
  path: "/project/{projectId}/reference-scenario",
  method: "PUT",
  integrationType: "httpApi",
  authorizerType: "cognito",
  headersSchema: headersSchema20,
  pathParametersSchema: pathParametersSchema15,
  bodySchema: bodySchema4,
  outputSchemas: { [HttpStatusCodes14.OK]: ProjectEntitySchema }
});

// contracts/httpApi/updateRecyclingChannelAllocationContract.ts
import { recyclingChannelAllocationEntitySchema } from "@afleya/project-schemas";
import { ApiGatewayContract as ApiGatewayContract21 } from "@swarmion/serverless-contracts";
var pathParametersSchema16 = {
  type: "object",
  properties: {
    projectId: { type: "string" }
  },
  required: ["projectId"],
  additionalProperties: false
};
var headersSchema21 = {
  type: "object",
  properties: {
    authorization: { type: "string" }
  },
  required: ["authorization"]
};
var bodySchema5 = {
  type: "object",
  properties: {
    recyclingChannelAllocationToEdit: recyclingChannelAllocationEntitySchema
  },
  required: ["recyclingChannelAllocationToEdit"]
};
var updateRecyclingChannelAllocationContract = new ApiGatewayContract21({
  id: "updateRecyclingChannelAllocationContract",
  path: "/scenario/{projectId}/recycling-channel-allocation",
  method: "PUT",
  integrationType: "httpApi",
  authorizerType: "cognito",
  pathParametersSchema: pathParametersSchema16,
  headersSchema: headersSchema21,
  bodySchema: bodySchema5
});
export {
  createBuildingPastOperationsContract,
  createProjectOwnerOrganizationContract,
  createUserContract,
  deleteBuildingContract,
  deleteProjectContract,
  deleteScenarioContract,
  deleteUserContract,
  getAsyncOperationContract,
  getBuildingPastOperationsContract,
  getEnvironmentalIndicatorsContract,
  getOrganizationInformationsBySiretContract,
  getPemAndWasteByTreatmentCategoriesContract,
  getProjectBuildingPartsContract,
  getProjectCoordinatesContract,
  getReportsDownloadRightContract,
  getUserProfileContract,
  listOrganizationUsersContract,
  listProjectOwnerOrganizationsContract,
  updateAllScenarioPEMsContract,
  updateProjectReferenceScenarioContract,
  updateRecyclingChannelAllocationContract
};
