import {
  ChangeType,
  MaterialInputCreateChange,
  MaterialInputDatabaseChange,
  MaterialInputDeleteChange,
  MaterialInputTable,
  MaterialInputUpdateChange,
} from '@afleya/sync-protocol-schemas';

import {
  ICreateChange,
  IDatabaseChange,
  IDeleteChange,
  IUpdateChange,
} from 'dexie-observable/api';
import { localMaterialInputToMaterialInputEntity } from 'services/materialInputs/localMaterialInputToMaterialInputEntity';
import { LocalMaterialInput } from 'services/materialInputs/types';

export const transformMaterialInputChangeToDatabaseChange = (
  change: IDatabaseChange,
): MaterialInputDatabaseChange | undefined => {
  switch (change.type as number) {
    case 1: // DatabaseChangeType.Create, but cannot import due to typescript issue
      return transformToMaterialInputCreateChange(change as ICreateChange);
    case 2: // DatabaseChangeType.Update, but cannot import due to typescript issue
      return transformToMaterialInputUpdateChange(change as IUpdateChange);
    default: // DatabaseChangeType.Delete, but cannot import due to typescript issue
      return transformToMaterialInputDeleteChange(change as IDeleteChange);
  }
};

const transformToMaterialInputCreateChange = (
  change: ICreateChange,
): MaterialInputCreateChange | undefined => {
  const localMaterialInput = change.obj as LocalMaterialInput;

  const materialInputToCreate =
    localMaterialInputToMaterialInputEntity(localMaterialInput);

  return localMaterialInput.loadedFromServer
    ? undefined
    : {
        type: ChangeType.Create,
        key: change.key as string,
        table: MaterialInputTable.MaterialInput,
        obj: {
          ...materialInputToCreate,
          materialToDeduce: materialInputToCreate.materialToDeduce
            ? true
            : false,
        },
      };
};

const transformToMaterialInputUpdateChange = (
  change: IUpdateChange,
): MaterialInputUpdateChange => {
  const mods = change.mods as Partial<LocalMaterialInput>;

  if (mods.materialToDeduce !== undefined) {
    return {
      type: ChangeType.Update,
      key: change.key as string,
      table: MaterialInputTable.MaterialInput,
      mods: {
        ...mods,
        materialToDeduce: mods.materialToDeduce ? true : false,
      },
    };
  } else {
    return {
      type: ChangeType.Update,
      key: change.key as string,
      table: MaterialInputTable.MaterialInput,
      mods,
    };
  }
};

const transformToMaterialInputDeleteChange = (
  change: IDeleteChange,
): MaterialInputDeleteChange => {
  return {
    type: ChangeType.Delete,
    key: change.key as string,
    table: MaterialInputTable.MaterialInput,
  };
};
