import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ReloadState {
  reload: boolean;
}

const initialState: ReloadState = { reload: false };

export const reloadSlice = createSlice({
  name: 'reload',
  initialState,
  reducers: {
    setReload: (state, action: PayloadAction<boolean>) => {
      state.reload = action.payload;
    },
  },
});

export const { setReload } = reloadSlice.actions;

export default reloadSlice.reducer;
