import { PageContent } from 'components/PageContent';
import { TableVirtuoso } from 'react-virtuoso';
import { MaterialInputForRecap } from 'services/offline/materialInputsForRecap';
import { useMemo, useState } from 'react';
import { InputModuleSummaryTableComponents } from './InputModuleSummaryTableComponents';
import {
  InputModuleSummaryTableHeader,
  OrderBy,
  SortDirection,
} from './InputModuleSummaryTableHeader';
import { InputModuleSummaryTableContent } from './InputModuleSummaryTableContent';
import { sortByNumber, sortByString } from '../libs';

interface Props {
  materialInputs: Array<MaterialInputForRecap>;
  setEditedMaterialInput: (
    newEditedMaterialInput: MaterialInputForRecap,
  ) => void;
  setShowEdit: (newShowEdit: boolean) => void;
  setShowPictures: (newShowPictures: boolean) => void;
  selectedMaterialInputs: Array<MaterialInputForRecap>;
  setSelectedMaterialInputs: (
    newSelectedMaterialInputs: Array<MaterialInputForRecap>,
  ) => void;
}

export const InputModuleSummaryPageContent = ({
  materialInputs,
  setEditedMaterialInput,
  setShowEdit,
  setShowPictures,
  selectedMaterialInputs,
  setSelectedMaterialInputs,
}: Props): JSX.Element => {
  const [orderBy, setOrderBy] = useState<OrderBy>(OrderBy.NICKNAME);
  const [sortDirection, setSortDirection] = useState<SortDirection>(
    SortDirection.ASC,
  );

  const onClickEdit = (newEditedMaterialInput: MaterialInputForRecap) => {
    setEditedMaterialInput(newEditedMaterialInput);
    setShowEdit(true);
  };
  const onClickCamera = (newEditedMaterialInput: MaterialInputForRecap) => {
    setEditedMaterialInput(newEditedMaterialInput);
    setShowPictures(true);
  };

  const onClickCheckbox = (
    isChecked: boolean,
    materialInputForRecap: MaterialInputForRecap,
  ) => {
    if (isChecked) {
      setSelectedMaterialInputs(
        selectedMaterialInputs.concat([materialInputForRecap]),
      );
    } else {
      setSelectedMaterialInputs(
        selectedMaterialInputs.filter(
          smi => smi.id !== materialInputForRecap.id,
        ),
      );
    }
  };

  const onClickHeaderCheckbox = (isChecked: boolean) => {
    if (isChecked) {
      setSelectedMaterialInputs(materialInputs);
    } else {
      setSelectedMaterialInputs([]);
    }
  };

  const sortedMaterialInputs = useMemo(() => {
    switch (orderBy) {
      case OrderBy.NICKNAME:
        return materialInputs.sort((a, b) =>
          sortByString(a.nickname, b.nickname, sortDirection),
        );
      case OrderBy.LOCATION:
        return materialInputs.sort((a, b) =>
          sortByString(a.location, b.location, sortDirection),
        );
      case OrderBy.AMOUNT:
        return materialInputs.sort((a, b) =>
          sortByNumber(a.amount, b.amount, sortDirection),
        );
      case OrderBy.REUSE_PERCENTAGE:
        return materialInputs.sort((a, b) =>
          sortByNumber(a.reusePercentage, b.reusePercentage, sortDirection),
        );
      case OrderBy.DIMENSIONS:
        return materialInputs.sort((a, b) =>
          sortByString(a.dimensions, b.dimensions, sortDirection),
        );
    }
  }, [materialInputs, orderBy, sortDirection]);

  return (
    <PageContent>
      <div style={{ height: '100vh', width: '100%' }}>
        <TableVirtuoso
          data={sortedMaterialInputs}
          components={InputModuleSummaryTableComponents}
          totalCount={materialInputs.length}
          fixedHeaderContent={() => (
            <InputModuleSummaryTableHeader
              checked={
                selectedMaterialInputs.length === materialInputs.length &&
                materialInputs.length > 0
              }
              onClickCheckbox={onClickHeaderCheckbox}
              orderBy={orderBy}
              setOrderBy={setOrderBy}
              sortDirection={sortDirection}
              setSortDirection={setSortDirection}
            />
          )}
          itemContent={(_, materialInput) => (
            <InputModuleSummaryTableContent
              checked={selectedMaterialInputs.some(
                smi => smi.id === materialInput.id,
              )}
              materialInput={materialInput}
              onClickEdit={onClickEdit}
              onClickCamera={onClickCamera}
              onClickCheckbox={onClickCheckbox}
            />
          )}
        />
      </div>
    </PageContent>
  );
};
