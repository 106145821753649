/* eslint-disable complexity */
import { InputMethod, InputUnit } from '@afleya/common';
import {
  formatInputMaterialInput,
  MaterialInputFormEntity,
} from 'services/materialInputs';
import { createLocalMaterialInput } from 'services/offline/requests/materialInput/createLocalMaterialInput';
import { getBuilding } from 'services/offline/requests/buildingAndBuildingPart/getBuilding';
import { getBuildingPart } from 'services/offline/requests/buildingAndBuildingPart/getBuildingPart';
import { Characteristic } from 'components/CreateOrEditCharacteristic/CharacteristicType';
import { createLocalCharacteristicInput } from 'services/offline/requests/materialInput/createLocalCharacteristicInput';

interface Props {
  buildingId?: string;
  buildingPartId?: string;
  formAnswers: MaterialInputFormEntity;
  inputMethod: InputMethod;
  inputUnit: InputUnit;
  materialId?: string;
  materialCategoryId?: string;
  projectId?: string;
  characteristics?: Characteristic[];
  setMaterialInputId: (newMaterialInputId: string) => void;
  setShowSuccessDrawer: (showSuccessDrawer: boolean) => void;
}

export const handleSubmitInputMaterialInputForm = async ({
  buildingId,
  buildingPartId,
  formAnswers,
  inputMethod,
  inputUnit,
  materialId,
  materialCategoryId,
  projectId,
  characteristics,
  setMaterialInputId,
  setShowSuccessDrawer,
}: Props): Promise<string> => {
  if (
    buildingId !== undefined &&
    buildingPartId !== undefined &&
    materialId !== undefined &&
    materialCategoryId !== undefined &&
    projectId !== undefined
  ) {
    const building = await getBuilding(buildingId);

    const buildingPart = await getBuildingPart(
      formAnswers.childBuildingPartId ?? buildingPartId,
    );

    const motherBuildingPart =
      buildingPart.motherBuildingPartId === null
        ? undefined
        : await getBuildingPart(buildingPart.motherBuildingPartId);

    const inputMaterialInput = formatInputMaterialInput({
      building,
      buildingPart,
      motherBuildingPart,
      formAnswers,
      inputMethod,
      inputUnit,
      materialId,
      materialCategoryId,
      projectId,
    });
    await createLocalMaterialInput(inputMaterialInput);
    setMaterialInputId(inputMaterialInput.id);
    setShowSuccessDrawer(true);

    if (characteristics) {
      await createLocalCharacteristicInput(
        characteristics,
        inputMaterialInput.id,
      );
    }

    return inputMaterialInput.id;
  }

  return '';
};
