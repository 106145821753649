import { BuildingPartEntity } from '@afleya/project-schemas';
import { materialsInputsDatabase } from '../../dexieDB';

export const listChildrenOfBuildingPart = async (
  motherBuildingPartId: string,
): Promise<BuildingPartEntity[]> => {
  const buildingParts = await materialsInputsDatabase.buildingParts
    .filter(
      buildingPart =>
        buildingPart.motherBuildingPartId === motherBuildingPartId,
    )
    .sortBy('buildingPartName');

  return buildingParts;
};
