import { deleteLocalBuildingPartMap } from './deleteLocalBuildingPartMap';
import { listBuildingPartMapIds } from './listBuildingPartMapIds';

export const deleteBuildingPartBuildingPartMaps = async (
  buildingPartId: string,
): Promise<void> => {
  const buildingPartMapIds = await listBuildingPartMapIds(buildingPartId);

  await Promise.all(
    buildingPartMapIds.map(buildingPartMapId =>
      deleteLocalBuildingPartMap(buildingPartMapId),
    ),
  );
};
