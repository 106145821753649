import { localMaterialInputIconToMaterialInputIconEntity } from 'services/materialInputIcons/localMaterialInputIconToMaterialInputIconEntity';
import { LocalMaterialInputIcon } from 'services/materialInputIcons/type';
import { listProjectMaterialInputIcons } from 'services/offline/requests/materialInput/listProjectMaterialInputIcons';
import { formatBoolean } from './formatBoolean';

export const generateMaterialInputIconsSqlExport = async (
  projectId: string,
): Promise<string> => {
  const sql = rawMaterialInputsIconExportEntitiesToSqlOffline(
    await listProjectMaterialInputIcons(projectId ? projectId : ''),
  );

  return sql;
};

const rawMaterialInputsIconExportEntitiesToSqlOffline = (
  data: LocalMaterialInputIcon[],
): string => {
  let resSql = '';
  if (data.length > 0) {
    const header = Object.keys(
      localMaterialInputIconToMaterialInputIconEntity(data[0]),
    );
    for (const row of data) {
      if (row['uploaded'] === true) {
        resSql += createUpdateSql(row, header);
      } else {
        resSql += createInsertSql(row, header);
      }
    }
  }

  return resSql;
};

const createInsertSql = (
  row: LocalMaterialInputIcon,
  header: string[],
): string => {
  const sqlHeaderInsert =
    'INSERT INTO projectsdatabase.material_input_icon (' +
    header.join(', ') +
    ')';
  const sqlFields: string[] = [];
  for (const key of header) {
    sqlFields.push(
      createSqlBodyInsert(row, key as keyof LocalMaterialInputIcon),
    );
  }

  return sqlHeaderInsert + ' VALUES\n(' + sqlFields.join(', ') + ');\n';
};

const createSqlBodyInsert = (
  row: LocalMaterialInputIcon,
  key: keyof LocalMaterialInputIcon,
): string => {
  let resSql = '';

  if (row[key] === null || row[key] === 'null') {
    resSql += 'NULL';
  } else {
    resSql +=
      typeof row[key] === 'boolean'
        ? formatBoolean(row, key)
        : '"' + String(row[key]) + '"';
  }

  return resSql;
};

const createUpdateSql = (
  row: LocalMaterialInputIcon,
  header: string[],
): string => {
  const sqlHeaderUpdate = 'UPDATE projectsdatabase.material_input_icon SET\n';
  const sqlFooterUpdate = ' WHERE id = "' + row['id'] + '";\n';
  const sqlUpdateBody = [];
  for (const key of header) {
    const sqlbody = createSqlBodyUpdate(
      row,
      key as keyof LocalMaterialInputIcon,
    );
    if (sqlbody !== '') {
      sqlUpdateBody.push(sqlbody);
    }
  }

  return sqlHeaderUpdate + sqlUpdateBody.join(', ') + sqlFooterUpdate;
};

const createSqlBodyUpdate = (
  row: LocalMaterialInputIcon,
  key: keyof LocalMaterialInputIcon,
): string => {
  let resSql = '';
  if (key !== 'id') {
    if (row[key] === undefined) {
      resSql += key + ' = NULL';
    } else {
      resSql +=
        row[key] === null || row[key] === 'null'
          ? key + ' = NULL'
          : key + ' = "' + String(row[key]) + '"';
    }
  }

  return resSql;
};
