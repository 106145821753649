import { getImageRecognitionResultContract } from '@afleya/imgrecog-contracts';
import { getAxiosRequest } from '@swarmion/serverless-contracts';
import { getJwtToken } from 'services/cognito';
import client from 'services/networking/client';

export const getImageRecognitionResult = async (
  image: string,
): Promise<string> => {
  const jwtToken = await getJwtToken();

  const { data: predictionsLabel } = await getAxiosRequest(
    getImageRecognitionResultContract,
    client,
    {
      headers: { authorization: `Bearer ${jwtToken}` },
      body: { image },
    },
  );

  return predictionsLabel;
};
