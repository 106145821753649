import { MaterialEvaluationMethod } from '@afleya/common';

export const meterToCentimeter = (
  evaluationMethod: MaterialEvaluationMethod,
  dimensions: { [x: string]: unknown },
): { [x: string]: unknown } => {
  let resultDim;
  switch (evaluationMethod) {
    case MaterialEvaluationMethod.LINEAR:
      resultDim = dimensions as {
        length: number;
      };
      resultDim.length *= 100;
      break;
    case MaterialEvaluationMethod.SURFACE:
      resultDim = dimensions as {
        length: number;
        width: number;
      };
      resultDim.length *= 100;
      resultDim.width *= 100;
      break;
    case MaterialEvaluationMethod.VOLUME:
      resultDim = dimensions as {
        length: number;
        width: number;
        height: number;
      };
      resultDim.length *= 100;
      resultDim.width *= 100;
      resultDim.height *= 100;
      break;
    default:
      resultDim = dimensions;
  }

  return resultDim;
};

export const roundDimension = (n: number): number =>
  Math.round(n * 1000) / 1000;

export const centimeterToMeter = (
  evaluationMethod: MaterialEvaluationMethod,
  dimensions: { [x: string]: unknown },
): { [x: string]: unknown } => {
  let resultDim;
  switch (evaluationMethod) {
    case MaterialEvaluationMethod.LINEAR:
      resultDim = dimensions as {
        length: number;
      };
      resultDim.length = roundDimension(resultDim.length / 100);
      break;
    case MaterialEvaluationMethod.SURFACE:
      resultDim = dimensions as {
        length: number;
        width: number;
      };
      resultDim.length = roundDimension(resultDim.length / 100);
      resultDim.width = roundDimension(resultDim.width / 100);
      break;
    case MaterialEvaluationMethod.VOLUME:
      resultDim = dimensions as {
        length: number;
        width: number;
        height: number;
      };
      resultDim.length = roundDimension(resultDim.length / 100);
      resultDim.width = roundDimension(resultDim.width / 100);
      resultDim.height = roundDimension(resultDim.height / 100);
      break;
    default:
      resultDim = dimensions;
  }

  return resultDim;
};
