import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAsync } from 'react-use';
import { beforeUnload } from 'services/beforeUnload/beforeUnload';
import { getMaterial } from 'services/offline/requests/material/getMaterial';
import { listCharacteristics } from 'services/offline/requests/material/listCharacteristics';
import { listCharacteristicValues } from 'services/offline/requests/material/listCharacteristicValues';
import { InputModuleCreateMaterialInput } from './InputModuleCreateMaterialInput';

export const InputModuleInputPage = (): JSX.Element => {
  useEffect(beforeUnload, []);

  const { projectId, buildingId, buildingPartId, materialId } = useParams<{
    projectId: string;
    buildingId: string;
    buildingPartId: string;
    materialId: string;
  }>();

  const { value } = useAsync(async () => {
    const material = await getMaterial(materialId ?? '');
    const characteristicValues = await listCharacteristicValues(
      material.categoryId,
    );
    const characteristicNames = await listCharacteristics(material.categoryId);

    return {
      material,
      characteristicValues,
      characteristicNames,
    };
  }, [materialId]);

  if (
    materialId !== undefined &&
    projectId !== undefined &&
    buildingId !== undefined &&
    buildingPartId !== undefined &&
    value?.material !== undefined
  ) {
    return (
      <InputModuleCreateMaterialInput
        projectId={projectId}
        buildingId={buildingId}
        buildingPartId={buildingPartId}
        material={value.material}
        characteristicNames={value.characteristicNames}
        characteristicValues={value.characteristicValues}
      />
    );
  }

  return <></>;
};
