import { BuildingEntity } from '@afleya/project-schemas';
import {
  ButtonSelector,
  DivSelectorButtonContainer,
  ToggleButtonGroupContainer,
} from 'services/buildingPart/DuplicateSelector.style';

interface Props {
  targetBuildingId: string;
  setTargetBuildingId: (targetBuildingId: string) => void;
  buildings: BuildingEntity[] | undefined;
  setTargetBuildingPartsId: (targetBuildingPartsId: string[]) => void;
}

export const DuplicateBuildingSelector = ({
  targetBuildingId,
  setTargetBuildingId,
  buildings,
  setTargetBuildingPartsId,
}: Props): JSX.Element => {
  return (
    <ToggleButtonGroupContainer exclusive={true}>
      {buildings?.map(building => (
        <DivSelectorButtonContainer key={building.id}>
          <ButtonSelector
            onClick={() => {
              if (targetBuildingId !== building.id) {
                setTargetBuildingId(building.id);
                setTargetBuildingPartsId([]);
              }
            }}
            color={building.id === targetBuildingId ? 'secondary' : 'primary'}
            variant={
              building.id === targetBuildingId ? 'contained' : 'outlined'
            }
          >
            {building.buildingName}
          </ButtonSelector>
        </DivSelectorButtonContainer>
      ))}
    </ToggleButtonGroupContainer>
  );
};
