import { fetchAuthSession } from 'aws-amplify/auth';

export const getUserGroups = async (): Promise<string[]> => {
  const { tokens } = await fetchAuthSession();
  if (tokens === undefined) {
    throw new Error('Error while fetching groups');
  }
  const groups = tokens.accessToken.payload['cognito:groups'];
  if (groups === null || groups === undefined) {
    return [];
  }

  return groups as string[];
};
