import { LocalBuildingPart } from 'services/buildingPart/types';
import { listProjectBuildings } from './listProjectBuildings';
import { getBuildingWithBuildingParts } from './getBuildingWithBuildingParts';

export const listProjectBuildingParts = async (
  projectId: string,
): Promise<LocalBuildingPart[]> => {
  const buildings = await listProjectBuildings(projectId);
  const buildingParts: LocalBuildingPart[] = [];
  for (const building of buildings) {
    const buildingWithParts = await getBuildingWithBuildingParts(building.id);
    buildingParts.push(...buildingWithParts.buildingParts);
  }

  return buildingParts;
};
