import { Characteristic } from 'components/CreateOrEditCharacteristic/CharacteristicType';
import { characteristicListToCharacteristics } from 'services/networking/requests/traceability/characteristicListToCharacteristics';
import { materialsInputsDatabase } from 'services/offline/dexieDB';

export const listCharacteristicInputs = async (
  materialInputId: string,
): Promise<Characteristic[]> => {
  const storedCharacteristicInputs =
    await materialsInputsDatabase.characteristicInputs
      .where({ materialInputId })
      .toArray();

  return characteristicListToCharacteristics(storedCharacteristicInputs);
};
