import { useIntl } from 'react-intl';
import { UseFormSetValue } from 'react-hook-form';
import { BuildingPartFormAnswers } from 'services/buildingPart/validationSchema';
import {
  ButtonSelector,
  DivSelectorButtonContainer,
  ToggleButtonGroupContainer,
} from 'services/buildingPart/DuplicateSelector.style';

interface Props {
  createNewBuildingPart: boolean;
  setCreateNewBuildingPart: (createNewBuildingPart: boolean) => void;
  setValue: UseFormSetValue<BuildingPartFormAnswers>;
  buildingPartName: string;
}

export const DuplicateNewBuildingPartSelector = ({
  createNewBuildingPart,
  setCreateNewBuildingPart,
  setValue,
  buildingPartName,
}: Props): JSX.Element => {
  const intl = useIntl();
  const choices = [true, false];

  return (
    <ToggleButtonGroupContainer>
      {choices.map(choice => (
        <DivSelectorButtonContainer key={choice ? '1' : '0'}>
          <ButtonSelector
            onClick={() => {
              if (choice !== createNewBuildingPart)
                setCreateNewBuildingPart(!createNewBuildingPart);

              setValue('buildingPartName', choice ? '' : buildingPartName, {
                shouldValidate: true,
              });
            }}
            color={createNewBuildingPart === choice ? 'secondary' : 'primary'}
            variant={
              createNewBuildingPart === choice ? 'contained' : 'outlined'
            }
          >
            {intl.formatMessage({
              id: choice
                ? 'input-module-duplicate-mother-building-part.yes'
                : 'input-module-duplicate-mother-building-part.no',
            })}
          </ButtonSelector>
        </DivSelectorButtonContainer>
      ))}
    </ToggleButtonGroupContainer>
  );
};
