import { BuildingPartEntity } from '@afleya/project-schemas';
import {
  ArrowDownward,
  ArrowUpward,
  ContentCopy,
  Delete,
  Edit,
  MoreVert,
} from '@mui/icons-material';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { ConfirmDialog } from 'components/ConfirmDialog';
import { MouseEvent, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes';
import { deleteBuildingPart } from 'services/offline/requests/buildingAndBuildingPart/deleteBuildingPart';
import { BuildingWithBuildingParts } from 'services/offline/requests/buildingAndBuildingPart/getBuildingWithBuildingParts';
import {
  Direction,
  swapBuildingPartFloor,
} from 'services/offline/requests/buildingAndBuildingPart/swapBuildingPartFloorLevel';
import { slugify } from 'services/slugify/slugify';
import { ListItemTextMenu } from './ListItemTextMenu';

interface Props {
  projectId: string;
  buildingPart: BuildingPartEntity;
  fetchBuildingParts: () => Promise<BuildingWithBuildingParts>;
}

export const BuildingPartMenu = ({
  projectId,
  buildingPart,
  fetchBuildingParts,
}: Props): JSX.Element => {
  const intl = useIntl();

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = (): void => {
    handleClose();
    navigate(
      routes.INPUT_MODULE_EDIT_BUILDING_PART.replace(':projectId', projectId)
        .replace(':buildingId', buildingPart.buildingId)
        .replace(':buildingPartId', buildingPart.id),
    );
  };

  const handleDelete = () => {
    handleClose();
    setConfirmOpen(true);
  };

  const handleConfirmDelete = async (): Promise<void> => {
    setConfirmOpen(false);
    await deleteBuildingPart(projectId, buildingPart.id);
    await fetchBuildingParts();
  };

  const handleCancelDelete = () => {
    setConfirmOpen(false);
  };

  const handleDuplicate = () => {
    handleClose();
    const route =
      buildingPart.motherBuildingPartId === null
        ? routes.INPUT_MODULE_DUPLICATE_MOTHER_BUILDING_PART
        : routes.INPUT_MODULE_DUPLICATE_BUILDING_PART;
    navigate(
      route
        .replace(':projectId', projectId)
        .replace(':buildingId', buildingPart.buildingId)
        .replace(':buildingPartId', buildingPart.id),
    );
  };

  const handleMove = async (direction: Direction): Promise<void> => {
    handleClose();
    await swapBuildingPartFloor({
      buildingId: buildingPart.buildingId,
      sourceBuildingPartId: buildingPart.id,
      direction,
    });
    await fetchBuildingParts();
  };

  return (
    <>
      <IconButton
        data-test={slugify('more-' + buildingPart.buildingPartName)}
        aria-label="More"
        onClick={handleClick}
      >
        <MoreVert />
      </IconButton>
      <Menu
        data-test={slugify('menu-' + buildingPart.buildingPartName)}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem data-test="editBuildingPart" onClick={handleEdit}>
          <Edit color="action" />
          <ListItemTextMenu id="input-module-menu.edit" />
        </MenuItem>
        <MenuItem data-test="duplicateBuildingPart" onClick={handleDuplicate}>
          <ContentCopy color="action" />
          <ListItemTextMenu id="input-module-menu.duplicate" />
        </MenuItem>
        <MenuItem data-test="deleteBuildingPart" onClick={handleDelete}>
          <Delete color="action" />
          <ListItemTextMenu id="input-module-menu.delete" />
        </MenuItem>
        <MenuItem
          data-test="ascendingSort"
          onClick={() => void handleMove(Direction.UP)}
        >
          <ArrowUpward color="action" />
          <ListItemTextMenu id="input-module-menu.up" />
        </MenuItem>
        <MenuItem
          data-test="descendingSort"
          onClick={() => void handleMove(Direction.DOWN)}
        >
          <ArrowDownward color="action" />
          <ListItemTextMenu id="input-module-menu.down" />
        </MenuItem>
      </Menu>

      {confirmOpen && (
        <ConfirmDialog
          title={intl.formatMessage(
            {
              id: 'input-module-menu.confirmDeleteBuildingPartTitle',
            },
            {
              buildingPartName: buildingPart.buildingPartName,
            },
          )}
          contentText={
            <FormattedMessage
              id="input-module-menu.confirmDeleteBuildingPartText"
              values={{
                buildingPartName: buildingPart.buildingPartName,
                n: () => <br />,
                b: chunks => <strong>{chunks}</strong>,
              }}
            />
          }
          confirmTitle={intl.formatMessage(
            { id: 'confirm-dialog-component.confirmText' },
            { confirmText: buildingPart.buildingPartName },
          )}
          confirmText={buildingPart.buildingPartName}
          onAgree={handleConfirmDelete}
          onDisagree={handleCancelDelete}
        />
      )}
    </>
  );
};
