import { NavigateFunction } from 'react-router-dom';
import { routes } from 'routes';

interface Props {
  materialInputId: string | undefined;
  projectId: string | undefined;
  buildingId: string | undefined;
  buildingPartId: string | undefined;
  navigate: NavigateFunction;
}

export const navigateToModuleInputMapSelect = ({
  materialInputId,
  projectId,
  buildingId,
  buildingPartId,
  navigate,
}: Props): void => {
  if (
    materialInputId === undefined ||
    projectId === undefined ||
    buildingId === undefined ||
    buildingPartId === undefined
  ) {
    console.error(
      'materialInputId, projectId, buildingId or buildingPartId is undefined',
    );

    return;
  }
  navigate(
    routes.INPUT_MODULE_INPUT_MAP_SELECT.replace(':projectId', projectId)
      .replace(':buildingId', buildingId)
      .replace(':buildingPartId', buildingPartId)
      .replace(':materialInputId', materialInputId),
  );
};
