import { Box, CircularProgress } from '@mui/material';

const LoadingBox = (): JSX.Element => (
  <Box
    height="150px"
    display="flex"
    justifyContent="center"
    alignItems="center"
  >
    <CircularProgress />
  </Box>
);

export default LoadingBox;
