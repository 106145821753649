import { localBuildingPartToBuildingPartEntity } from 'services/buildingPart/localBuildingPartToBuildingPartEntity';
import { LocalBuildingPart } from 'services/buildingPart/types';
import { listProjectBuildingParts } from 'services/offline/requests/buildingAndBuildingPart/listProjectBuildingParts';
import { formatBoolean } from './formatBoolean';

export const generateBuildingPartsSqlExport = async (
  projectId: string,
): Promise<string> => {
  const sql = BuildingPartsExportEntitiesToSqlOffline(
    await listProjectBuildingParts(projectId),
  );

  return sql;
};

const BuildingPartsExportEntitiesToSqlOffline = (
  data: LocalBuildingPart[],
): string => {
  let resSql = '';
  if (data.length > 0) {
    const header = Object.keys(localBuildingPartToBuildingPartEntity(data[0]));
    for (const row of data) {
      console.log(row);
      if (row['uploaded'] === undefined || !row['uploaded']) {
        resSql = createInsertSql(row, header, resSql);
      } else {
        resSql = createUpdateSql(row, header, resSql);
      }
    }
  }

  return resSql;
};

const createInsertSql = (
  row: LocalBuildingPart,
  header: string[],
  resSql: string,
): string => {
  const sqlHeaderInsert =
    'INSERT INTO projectsdatabase.building_part (' + header.join(', ') + ')';
  const sqlFields: string[] = [];
  for (const key of header) {
    sqlFields.push(createSqlBodyInsert(row, key as keyof LocalBuildingPart));
  }
  resSql += sqlHeaderInsert + ' VALUES\n(' + sqlFields.join(', ') + ');\n';

  return resSql;
};

const createSqlBodyInsert = (
  row: LocalBuildingPart,
  key: keyof LocalBuildingPart,
): string => {
  let resSql = '';
  if (row[key] === undefined) {
    resSql += convertUndefinedtoValidValue(key);
  } else {
    if (row[key] === null || row[key] === 'null') {
      resSql += 'NULL';
    } else {
      resSql +=
        typeof row[key] === 'boolean'
          ? '"' + formatBoolean(row, key) + '"'
          : '"' + String(row[key]) + '"';
    }
  }

  return resSql;
};

const convertUndefinedtoValidValue = (key: string): string => {
  if (key === 'materialToDeduce') {
    return '0';
  }
  if (key === 'assemblyTypeId') {
    return 'NULL';
  }

  return '';
};

const createUpdateSql = (
  row: LocalBuildingPart,
  header: string[],
  resSql: string,
): string => {
  const sqlHeaderUpdate = 'UPDATE projectsdatabase.building_part SET\n';
  const sqlFooterUpdate = ' WHERE id = "' + row['id'] + '";\n';
  resSql += sqlHeaderUpdate;
  const sqlUpdateBody = [];
  for (const key of header) {
    const sqlBody = createSqlBodyUpdate(row, key as keyof LocalBuildingPart);
    if (sqlBody !== '') {
      sqlUpdateBody.push(sqlBody);
    }
  }
  resSql += sqlUpdateBody.join(', ');
  resSql += sqlFooterUpdate;

  return resSql;
};

const createSqlBodyUpdate = (
  row: LocalBuildingPart,
  key: keyof LocalBuildingPart,
): string => {
  let resSql = '';
  switch (key) {
    case 'id':
      break;
    default:
      if (row[key] === undefined) {
        resSql += key + ' = NULL';
      } else if (typeof row[key] === 'boolean') {
        resSql += key + ' = "' + formatBoolean(row, key) + '"';
      } else {
        resSql +=
          row[key] === null || row[key] === 'null'
            ? key + ' = NULL'
            : key + ' = "' + String(row[key]) + '"';
      }
  }

  return resSql;
};
