import { materialInputsPicturesDatabase } from '../../dexieDB';

export const listMaterialInputPicturesIds = async (
  materialInputId: string,
): Promise<string[]> => {
  const materialInputPictures =
    await materialInputsPicturesDatabase.listMaterialInputPicturesIds(
      materialInputId,
    );

  return materialInputPictures;
};
