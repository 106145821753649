import { Fab, useTheme } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { RefObject } from 'react';

interface ScrollTopButtonProps {
  targetRef: RefObject<HTMLDivElement>;
}

export const ScrollTopButton = ({
  targetRef,
}: ScrollTopButtonProps): JSX.Element => {
  const theme = useTheme();
  const handleToTop = () => {
    if (targetRef.current) {
      targetRef.current.scrollTop = 0;
    }
  };

  return (
    <div style={{ marginLeft: '10px' }}>
      <Fab
        color="primary"
        aria-label="scroll top"
        onClick={handleToTop}
        size="small"
        sx={{
          width: '36px',
          height: '36px',
          '&:hover': {
            background: theme.palette.primary.main,
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
          },
        }}
      >
        <KeyboardArrowUpIcon sx={{ fontSize: '1.5rem' }} />
      </Fab>
    </div>
  );
};
