import { unifyString } from '@afleya/common';

interface ObjectWithNameAndTags {
  name: string;
  tags?: string;
}

export const filter = <T extends ObjectWithNameAndTags>(
  values: T[],
  searchTerms: string[],
): T[] => {
  return values.filter(({ name, tags }) =>
    searchTerms.reduce((alreadyHasNoMatch, currentSearchTerm) => {
      const unifiedName = unifyString(name);
      const unifiedTags = tags !== undefined ? unifyString(tags) : '';

      return (
        alreadyHasNoMatch &&
        (unifiedName.includes(unifyString(currentSearchTerm)) ||
          unifiedTags.includes(unifyString(currentSearchTerm)))
      );
    }, true),
  );
};
