import { InputModuleSummary } from 'components/InputModuleSummary';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { RootState } from 'store';

export const InputModuleBuildingPartsSummary = (): JSX.Element => {
  const navigate = useNavigate();
  const project = useSelector(
    (state: RootState) => state.offlineProject.project,
  );

  const { buildingId } = useParams<{
    projectId: string;
    buildingId: string;
  }>();

  if (project === undefined || buildingId === undefined) {
    navigate(-1);

    return <></>;
  }

  return (
    <InputModuleSummary
      project={project}
      backlinkTitleId="input-module-building-parts-page.title"
      buildingId={buildingId}
    />
  );
};
