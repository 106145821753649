import { BuildingEntity } from '@afleya/project-schemas';
import { materialsInputsDatabase } from '../../dexieDB';

export const listProjectBuildings = async (
  projectId: string,
): Promise<BuildingEntity[]> => {
  const buildings = await materialsInputsDatabase.buildings
    .where({ projectId })
    .reverse()
    .reverse()
    .sortBy('buildingName');

  return buildings;
};
