import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ScenarioTabState {
  tab: number;
}

const initialState: ScenarioTabState = { tab: 0 };

export const scenarioTabSlice = createSlice({
  name: 'scenarioTabState',
  initialState,
  reducers: {
    setScenarioTabState: (state, action: PayloadAction<number>) => {
      state.tab = action.payload;
    },
  },
});

export const { setScenarioTabState } = scenarioTabSlice.actions;

export default scenarioTabSlice.reducer;
