import { LocalMaterialInput } from 'services/materialInputs/types';
import { materialsInputsDatabase } from 'services/offline/dexieDB';
import { getBuildingPart } from '../buildingAndBuildingPart/getBuildingPart';
import { listLocalBuildingParts } from '../buildingAndBuildingPart/listLocalBuildingParts';

export const listMotherBuildingPartMaterialInputs = async (
  buildingPartId: string,
  materialCategoryId?: string,
): Promise<LocalMaterialInput[]> => {
  const motherBuildingPart = await getBuildingPart(buildingPartId);

  const buildingPartIds = (
    await listLocalBuildingParts({
      buildingId: motherBuildingPart.buildingId,
      motherBuildingPartId: buildingPartId,
    })
  ).map(bp => bp.id);
  buildingPartIds.push(buildingPartId);

  const materialInputs = await materialsInputsDatabase.materialInputs
    .where('buildingPartId')
    .anyOf(buildingPartIds)
    .sortBy('nickname');

  return materialCategoryId !== undefined
    ? materialInputs.filter(mi => mi.materialCategoryId === materialCategoryId)
    : materialInputs;
};
