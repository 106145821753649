import { unifyString } from '@afleya/common';

export const slugify = (str: string): string =>
  unifyString(
    str
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, '')
      .replace(/[\s_-]+/g, '-')
      .replace(/^-+|-+$/g, ''),
  );
