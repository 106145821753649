import { DivIcon } from 'leaflet';

const FREIGHT_ELEVATOR = '0000ba69-4734-4852-bce5-dad7e2bef1dc';
const SECURITY_SYSTEM = '064277c6-5de3-4a18-9895-708809c83857';
const METALWORK = '0bef4273-d2bc-4206-85a1-4719296f0be2';
const STONEWORK = '149f90ce-5d48-40fb-a08c-cbbcebaf031f';
const STAIRCASE_MASONRY_RAILINGS = '15b81a51-08e2-422f-8509-706476061f2d';
const STORAGE_TANK = '18fb231b-be95-45bd-b4af-977c02dd5f48	';
const INSULATION = '1c365f7b-1e79-4618-8b9d-2bdf5df65141';
const WALLS_UNDERGROUND_STRUCTURES = '1cf0c45a-3d8d-4826-9c93-05dc9159631e';
const FONDATIONS = '20b8dd54-4a68-4611-804e-4f4a03d2698a';
const ESCALATORS = '22ed84f1-e484-4b66-abe5-ff83dbd77c91';
const SUSPENDED_CEILINGS = '28186efe-92cc-4ef7-9c8d-2d1dfc50c7c6';
const ELEVATOR = '2942227b-b841-41c6-a5bc-da8ff34387bb';
const TECHNICAL_ROOF_COMPONENTS = '3b6b060e-6941-4de7-9696-8d7b6354fdab';
const OTHERS = '3fa6ceb5-9765-4b1c-912b-8fd95a1c58e6';
const FLOORS_SLABS_BALCONIES = '40a97580-f2ba-47b1-b523-43629bd76b19';
const INTERIOR_WOODWORK = '42ee947c-9536-4ff9-acc2-a99e856a8135';
const LININGS_INSULATING_MEMBRANES = '4bc5ec15-7d29-46b3-83be-8b4eef68be0f';
const EXTERNAL_NETWORK = '50bc5a09-8f09-4011-86bb-3416b39121b1';
const ROADS_PAVEMENTS = '51676e62-c584-4e47-87cc-d2878c377ce8	';
const FRONTS = '5349706a-2bd5-45f5-90fb-9fe370134eb2';
const SPECIFIC_EQUIPEMENTS_CAMERAS = '582f816d-b3c9-4621-824a-9c528b4b71c7';
const DOORS_WINDOWS = '5a11b502-1df0-4abb-9f38-e89194777646';
const ELECTRICAL_WIRING = '5fbb86a5-dfd5-436f-a6d1-dc3fe71d4107';
const HOT_WATER_PRODUCTION = '617596da-5cdc-4a6e-b174-a4380b120d91';
const FLOOR_COVERINGS = '675fd15f-b7a1-4c04-96cf-b4899fa39ab8';
const INTERNAL_DISTRIB_DRAINAGE = '6831b490-9331-4a26-8106-0b2a5829df65';
const EQUIPMENTS = '6f07ff29-e5bd-4d13-825e-0247d51f2263';
const COMPUTER_BAY = '733cb2a1-2145-47bc-bb07-b9de67fbab6a';
const CLADING_INSULATION_LINING = '74cbd899-cb2b-4a2d-8afb-03165c162302';
const OTHERS2 = '74e73971-4f14-4b4c-8872-e0a1b06dcf89';
const INTERIOR_FURNITURE = '76dac5c8-0c2c-4936-bd91-4f4f0e52c556';
const SANITARY_FIXTURES = '79dd8cbe-a339-4b8a-b061-5faa010fd768';
const BEAMS = '8aaf01fc-2cba-47e1-abdd-e0dc12d9289b';
const DECORATIVE_ELEMENTS = '99f45351-56ac-44f2-8454-024d00c2b93f';
const WALL_CEILINGS_COVERINGS = '9a78666c-4723-449f-84fa-6be1933b36a4';
const CLADING_FRAMING = '9b8e4015-7730-4985-983a-05cfa2f46cbf';
const ELECTRICAL_TERMINALS = '9d4f5dc5-91a7-4297-a8c8-6d57d89ff95f';
const GENERATOR = 'a8b64ba5-eac3-48a0-a703-1ec61376908d';
const SPRINKLERS_NETWORKS = 'a998450c-9025-4c74-9423-27106b5e99c3';
const MISC_SPECIAL_EQUIPMENT = 'b1656dbb-411b-4447-b56f-c97a9e5bacd4';
const FENCES = 'b6f5e11e-44fd-4df3-8cbd-e9b98779bfc8';
const EXTERIOR_FURNITURES = 'c43d2af3-3f18-451c-a78a-b475760bb2a1';
const HEATING_EQUIPMENT = 'c8e4d620-96a2-42d0-b0e4-2d5901603f75';
const AC_EQUIPMENTS = 'd1d9739c-2527-4caf-b1ea-b33b62fad101';
const POLES = 'd823f96d-08be-4e16-b1a7-990f2e4770a0';
const PARTITIONS = 'd8552d45-316a-4fd5-a2f8-343ad11bf3af';
const PITCHED_ROOFS = 'd97c2126-bc3f-4760-a008-455865d30e29';
const LIGHTING = 'e88e44bd-3517-4476-8221-fcaa458478ea';
const LOW_VOLTAGE_SWITCHGEAR = 'f0874bd1-8473-4e38-9bbc-323e9264ed23';
const RAISED_FLOORS = 'fa306ec9-5d57-4fdf-82c8-b18dc23482e4';
const PARAPETS = 'fb43ebc3-ae33-4a69-b92c-6fa805071e08';
const VENTILATION_EQUIPMENT = 'fb5daa6c-86be-4d4f-8070-f254c53e4e16';
const FLAT_ROOFS = 'fede9bac-4eac-4d06-bb17-57228ac108f6';

// eslint-disable-next-line complexity
export const getIconClassNameFromMotherMaterialCategoryId = (
  motherMaterialCategoryId: string,
): string => {
  switch (motherMaterialCategoryId) {
    case FREIGHT_ELEVATOR:
      return 'goldIcon';
    case SECURITY_SYSTEM:
      return 'goldIcon';
    case METALWORK:
      return 'burlyWoodIcon';
    case STONEWORK:
      return 'burlyWoodIcon';
    case STAIRCASE_MASONRY_RAILINGS:
      return 'burlyWoodIcon';
    case STORAGE_TANK:
      return 'aquamarineIcon';
    case INSULATION:
      return 'tealIcon';
    case WALLS_UNDERGROUND_STRUCTURES:
      return 'defaultIcon';
    case FONDATIONS:
      return 'defaultIcon';
    case ESCALATORS:
      return 'goldIcon';
    case SUSPENDED_CEILINGS:
      return 'defaultIcon';
    case ELEVATOR:
      return 'goldIcon';
    case TECHNICAL_ROOF_COMPONENTS:
      return 'defaultIcon';
    case OTHERS:
      return 'grayIcon';
    case FLOORS_SLABS_BALCONIES:
      return 'defaultIcon';
    case INTERIOR_WOODWORK:
      return 'burlyWoodIcon';
    case LININGS_INSULATING_MEMBRANES:
      return 'tealIcon';
    case EXTERNAL_NETWORK:
      return 'tealIcon';
    case ROADS_PAVEMENTS:
      return 'defaultIcon';
    case FRONTS:
      return 'defaultIcon';
    case SPECIFIC_EQUIPEMENTS_CAMERAS:
      return 'goldIcon';
    case DOORS_WINDOWS:
      return 'steelIcon';
    case ELECTRICAL_WIRING:
      return 'goldIcon';
    case HOT_WATER_PRODUCTION:
      return 'aquamarineIcon';
    case FLOOR_COVERINGS:
      return 'burlyWoodIcon';
    case INTERNAL_DISTRIB_DRAINAGE:
      return 'aquamarineIcon';
    case EQUIPMENTS:
      return 'goldIcon';
    case COMPUTER_BAY:
      return 'goldIcon';
    case CLADING_INSULATION_LINING:
      return 'tealIcon';
    case OTHERS2:
      return 'grayIcon';
    case INTERIOR_FURNITURE:
      return 'burlyWoodIcon';
    case SANITARY_FIXTURES:
      return 'aquamarineIcon';
    case BEAMS:
      return 'defaultIcon';
    case DECORATIVE_ELEMENTS:
      return 'burlyWoodIcon';
    case WALL_CEILINGS_COVERINGS:
      return 'burlyWoodIcon';
    case CLADING_FRAMING:
      return 'defaultIcon';
    case ELECTRICAL_TERMINALS:
      return 'goldIcon';
    case GENERATOR:
      return 'goldIcon';
    case SPRINKLERS_NETWORKS:
      return 'aquamarineIcon';
    case MISC_SPECIAL_EQUIPMENT:
      return 'goldIcon';
    case FENCES:
      return 'defaultIcon';
    case EXTERIOR_FURNITURES:
      return 'burlyWoodIcon';
    case HEATING_EQUIPMENT:
      return 'crimsonIcon';
    case AC_EQUIPMENTS:
      return 'skyBlueIcon';
    case POLES:
      return 'defaultIcon';
    case PARTITIONS:
      return 'defaultIcon';
    case PITCHED_ROOFS:
      return 'defaultIcon';
    case LIGHTING:
      return 'goldIcon';
    case LOW_VOLTAGE_SWITCHGEAR:
      return 'goldIcon';
    case RAISED_FLOORS:
      return 'burlyWoodIcon';
    case PARAPETS:
      return 'burlyWoodIcon';
    case VENTILATION_EQUIPMENT:
      return 'skyBlueIcon';
    case FLAT_ROOFS:
      return 'defaultIcon';
    default:
      return 'defaultIcon';
  }
};

export const getDivIcon = (
  isEdit: boolean,
  materialCategoryId: string,
  index: number,
): DivIcon => {
  if (isEdit) {
    return new DivIcon({
      iconSize: [30, 30],
      className:
        getIconClassNameFromMotherMaterialCategoryId(materialCategoryId),
    });
  } else {
    return new DivIcon({
      iconSize: [30, 30],
      className: 'numberIcon',
      html: (index + 1).toString(),
    });
  }
};
