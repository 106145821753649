import {
  materialInputsPicturesDatabase,
  materialsInputsDatabase,
} from '../../dexieDB';

export const deleteLocalMaterialInput = async (
  localMaterialInputId: string,
): Promise<void> => {
  await materialInputsPicturesDatabase.deletePicturesOfMaterialInput(
    localMaterialInputId,
  );
  await materialsInputsDatabase.deleteIconsOfMaterialInput(
    localMaterialInputId,
  );
  await materialsInputsDatabase.deleteCharacteristicInputsOfMaterialInput(
    localMaterialInputId,
  );
  await materialsInputsDatabase.deleteMaterialInput(localMaterialInputId);
};
