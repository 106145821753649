import { Button } from '@mui/material';

interface Props {
  onClickCharacteristic: () => void;
}

export const PlusCharacteristicButton = ({
  onClickCharacteristic,
}: Props): JSX.Element => {
  return (
    <Button
      variant="contained"
      color="primary"
      onClick={onClickCharacteristic}
      style={{
        width: '3em',
        height: '3em',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 0,
        minWidth: 0,
      }}
    >
      <span style={{ fontSize: '2em' }}>+</span>
    </Button>
  );
};
