import { Box } from '@mui/material';

interface Props {
  children: JSX.Element | JSX.Element[];
  overflow?: string;
  display?: string;
}

export const PageContent = ({
  children,
  overflow = 'hidden',
  display,
}: Props): JSX.Element => (
  <Box display={display} flexGrow={1} overflow={overflow} id="pageContent">
    {children}
  </Box>
);
