import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import App from './App';

import { store } from './store';

const container = document.getElementById('root');
if (container) {
  const rootEl = createRoot(container);
  rootEl.render(
    <Provider store={store}>
      <App />
    </Provider>,
  );
}

serviceWorkerRegistration.unregister();
