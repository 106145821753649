import { materialsDatabase } from '../../dexieDB';

export const getMotherMaterialCategoryId = async (
  materialCategoryId: string,
): Promise<string> => {
  const category = await materialsDatabase.categories.get(materialCategoryId);
  if (category) {
    return category.motherId !== null
      ? getMotherMaterialCategoryId(category.motherId)
      : category.id;
  }

  return materialCategoryId;
};
