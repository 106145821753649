import { v4 } from 'uuid';
import {
  getBuildingAndBuildingPartNames,
  InputMethod,
  InputUnit,
} from '@afleya/common';
import { BuildingEntity, BuildingPartEntity } from '@afleya/project-schemas';
import { MaterialInputFormEntity } from './MaterialInputFormEntity';
import { LocalMaterialInput } from './types';
import { formatDimensions } from './formatDimensions';

interface FormatInputMaterialInputArgs {
  building: BuildingEntity;
  buildingPart: BuildingPartEntity;
  motherBuildingPart?: BuildingPartEntity;
  formAnswers: MaterialInputFormEntity;
  inputUnit: InputUnit;
  inputMethod: InputMethod;
  materialId: string;
  materialCategoryId: string;
  projectId: string;
}

export const formatInputMaterialInput = ({
  building,
  buildingPart,
  motherBuildingPart,
  formAnswers,
  inputUnit,
  inputMethod,
  materialId,
  materialCategoryId,
  projectId,
}: FormatInputMaterialInputArgs): LocalMaterialInput => {
  const {
    nickname,
    locationDescription,
    amount,
    materialToDeduce,
    hasAsbestos,
    hasLead,
    hasOtherPollution,
    age,
    stateNewPercentage,
    stateGoodPercentage,
    stateMediumPercentage,
    stateObsoletePercentage,
    stateComments,
    assemblyTypeId,
    comments,
    evaluationMethod,
    length,
    width,
    height,
    reusePercentage,
    weightPerFunctionalUnit,
  } = formAnswers;

  const id = v4();

  const buildingId = building.id;
  const buildingPartId = buildingPart.id;
  const buildingAndBuildingPartNames = getBuildingAndBuildingPartNames(
    building.buildingName,
    buildingPart.buildingPartName,
    motherBuildingPart?.buildingPartName,
  );

  const dimensions = formatDimensions({
    evaluationMethod,
    inputMethod,
    inputUnit,
    length: Number(length),
    width: Number(width),
    height: Number(height),
  });

  return {
    id,
    projectId,
    buildingId,
    buildingPartId,
    buildingAndBuildingPartNames,
    materialId,
    materialCategoryId,
    dimensions,
    nickname,
    locationDescription: locationDescription ?? '',
    amount: Number(amount),
    reusePercentage:
      hasAsbestos || hasLead || hasOtherPollution ? 0 : reusePercentage,
    materialToDeduce,
    hasAsbestos,
    hasLead,
    hasOtherPollution,
    age,
    stateNewPercentage,
    stateGoodPercentage,
    stateMediumPercentage,
    stateObsoletePercentage,
    stateComments,
    assemblyTypeId,
    comments,
    materialEvaluationMethod: evaluationMethod,
    inputUnit,
    inputMethod,
    weightPerFunctionalUnit,
    loadedFromServer: false,
  };
};
