import { useIntl } from 'react-intl';
import { routes } from 'routes';
import PageHeader from 'components/PageHeader';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { beforeUnload } from 'services/beforeUnload/beforeUnload';
import { useAsync } from 'react-use';
import LoadingBox from 'components/LoadingBox';
import { getBuildingPart } from 'services/offline/requests/buildingAndBuildingPart/getBuildingPart';
import { EditBuildingPartSuccessDrawer } from './components';
import { EditBuildingPartForm } from './components/EditBuildingPartForm';

export const InputModuleEditBuildingPart = (): JSX.Element => {
  const { projectId, buildingId, buildingPartId } = useParams<{
    projectId: string;
    buildingId: string;
    buildingPartId: string;
  }>();
  const intl = useIntl();
  const navigate = useNavigate();

  const [editState, setEditState] = useState<boolean>(false);

  const { loading, value: isMother } = useAsync(async () => {
    return (
      (await getBuildingPart(buildingPartId ?? '')).motherBuildingPartId ===
      null
    );
  });

  useEffect(beforeUnload, []);
  if (
    projectId === undefined ||
    buildingId === undefined ||
    buildingPartId === undefined
  ) {
    navigate(-1);

    return <></>;
  }

  return loading || isMother === undefined ? (
    <LoadingBox />
  ) : (
    <>
      <PageHeader
        title={intl.formatMessage({
          id: isMother
            ? 'input-module-edit-building-part.mother-title'
            : 'input-module-edit-building-part.child-title',
        })}
        backLinkTitle={intl.formatMessage({
          id: 'input-module-building-parts-page.title',
        })}
        backLinkPath={routes.INPUT_MODULE_BUILDING_PARTS.replace(
          ':projectId',
          projectId,
        ).replace(':buildingId', buildingId)}
      />

      <EditBuildingPartForm
        setEditState={setEditState}
        buildingId={buildingId}
        buildingPartId={buildingPartId}
        isMother={isMother}
      />
      {editState && (
        <EditBuildingPartSuccessDrawer
          projectId={projectId}
          buildingId={buildingId}
          buildingPartId={buildingPartId}
        />
      )}
    </>
  );
};
