import { materialsInputsDatabase } from '../../dexieDB';
import { deleteBuildingPartContent } from './deleteBuildingPartContent';

export const deleteBuildingPart = async (
  projectId: string,
  buildingPartId: string,
): Promise<void> => {
  await deleteBuildingPartContent(projectId, buildingPartId);
  await materialsInputsDatabase.deleteBuildingPart(buildingPartId);
};
