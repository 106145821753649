import {
  ChangeType,
  CharacteristicInputCreateChange,
  CharacteristicInputDatabaseChange,
  CharacteristicInputDeleteChange,
  MaterialInputTable,
} from '@afleya/sync-protocol-schemas';

import {
  ICreateChange,
  IDatabaseChange,
  IDeleteChange,
} from 'dexie-observable/api';
import { localCharacteristicInputToCharacteristicInputEntity } from 'services/characteristicInput/localCharacteristicInputToCharacteristicInputEntity';
import { LocalCharacteristicInput } from 'services/characteristicInput/types';

export const transformCharacteristicInputChangeToDatabaseChange = (
  change: IDatabaseChange,
): CharacteristicInputDatabaseChange | undefined => {
  switch (change.type as number) {
    case 1: // DatabaseChangeType.Create, but cannot import due to typescript issue
      return transformToCharacteristicInputCreateChange(
        change as ICreateChange,
      );
    default: //DatabaseChangeType.Delete, but cannot import due to typescript issue
      return transformToCharacteristicInputDeleteChange(
        change as IDeleteChange,
      );
  }
};

const transformToCharacteristicInputCreateChange = (
  change: ICreateChange,
): CharacteristicInputCreateChange | undefined => {
  const localCharacteristicInput = change.obj as LocalCharacteristicInput;
  const characteristicInputToCreate =
    localCharacteristicInputToCharacteristicInputEntity(
      localCharacteristicInput,
    );

  return localCharacteristicInput.uploaded === false ||
    localCharacteristicInput.uploaded === undefined
    ? {
        type: ChangeType.Create,
        key: change.key as string,
        table: MaterialInputTable.CharacteristicInput,
        obj: {
          ...characteristicInputToCreate,
        },
      }
    : undefined;
};

const transformToCharacteristicInputDeleteChange = (
  change: IDeleteChange,
): CharacteristicInputDeleteChange => {
  return {
    type: ChangeType.Delete,
    key: change.key as string,
    table: MaterialInputTable.CharacteristicInput,
  };
};
