import { FooterButton, FormContainer } from 'components/Layout';
import { useForm } from 'react-hook-form';
import { PageContent } from 'components/PageContent';
import PageFooter from 'components/PageFooter';
import { FormattedMessage } from 'react-intl';
import { CircularProgress } from '@mui/material';
import { useAsyncFn } from 'react-use';
import { createLocalBuildingPart } from 'services/offline/requests/buildingAndBuildingPart/createLocalBuildingPart';
import { formatLocalBuildingPart } from 'services/buildingPart/formatLocalBuildingPart';
import {
  BuildingPartFormAnswers,
  buildingPartFormAnswersSchema,
} from 'services/buildingPart/validationSchema';
import { zodResolver } from '@hookform/resolvers/zod';
import { getLastBuildingPartFloorLevel } from 'services/offline/requests/buildingAndBuildingPart/getLastBuildingPartFloorLevel';
import { MotherBuildingPartField, NameField } from './Fields';

interface Props {
  setBuildingPartId: (newBuildingPartId: string) => void;
  buildingId: string;
}

export const CreateBuildingPartForm = ({
  setBuildingPartId,
  buildingId,
}: Props): JSX.Element => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { isValid },
  } = useForm<BuildingPartFormAnswers>({
    mode: 'onChange',
    resolver: zodResolver(buildingPartFormAnswersSchema),
    defaultValues: {
      motherBuildingPartId: undefined,
    },
  });

  const [{ loading: submitLoading }, onSubmit] = useAsyncFn(
    async (formAnswers: BuildingPartFormAnswers) => {
      const lastFloorLevel =
        (await getLastBuildingPartFloorLevel(
          buildingId,
          formAnswers.motherBuildingPartId ?? null,
        )) ?? -1;
      const localBuildingPartToCreate = formatLocalBuildingPart({
        buildingPartName: formAnswers.buildingPartName,
        motherBuildingPartId: formAnswers.motherBuildingPartId ?? null,
        buildingId,
        floorLevel: lastFloorLevel + 1,
      });

      await createLocalBuildingPart(localBuildingPartToCreate);
      setBuildingPartId(localBuildingPartToCreate.id);
    },
  );

  return (
    <>
      <PageContent>
        <FormContainer
          id="create-buildingPart-form"
          noValidate
          autoComplete="off"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmit={handleSubmit(onSubmit)}
        >
          <NameField
            control={control}
            buildingPartName=""
            setValue={setValue}
            isMother={false}
          />
          <MotherBuildingPartField
            control={control}
            buildingId={buildingId}
            buildingPartId=""
            motherBuildingId={null}
          />
        </FormContainer>
      </PageContent>
      <PageFooter>
        <FooterButton
          form="create-buildingPart-form"
          type="submit"
          color="primary"
          variant="contained"
          disabled={!isValid || submitLoading}
        >
          {submitLoading ? (
            <CircularProgress size={20} />
          ) : (
            <FormattedMessage
              id={'input-module-create-building-part.submitButton'}
            />
          )}
        </FooterButton>
      </PageFooter>
    </>
  );
};
