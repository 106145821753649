import { scaleImage } from 'services/images/scaleImage';
import { v4 as uuidV4 } from 'uuid';
import { materialInputsPicturesDatabase } from '../../dexieDB';

export const createLocalBuildingPartMap = async (
  dataUrl: string,
  buildingPartId: string,
  projectId: string,
): Promise<string> => {
  const base64 = await scaleImage(dataUrl, {
    width: 3840,
    height: 2160,
  });
  const id = uuidV4();
  console.log('image size: ', base64.length);

  await materialInputsPicturesDatabase.addMap({
    id,
    buildingPartId,
    projectId,
    name: 'plan 1',
    base64,
  });

  return id;
};
