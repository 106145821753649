import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import React from 'react';
import { RootState } from '../../store';

export const RequireAuth = (): React.ReactElement => {
  const isLogged = useSelector((state: RootState) => state.loginState.isLogged);

  return isLogged ? <Outlet /> : <Navigate to="/Login" />;
};
