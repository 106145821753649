import TextField from '@mui/material/TextField';
import { useDebounce } from 'react-use';
import { useIntl } from 'react-intl';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import { MaterialCategoryEntity } from '@afleya/material-schemas';
import { Dispatch, SetStateAction, SyntheticEvent, useState } from 'react';
import { Autocomplete } from '@mui/material';
import { filter } from 'services/filter/SearchFilter';
import getCategoryName from 'services/GetMotherCategory/getCategoryName';
import { sortCategories } from 'services/sort/sortCategories';
import { sortByMotherCategoryNames } from 'services/sort/sortByMotherCategoryNames';
import { filterCategoriesByMotherCategories } from 'services/filter/filterCategoriesByMotherCategories';
import { ExpandMore } from '@mui/icons-material';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface Props {
  selectedMotherCategories: MaterialCategoryEntity[];
  selectedDaughterCategories: MaterialCategoryEntity[];
  setSelectedDaughterCategories: Dispatch<
    SetStateAction<MaterialCategoryEntity[]>
  >;
  categories: MaterialCategoryEntity[];
}

export const DaughterCategoryFilter = ({
  selectedMotherCategories,
  selectedDaughterCategories,
  setSelectedDaughterCategories,
  categories,
}: Props): JSX.Element => {
  const intl = useIntl();

  const [debouncedSearchTerms, setDebouncedSearchTerms] = useState<string[]>(
    [],
  );
  const [searchTerm, setSearchTerm] = useState('');
  useDebounce(
    () => {
      setDebouncedSearchTerms(searchTerm.split(' '));
    },
    500,
    [searchTerm],
  );

  const handleCategoryChange = (
    _event: SyntheticEvent<Element, Event> | null,
    newValue: MaterialCategoryEntity[],
  ) => {
    setSelectedDaughterCategories(newValue);
  };

  return (
    <>
      <Autocomplete
        multiple
        limitTags={3}
        id="daughterCategoryFilterBarID"
        value={selectedDaughterCategories}
        onChange={handleCategoryChange}
        options={sortByMotherCategoryNames(
          sortCategories(
            filterCategoriesByMotherCategories(
              categories,
              selectedMotherCategories,
            ),
          ),
          categories,
        )}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        disableCloseOnSelect
        getOptionLabel={(option: MaterialCategoryEntity) => {
          return option.name;
        }}
        filterOptions={options => filter(options, debouncedSearchTerms)}
        groupBy={option => getCategoryName(categories, option.motherId)}
        renderOption={(props, option: MaterialCategoryEntity, { selected }) => {
          return (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.name}
            </li>
          );
        }}
        renderInput={params => (
          <TextField
            {...params}
            label={intl.formatMessage({
              id: 'select-category-filter.filterBox',
            })}
            onChange={event => setSearchTerm(event.target.value)}
            onBlur={() => setDebouncedSearchTerms([])}
          />
        )}
        popupIcon={<ExpandMore color="primary" />}
      />
    </>
  );
};
