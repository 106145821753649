import {
  ButtonSelector,
  DivSelectorButtonContainer,
  ToggleButtonGroupContainer,
} from 'components/MaterialInputMoveDialog/Selectors.style';

interface Data {
  id: string;
  name: string;
}

interface Props {
  data?: Array<Data>;
  handleOnClick: (id: string) => void;
  targetId: string | null;
}

export const InputModuleSummaryMoveButtonGroup = ({
  data,
  handleOnClick,
  targetId,
}: Props): JSX.Element => (
  <ToggleButtonGroupContainer exclusive={true}>
    {data?.map(d => (
      <DivSelectorButtonContainer key={d.id}>
        <ButtonSelector
          onClick={() => handleOnClick(d.id)}
          color={d.id === targetId ? 'secondary' : 'primary'}
          variant={d.id === targetId ? 'contained' : 'outlined'}
        >
          {d.name}
        </ButtonSelector>
      </DivSelectorButtonContainer>
    ))}
  </ToggleButtonGroupContainer>
);
