import { BuildingPartEntity } from '@afleya/project-schemas';
import {
  ButtonSelector,
  DivSelectorButtonContainer,
  ToggleButtonGroupContainer,
} from 'services/buildingPart/DuplicateSelector.style';

interface Props {
  targetBuildingPartsId: string[];
  setTargetBuildingPartsId: (targetBuildingPartsId: string[]) => void;
  motherBuildingParts: BuildingPartEntity[] | undefined;
}

export const DuplicateBuildingPartSelector = ({
  targetBuildingPartsId,
  setTargetBuildingPartsId,
  motherBuildingParts,
}: Props): JSX.Element => {
  return (
    <ToggleButtonGroupContainer>
      {motherBuildingParts?.map(buildingPart => (
        <DivSelectorButtonContainer key={buildingPart.id}>
          <ButtonSelector
            onClick={() => {
              if (targetBuildingPartsId.includes(buildingPart.id)) {
                setTargetBuildingPartsId(
                  targetBuildingPartsId.filter(id => id !== buildingPart.id),
                );
              } else {
                setTargetBuildingPartsId([
                  ...targetBuildingPartsId,
                  buildingPart.id,
                ]);
              }
            }}
            color={
              targetBuildingPartsId.includes(buildingPart.id)
                ? 'secondary'
                : 'primary'
            }
            variant={
              targetBuildingPartsId.includes(buildingPart.id)
                ? 'contained'
                : 'outlined'
            }
          >
            {buildingPart.buildingPartName}
          </ButtonSelector>
        </DivSelectorButtonContainer>
      ))}
    </ToggleButtonGroupContainer>
  );
};
