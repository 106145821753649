import {
  Autocomplete,
  Chip,
  createFilterOptions,
  TextField,
} from '@mui/material';
import { CharacteristicAutoComplete } from '../CharacteristicType';

interface Props {
  characteristicValues: CharacteristicAutoComplete[];
  label: string;
  initialValues: CharacteristicAutoComplete[];
  onChange: (newValues: CharacteristicAutoComplete[]) => void;
  inputCharacteristicInputs?: string[];
}

export const AutocompleteCharacteristic = ({
  characteristicValues,
  label,
  initialValues = [],
  onChange,
  inputCharacteristicInputs = [],
}: Props): JSX.Element => {
  const filter = createFilterOptions<CharacteristicAutoComplete>();

  return (
    <Autocomplete
      multiple
      value={initialValues}
      onChange={(_event, newValue) => {
        const newValues = newValue.map(option => {
          if (typeof option === 'string') {
            return {
              name: option,
            };
          }
          if (option.inputValue != null) {
            return {
              name: option.inputValue,
            };
          }

          return option;
        });

        const combinedValues = [
          ...newValues,
          ...inputCharacteristicInputs.map(item => ({ name: item })),
        ];

        const uniqueValues = Array.from(
          new Set(combinedValues.map(item => item.name)),
        )
          .map(name => combinedValues.find(item => item.name === name))
          .filter(item => item !== undefined);

        onChange(uniqueValues);
      }}
      options={characteristicValues}
      getOptionLabel={option => {
        if (typeof option === 'string') {
          return option;
        }
        if (option.inputValue != null) {
          return option.inputValue;
        }

        return option.name;
      }}
      renderOption={(props, option) => {
        return (
          <li {...props} key={`${option.inputValue}-${option.name}`}>
            {option.name}{' '}
          </li>
        );
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        const isExisting = options.some(option => inputValue === option.name);
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            inputValue,
            name: `Ajouter "${inputValue}"`,
          });
        }

        return filtered;
      }}
      clearOnBlur={true}
      freeSolo
      renderTags={(
        renderTagsValue: readonly CharacteristicAutoComplete[],
        getTagProps,
      ) =>
        renderTagsValue.map(
          (option: CharacteristicAutoComplete, index: number) => (
            <Chip
              variant="outlined"
              label={option.name}
              {...(!inputCharacteristicInputs.includes(option.name)
                ? getTagProps({ index })
                : {})}
              key={option.name}
            />
          ),
        )
      }
      renderInput={params => (
        <TextField {...params} label={label} margin="normal" fullWidth />
      )}
    />
  );
};
