import { ProjectEntity } from '@afleya/project-schemas';
import { ArrowRightAlt, ContentCopy, Delete } from '@mui/icons-material';
import { Grid2 } from '@mui/material';
import Filter from 'components/Filter';
import { CharacteristicFilter } from 'components/Filter/CharacteristicFilter';
import { FooterButton } from 'components/Layout';
import PageHeader from 'components/PageHeader';
import { useIntl } from 'react-intl';
import { useAsync } from 'react-use';
import { getBuilding } from 'services/offline/requests/buildingAndBuildingPart/getBuilding';
import { getBuildingPart } from 'services/offline/requests/buildingAndBuildingPart/getBuildingPart';

interface Props {
  project: ProjectEntity;
  backlinkTitleId: string;
  buildingId?: string;
  buildingPartId?: string;
  setFilter: (newFilter: string) => void;
  setShowDuplicate: (newShowDuplicate: boolean) => void;
  setShowMove: (newShowMove: boolean) => void;
  setShowDuplicateAndMove: (newShowDuplicateAndMove: boolean) => void;
  setShowDelete: (newShowDelete: boolean) => void;
  disabledActions: boolean;
  setCharacteristicFilter: (newCharacteristicFilter: boolean) => void;
  characteristicFilter: boolean;
}

export const InputModuleSummaryPageHeader = ({
  project,
  backlinkTitleId,
  buildingId,
  buildingPartId,
  setCharacteristicFilter,
  setFilter,
  setShowDuplicate,
  setShowMove,
  setShowDuplicateAndMove,
  setShowDelete,
  disabledActions,
  characteristicFilter,
}: Props): JSX.Element => {
  const intl = useIntl();

  const title = intl.formatMessage({ id: 'input-module-summary.title' });

  const { value: locationTitle } = useAsync(async () => {
    if (buildingId !== undefined) {
      const building = await getBuilding(buildingId);
      let location = `${project.projectName} - ${building.buildingName}`;

      if (buildingPartId !== undefined) {
        const buildingPart = await getBuildingPart(buildingPartId);

        if (buildingPart.motherBuildingPartId !== null) {
          const motherBuildingPart = await getBuildingPart(
            buildingPart.motherBuildingPartId,
          );
          location += ` - ${motherBuildingPart.buildingPartName}`;
        }

        location += ` - ${buildingPart.buildingPartName}`;
      }

      return location;
    }

    return project.projectName;
  });

  return (
    <PageHeader
      title={title}
      backLinkTitle={intl.formatMessage({ id: backlinkTitleId })}
      backLinkPath={-1}
      locationTitle={locationTitle}
    >
      <Grid2 container sx={{ marginTop: 1.5 }} spacing={1}>
        <Grid2 size={4.5}>
          <Filter
            label="input-module-summary.filter.label"
            placeholder="input-module-summary.filter.placeholder"
            setFilter={setFilter}
          />
        </Grid2>
        <Grid2 size={1.5}>
          <CharacteristicFilter
            setCharacteristicFilter={setCharacteristicFilter}
            characteristicFilter={characteristicFilter}
          />
        </Grid2>
        <Grid2 size={1.5}>
          <FooterButton
            variant="outlined"
            disabled={disabledActions}
            onClick={() => setShowDuplicate(true)}
          >
            <ContentCopy />
          </FooterButton>
        </Grid2>
        <Grid2 size={1.5}>
          <FooterButton
            variant="outlined"
            disabled={disabledActions}
            onClick={() => setShowMove(true)}
          >
            <ArrowRightAlt />
          </FooterButton>
        </Grid2>
        <Grid2 size={1.5}>
          <FooterButton
            variant="outlined"
            disabled={disabledActions}
            onClick={() => setShowDuplicateAndMove(true)}
          >
            <ContentCopy />
            <ArrowRightAlt />
          </FooterButton>
        </Grid2>
        <Grid2 size={1.5}>
          <FooterButton
            variant="outlined"
            disabled={disabledActions}
            onClick={() => setShowDelete(true)}
          >
            {disabledActions ? <Delete /> : <Delete color="error" />}
          </FooterButton>
        </Grid2>
      </Grid2>
    </PageHeader>
  );
};
