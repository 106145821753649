import { fetchAuthSession } from 'aws-amplify/auth';

export const getJwtToken = async (): Promise<string> => {
  const { tokens } = await fetchAuthSession();

  if (tokens === undefined) {
    throw new Error();
  }

  const jwtToken = tokens.accessToken.toString();

  return jwtToken;
};
