import { MaterialCategoryEntity } from '@afleya/material-schemas';
import { Box } from '@mui/material';
import BoxAlert from 'components/BoxAlert';
import LoadingBox from 'components/LoadingBox';
import { DaughterCategoryFilter } from 'pages/InputModules/InputModuleCategories/DaughterCategoryFilter';
import { MotherCategoryFilter } from 'pages/InputModules/InputModuleCategories/MotherCategoryFilter';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { useAsync, useDebounce } from 'react-use';
import { listCategories } from 'services/offline/requests/material/listCategories';
import { navigateToModuleInput } from 'services/navigation/navigateToModuleInput';
import { ListNicknamesAndMaterials } from 'pages/InputModules/InputModuleImageRecognition/components/ListNicknamesAndMaterials';

export const MaterialsSearch = ({
  searchTerm,
}: {
  searchTerm: string;
}): JSX.Element => {
  const navigate = useNavigate();
  const intl = useIntl();
  const { projectId, buildingId, buildingPartId } = useParams<{
    projectId: string;
    buildingId: string;
    buildingPartId: string;
  }>();

  const [debouncedSearchTerms, setDebouncedSearchTerms] = useState<string>('');
  useDebounce(
    () => {
      setDebouncedSearchTerms(searchTerm);
    },
    500,
    [searchTerm],
  );

  const [selectedMotherCategories, setSelectedMotherCategories] = useState<
    MaterialCategoryEntity[]
  >([]);

  const [selectedDaughterCategories, setSelectedDaughterCategories] = useState<
    MaterialCategoryEntity[]
  >([]);

  const {
    loading,
    error,
    value: fetched_data,
  } = useAsync(async () => {
    const categories = await listCategories();
    const motherCategories = categories.filter(
      category => category.motherId === null,
    );

    return { categories, motherCategories };
  });

  if (
    projectId === undefined ||
    buildingId === undefined ||
    buildingPartId === undefined
  ) {
    return <></>;
  }

  const handleNavigate = (
    materialId: string,
    categoryId: string,
    materialInputId?: string,
  ) => {
    navigateToModuleInput({
      materialInputId,
      projectId,
      buildingId,
      buildingPartId,
      categoryId,
      materialId,
      navigate,
    });
  };

  const motherCategories = fetched_data ? fetched_data.motherCategories : [];
  const categories = fetched_data ? fetched_data.categories : [];

  return (
    <>
      {loading ? (
        <LoadingBox />
      ) : error ? (
        <BoxAlert
          contentId={intl.formatMessage({
            id: 'select-mother-category-filter.loadingError',
          })}
        />
      ) : (
        <>
          <Box marginBlockStart="0.80em" marginBlockEnd="0.80em">
            <MotherCategoryFilter
              selectedMotherCategories={selectedMotherCategories}
              setSelectedMotherCategories={setSelectedMotherCategories}
              selectedDaughterCategories={selectedDaughterCategories}
              setSelectedDaughterCategories={setSelectedDaughterCategories}
              motherCategories={motherCategories}
            />
          </Box>
          <Box marginBlockStart="0.80em" marginBlockEnd="0.80em">
            <DaughterCategoryFilter
              selectedMotherCategories={selectedMotherCategories}
              selectedDaughterCategories={selectedDaughterCategories}
              setSelectedDaughterCategories={setSelectedDaughterCategories}
              categories={categories}
            />
          </Box>
          <ListNicknamesAndMaterials
            selectedMotherCategories={selectedMotherCategories}
            selectedDaughterCategories={selectedDaughterCategories}
            categories={categories}
            projectId={projectId}
            debouncedSearchTerms={debouncedSearchTerms}
            handleNavigate={handleNavigate}
            nbNicknamesToFetch={10}
            nbMaterialsToFetch={25}
          />
        </>
      )}
    </>
  );
};
