import { Checkbox, TableCell, TableRow, TableSortLabel } from '@mui/material';
import { FormattedMessage } from 'react-intl';

export enum OrderBy {
  NICKNAME,
  LOCATION,
  AMOUNT,
  REUSE_PERCENTAGE,
  DIMENSIONS,
}

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

interface Props {
  checked: boolean;
  onClickCheckbox: (isChecked: boolean) => void;
  orderBy: OrderBy;
  setOrderBy: (newOrderby: OrderBy) => void;
  sortDirection: SortDirection;
  setSortDirection: (newSortDirection: SortDirection) => void;
}

export const InputModuleSummaryTableHeader = ({
  checked,
  onClickCheckbox,
  orderBy,
  setOrderBy,
  sortDirection,
  setSortDirection,
}: Props): JSX.Element => {
  const handleLabelClick = (newOrderBy: OrderBy) => {
    if (newOrderBy !== orderBy) {
      setOrderBy(newOrderBy);
      setSortDirection(SortDirection.ASC);
    } else {
      if (sortDirection === SortDirection.ASC) {
        setSortDirection(SortDirection.DESC);
      } else {
        setSortDirection(SortDirection.ASC);
      }
    }
  };

  return (
    <TableRow
      sx={{
        backgroundColor: 'background.paper',
      }}
    >
      <TableCell
        align="left"
        sx={{
          width: '5%',
          padding: 'checkbox',
        }}
      >
        <Checkbox
          checked={checked}
          onChange={(_, isChecked) => onClickCheckbox(isChecked)}
        />
      </TableCell>
      <TableCell sx={{ width: '30%', padding: 1 }} align="left" variant="head">
        <TableSortLabel
          active={orderBy === OrderBy.NICKNAME}
          direction={sortDirection}
          onClick={() => handleLabelClick(OrderBy.NICKNAME)}
        >
          <FormattedMessage id="input-module-summary.table-header.nickname" />
        </TableSortLabel>
      </TableCell>
      <TableCell sx={{ width: '25%', padding: 1 }} align="left" variant="head">
        <TableSortLabel
          active={orderBy === OrderBy.LOCATION}
          direction={sortDirection}
          onClick={() => handleLabelClick(OrderBy.LOCATION)}
        >
          <FormattedMessage id="input-module-summary.table-header.location" />
        </TableSortLabel>
      </TableCell>
      <TableCell sx={{ padding: 1 }} align="right" variant="head">
        <TableSortLabel
          active={orderBy === OrderBy.AMOUNT}
          direction={sortDirection}
          onClick={() => handleLabelClick(OrderBy.AMOUNT)}
        >
          <FormattedMessage id="input-module-summary.table-header.amount" />
        </TableSortLabel>
      </TableCell>
      <TableCell sx={{ padding: 1 }} align="right" variant="head">
        <TableSortLabel
          active={orderBy === OrderBy.REUSE_PERCENTAGE}
          direction={sortDirection}
          onClick={() => handleLabelClick(OrderBy.REUSE_PERCENTAGE)}
        >
          <FormattedMessage id="input-module-summary.table-header.reusePercentage" />
        </TableSortLabel>
      </TableCell>
      <TableCell sx={{ padding: 1 }} align="right" variant="head">
        <TableSortLabel
          active={orderBy === OrderBy.DIMENSIONS}
          direction={sortDirection}
          onClick={() => handleLabelClick(OrderBy.DIMENSIONS)}
        >
          <FormattedMessage id="input-module-summary.table-header.dimensions" />
        </TableSortLabel>
      </TableCell>
      <TableCell sx={{ width: '12%', padding: 1 }} align="right" variant="head">
        <FormattedMessage id="input-module-summary.table-header.actions" />
      </TableCell>
    </TableRow>
  );
};
