import { Badge, Stack } from '@mui/material';
import { Sync, SyncDisabled } from '@mui/icons-material';
import { AnimatedSync } from './PageHeader.style';

interface SyncStatusProps {
  unsynchronizedOperations: number;
  syncing: boolean;
  onSync?: () => void;
  syncingDescription?: string;
}

export const SyncStatus = ({
  unsynchronizedOperations,
  syncing,
  onSync,
  syncingDescription,
}: SyncStatusProps): JSX.Element => {
  return (
    <Badge
      badgeContent={unsynchronizedOperations}
      color="error"
      max={999}
      onClick={() => syncing === false && onSync && onSync()}
      sx={{ maxWidth: '60%' }}
    >
      {syncing ? (
        <Stack sx={{ alignItems: 'center' }}>
          <AnimatedSync />
          {syncingDescription !== undefined && <div>{syncingDescription}</div>}
        </Stack>
      ) : unsynchronizedOperations === 0 ? (
        <Sync color="primary" />
      ) : (
        <SyncDisabled />
      )}
    </Badge>
  );
};
