import { LocalMaterialInputIcon } from 'services/materialInputIcons/type';
import { materialsInputsDatabase } from '../../dexieDB';

export const listMaterialInputIcons = async (
  buildingPartMapId: string,
): Promise<LocalMaterialInputIcon[]> => {
  const materialInputIcons = await materialsInputsDatabase.materialInputIcons
    .where({
      buildingPartMapId,
    })
    .toArray();

  return materialInputIcons;
};
