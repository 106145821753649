import { InputUnit } from '@afleya/common';
import { Button, ButtonGroup } from '@mui/material';
import { FormattedMessage } from 'react-intl';

interface Props {
  inputUnit: InputUnit;
  setInputUnit: (newInputUnit: InputUnit) => void;
}

export const InputUnitSelector = ({
  inputUnit,
  setInputUnit,
}: Props): JSX.Element => (
  <ButtonGroup style={{ justifyContent: 'right' }}>
    <Button
      variant={inputUnit === InputUnit.METER ? 'contained' : 'outlined'}
      onClick={() => setInputUnit(InputUnit.METER)}
    >
      <FormattedMessage id="material-input-form.label.inputUnitMeter" />
    </Button>
    <Button
      variant={inputUnit === InputUnit.CENTIMETER ? 'contained' : 'outlined'}
      onClick={() => setInputUnit(InputUnit.CENTIMETER)}
    >
      <FormattedMessage id="material-input-form.label.inputUnitCentimeter" />
    </Button>
  </ButtonGroup>
);
