/* eslint-disable max-depth */
/* eslint-disable complexity */
const ShuntingYard = (infix: string): string => {
  const output: (number | string)[] = [];
  const operator: string[] = [];
  for (let i = 0; i < infix.length; i++) {
    const token = infix[i];
    if (token === ' ') {
      continue;
    }
    const num = parseInt(token);
    if (!isNaN(num) && isFinite(num)) {
      if (i > 0) {
        const num2 = parseInt(infix[i - 1]);
        if (infix[i - 1] !== '.' && (isNaN(num2) || !isFinite(num2))) {
          output.push(' ');
        }
      }
      output.push(num);
    } else if (token === '.') {
      if (i > 0) {
        const num2 = parseInt(infix[i - 1]);
        if (!isNaN(num2) && isFinite(num2)) {
          output.push(token);
        } else {
          return '';
        }
      } else {
        return '';
      }
    } else if (
      token === '+' ||
      token === '-' ||
      token === '*' ||
      token === '/'
    ) {
      let j = operator.length - 1;
      while (
        j >= 0 &&
        (token === '+' || token === '-') &&
        (operator[j] === '*' || operator[j] === '/')
      ) {
        const op = operator.pop();
        if (op !== undefined) {
          output.push(' ');
          output.push(op);
        }
        j--;
      }
      operator.push(token);
    } else if (token === '(') {
      operator.push(token);
    } else if (token === ')') {
      let j = operator.length - 1;
      while (j >= 0 && operator[j] !== '(') {
        const op = operator.pop();
        if (op !== undefined) {
          output.push(' ');
          output.push(op);
        }
        j--;
      }
      if (j < 0) {
        return '';
      }
      operator.pop();
    } else {
      return '';
    }
  }
  let i = operator.length - 1;
  while (i >= 0) {
    const op = operator.pop();
    if (op !== undefined) {
      output.push(' ');
      output.push(op);
    }
    i--;
  }
  const result = output.join('');

  return result;
};

export default ShuntingYard;
