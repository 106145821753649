import { MaterialCategoryEntity } from '@afleya/material-schemas';
import { Dispatch, SetStateAction } from 'react';
import { getImageRecognitionResult } from 'services/networking/requests/materials/getImageRecognitionResult';
import { getDaughterCategoriesFromImageRecognitionLabelIndex } from 'services/offline/requests/material/getDaughterCategoriesFromImageRecognitionLabelIndex';
import { getImageRecognitionLabel } from 'services/offline/requests/material/getImageRecognitionLabel';
import { getImageRecognitionLabelIndex } from 'services/offline/requests/material/getImageRecognitionLabelIndex';
import { getMotherCategoriesFromImageRecognitionLabelIndex } from 'services/offline/requests/material/getMotherCategoriesFromImageRecognitionLabelIndex';

interface InferenceProps {
  imageSrc: string;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setSelectedPredictions: Dispatch<SetStateAction<string[]>>;
  setSelectedMotherCategories: Dispatch<
    SetStateAction<MaterialCategoryEntity[]>
  >;
  setSelectedDaughterCategories: Dispatch<
    SetStateAction<MaterialCategoryEntity[]>
  >;
}

export const handleImageInference = async ({
  imageSrc,
  setLoading,
  setSelectedPredictions,
  setSelectedMotherCategories,
  setSelectedDaughterCategories,
}: InferenceProps): Promise<void> => {
  setLoading(true);

  const predictionsString: string = await getImageRecognitionResult(imageSrc);
  const predictions: number[] = Array.from(predictionsString, Number);

  console.log(predictions);

  let max1 = predictions[0];
  let max2 = Number.MIN_VALUE;
  let max3 = Number.MIN_VALUE;

  let indexMax1 = 0;
  let indexMax2 = -1;
  let indexMax3 = -1;

  for (let i = 1; i < predictions.length; i++) {
    const current = predictions[i];
    if (current > max1) {
      max3 = max2;
      indexMax3 = indexMax2;

      max2 = max1;
      indexMax2 = indexMax1;

      max1 = current;
      indexMax1 = i;
    } else if (current > max2) {
      max3 = max2;
      indexMax3 = indexMax2;

      max2 = current;
      indexMax2 = i;
    } else if (current > max3) {
      max3 = current;
      indexMax3 = i;
    }
  }

  const [label1, label2, label3] = await Promise.all([
    getImageRecognitionLabel(indexMax1),
    getImageRecognitionLabel(indexMax2),
    getImageRecognitionLabel(indexMax3),
  ]);

  if (label1 !== undefined && label2 !== undefined && label3 !== undefined) {
    setSelectedPredictions([label1.name, label2.name, label3.name]);

    const labelId1 = await getImageRecognitionLabelIndex(label1.name);

    const [motherCategories, daughterCategories] = await Promise.all([
      getMotherCategoriesFromImageRecognitionLabelIndex(labelId1),
      getDaughterCategoriesFromImageRecognitionLabelIndex(labelId1),
    ]);

    setSelectedMotherCategories(motherCategories);
    setSelectedDaughterCategories(daughterCategories);
  }

  setLoading(false);
};
