import { MaterialCategoryEntity } from '@afleya/material-schemas';
import { filterCategoriesByMotherCategories } from 'services/filter/filterCategoriesByMotherCategories';
import { sortByMotherCategoryNames } from 'services/sort/sortByMotherCategoryNames';
import { sortCategories } from 'services/sort/sortCategories';

export const getNewSelectedDaughterCategories = (
  selectedMotherCategories: MaterialCategoryEntity[],
  selectedDaughterCategories: MaterialCategoryEntity[],
  categories: MaterialCategoryEntity[],
): MaterialCategoryEntity[] => {
  let newSelectedDaughterCategories: MaterialCategoryEntity[] = [];

  if (selectedMotherCategories.length === 0) {
    newSelectedDaughterCategories = categories;
  } else {
    const daughterCategories = sortByMotherCategoryNames(
      sortCategories(
        filterCategoriesByMotherCategories(
          categories,
          selectedMotherCategories,
        ),
      ),
      categories,
    );
    const daughterCategoriesNotSelected = daughterCategories.filter(
      category =>
        !selectedDaughterCategories.some(
          daughter => daughter.motherId === category.motherId,
        ),
    );
    newSelectedDaughterCategories = [
      ...selectedDaughterCategories,
      ...daughterCategoriesNotSelected,
    ];
  }

  return newSelectedDaughterCategories;
};
