import { ChangeEvent } from 'react';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { MaterialInputFormEntity } from 'services/materialInputs';

const getSumValues = (
  getValues: UseFormGetValues<MaterialInputFormEntity>,
): number => {
  return getValues([
    'stateNewPercentage',
    'stateGoodPercentage',
    'stateMediumPercentage',
    'stateObsoletePercentage',
  ]).reduce((acc, current) => (acc += current), 0);
};

export const computePercentage = (
  onChange: (...event: unknown[]) => void,
  event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  getValues: UseFormGetValues<MaterialInputFormEntity>,
  setValue: UseFormSetValue<MaterialInputFormEntity>,
): void => {
  if (!isNaN(Number(event.currentTarget.value))) {
    onChange(Number(event.currentTarget.value));
    if (event.currentTarget.id !== 'stateGoodPercentage') {
      const stateGoodPercentage = getValues('stateGoodPercentage');

      const sum = getSumValues(getValues);
      if ((sum > 100 && sum - 100 <= stateGoodPercentage) || sum < 100) {
        setValue('stateGoodPercentage', stateGoodPercentage - (sum - 100));
      }
    }
  }
};
