import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface LoginState {
  isLogged: boolean;
}

const initialState: LoginState = {
  isLogged: false,
};

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setLogged: (state, action: PayloadAction<boolean>) => {
      state.isLogged = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLogged } = loginSlice.actions;

export default loginSlice.reducer;
