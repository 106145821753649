import { Characteristic } from 'components/CreateOrEditCharacteristic/CharacteristicType';

export const updateCharacteristics = (
  prevState: Characteristic[],
  characteristicId: string,
  values: string[],
): Characteristic[] => {
  if (values.length === 1 && values[0] === '') {
    return prevState.filter(item => item.characteristicId !== characteristicId);
  }

  const index = prevState.findIndex(
    item => item.characteristicId === characteristicId,
  );

  if (index !== -1) {
    const updatedState = [...prevState];
    updatedState[index] = {
      characteristicId,
      characteristicValues: values,
    };

    return updatedState;
  }

  return [...prevState, { characteristicId, characteristicValues: values }];
};
