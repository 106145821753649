import {
  BuildingPartDatabaseChange,
  CharacteristicInputDatabaseChange,
  MaterialInputDatabaseChange,
  MaterialInputIconDatabaseChange,
  MaterialInputTable,
  stringIsMaterialInputTable,
} from '@afleya/sync-protocol-schemas';
import Dexie from 'dexie';
import { IDatabaseChange } from 'dexie-observable/api';
import {
  ApplyRemoteChangesFunction,
  IPersistedContext,
  PollContinuation,
  ReactiveContinuation,
} from 'dexie-syncable/api';
import { syncProtocol } from 'services/networking/requests/syncProtocol';
import { synchroDatabase } from '../dexieDB';
import { transformMaterialInputChangeToDatabaseChange } from './transformMaterialInputChangeToDatabaseChange';
import { updateMaterialInputUploadedStatus } from './updateMaterialInputUploadedStatus';
import { transformMaterialInputIconChangeToDatabaseChange } from './transformMaterialInputIconChangeToDatabaseChange';
import { transformBuildingPartChangeToDatabaseChange } from './transformBuildingPartChangeToDatabaseChange';
import { transformCharacteristicInputChangeToDatabaseChange } from './transformCharacteristicInputChangeToDatabaseChange';

Dexie.Syncable.registerSyncProtocol('materialInputsSyncProtocol', {
  partialsThreshold: 30,
  // eslint-disable-next-line max-params
  sync: (
    context: IPersistedContext,
    _url: string,
    _options: unknown,
    _baseRevision: unknown,
    _syncedRevision: unknown,
    changes: IDatabaseChange[],
    partial: boolean,
    applyRemoteChanges: ApplyRemoteChangesFunction,
    onChangesAccepted: () => void,
    onSuccess: (continuation: PollContinuation | ReactiveContinuation) => void,
    onError: (error: unknown, again?: number) => void,
    // Dexie forces us to use this number of params
    // eslint-disable-next-line max-params
  ) => {
    void materialInputsSyncProtocol(
      context,
      changes,
      partial,
      applyRemoteChanges,
      onChangesAccepted,
      onSuccess,
      onError,
    );
  },
});

const materialInputsSyncProtocol = async (
  context: IPersistedContext,
  changes: IDatabaseChange[],
  partial: boolean,
  applyRemoteChanges: ApplyRemoteChangesFunction,
  onChangesAccepted: () => void,
  onSuccess: (continuation: PollContinuation | ReactiveContinuation) => void,
  onError: (error: unknown, again?: number) => void,
  // eslint-disable-next-line max-params
): Promise<void> => {
  const POLL_INTERVAL = 5000; // 5 sec
  const POLL_INTERVAL_PARTIAL = 20;

  try {
    await uploadChanges(changes);
    await context.save();
    onChangesAccepted();
    await applyRemoteChanges(updateUploadedStatus(changes), 1, false);
    onSuccess({
      again: partial ? POLL_INTERVAL_PARTIAL : POLL_INTERVAL,
    });
  } catch (error) {
    console.log('materialInputsSyncProtocol error: ', error);
    onError(error, POLL_INTERVAL);
  }
};

const uploadChanges = async (changes: IDatabaseChange[]): Promise<void> => {
  console.log('nb changes to upload: ', changes.length);
  if (changes.length > 0) {
    const databaseChanges = transformToDatabaseChanges(changes);
    if (databaseChanges.length > 0) {
      const nbChanges = await syncProtocol(databaseChanges);
      synchroDatabase.decrementUnsynchronizedOperationsByAmount(nbChanges);
    }
  }
};

const transformToDatabaseChanges = (
  changes: IDatabaseChange[],
): (
  | MaterialInputDatabaseChange
  | MaterialInputIconDatabaseChange
  | BuildingPartDatabaseChange
  | CharacteristicInputDatabaseChange
)[] => {
  const materialInputChanges = changes.reduce((acc, current) => {
    if (stringIsMaterialInputTable(current.table)) {
      let change = undefined;
      switch (current.table) {
        case MaterialInputTable.MaterialInput:
          change = transformMaterialInputChangeToDatabaseChange(current);
          break;

        case MaterialInputTable.MaterialInputIcon:
          change = transformMaterialInputIconChangeToDatabaseChange(current);
          break;

        case MaterialInputTable.BuildingPart:
          change = transformBuildingPartChangeToDatabaseChange(current);
          break;

        case MaterialInputTable.CharacteristicInput:
          change = transformCharacteristicInputChangeToDatabaseChange(current);
          break;
      }
      if (change !== undefined) {
        acc.push(change);
      }
    }

    return acc;
  }, new Array<MaterialInputDatabaseChange | MaterialInputIconDatabaseChange | BuildingPartDatabaseChange | CharacteristicInputDatabaseChange>());

  return materialInputChanges;
};

const updateUploadedStatus = (
  changes: IDatabaseChange[],
): IDatabaseChange[] => {
  const materialInputTableChanges = updateMaterialInputUploadedStatus(changes);

  return materialInputTableChanges;
};
