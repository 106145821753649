import { Button, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

const ratio = 1280 / 720;
const videoHeightMargin = 150;
const videoWidthMargin = 10;

export const StackPictureDetail = styled(Stack)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'space-between',
  justifyContent: 'space-around',
  flexWrap: 'wrap',
  width: '100%',
  height: '100%',
  rowGap: theme.spacing(4),
}));

export const DivPhotoDetail = styled(Stack)(() => ({
  width:
    (window.innerWidth - videoWidthMargin) /
      (window.innerHeight - videoHeightMargin) >
    ratio
      ? (window.innerHeight - videoHeightMargin) * ratio
      : window.innerWidth - videoWidthMargin,

  height:
    (window.innerWidth - videoWidthMargin) /
      (window.innerHeight - videoHeightMargin) >
    ratio
      ? window.innerHeight - videoHeightMargin
      : (window.innerWidth - videoWidthMargin) / ratio,
}));

export const StackFooter = styled(Stack)(() => ({
  width: '100%',
}));

export const ButtonPicture = styled(Button)(() => ({
  width: '100%',
  height: '100%',
}));
