import { Button } from '@mui/material';
import { FormattedMessage } from 'react-intl';

interface Props {
  setCharacteristicFilter: (newCharacteristicFilter: boolean) => void;
  characteristicFilter: boolean;
}

export const CharacteristicFilter = ({
  setCharacteristicFilter,
  characteristicFilter,
}: Props): JSX.Element => {
  const handleClick = () => {
    setCharacteristicFilter(!characteristicFilter);
  };

  return (
    <Button
      fullWidth
      variant={characteristicFilter ? 'contained' : 'outlined'}
      onClick={handleClick}
      sx={{
        width: '100%',
        height: '100%',
      }}
    >
      <FormattedMessage id={'input-module-summary.characteristicFilter'} />
    </Button>
  );
};
