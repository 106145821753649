import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { BuildingPartEntity } from '@afleya/project-schemas';
import { Fragment } from 'react';
import { MapModuleMenu } from 'components/MapModuleMenu';
import { slugify } from 'services/slugify/slugify';
import { routes } from 'routes';
import { useNavigate } from 'react-router-dom';
import { sortBuildingPartsByFloorLevel } from 'services/projects/sortBuildingParts';
import { BuildingWithBuildingParts } from 'services/offline/requests/buildingAndBuildingPart/getBuildingWithBuildingParts';
import { BuildingPartMenu } from './BuildingPartMenu';

interface Props {
  buildingId: string;
  projectId: string;
  buildingParts: BuildingPartEntity[];
  motherBuildingPartId: string;
  fetchBuildingParts: () => Promise<BuildingWithBuildingParts>;
}

export const ListBuildingParts = ({
  buildingId,
  projectId,
  buildingParts,
  motherBuildingPartId,
  fetchBuildingParts,
}: Props): JSX.Element => {
  const navigate = useNavigate();

  return (
    <List>
      {sortBuildingPartsByFloorLevel(
        buildingParts.filter(
          buildingPart =>
            buildingPart.motherBuildingPartId === motherBuildingPartId,
        ),
      ).map(buildingPart => (
        <Fragment key={buildingPart.id}>
          <ListItem
            data-test={slugify(buildingPart.buildingPartName)}
            disableGutters
            sx={{ paddingTop: 0, paddingBottom: 0 }}
            secondaryAction={
              <>
                <MapModuleMenu
                  buildingPart={buildingPart}
                  projectId={projectId}
                />
                <BuildingPartMenu
                  projectId={projectId}
                  buildingPart={buildingPart}
                  fetchBuildingParts={fetchBuildingParts}
                />
              </>
            }
          >
            <ListItemButton
              alignItems="flex-start"
              onClick={() => {
                navigate({
                  pathname: routes.INPUT_MODULE_CATEGORIES.replace(
                    ':projectId',
                    projectId,
                  )
                    .replace(':buildingId', buildingId)
                    .replace(':buildingPartId', buildingPart.id),
                });
              }}
            >
              <ListItemText>{buildingPart.buildingPartName}</ListItemText>
            </ListItemButton>
          </ListItem>
          <Divider component="li" />
        </Fragment>
      ))}
    </List>
  );
};
