import { BuildingEntity, BuildingPartEntity } from '@afleya/project-schemas';
import { materialsInputsDatabase } from '../../dexieDB';
import { listLocalBuildingParts } from './listLocalBuildingParts';

export interface BuildingAndBuildingPart {
  building: BuildingEntity;
  buildingPart: BuildingPartEntity;
  childBuildingPartCount: number;
}

export const getBuildingAndBuildingPart = async (
  buildingId: string,
  buildingPartId: string,
): Promise<BuildingAndBuildingPart> => {
  const building = (await materialsInputsDatabase.buildings.get(
    buildingId,
  )) as BuildingEntity;

  const buildingPart = (await materialsInputsDatabase.buildingParts.get(
    buildingPartId,
  )) as BuildingPartEntity;

  const childBuildingPartCount = (
    await listLocalBuildingParts({
      buildingId,
      motherBuildingPartId: buildingPartId,
    })
  ).length;

  return { building, buildingPart, childBuildingPartCount };
};
