import { materialInputsPicturesDatabase } from '../../dexieDB';

export const listBuildingPartMapIds = async (
  buildingPartId: string,
): Promise<string[]> => {
  const buildingPartMapIds =
    (await materialInputsPicturesDatabase.buildingPartMaps
      .where({ buildingPartId })
      .primaryKeys()) as string[];

  return buildingPartMapIds;
};
