import { styled } from '@mui/material/styles';

interface Props {
  color: string;
  selected: boolean;
  selectedColor: string | null;
}

export const ColorCircle = styled('div')<Props>(
  ({ color, selected, selectedColor }) => ({
    width: 30,
    height: 30,
    borderRadius: '50%',
    background:
      color === 'multicolor'
        ? 'linear-gradient(45deg, black, red, yellow, blue, green, white)'
        : color,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    border: color === '#FFFFFF' ? '0.125em solid black' : '',
    '&::after': {
      content: selected ? '"✔"' : '""',
      fontSize: 25,
      color: color === '#000000' ? 'white' : 'black',
    },
    opacity: selectedColor === null || selected ? '100%' : '50%',
  }),
);
