import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { ColorList } from './ColorList';
import { AnalysisModuleColorCircle } from './AnalysisModuleColorCircle';

interface Props {
  initialColors: string[];
  label: string;
  onChange: (newColors: string[]) => void;
  inputCharacteristicInputs: string[];
}

export const AnalysisModuleColorCharacteristic = ({
  initialColors,
  label,
  onChange,
  inputCharacteristicInputs,
}: Props): JSX.Element => {
  const colors = ColorList();
  const [selectedColors, setSelectedColors] = useState<string[]>(initialColors);
  useEffect(() => {
    setSelectedColors(initialColors);
  }, [initialColors]);

  const handleColorClick = (color: string) => {
    setSelectedColors(prevColors => {
      const currentColors = prevColors ?? [];
      const newColors = currentColors.includes(color)
        ? currentColors.filter(c => c !== color)
        : [...currentColors, color];

      setTimeout(() => {
        onChange(newColors ?? '');
      }, 5);

      return newColors;
    });
  };

  return (
    <div style={{ marginTop: '1em' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          position: 'relative',
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: '-0.7em',
            left: '1.125em',
            backgroundColor: 'white',
            fontSize: '0.7rem',
            color: 'rgba(0, 0, 0, 1)',
            fontWeight: 400,
            fontFamily: 'Roboto, sans-serif',
          }}
        >
          {label}
        </div>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            border: '0.063em solid #c4c4c4',
            borderRadius: '0.25em',
            padding: '1em',
          }}
        >
          {colors.map(color => (
            <div
              key={color.name}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                margin: '1em',
              }}
            >
              <AnalysisModuleColorCircle
                colorName={color.name}
                colorCode={color.code}
                selected={selectedColors.includes(color.name)}
                onClick={() => handleColorClick(color.name)}
                inputCharacteristicInputs={inputCharacteristicInputs}
              />
              <Typography align="center" sx={{ fontSize: '0.7rem' }}>
                {color.name}
              </Typography>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
