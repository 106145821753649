import { SortDirection } from '../components/InputModuleSummaryTableHeader';

export const sortByString = (
  a: string,
  b: string,
  sortDirection: SortDirection = SortDirection.ASC,
): number => {
  if (sortDirection === SortDirection.ASC) {
    return a.localeCompare(b);
  } else {
    return b.localeCompare(a);
  }
};
