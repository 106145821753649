import { listMaterialsSearchEngine } from 'services/offline/requests/material/listMaterialsSearchEngine';
import { MaterialCategoryEntity } from '@afleya/material-schemas';
import { MaterialItem, TypeEnum } from './type';

interface getMaterialItemsProps {
  skip: number;
  take: number;
  filter: string;
  selectedMotherCategories: MaterialCategoryEntity[];
  selectedDaughterCategories: MaterialCategoryEntity[];
  categories: MaterialCategoryEntity[];
}

export const getMaterialItems = async ({
  skip,
  take,
  filter,
  selectedMotherCategories,
  selectedDaughterCategories,
  categories,
}: getMaterialItemsProps): Promise<MaterialItem[]> => {
  const materials = await listMaterialsSearchEngine({
    skip,
    take,
    searchTerms: filter,
    selectedMotherCategories,
    selectedDaughterCategories,
    categories,
  });

  const materialsConverted: MaterialItem[] = materials.map(material => ({
    type: TypeEnum.Material,
    name: material.name,
    tags: material.tags,
    materialId: material.id,
    categoryId: material.categoryId,
    defaultWasteCategory: material.defaultWasteCategory,
    organizationId: material.organizationId,
    isValidated: material.isValidated,
    generic: material.generic,
  }));

  return materialsConverted;
};
