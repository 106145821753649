import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface DimensionsState {
  dimensions: Record<string, string>;
}

const initialState: DimensionsState = {
  dimensions: {},
};

export const dimensionsSlice = createSlice({
  name: 'dimensions',
  initialState,
  reducers: {
    setDimensions: (
      state,
      action: PayloadAction<{ key: string; value: string }>,
    ) => {
      const { key, value } = action.payload;
      state.dimensions[key] = value;
    },
    clearDimensions: state => {
      state.dimensions = {};
    },
  },
});

// Action creators are generated for each case reducer function
export const { setDimensions, clearDimensions } = dimensionsSlice.actions;

export default dimensionsSlice.reducer;
