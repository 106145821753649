export const setCameraStream = async (
  videoRef: React.RefObject<HTMLVideoElement>,
  //  setDisableCloseButton: React.Dispatch<React.SetStateAction<boolean>>,
  setDisableCloseButton: (disable: boolean) => void,
  setDisableTakePictureButton: (disable: boolean) => void,
  setErrorMessageId: (errorMessageId: string) => void,
): Promise<(() => void) | undefined> => {
  try {
    const videoStream = await navigator.mediaDevices.getUserMedia({
      video: {
        facingMode: {
          // Use the back camera if possible
          ideal: 'environment',
        },
        // choose camera resolution
        width: { min: 720, ideal: 1280, max: 1920 },
        height: { min: 480, ideal: 720, max: 1080 },
      },
    });
    if (videoRef.current === null) {
      setDisableCloseButton(false);

      return;
    }
    videoRef.current.srcObject = videoStream;
    setDisableCloseButton(false);
    setDisableTakePictureButton(false);

    return () => {
      if (videoStream === undefined || !('getVideoTracks' in videoStream)) {
        console.error('context null or videoStream null');
        setDisableCloseButton(false);

        return;
      }
      videoStream.getVideoTracks().map(track => track.stop());
    };
  } catch (error) {
    if (error instanceof DOMException) {
      switch (error.name) {
        case 'NotAllowedError':
          setErrorMessageId('camera.unAuthorised');
          break;
      }
    } else {
      console.log({ error });
      setErrorMessageId('camera.unknwonError');
    }
    setDisableCloseButton(false);

    return;
  }
};
