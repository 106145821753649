/* eslint-disable complexity */
import { useAsyncFn } from 'react-use';
import {
  BuildingPartFormAnswers,
  buildingPartFormAnswersSchema,
} from 'services/buildingPart/validationSchema';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import PageFooter from 'components/PageFooter';
import { CircularProgress, Typography } from '@mui/material';
import { FooterButton, FormContainer } from 'components/Layout';
import { PageContent } from 'components/PageContent';
import { FormattedMessage, useIntl } from 'react-intl';
import { NameField } from 'pages/InputModules/InputModuleCreateBuildingPart/components/CreateBuildingPartForm/Fields';
import { DuplicateBuildingPartSelector } from 'pages/InputModules/InputModuleDuplicateBuildingPart/components';
import { useState } from 'react';
import { BuildingPartEntity } from '@afleya/project-schemas';
import { duplicateBuildingPart } from 'services/offline/requests/buildingAndBuildingPart/duplicateBuildingPart';
import { deleteBuildingPartContent } from 'services/offline/requests/buildingAndBuildingPart/deleteBuildingPartContent';
import { ConfirmDialog } from 'components/ConfirmDialog';
import { DuplicateNewBuildingPartSelector } from './DuplicateNewBuildingPartSelector';

interface Props {
  projectId: string;
  buildingPart: BuildingPartEntity | undefined;
  motherBuildingParts: BuildingPartEntity[];
  targetBuildingId: string;
  setTargetBuildingPartsId: (targetBuildingPartsId: string[]) => void;
  targetBuildingPartsId: string[];
  setDuplicateState: (duplicateState: boolean) => void;
}

export const DuplicateMotherBuildingPartForm = ({
  projectId,
  buildingPart,
  motherBuildingParts,
  targetBuildingId,
  setTargetBuildingPartsId,
  targetBuildingPartsId,
  setDuplicateState,
}: Props): JSX.Element => {
  const [showDialog, setShowDialog] = useState(false);
  const onDisagree = () => setShowDialog(false);
  const intl = useIntl();
  const [createNewBuildingPart, setCreateNewBuildingPart] = useState(false);

  const [{ loading: duplicating }, handleDuplicateBuildingParts] =
    useAsyncFn(async () => {
      setShowDialog(false);
      const validBuildingPartId = buildingPart?.id ?? '';
      if (validBuildingPartId && targetBuildingPartsId.length) {
        await Promise.all(
          targetBuildingPartsId.map(
            async id => await deleteBuildingPartContent(projectId, id),
          ),
        );
        await Promise.all(
          targetBuildingPartsId.map(
            async id =>
              await duplicateBuildingPart({
                projectId: projectId,
                sourceBuildingPartId: validBuildingPartId,
                targetBuildingId: targetBuildingId,
                targetBuildingPartId: id,
              }),
          ),
        );
        setDuplicateState(true);
      } else console.error('Required data is missing for duplication');
    }, [
      projectId,
      buildingPart?.id,
      targetBuildingId,
      targetBuildingPartsId,
      setDuplicateState,
    ]);

  const {
    control,
    setValue,
    formState: { isValid },
  } = useForm<BuildingPartFormAnswers>({
    mode: 'onChange',
    resolver: zodResolver(buildingPartFormAnswersSchema),
    defaultValues: { motherBuildingPartId: undefined, buildingPartName: ' ' },
  });

  const onClick = () => {
    if (!createNewBuildingPart) {
      setShowDialog(true);
    } else {
      setDuplicateState(true);
    }
  };

  return (
    <>
      <PageContent>
        <FormContainer
          id="duplicate-motherBuildingPart-form"
          noValidate
          autoComplete="off"
        >
          <Typography variant="h2" sx={{ marginTop: 3 }}>
            {intl.formatMessage({
              id: 'input-module-duplicate-mother-building-part.createNewBuildingPart',
            })}
          </Typography>
          <DuplicateNewBuildingPartSelector
            createNewBuildingPart={createNewBuildingPart}
            setCreateNewBuildingPart={setCreateNewBuildingPart}
            setValue={setValue}
            buildingPartName={buildingPart?.buildingPartName ?? ' '}
          />
          {createNewBuildingPart ? (
            <>
              <Typography variant="h2" sx={{ marginTop: 3 }}>
                {intl.formatMessage({
                  id: 'input-module-duplicate-mother-building-part.newBuildingPartName',
                })}
              </Typography>
              <NameField
                control={control}
                setValue={setValue}
                buildingPartName=""
                isMother={true}
              />
            </>
          ) : (
            <>
              <Typography variant="h2" sx={{ marginTop: 3 }}>
                {intl.formatMessage({
                  id: 'input-module-duplicate-mother-building-part.toBuildingParts',
                })}
              </Typography>
              <DuplicateBuildingPartSelector
                targetBuildingPartsId={targetBuildingPartsId}
                setTargetBuildingPartsId={setTargetBuildingPartsId}
                motherBuildingParts={motherBuildingParts
                  ?.filter(part => part.buildingId === targetBuildingId)
                  .filter(part => part.id !== buildingPart?.id)}
              />
            </>
          )}
        </FormContainer>
      </PageContent>

      <PageFooter>
        <FooterButton
          form="duplicate-motherBuildingPart-form"
          color="primary"
          variant="contained"
          onClick={onClick}
          disabled={
            !isValid ||
            (!createNewBuildingPart && !targetBuildingPartsId.length)
          }
        >
          {duplicating ? (
            <CircularProgress size={20} />
          ) : (
            <FormattedMessage id="input-module-duplicate-mother-building-part.submitButton" />
          )}
        </FooterButton>
      </PageFooter>

      {showDialog && (
        <ConfirmDialog
          title={intl.formatMessage({
            id: 'input-module-duplicate-mother-building-part.submitButton',
          })}
          contentText={
            <FormattedMessage
              id="input-module-duplicate-building.confirmBuildingPart"
              values={{
                confirmText: buildingPart?.buildingPartName,
                n: () => <br />,
                b: chunks => <strong>{chunks}</strong>,
              }}
            />
          }
          confirmTitle={
            <FormattedMessage
              id="confirm-dialog-component.confirmDuplicateText"
              values={{
                confirmDuplicateText: buildingPart?.buildingPartName ?? ' ',
              }}
            />
          }
          onAgree={handleDuplicateBuildingParts}
          confirmText={buildingPart?.buildingPartName ?? ' '}
          onDisagree={onDisagree}
          agreeing={duplicating}
        />
      )}
    </>
  );
};
