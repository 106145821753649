import { Route } from 'react-router-dom';
import { routes } from 'routes';

// Les pages du module diagnostic doivent être chargées pour fonctionner en mode offline
import { InputModuleHomePage } from 'pages/InputModules/InputModuleHomePage';
import { InputModuleBuildingParts } from 'pages/InputModules/InputModuleBuildingParts';
import { InputModuleEditMap } from 'pages/InputModules/InputModuleEditMap';
import { InputModuleCategories } from 'pages/InputModules/InputModuleCategories';
import { InputModuleDuplicateBuilding } from 'pages/InputModules/InputModuleDuplicateBuilding';
import { InputModuleCreateMaterial } from 'pages/InputModules/InputModuleCreateMaterial';
import { InputModuleCreateBuildingPart } from 'pages/InputModules/InputModuleCreateBuildingPart';
import { InputModuleInputPage } from 'pages/InputModules/InputModuleInputPage';
import { InputModuleInputPhotosPage } from 'pages/InputModules/InputModuleInputPhotosPage';
import { InputModuleInputSelectMapPage } from 'pages/InputModules/InputModuleInputSelectMapPage';
import { InputModuleEditBuildingPart } from 'pages/InputModules/InputModuleEditBuildingPart';
import { InputModuleDuplicateBuildingPart } from 'pages/InputModules/InputModuleDuplicateBuildingPart';
import { InputModuleDuplicateMotherBuildingPart } from 'pages/InputModules/InputModuleDuplicateMotherBuildingPart';
import { InputModuleHomePageSummary } from 'pages/InputModules/InputModuleHomePageSummary';
import { InputModuleBuildingPartsSummary } from 'pages/InputModules/InputModuleBuildingPartsSummary';
import { InputModuleCategoriesSummary } from 'pages/InputModules/InputModuleCategoriesSummary/InputModuleCategoriesSummary';
import { InputModuleImageRecognition } from 'pages/InputModules/InputModuleImageRecognition';

export const InputModuleRoutes = [
  <Route
    key="INPUT_MODULE_HOME_PAGE"
    path={routes.INPUT_MODULE_HOME_PAGE}
    element={<InputModuleHomePage />}
  />,
  <Route
    key="INPUT_MODULE_HOME_PAGE_SUMMARY"
    path={routes.INPUT_MODULE_HOME_PAGE_SUMMARY}
    element={<InputModuleHomePageSummary />}
  />,
  <Route
    key="INPUT_MODULE_BUILDING_PARTS"
    path={routes.INPUT_MODULE_BUILDING_PARTS}
    element={<InputModuleBuildingParts />}
  />,
  <Route
    key="INPUT_MODULE_BUILDING_PARTS_SUMMARY"
    path={routes.INPUT_MODULE_BUILDING_PARTS_SUMMARY}
    element={<InputModuleBuildingPartsSummary />}
  />,
  <Route
    key="INPUT_MODULE_EDIT_MAP"
    path={routes.INPUT_MODULE_EDIT_MAP}
    element={<InputModuleEditMap />}
  />,
  <Route
    key="INPUT_MODULE_CATEGORIES"
    path={routes.INPUT_MODULE_CATEGORIES}
    element={<InputModuleCategories />}
  />,
  <Route
    key="INPUT_MODULE_CATEGORIES_SUMMARY"
    path={routes.INPUT_MODULE_CATEGORIES_SUMMARY}
    element={<InputModuleCategoriesSummary />}
  />,
  <Route
    key="INPUT_MODULE_DUPLICATE_BUILDING"
    path={routes.INPUT_MODULE_DUPLICATE_BUILDING}
    element={<InputModuleDuplicateBuilding />}
  />,

  <Route
    key="INPUT_MODULE_CREATE_MATERIAL"
    path={routes.INPUT_MODULE_CREATE_MATERIAL}
    element={<InputModuleCreateMaterial />}
  />,
  <Route
    key="INPUT_MODULE_INPUT"
    path={routes.INPUT_MODULE_INPUT}
    element={<InputModuleInputPage />}
  />,
  <Route
    key="INPUT_MODULE_INPUT_MAP_SELECT"
    path={routes.INPUT_MODULE_INPUT_MAP_SELECT}
    element={<InputModuleInputSelectMapPage />}
  />,
  <Route
    key="INPUT_MODULE_INPUT_PHOTOS"
    path={routes.INPUT_MODULE_INPUT_PHOTOS}
    element={<InputModuleInputPhotosPage />}
  />,
  <Route
    key="INPUT_MODULE_CREATE_BUILDING_PART"
    path={routes.INPUT_MODULE_CREATE_BUILDING_PART}
    element={<InputModuleCreateBuildingPart />}
  />,
  <Route
    key="INPUT_MODULE_EDIT_BUILDING_PART"
    path={routes.INPUT_MODULE_EDIT_BUILDING_PART}
    element={<InputModuleEditBuildingPart />}
  />,
  <Route
    key="INPUT_MODULE_DUPLICATE_BUILDING_PART"
    path={routes.INPUT_MODULE_DUPLICATE_BUILDING_PART}
    element={<InputModuleDuplicateBuildingPart />}
  />,
  <Route
    key="INPUT_MODULE_DUPLICATE_MOTHER_BUILDING_PART"
    path={routes.INPUT_MODULE_DUPLICATE_MOTHER_BUILDING_PART}
    element={<InputModuleDuplicateMotherBuildingPart />}
  />,
  <Route
    key="INPUT_MODULE_IMAGE_RECOGNITION"
    path={routes.INPUT_MODULE_IMAGE_RECOGNITION}
    element={<InputModuleImageRecognition />}
  />,
];
