import { CameraAlt } from '@mui/icons-material';
import { Button, Stack } from '@mui/material';
import SuccessDrawer from 'components/SuccessDrawer';
import { FormattedMessage, useIntl } from 'react-intl';
import { DivButton, DivContainer } from './InputModuleSuccessDrawer.style';

interface Props {
  open?: boolean;
  onClose: () => void;
  onClickPhoto: () => void;
}

export const InputModuleSuccessEditDrawer = ({
  open,
  onClose,
  onClickPhoto,
}: Props): JSX.Element => {
  const intl = useIntl();

  return (
    <SuccessDrawer
      open={open}
      title={intl.formatMessage({
        id: 'input-module-summary.drawer-edit.SuccessDrawerMessage',
      })}
    >
      <DivContainer>
        <DivButton>
          <Button
            data-test="addPhoto"
            color="primary"
            variant="contained"
            onClick={onClickPhoto}
          >
            <CameraAlt fontSize="large" />
          </Button>
        </DivButton>
      </DivContainer>
      <Stack direction="row" spacing={1} justifyContent="center">
        <Button
          id="edit-another-material-button"
          color="secondary"
          variant="contained"
          onClick={onClose}
        >
          <FormattedMessage id="input-module-summary.drawer-edit.editAnotherMaterial" />
        </Button>
      </Stack>
    </SuccessDrawer>
  );
};
