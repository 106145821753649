/* eslint-disable max-lines */
import { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useAsync, useAsyncFn } from 'react-use';
import { zodResolver } from '@hookform/resolvers/zod';
import { CircularProgress } from '@mui/material';
import { InputMethod, InputUnit } from '@afleya/common';
import {
  CharacteristicsEntity,
  CharacteristicValuesEntity,
  MaterialEntity,
} from '@afleya/material-schemas';
import { CreateOrEditCharacteristic } from 'components/CreateOrEditCharacteristic';
import { FooterButton, FormContainer } from 'components/Layout';
import {
  InputSelectors,
  MaterialInputForm,
} from 'components/MaterialInputForm';
import { PageContent } from 'components/PageContent';
import PageFooter from 'components/PageFooter';
import { beforeUnload } from 'services/beforeUnload/beforeUnload';
import {
  getInputMaterialInputFormDefaultValues,
  MaterialInputFormEntity,
  materialInputFormSchema,
} from 'services/materialInputs';
import { navigateToModuleInputMapSelect } from 'services/navigation/navigateToModuleInputMapSelect';
import { navigateToModuleInputPhotos } from 'services/navigation/navigateToModuleInputPhotos';
import { getBuildingAndBuildingPart } from 'services/offline/requests/buildingAndBuildingPart/getBuildingAndBuildingPart';
import { getBuildingPart } from 'services/offline/requests/buildingAndBuildingPart/getBuildingPart';
import { Characteristic } from 'components/CreateOrEditCharacteristic/CharacteristicType';
import { PlusCharacteristicButton } from 'components/PlusButtonCharacteristic';
import { clearInputImageRecognition } from 'services/store/inputImageRecognition';
import { store } from 'store';
import { createLocalMaterialInputPicture } from 'services/offline/requests/materialInput/createLocalMaterialInputPicture';
import {
  InputModuleInputPageHeader,
  InputModuleSuccessDrawer,
} from './components';
import { handleSubmitInputMaterialInputForm } from './libs';

interface Props {
  projectId: string;
  buildingId: string;
  buildingPartId: string;
  material: MaterialEntity;
  characteristicNames: CharacteristicsEntity[];
  characteristicValues: CharacteristicValuesEntity[];
}

export const InputModuleCreateMaterialInput = ({
  projectId,
  buildingId,
  buildingPartId,
  material,
  characteristicNames,
  characteristicValues,
}: Props): JSX.Element => {
  useEffect(beforeUnload, []);
  const navigate = useNavigate();

  const [inputUnit, setInputUnit] = useState<InputUnit>(InputUnit.METER);
  const [inputMethod, setInputMethod] = useState<InputMethod>(
    InputMethod.DIMENSIONS,
  );

  const [materialInputId, setMaterialInputId] = useState<string>();
  const [showSuccessDrawer, setShowSuccessDrawer] = useState<boolean>(false);

  const { value: buildingAndBuildingPart } = useAsync(
    async () =>
      await getBuildingAndBuildingPart(buildingId ?? '', buildingPartId ?? ''),
    [buildingId, buildingPartId],
  );

  const [characteristics, setCharacteristics] = useState<Characteristic[]>([]);
  const [editCharacteristic, setEditCharacteristic] = useState<boolean>(false);
  const onClickCharacteristic = () => {
    setEditCharacteristic(true);
  };

  const { value: motherBuildingPartName } = useAsync(async () => {
    const motherBuildingPartId =
      buildingAndBuildingPart?.buildingPart.motherBuildingPartId;

    return typeof motherBuildingPartId === 'string'
      ? (await getBuildingPart(motherBuildingPartId)).buildingPartName
      : undefined;
  }, [buildingAndBuildingPart]);

  const {
    control,
    formState: { errors, isValidating },
    getValues,
    setValue,
    handleSubmit,
  } = useForm<MaterialInputFormEntity>({
    resolver: zodResolver(materialInputFormSchema),
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: () => getInputMaterialInputFormDefaultValues(material),
  });

  const [{ loading: isSubmitting }, onSubmit] = useAsyncFn(
    async (formAnswers: MaterialInputFormEntity) => {
      const inputFormMaterialInputId = await handleSubmitInputMaterialInputForm(
        {
          buildingId,
          buildingPartId,
          formAnswers,
          inputMethod,
          inputUnit,
          materialId: material.id,
          materialCategoryId: material.categoryId,
          projectId,
          characteristics,
          setMaterialInputId,
          setShowSuccessDrawer,
        },
      );

      if (
        store.getState().inputImageRecognition.image !== '' &&
        inputFormMaterialInputId !== undefined
      ) {
        await createLocalMaterialInputPicture(
          store.getState().inputImageRecognition.image,
          inputFormMaterialInputId,
          projectId,
        );
      }
      store.dispatch(clearInputImageRecognition());
    },
    [
      buildingId,
      buildingPartId,
      inputMethod,
      inputUnit,
      material.id,
      material.categoryId,
      projectId,
      characteristics,
    ],
  );

  const onCloseSuccessDrawer = () => {
    if (inputMethod === InputMethod.DIMENSIONS) {
      setValue('length', '');
      setValue('width', '');
      setValue('height', '');
    } else {
      setValue('length', '');
    }
    setShowSuccessDrawer(false);
    setCharacteristics([]);
  };

  const onOpenCamera = () => {
    navigateToModuleInputPhotos({
      materialInputId,
      projectId,
      buildingId,
      buildingPartId,
      navigate,
    });
  };

  const onOpenPlan = () => {
    navigateToModuleInputMapSelect({
      materialInputId,
      projectId,
      buildingId,
      buildingPartId,
      navigate,
    });
  };

  return (
    <Fragment>
      <InputModuleInputPageHeader
        building={buildingAndBuildingPart?.building}
        buildingPart={buildingAndBuildingPart?.buildingPart}
        motherBuildingPartName={motherBuildingPartName}
        material={material}
        projectId={projectId}
      />
      <InputSelectors
        getValues={getValues}
        setValue={setValue}
        inputMethod={inputMethod}
        setInputMethod={setInputMethod}
        inputUnit={inputUnit}
        setInputUnit={setInputUnit}
      />
      <PageContent>
        <FormContainer
          id="input-material-input-form"
          noValidate
          autoComplete="off"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmit={handleSubmit(onSubmit)}
        >
          <MaterialInputForm
            buildingAndBuildingPart={buildingAndBuildingPart}
            control={control}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
            inputMethod={inputMethod}
            inputUnit={inputUnit}
            projectId={projectId}
            material={material}
          />
        </FormContainer>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {characteristicNames.length > 0 && (
            <PlusCharacteristicButton
              onClickCharacteristic={onClickCharacteristic}
            ></PlusCharacteristicButton>
          )}
        </div>
      </PageContent>
      <PageFooter>
        <FooterButton
          data-test="submit"
          form="input-material-input-form"
          type="submit"
          color="primary"
          variant="contained"
          disabled={isValidating || isSubmitting}
          onClick={() => window.scrollTo({ top: 0, left: 0 })}
        >
          {isValidating || isSubmitting ? (
            <CircularProgress size={20} />
          ) : (
            <FormattedMessage id="input-module-input-page.footerButton" />
          )}
        </FooterButton>
      </PageFooter>
      {editCharacteristic && (
        <CreateOrEditCharacteristic
          characteristics={characteristics}
          setCharacteristics={setCharacteristics}
          setDisplayCreateCharacteristic={setEditCharacteristic}
          materialName={getValues('nickname')}
          characteristicNames={characteristicNames}
          characteristicValues={characteristicValues}
          materialId={material.id}
          projectId={projectId}
        />
      )}
      <InputModuleSuccessDrawer
        open={showSuccessDrawer}
        onAddIdenticalClick={onCloseSuccessDrawer}
        projectId={projectId}
        buildingId={buildingId}
        buildingPartId={buildingPartId}
        openCamera={onOpenCamera}
        openPlan={onOpenPlan}
      />
    </Fragment>
  );
};
