/* eslint-disable complexity */
import {
  CharacteristicsEntity,
  CharacteristicValuesEntity,
} from '@afleya/material-schemas';
import { ColorCharacteristic } from './ColorCharacteristic';
import { RenderAutocompleteCharacteristics } from './RenderAutocompleteCharacteristics';
import { Characteristic } from '../CharacteristicType';
import { CharacteristicDivider } from './Characteristic.style';
import { AnalysisModuleColorCharacteristic } from './AnalysisModuleColorCharacteristic';
import { groupedByPosition } from '../../../services/characteristic/GroupedByPosition';

interface Props {
  characteristics: CharacteristicsEntity[];
  characteristicValues: CharacteristicValuesEntity[];
  tempCharacteristics: Characteristic[];
  handleCharacteristicChange: (
    characteristicId: string,
    values: string[],
  ) => void;
  inputCharacteristicInputs?: Characteristic[];
}

const color = 'Couleur';

export const CreateOrEditCharacteristicForm = ({
  characteristicValues,
  characteristics,
  tempCharacteristics,
  inputCharacteristicInputs,
  handleCharacteristicChange,
}: Props): JSX.Element => {
  const colorCharacteristic = characteristics.find(item => item.name === color);
  const characteriticGroupedByPosition = groupedByPosition(
    characteristics,
    color,
  );

  return (
    <>
      {Object.keys(characteriticGroupedByPosition)
        .map(Number)
        .sort((a, b) => a - b)
        .map(value => {
          const characteristicsGrouped = characteriticGroupedByPosition[value];

          return (
            <div key={value}>
              <RenderAutocompleteCharacteristics
                characteristics={characteristicsGrouped}
                characteristicValues={characteristicValues}
                tempCharacteristics={tempCharacteristics}
                handleCharacteristicChange={handleCharacteristicChange}
                inputCharacteristicInputs={inputCharacteristicInputs}
              />
              {colorCharacteristic !== undefined &&
                colorCharacteristic.position === value &&
                inputCharacteristicInputs === undefined && (
                  <ColorCharacteristic
                    key={colorCharacteristic.id}
                    initialColor={
                      tempCharacteristics.find(
                        item =>
                          item.characteristicId === colorCharacteristic.id,
                      )?.characteristicValues[0] ?? null
                    }
                    onChange={newColor =>
                      handleCharacteristicChange(colorCharacteristic.id, [
                        newColor,
                      ])
                    }
                    label={colorCharacteristic.name}
                  />
                )}
              {colorCharacteristic !== undefined &&
                colorCharacteristic.position === value &&
                inputCharacteristicInputs && (
                  <AnalysisModuleColorCharacteristic
                    key={colorCharacteristic.id}
                    initialColors={
                      tempCharacteristics?.find(
                        item =>
                          item.characteristicId === colorCharacteristic.id,
                      )?.characteristicValues ?? []
                    }
                    onChange={newColor =>
                      handleCharacteristicChange(
                        colorCharacteristic.id,
                        newColor,
                      )
                    }
                    label={colorCharacteristic.name}
                    inputCharacteristicInputs={
                      inputCharacteristicInputs?.find(
                        item =>
                          item.characteristicId === colorCharacteristic.id,
                      )?.characteristicValues ?? []
                    }
                  />
                )}

              <CharacteristicDivider />
            </div>
          );
        })}
    </>
  );
};
