import { ListItemText, Typography } from '@mui/material';
import { useIntl } from 'react-intl';

interface Props {
  id: string;
}

export const ListItemTextMenu = ({ id }: Props): JSX.Element => {
  const intl = useIntl();

  return (
    <ListItemText
      primary={
        <Typography sx={{ marginLeft: 1 }}>
          {intl.formatMessage({
            id,
          })}
        </Typography>
      }
    />
  );
};
