import {
  materialInputsPicturesDatabase,
  materialsInputsDatabase,
} from '../../dexieDB';

export const deleteLocalBuildingPartMap = async (
  localBuildingPartMapId: string,
): Promise<void> => {
  await materialsInputsDatabase.deleteIconsOfMap(localBuildingPartMapId);

  await materialInputsPicturesDatabase.deleteMap(localBuildingPartMapId);
};
