import { Box, Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const BoxFooter = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.primary.main,
}));

export const BoxFooter2 = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.primary.main,
}));

export const BoxFooterColumns = styled(Box)(() => ({
  display: 'flex',
  flexFlow: 'row wrap',
  justifyContent: 'space-evenly',
  alignItems: 'flex-start',
}));

export const BoxFooterLinks = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const LinkSocialMedia = styled(Link)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  color: theme.palette.secondary.light,
}));

export const LinkFooter = styled(Link)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  color: theme.palette.secondary.light,
}));

export const TypographyFooter = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.light,
}));
