import { distance } from 'fastest-levenshtein';
import {
  MaterialCategoryEntity,
  MaterialEntity,
} from '@afleya/material-schemas';
import { sanitizeString, unifyString } from '@afleya/common';

export const FilterMaterialsByLevenshteinDistance = (
  material: MaterialEntity,
  newSelectedDaughterCategories: MaterialCategoryEntity[],
  unifiedSearchTerms: string[],
  maxDistance: number,
): boolean => {
  const tags = material.tags.split(';').map(tag => tag.trim());
  const nameParts = material.name
    .split(' ')
    .map(namePart => sanitizeString(unifyString(namePart.trim())));

  return (
    newSelectedDaughterCategories
      .map(category => category.id)
      .includes(material.categoryId) &&
    unifiedSearchTerms.every(term => {
      return (
        tags.some(tag => distance(tag, term) <= maxDistance) ||
        nameParts.some(namePart => distance(namePart, term) <= maxDistance)
      );
    })
  );
};
