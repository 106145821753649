import { listLocalBuildingParts } from '../requests/buildingAndBuildingPart/listLocalBuildingParts';
import { listLocalMotherBuildingParts } from '../requests/buildingAndBuildingPart/listLocalMotherBuildingParts';
import { listProjectBuildings } from '../requests/buildingAndBuildingPart/listProjectBuildings';
import { BuildingForRecap, BuildingPartForRecap } from './LocationForRecap';

export const listLocationsForRecap = async (
  projectId: string,
): Promise<Array<BuildingForRecap>> => {
  const buildings = await listProjectBuildings(projectId);
  const locationForRecap = new Array<BuildingForRecap>();

  for (const building of buildings) {
    const buildingLocation: BuildingForRecap = {
      id: building.id,
      name: building.buildingName,
      buildingParts: new Array<BuildingPartForRecap>(),
    };

    const motherBuildingParts = (
      await listLocalMotherBuildingParts(building.id)
    ).sort((a, b) => a.buildingPartName.localeCompare(b.buildingPartName));

    for (const motherBuildingPart of motherBuildingParts) {
      const motherBuildingPartLocation: BuildingPartForRecap = {
        id: motherBuildingPart.id,
        name: motherBuildingPart.buildingPartName,
        daugtherBuildingParts: new Array<BuildingPartForRecap>(),
      };

      const daugtherBuildingParts = (
        await listLocalBuildingParts({
          buildingId: building.id,
          motherBuildingPartId: motherBuildingPart.id,
        })
      ).sort((a, b) => a.buildingPartName.localeCompare(b.buildingPartName));

      for (const daugtherBuildingPart of daugtherBuildingParts) {
        const daugtherBuildingPartLocation: BuildingPartForRecap = {
          id: daugtherBuildingPart.id,
          name: daugtherBuildingPart.buildingPartName,
          daugtherBuildingParts: new Array<BuildingPartForRecap>(),
        };

        motherBuildingPartLocation.daugtherBuildingParts.push(
          daugtherBuildingPartLocation,
        );
      }

      buildingLocation.buildingParts.push(motherBuildingPartLocation);
    }

    locationForRecap.push(buildingLocation);
  }

  return locationForRecap;
};
