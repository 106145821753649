import { localMaterialInputToMaterialInputEntity } from 'services/materialInputs/localMaterialInputToMaterialInputEntity';
import { LocalMaterialInput } from 'services/materialInputs/types';
import { listMaterialInputs } from 'services/offline/requests/materialInput/listMaterialInputs';
import { formatBoolean } from './formatBoolean';

export const generateMaterialInputsSqlExport = async (
  projectId: string,
  userId: string,
): Promise<string> => {
  const sql = MaterialInputsExportEntitiesToSqlOffline(
    await listMaterialInputs({ projectId: projectId ?? '' }),
    userId,
  );

  return sql;
};

const MaterialInputsExportEntitiesToSqlOffline = (
  data: LocalMaterialInput[],
  authorUserName: string,
): string => {
  let resSql = '';
  if (data.length > 0) {
    const header = Object.keys(
      localMaterialInputToMaterialInputEntity(data[0]),
    );
    header.push('authorUserName');
    for (const row of data) {
      if (!row['loadedFromServer']) {
        resSql = createInsertSql(row, header, resSql, authorUserName);
      } else {
        resSql = createUpdateSql(row, header, resSql);
      }
    }
  }

  return resSql;
};

const createInsertSql = (
  row: LocalMaterialInput,
  header: string[],
  resSql: string,
  authorUserName: string,
): string => {
  const sqlHeaderInsert =
    'INSERT INTO projectsdatabase.material_input (' + header.join(', ') + ')';
  const sqlFields: string[] = [];
  for (const key of header) {
    if (key === 'authorUserName') {
      sqlFields.push('"' + authorUserName + '"');
    } else if (key !== 'dimensions') {
      sqlFields.push(createSqlBodyInsert(row, key as keyof LocalMaterialInput));
    } else {
      sqlFields.push(createSqlDimensions(row));
    }
  }
  resSql += sqlHeaderInsert + ' VALUES\n(' + sqlFields.join(', ') + ');\n';

  return resSql;
};

const createSqlBodyInsert = (
  row: LocalMaterialInput,
  key: keyof LocalMaterialInput,
): string => {
  let resSql = '';
  if (row[key] === undefined) {
    resSql += convertUndefinedtoValidValue(key);
  } else {
    if (row[key] === null || row[key] === 'null') {
      resSql += 'NULL';
    } else {
      resSql +=
        typeof row[key] === 'boolean'
          ? '"' + formatBoolean(row, key) + '"'
          : '"' + String(row[key]) + '"';
    }
  }

  return resSql;
};

const convertUndefinedtoValidValue = (key: string): string => {
  if (key === 'materialToDeduce') {
    return 'false';
  }
  if (key === 'assemblyTypeId') {
    return 'NULL';
  }

  return '';
};

const createSqlDimensions = (row: LocalMaterialInput): string => {
  let dimensionsString = '';

  dimensionsString += "'{";
  let recordInd = 0;
  for (const i in row['dimensions']) {
    dimensionsString += '"' + i + '": ' + String(row['dimensions'][i]);
    if (recordInd < Object.keys(row['dimensions']).length - 1) {
      dimensionsString += ',';
    }
    recordInd++;
  }
  dimensionsString += "}'";

  return dimensionsString;
};

const createUpdateSql = (
  row: LocalMaterialInput,
  header: string[],
  resSql: string,
): string => {
  const sqlHeaderUpdate = 'UPDATE projectsdatabase.material_input SET\n';
  const sqlFooterUpdate = ' WHERE id = "' + row['id'] + '";\n';
  resSql += sqlHeaderUpdate;
  const sqlUpdateBody = [];
  for (const key of header) {
    if (key !== 'dimensions' && key !== 'authorUserName') {
      const sqlBody = createSqlBodyUpdate(row, key as keyof LocalMaterialInput);
      if (sqlBody !== '') {
        sqlUpdateBody.push(sqlBody);
      }
    } else if (key !== 'authorUserName') {
      sqlUpdateBody.push('dimensions = ' + createSqlDimensions(row));
    }
  }
  resSql += sqlUpdateBody.join(', ');
  resSql += sqlFooterUpdate;

  return resSql;
};

const createSqlBodyUpdate = (
  row: LocalMaterialInput,
  key: keyof LocalMaterialInput,
): string => {
  let resSql = '';
  switch (key) {
    case 'id':
      break;
    default:
      if (row[key] === undefined) {
        resSql += key + ' = NULL';
      } else if (typeof row[key] === 'boolean') {
        resSql += key + ' = "' + formatBoolean(row, key) + '"';
      } else {
        resSql +=
          row[key] === null || row[key] === 'null'
            ? key + ' = NULL'
            : key + ' = "' + String(row[key]) + '"';
      }
  }

  return resSql;
};
