import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import {
  BoxFooter,
  BoxFooter2,
  BoxFooterColumns,
  BoxFooterLinks,
  LinkFooter,
  LinkSocialMedia,
  TypographyFooter,
} from './AkiboFooter.style';
import { RootState } from '../../store';
import logoAfleya from '../../images/logoAfleya.svg';
import logoAkibo from '../../images/logoAkibo.svg';

const akiboUrl =
  process.env.REACT_APP_REDIRECT_SIGN_IN ?? 'https://www.akibo.fr';
const akiboVersion = process.env.REACT_APP_VERSION ?? '';

const AkiboFooter = (): JSX.Element => {
  const isLogged = useSelector((state: RootState) => state.loginState.isLogged);

  return (
    <>
      <BoxFooter>
        <img src={logoAkibo} width="200" height="200" />
        <TypographyFooter>
          <FormattedMessage
            id="akibo-footer.version"
            values={{
              version: akiboVersion,
            }}
          />
        </TypographyFooter>

        <BoxFooter2>
          <BoxFooterColumns>
            <BoxFooterLinks>
              <img src={logoAfleya} width="100" height="100" />
              <TypographyFooter variant="h2">
                <FormattedMessage id="akibo-footer.about" />
              </TypographyFooter>

              <LinkFooter href="https://www.afleya.com" underline="hover">
                <FormattedMessage id="akibo-footer.company" />
              </LinkFooter>

              <LinkFooter
                href="https://www.afleya.com/index.php/contact/"
                underline="hover"
              >
                <FormattedMessage id="akibo-footer.contact" />
              </LinkFooter>
              {isLogged === true && (
                <>
                  <LinkFooter
                    href={akiboUrl + '/AFLEYA_Akibo_CGU-CGV.pdf'}
                    underline="hover"
                  >
                    <FormattedMessage id="akibo-footer.cgus" />
                  </LinkFooter>
                  <LinkFooter
                    href={akiboUrl + '/charte_confidentialite_AKIBO.pdf'}
                    underline="hover"
                  >
                    <FormattedMessage id="akibo-footer.confidentiality" />
                  </LinkFooter>
                </>
              )}
              <LinkSocialMedia
                href="mailTo:contact@afleya.com"
                underline="hover"
              >
                <span>contact@afleya.com</span>
              </LinkSocialMedia>
            </BoxFooterLinks>
          </BoxFooterColumns>
        </BoxFooter2>
      </BoxFooter>
    </>
  );
};

export default AkiboFooter;
