import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import {
  BLocationTitle,
  DivHeader,
} from 'components/PageHeader/PageHeader.style';
import { Badge, Button, Typography } from '@mui/material';
import { ArrowBackIos, Sync, SyncDisabled } from '@mui/icons-material';
import { useState } from 'react';
import { ConfirmDialog } from 'components/ConfirmDialog';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes';
import { RootState } from '../../../store';

interface Props {
  locationTitle?: string;
  projectId: string;
}

export const InputModuleHomePageHeader = ({
  locationTitle,
  projectId,
}: Props): JSX.Element => {
  const [showDialog, setShowDialog] = useState<boolean>(false);

  const navigate = useNavigate();

  const unsynchronizedOperations = useSelector(
    (state: RootState) => state.unsynchronizedOperationsCounter.value,
  );

  const onClick = () => {
    setShowDialog(true);
  };

  const onAgree = () => {
    navigate(routes.PROJECT_BUILDINGS.replace(':projectId', projectId));
  };

  const onDisagree = () => {
    setShowDialog(false);
  };

  const intl = useIntl();

  return (
    <div>
      <DivHeader>
        <div>
          <Button onClick={onClick} data-test="backLink">
            <ArrowBackIos fontSize="small" />
            {intl.formatMessage({ id: 'input-module-home-page.backLinkTitle' })}
          </Button>
        </div>
        <Badge badgeContent={unsynchronizedOperations} color="error" max={999}>
          {unsynchronizedOperations === 0 ? <Sync /> : <SyncDisabled />}
        </Badge>
        <BLocationTitle>{locationTitle}</BLocationTitle>
      </DivHeader>
      <Typography variant="h1">
        {intl.formatMessage({ id: 'input-module-home-page.title' })}
      </Typography>
      {intl.formatMessage({ id: 'input-module-home-page.subtitle' })}
      {showDialog && (
        <ConfirmDialog
          title={intl.formatMessage({
            id: 'input-module-home-page.confirmDialog.title',
          })}
          contentText={intl.formatMessage({
            id: 'input-module-home-page.confirmDialog.contentText',
          })}
          onAgree={onAgree}
          onDisagree={onDisagree}
        />
      )}
    </div>
  );
};
