import { filterUserGroups } from '@afleya/common';
import { getUserGroups } from 'services/cognito';
import { getUserProfile } from 'services/networking/requests/getUserProfile';
import { setUserProfile } from 'services/store/userProfile';
import { store } from '../../store';
export const getLocalUserProfile = async (): Promise<void> => {
  const [userProfile, userGroups] = await Promise.all([
    getUserProfile(),
    getUserGroups(),
  ]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { roles, ...otherProperties } = userProfile;

  const localUserProfile = {
    ...otherProperties,
    groups: filterUserGroups(userGroups),
  };

  store.dispatch(setUserProfile(localUserProfile));
};
