import { getJwtToken } from 'services/cognito';
import { getAxiosRequest } from '@swarmion/serverless-contracts';
import { downloadImageContract } from '@afleya/material-inputs-contracts';
import { ImageTypes } from '@afleya/common';
import client from '../client';

export const downloadImage = async (
  projectId: string,
  imageType: ImageTypes,
  hostId: string,
  imageId: string,
): Promise<string> => {
  const jwtToken = await getJwtToken();

  const { data: base64 } = await getAxiosRequest(
    downloadImageContract,
    client,
    {
      headers: {
        authorization: `Bearer ${jwtToken}`,
      },
      pathParameters: { projectId, imageType, hostId, imageId },
    },
  );

  return base64;
};
