import { getJwtToken } from 'services/cognito';
import { AxiosResponse } from 'axios';
import {
  CreateMaterialRequestEntity,
  MaterialEntity,
} from '@afleya/material-schemas';
import client from '../client';

const BASE_URL = '/materials';

const createMaterial = async (
  data: CreateMaterialRequestEntity,
): Promise<MaterialEntity> => {
  const jwtToken = await getJwtToken();
  const { data: material } = await client.post<
    CreateMaterialRequestEntity,
    AxiosResponse<MaterialEntity>
  >(BASE_URL, data, {
    headers: { Authorization: `Bearer ${jwtToken}` },
  });

  return material;
};

export default createMaterial;
