import { BuildingPartEntity } from '@afleya/project-schemas';
import { materialsInputsDatabase } from '../../dexieDB';

export const getBuildingPart = async (
  buildingPartId: string,
): Promise<BuildingPartEntity> => {
  const buildingPart =
    await materialsInputsDatabase.getBuildingPart(buildingPartId);

  return buildingPart;
};
