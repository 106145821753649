import { ListItem, ListItemText } from '@mui/material';
import { useDrag } from 'react-dnd';
import { useEffect, useMemo, useState } from 'react';
import MaterialInputDimensionsSummary from 'components/MaterialInputSummaryItem/MaterialInputDimensionsSummary';
import { IMaterial, IMaterialOnMap, IOffMapAmounts } from '../interfaces';
import { NumberPicker } from './NumberPicker';
import { getIconClassNameFromMotherMaterialCategoryId } from '../icons/getIcon';

interface IProps {
  index: number;
  isEdit: boolean;
  material: IMaterial | IMaterialOnMap;
  setPreviewClassName: React.Dispatch<React.SetStateAction<string>>;
  setSelectedAmount: React.Dispatch<React.SetStateAction<IOffMapAmounts>>;
  selectedAmount: IOffMapAmounts;
}

export const Material = ({
  index,
  isEdit,
  material,
  setPreviewClassName,
  setSelectedAmount,
  selectedAmount,
}: IProps): JSX.Element => {
  const className = useMemo(
    () =>
      getIconClassNameFromMotherMaterialCategoryId(material.materialCategoryId),
    [material.materialCategoryId],
  );

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: 'material',
      item: { ...material },
      collect: monitor => {
        return {
          isDragging: monitor.isDragging(),
        };
      },
    }),
    [material],
  );

  const [defaultValue, setDefaultValue] = useState<number>(material.amount);

  useEffect(() => {
    setDefaultValue(selectedAmount[material.materialInputId]);
  }, [material, setDefaultValue, selectedAmount]);

  const onChange = (value: number): void => {
    selectedAmount[material.materialInputId] = value;
    setSelectedAmount(selectedAmount);
  };

  useEffect(() => {
    if (isDragging) {
      setPreviewClassName(className);
    }
  }, [isDragging, setPreviewClassName, className]);

  return (
    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        borderRadius: '6px',
        border: '1px solid grey',
        marginTop: '3px',
        padding: 0,
        backgroundColor: 'white',
      }}
    >
      {isEdit ? (
        isDragging ? (
          <></>
        ) : (
          <div
            ref={drag}
            className={className}
            style={{
              justifyContent: 'center',
              alignContent: 'center',
              margin: '6px',
            }}
          ></div>
        )
      ) : (
        <div className="numberIcon" style={{ margin: '6px' }}>
          {index + 1}
        </div>
      )}

      <div
        style={{
          justifyContent: 'left',
          flex: '3',
          padding: 0,
          margin: 0,
          paddingLeft: '6px',
          borderLeft: '1px solid grey',
        }}
      >
        <ListItemText
          primary={material.nickname}
          secondary={`x${material.amount}${
            material.locationDescription !== ''
              ? ', ' + material.locationDescription
              : ''
          }`}
        />
        <MaterialInputDimensionsSummary
          materialDimensions={material.dimensions}
          inputUnit={material.inputUnit}
          inputMethod={material.inputMethod}
        />
      </div>
      {isEdit && (
        <NumberPicker
          startValue={defaultValue}
          min={1}
          max={material.amount}
          onChange={onChange}
        />
      )}
    </ListItem>
  );
};
