import { LatLng } from 'leaflet';
import { handleOOBInput, IMaterial, IMaterialOnMap } from '../interfaces';

export const handleInBounds = (
  materialsOnMap: IMaterialOnMap[],
  setMaterialsOnMap: React.Dispatch<React.SetStateAction<IMaterialOnMap[]>>,
  material: IMaterialOnMap,
  position: LatLng,
): void => {
  setMaterialsOnMap(
    materialsOnMap.map(mat => {
      if (mat.iconId === material.iconId) {
        const newPos: IMaterialOnMap = {
          ...material,
          coordinates: position,
        };

        return newPos;
      } else {
        return mat;
      }
    }),
  );
};

export const handleOutOfBounds = ({
  materialsOnMap,
  setMaterialsOnMap,
  materialsOffMap,
  setMaterialsOffMap,
  material,
}: handleOOBInput): void => {
  setMaterialsOnMap(
    materialsOnMap.filter(mat => mat.iconId !== material.iconId),
  );
  const correspondingOffMap = materialsOffMap.find(
    mat => mat.materialInputId === material.materialInputId,
  );
  if (correspondingOffMap !== undefined) {
    setMaterialsOffMap(
      materialsOffMap.map(mat => {
        if (mat.materialInputId === material.materialInputId) {
          const offMap: IMaterial = {
            ...material,
            amount: material.amount + mat.amount,
          };

          return offMap;
        } else {
          return mat;
        }
      }),
    );
  } else {
    const offMap = { ...material };
    setMaterialsOffMap([offMap, ...materialsOffMap]);
  }
};
