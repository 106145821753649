import { Button, ToggleButtonGroup } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ToggleButtonGroupContainer = styled(ToggleButtonGroup)(() => ({
  flexWrap: 'wrap',
}));

export const DivSelectorButtonContainer = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingRight: theme.spacing(1),
}));

export const ButtonSelector = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1),
  minWidth: theme.spacing(7),
}));
