import { getBuilding } from '../requests/buildingAndBuildingPart/getBuilding';
import { getBuildingPart } from '../requests/buildingAndBuildingPart/getBuildingPart';

interface Props {
  buildingId?: string;
  buildingPartId?: string;
  materialInputBuildingId: string;
  materialInputBuildingPartId: string;
  materialInputLocationDescription: string;
}
export const computeMaterialInputForRecapLocation = async ({
  buildingId,
  buildingPartId,
  materialInputBuildingId,
  materialInputBuildingPartId,
  materialInputLocationDescription,
}: Props): Promise<string> => {
  const locationDescription =
    materialInputLocationDescription === ''
      ? ''
      : ` - ${materialInputLocationDescription}`;

  let location = '';

  if (buildingId === undefined) {
    const building = await getBuilding(materialInputBuildingId);
    const buildingPart = await getBuildingPart(materialInputBuildingPartId);

    location = building.buildingName;

    if (buildingPart.motherBuildingPartId !== null) {
      const motherBuildingPart = await getBuildingPart(
        buildingPart.motherBuildingPartId,
      );
      location += ` - ${motherBuildingPart.buildingPartName}`;
    }
    location += ` - ${buildingPart.buildingPartName}${locationDescription}`;
  } else if (buildingPartId === undefined) {
    const buildingPart = await getBuildingPart(materialInputBuildingPartId);

    if (buildingPart.motherBuildingPartId !== null) {
      const motherBuildingPart = await getBuildingPart(
        buildingPart.motherBuildingPartId,
      );
      location = `${motherBuildingPart.buildingPartName} - ${buildingPart.buildingPartName}${locationDescription}`;
    } else {
      location = `${buildingPart.buildingPartName}${locationDescription}`;
    }
  } else if (buildingPartId !== materialInputBuildingPartId) {
    const buildingPart = await getBuildingPart(materialInputBuildingPartId);

    location = `${buildingPart.buildingPartName}${locationDescription}`;
  } else {
    location = materialInputLocationDescription;
  }

  return location;
};
