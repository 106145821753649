import { materialsDatabase } from 'services/offline/dexieDB';
import { unifyString } from '@afleya/common';
import {
  MaterialCategoryEntity,
  MaterialEntity,
} from '@afleya/material-schemas';
import { FilterMaterialsByLevenshteinDistance } from './FilterMaterialsByLevenshteinDistance';

interface fetchMaterialsProps {
  materials: MaterialEntity[];
  newSelectedDaughterCategories: MaterialCategoryEntity[];
  maxDistance: number;
  unifiedSearchTerms: string[];
  skip: number;
  take: number;
}

export const fetchMaterials = async ({
  materials,
  newSelectedDaughterCategories,
  maxDistance,
  unifiedSearchTerms,
  skip,
  take,
}: fetchMaterialsProps): Promise<MaterialEntity[]> => {
  const fetchedMaterials = await materialsDatabase.materials
    .orderBy('name')
    .filter(material => {
      const unifiedName = unifyString(material.name);
      const unifiedTags =
        material.tags !== undefined ? unifyString(material.tags) : '';

      return (
        newSelectedDaughterCategories
          .map(category => category.id)
          .includes(material.categoryId) &&
        (maxDistance === 0
          ? unifiedSearchTerms.every(term => {
              return unifiedName.includes(term) || unifiedTags.includes(term);
            })
          : FilterMaterialsByLevenshteinDistance(
              material,
              newSelectedDaughterCategories,
              unifiedSearchTerms,
              maxDistance,
            ))
      );
    })
    .offset(skip)
    .limit(take)
    .toArray();

  materials = materials.concat(
    fetchedMaterials.filter(
      fetchedMaterial =>
        !materials.some(material => material.id === fetchedMaterial.id),
    ),
  );

  return materials;
};
