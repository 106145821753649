import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LocalUserProfile } from './types/LocalUserProfile';

export interface UserProfileState {
  userProfile: LocalUserProfile | undefined;
}

const initialState: UserProfileState = {
  userProfile: undefined,
};

export const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState,
  reducers: {
    setUserProfile: (state, action: PayloadAction<LocalUserProfile>) => {
      state.userProfile = action.payload;
    },
    clearUserProfile: state => {
      state.userProfile = undefined;
    },
  },
});

// Action creators are generated for each case reducer function
export const { clearUserProfile, setUserProfile } = userProfileSlice.actions;

export default userProfileSlice.reducer;
