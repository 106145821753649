import { getJwtToken } from 'services/cognito';
import { MaterialInputEntity } from '@afleya/project-schemas';
import client from '../../client';

interface FilterOptions {
  buildingId: string;
  buildingPartId: string;
  skip: number;
  take: number;
  considerChildBuildingParts: boolean;
}

const listProjectMaterialInputs = async (
  projectId: string,
  {
    buildingId,
    buildingPartId,
    skip,
    take,
    considerChildBuildingParts,
  }: Partial<FilterOptions>,
): Promise<MaterialInputEntity[]> => {
  const BASE_URL = `/material-inputs/${projectId}/inputs`;

  const jwtToken = await getJwtToken();

  const { data: materialInputs } = await client.get<MaterialInputEntity[]>(
    BASE_URL,
    {
      headers: { Authorization: `Bearer ${jwtToken}` },
      params: {
        buildingId,
        buildingPartId,
        skip,
        take,
        considerChildBuildingParts,
      },
    },
  );

  return materialInputs;
};

export default listProjectMaterialInputs;
