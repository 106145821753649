import { styled } from '@mui/material/styles';
import { Divider } from '@mui/material';

export const CharacteristicDivider = styled(Divider)(() => ({
  width: '100%',
  backgroundColor: '#cccccc',
  height: '1px',
  marginTop: '1.25rem',
  marginBottom: '1.25rem',
}));
