import Dexie from 'dexie';
import {
  ICreateChange,
  IDatabaseChange,
  IDeleteChange,
  IUpdateChange,
} from 'dexie-observable/api';
import {
  ApplyRemoteChangesFunction,
  IPersistedContext,
  PollContinuation,
  ReactiveContinuation,
} from 'dexie-syncable/api';
import { LocalBuildingPartMap } from 'services/buildingPartMaps/type';
import { LocalMaterialInputPicture } from 'services/materialInputsPictures/type';
import deleteMaterialInputPicture from 'services/networking/requests/deleteMaterialInputPicture';
import { deleteBuildingPartMap } from 'services/networking/requests/deleteBuildingPartMap';
import { createImage } from 'services/networking/requests/createImage';
import { ImageTypes } from '@afleya/common';
import { materialInputsPicturesDatabase } from './dexieDB';

Dexie.Syncable.registerSyncProtocol('materialInputsPicturesSyncProtocol', {
  partialsThreshold: 5,
  // eslint-disable-next-line max-params
  sync: (
    context: IPersistedContext,
    _url: string,
    _options: unknown,
    _baseRevision: unknown,
    _syncedRevision: unknown,
    changes: IDatabaseChange[],
    partial: boolean,
    applyRemoteChanges: ApplyRemoteChangesFunction,
    onChangesAccepted: () => void,
    onSuccess: (continuation: PollContinuation | ReactiveContinuation) => void,
    onError: (error: unknown, again?: number) => void,
    // Dexie forces us to use this number of params
    // eslint-disable-next-line max-params
  ) => {
    void materialInputsPictureSyncProtocol(
      context,
      changes,
      partial,
      applyRemoteChanges,
      onChangesAccepted,
      onSuccess,
      onError,
    );
  },
});

const materialInputsPictureSyncProtocol = async (
  context: IPersistedContext,
  changes: IDatabaseChange[],
  partial: boolean,
  applyRemoteChanges: ApplyRemoteChangesFunction,
  onChangesAccepted: () => void,
  onSuccess: (continuation: PollContinuation | ReactiveContinuation) => void,
  onError: (error: unknown, again?: number) => void,
  // eslint-disable-next-line max-params
): Promise<void> => {
  const POLL_INTERVAL = 5000; // 5 sec
  const POLL_INTERVAL_PARTIAL = 20;

  try {
    await Promise.all(changes.map(syncMaterialInputPictureChange));
    await context.save();
    onChangesAccepted();
    await applyRemoteChanges(
      updateUploadedStatus(
        keepOnlyCreateMaterialInputPictureChange(changes) as ICreateChange[],
      ),
      1,
      false,
    );
    onSuccess({
      again: partial ? POLL_INTERVAL_PARTIAL : POLL_INTERVAL,
    });
  } catch (error) {
    console.log('sync error : ', error);
    onError(error, POLL_INTERVAL);
  }
};

const handleCreate = async (change: ICreateChange): Promise<void> => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (change.obj.uploaded !== true) {
    switch (change.table) {
      case materialInputsPicturesDatabase.materialInputsPictures.name:
        // eslint-disable-next-line no-case-declarations
        const newPicture = change.obj as LocalMaterialInputPicture;
        await createImage(
          ImageTypes.materialInputPicture,
          newPicture.materialInputId,
          newPicture,
        );
        break;
      case materialInputsPicturesDatabase.buildingPartMaps.name:
        // eslint-disable-next-line no-case-declarations
        const newImage = change.obj as LocalBuildingPartMap;
        await createImage(
          ImageTypes.buildingPartMap,
          newImage.buildingPartId,
          newImage,
        );
        break;
      default:
        break;
    }
  }
};

const handleDelete = async (change: IDeleteChange): Promise<void> => {
  switch (change.table) {
    case materialInputsPicturesDatabase.materialInputsPictures.name:
      await deleteMaterialInputPicture(change.key as string);
      break;
    case materialInputsPicturesDatabase.buildingPartMaps.name:
      await deleteBuildingPartMap(change.key as string);
      break;
    default:
      break;
  }
};

const syncMaterialInputPictureChange = async (
  change: IDatabaseChange,
): Promise<void> => {
  switch (change.type as number) {
    case 1: // DatabaseChangeType.Create, but cannot import due to typescript issue
      await handleCreate(change as ICreateChange);
      break;
    case 2: // DatabaseChangeType.Update, but cannot import due to typescript issue
      // We don't update images
      break;
    case 3: // DatabaseChangeType.Delete, but cannot import due to typescript issue
      await handleDelete(change as IDeleteChange);
      break;
    default:
      break;
  }
};

const updateUploadedStatus = (changes: ICreateChange[]): IUpdateChange[] => {
  return changes.map(change => ({
    type: 2,
    table: change.table,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    key: change.key,
    mods: { uploaded: true },
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    obj: { ...change.obj, uploaded: true },
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    oldObj: change.obj,
  }));
};

const keepOnlyCreateMaterialInputPictureChange = (
  changes: IDatabaseChange[],
): IDatabaseChange[] => {
  return changes.filter(change => (change.type as number) === 1);
};
