import { unifyString } from '@afleya/common';

export const sortNameASC = (a: string, b: string): number => {
  const ua = unifyString(a);
  const ub = unifyString(b);

  if (ua > ub) {
    return 1;
  } else {
    if (ua < ub) {
      return -1;
    }
  }

  return 0;
};

export const sortNameDSC = (a: string, b: string): number =>
  sortNameASC(a, b) * -1;
