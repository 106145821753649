import { IntlShape } from 'react-intl';

export const confirmDialogTitle = (
  intl: IntlShape,
  id: string,
  buildingName: string | undefined,
): string =>
  intl.formatMessage(
    {
      id: id,
    },
    {
      buildingName: buildingName,
    },
  );

export const confirmDialogContentText = (
  intl: IntlShape,
  id: string,
  sourceBuilding: string | undefined,
  targetBuilding: string | undefined,
): string =>
  intl.formatMessage(
    {
      id: id,
    },
    {
      sourceBuilding: sourceBuilding,
      targetBuilding: targetBuilding,
    },
  );
