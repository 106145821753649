import { List } from '@mui/material';
import { useEffect, useState } from 'react';
import { MaterialCategoryEntity } from '@afleya/material-schemas';
import { useAsyncFn } from 'react-use';
import { MaterialItem } from 'services/MaterialOrNicknameItems/type';
import LoadingBox from 'components/LoadingBox';
import BoxAlert from 'components/BoxAlert';
import { getMaterialItems } from 'services/MaterialOrNicknameItems/getMaterialItems';
import { useIntl } from 'react-intl';
import { PageContent } from 'components/PageContent';
import { SeeMoreMaterialsButton } from './SeeMoreMaterialsButton';
import { ListMaterialItem } from './ListMaterialItem';

interface ListMaterialsProps {
  selectedMotherCategories: MaterialCategoryEntity[];
  selectedDaughterCategories: MaterialCategoryEntity[];
  categories: MaterialCategoryEntity[];
  debouncedSearchTerms: string;
  handleNavigate: (materialId: string, categoryId: string) => void;
  nbMaterialsToFetch: number;
}

export const ListMaterials = ({
  selectedMotherCategories,
  selectedDaughterCategories,
  categories,
  debouncedSearchTerms,
  handleNavigate,
  nbMaterialsToFetch,
}: ListMaterialsProps): JSX.Element => {
  const intl = useIntl();
  const [skip, setSkip] = useState<number>(0);
  const [canSeeMore, setCanSeeMore] = useState<boolean>(false);
  const [materialItems, setMaterialItems] = useState<MaterialItem[]>([]);

  const [{ loading, error }, getMaterials] = useAsyncFn(
    async (
      skipMaterials: number,
      filterMaterials: string,
    ): Promise<MaterialItem[]> => {
      const materialsChunks = await getMaterialItems({
        skip: skipMaterials,
        take: nbMaterialsToFetch,
        filter: filterMaterials,
        selectedMotherCategories: selectedMotherCategories,
        selectedDaughterCategories: selectedDaughterCategories,
        categories: categories,
      });

      if (materialsChunks.length === nbMaterialsToFetch) {
        setCanSeeMore(true);
      } else {
        setCanSeeMore(false);
      }

      return materialsChunks;
    },
    [
      nbMaterialsToFetch,
      categories,
      selectedDaughterCategories,
      selectedMotherCategories,
    ],
  );

  const [{ loading: loadingSeeMore }, fetchMoreMaterials] =
    useAsyncFn(async () => {
      const materialsChunks = await getMaterials(
        skip + nbMaterialsToFetch,
        debouncedSearchTerms,
      );
      setSkip(skip + nbMaterialsToFetch);
      setMaterialItems(materialItems.concat(materialsChunks));
    }, [
      getMaterials,
      skip,
      nbMaterialsToFetch,
      debouncedSearchTerms,
      materialItems,
    ]);

  useEffect(() => {
    const fetchData = async () => {
      const materialsChunks = await getMaterials(0, debouncedSearchTerms);
      setMaterialItems(materialsChunks);
      setSkip(0);
    };

    void fetchData();
  }, [debouncedSearchTerms, getMaterials]);

  return (
    <>
      <PageContent overflow="auto">
        {loading && materialItems.length === 0 ? (
          <LoadingBox />
        ) : error ? (
          <BoxAlert contentId={'input-module-materials-page.loadingError'} />
        ) : (
          <div>
            <List data-test="materials">
              <h2
                style={{ marginBlockStart: '0.40em', marginBlockEnd: '0.20em' }}
              >
                {intl.formatMessage({
                  id: 'input-module-categories-page.material',
                })}
              </h2>
              {materialItems.map(materialItem => (
                <ListMaterialItem
                  key={`material-${materialItem.materialId}`}
                  material={materialItem}
                  handleNavigate={handleNavigate}
                />
              ))}
              {canSeeMore && (
                <SeeMoreMaterialsButton
                  loading={loadingSeeMore}
                  fetchMore={fetchMoreMaterials}
                />
              )}
            </List>
          </div>
        )}
      </PageContent>
    </>
  );
};
