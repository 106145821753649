// contracts/httpApi/deleteBuildingPartMapContract.ts
import { ApiGatewayContract } from "@swarmion/serverless-contracts";
var pathParametersSchema = {
  type: "object",
  properties: {
    buildingPartMapId: { type: "string" }
  },
  required: ["buildingPartMapId"],
  additionalProperties: false
};
var headersSchema = {
  type: "object",
  properties: {
    authorization: { type: "string" }
  },
  required: ["authorization"]
};
var deleteBuildingPartMapContract = new ApiGatewayContract({
  id: "deleteBuildingPartMapContract",
  path: "/material-inputs/building-part-map/{buildingPartMapId}",
  method: "DELETE",
  integrationType: "httpApi",
  authorizerType: "cognito",
  pathParametersSchema,
  headersSchema
});

// contracts/httpApi/deleteMaterialInputPictureContract.ts
import { ApiGatewayContract as ApiGatewayContract2 } from "@swarmion/serverless-contracts";
var pathParametersSchema2 = {
  type: "object",
  properties: {
    materialInputPictureId: { type: "string" }
  },
  required: ["materialInputPictureId"],
  additionalProperties: false
};
var headersSchema2 = {
  type: "object",
  properties: {
    authorization: { type: "string" }
  },
  required: ["authorization"]
};
var deleteMaterialInputPictureContract = new ApiGatewayContract2({
  id: "deleteMaterialInputPictureContract",
  path: "/material-inputs/picture/{materialInputPictureId}",
  method: "DELETE",
  integrationType: "httpApi",
  authorizerType: "cognito",
  pathParametersSchema: pathParametersSchema2,
  headersSchema: headersSchema2
});

// contracts/httpApi/downloadImageContract.ts
import { ImageTypes } from "@afleya/common";
import {
  ApiGatewayContract as ApiGatewayContract3,
  HttpStatusCodes
} from "@swarmion/serverless-contracts";
var pathParametersSchema3 = {
  type: "object",
  properties: {
    projectId: { type: "string" },
    imageType: { enum: Object.values(ImageTypes) },
    hostId: { type: "string" },
    imageId: { type: "string" }
  },
  required: ["projectId", "imageType", "hostId", "imageId"],
  additionalProperties: false
};
var headersSchema3 = {
  type: "object",
  properties: {
    authorization: { type: "string" }
  },
  required: ["authorization"]
};
var outputSchema = {
  type: "string"
};
var downloadImageContract = new ApiGatewayContract3({
  id: "downloadImageContract",
  path: "/material-inputs/{projectId}/download-image/{imageType}/{hostId}/{imageId}",
  method: "GET",
  integrationType: "httpApi",
  authorizerType: "cognito",
  pathParametersSchema: pathParametersSchema3,
  headersSchema: headersSchema3,
  outputSchemas: { [HttpStatusCodes.OK]: outputSchema }
});

// contracts/httpApi/generatePresignedDownloadUrlContract.ts
import {
  ApiGatewayContract as ApiGatewayContract4,
  HttpStatusCodes as HttpStatusCodes2
} from "@swarmion/serverless-contracts";
var pathParametersSchema4 = {
  type: "object",
  properties: {
    projectId: { type: "string" },
    materialInputId: { type: "string" },
    pictureId: { type: "string" }
  },
  required: ["projectId", "materialInputId", "pictureId"],
  additionalProperties: false
};
var headersSchema4 = {
  type: "object",
  properties: {
    authorization: { type: "string" }
  },
  required: ["authorization"]
};
var outputSchema2 = {
  type: "string"
};
var generatePresignedDownloadUrlContract = new ApiGatewayContract4({
  id: "GeneratePresignedDownloadUrlContract",
  path: "/material-inputs/{projectId}/generate-download-url/{materialInputId}/{pictureId}",
  method: "GET",
  integrationType: "httpApi",
  authorizerType: "cognito",
  pathParametersSchema: pathParametersSchema4,
  headersSchema: headersSchema4,
  outputSchemas: { [HttpStatusCodes2.OK]: outputSchema2 }
});

// contracts/httpApi/generatePresignedImageUploadUrlContract.ts
import { ImageTypes as ImageTypes2 } from "@afleya/common";
import {
  ApiGatewayContract as ApiGatewayContract5,
  HttpStatusCodes as HttpStatusCodes3
} from "@swarmion/serverless-contracts";
var pathParametersSchema5 = {
  type: "object",
  properties: {
    projectId: { type: "string" },
    hostId: { type: "string" },
    imageId: { type: "string" },
    imageType: { enum: Object.values(ImageTypes2) }
  },
  required: ["projectId", "hostId", "imageId", "imageType"],
  additionalProperties: false
};
var headersSchema5 = {
  type: "object",
  properties: {
    authorization: { type: "string" }
  },
  required: ["authorization"]
};
var outputSchema3 = {
  type: "object",
  properties: {
    url: {
      type: "string"
    },
    fields: {
      type: "object",
      properties: {
        key: { type: "string" },
        bucket: { type: "string" },
        Policy: { type: "string" },
        "X-Amz-Algorithm": { type: "string" },
        "X-Amz-Credential": { type: "string" },
        "X-Amz-Date": { type: "string" },
        "X-Amz-Security-Token": { type: "string" },
        "X-Amz-Signature": { type: "string" },
        "x-amz-meta-akibo-project-id": { type: "string" },
        "x-amz-meta-akibo-host-id": { type: "string" },
        "x-amz-meta-akibo-image-id": { type: "string" },
        "x-amz-meta-akibo-image-type": {
          enum: Object.values(ImageTypes2)
        }
      },
      required: [
        "key",
        "x-amz-meta-akibo-project-id",
        "x-amz-meta-akibo-host-id",
        "x-amz-meta-akibo-image-id",
        "x-amz-meta-akibo-image-type",
        "bucket",
        "X-Amz-Algorithm",
        "X-Amz-Credential",
        "X-Amz-Date",
        "X-Amz-Security-Token",
        "Policy",
        "X-Amz-Signature"
      ]
    }
  },
  required: ["url", "fields"]
};
var generatePresignedImageUploadUrlContract = new ApiGatewayContract5({
  id: "GeneratePresignedImageUploadUrlContract",
  path: "/material-inputs/{projectId}/generate-image-upload-url/{imageType}/{hostId}/{imageId}",
  method: "GET",
  integrationType: "httpApi",
  authorizerType: "cognito",
  pathParametersSchema: pathParametersSchema5,
  headersSchema: headersSchema5,
  outputSchemas: { [HttpStatusCodes3.OK]: outputSchema3 }
});

// contracts/httpApi/generatePresignedUploadUrlContract.ts
import { ImageTypes as ImageTypes3 } from "@afleya/common";
import {
  ApiGatewayContract as ApiGatewayContract6,
  HttpStatusCodes as HttpStatusCodes4
} from "@swarmion/serverless-contracts";
var pathParametersSchema6 = {
  type: "object",
  properties: {
    projectId: { type: "string" },
    materialInputId: { type: "string" },
    pictureId: { type: "string" }
  },
  required: ["projectId", "materialInputId", "pictureId"],
  additionalProperties: false
};
var headersSchema6 = {
  type: "object",
  properties: {
    authorization: { type: "string" }
  },
  required: ["authorization"]
};
var outputSchema4 = {
  type: "object",
  properties: {
    url: {
      type: "string"
    },
    fields: {
      type: "object",
      properties: {
        key: { type: "string" },
        bucket: { type: "string" },
        Policy: { type: "string" },
        "X-Amz-Algorithm": { type: "string" },
        "X-Amz-Credential": { type: "string" },
        "X-Amz-Date": { type: "string" },
        "X-Amz-Security-Token": { type: "string" },
        "X-Amz-Signature": { type: "string" },
        "x-amz-meta-akibo-project-id": { type: "string" },
        "x-amz-meta-akibo-host-id": { type: "string" },
        "x-amz-meta-akibo-image-id": { type: "string" },
        "x-amz-meta-akibo-image-type": {
          enum: Object.values(ImageTypes3)
        }
      },
      required: [
        "key",
        "x-amz-meta-akibo-project-id",
        "x-amz-meta-akibo-host-id",
        "x-amz-meta-akibo-image-id",
        "x-amz-meta-akibo-image-type",
        "bucket",
        "X-Amz-Algorithm",
        "X-Amz-Credential",
        "X-Amz-Date",
        "X-Amz-Security-Token",
        "Policy",
        "X-Amz-Signature"
      ]
    }
  },
  required: ["url", "fields"]
};
var generatePresignedUploadUrlContract = new ApiGatewayContract6({
  id: "GeneratePresignedUploadUrlContract",
  path: "/material-inputs/{projectId}/generate-upload-url/{materialInputId}/{pictureId}",
  method: "GET",
  integrationType: "httpApi",
  authorizerType: "cognito",
  pathParametersSchema: pathParametersSchema6,
  headersSchema: headersSchema6,
  outputSchemas: { [HttpStatusCodes4.OK]: outputSchema4 }
});

// contracts/httpApi/getReuseWeightByWasteCategoryContract.ts
import { reuseWeightByWasteCategorySchema } from "@afleya/project-schemas";
import {
  ApiGatewayContract as ApiGatewayContract7,
  HttpStatusCodes as HttpStatusCodes5
} from "@swarmion/serverless-contracts";
var pathParametersSchema7 = {
  type: "object",
  properties: {
    projectId: { type: "string" },
    scenarioId: { type: "string" }
  },
  required: ["projectId", "scenarioId"],
  additionalProperties: false
};
var headersSchema7 = {
  type: "object",
  properties: {
    authorization: { type: "string" }
  },
  required: ["authorization"]
};
var outputSchema5 = {
  type: "array",
  items: reuseWeightByWasteCategorySchema
};
var getReuseWeightByWasteCategoryContract = new ApiGatewayContract7({
  id: "getReuseWeightByWasteCategoryContract",
  path: "/material-inputs/{projectId}/scenarios/{scenarioId}/reuse-weights",
  method: "GET",
  integrationType: "httpApi",
  authorizerType: "cognito",
  pathParametersSchema: pathParametersSchema7,
  headersSchema: headersSchema7,
  outputSchemas: { [HttpStatusCodes5.OK]: outputSchema5 }
});

// contracts/httpApi/listProjectBuildingPartMapsContract.ts
import { buildingPartMapEntitySchema } from "@afleya/project-schemas";
import {
  ApiGatewayContract as ApiGatewayContract8,
  HttpStatusCodes as HttpStatusCodes6
} from "@swarmion/serverless-contracts";
var pathParametersSchema8 = {
  type: "object",
  properties: {
    projectId: { type: "string" }
  },
  required: ["projectId"],
  additionalProperties: false
};
var headersSchema8 = {
  type: "object",
  properties: {
    authorization: { type: "string" }
  },
  required: ["authorization"]
};
var outputSchema6 = {
  type: "array",
  items: buildingPartMapEntitySchema
};
var listBuildingPartMapsContract = new ApiGatewayContract8({
  id: "listBuildingPartMapsContract",
  path: "/material-inputs/{projectId}/building-part-maps",
  method: "GET",
  integrationType: "httpApi",
  authorizerType: "cognito",
  pathParametersSchema: pathParametersSchema8,
  headersSchema: headersSchema8,
  outputSchemas: { [HttpStatusCodes6.OK]: outputSchema6 }
});

// contracts/httpApi/listProjectMaterialInputIconsContract.ts
import { materialInputIconEntitySchema } from "@afleya/project-schemas";
import {
  ApiGatewayContract as ApiGatewayContract9,
  HttpStatusCodes as HttpStatusCodes7
} from "@swarmion/serverless-contracts";
var pathParametersSchema9 = {
  type: "object",
  properties: {
    projectId: { type: "string" }
  },
  required: ["projectId"],
  additionalProperties: false
};
var queryStringParametersSchema = {
  type: "object",
  properties: {
    skip: { type: "string" },
    take: { type: "string" },
    buildingPartId: { type: "string" },
    considerChildBuildingParts: { type: "string" }
  },
  required: ["skip", "take"],
  additionalProperties: false
};
var headersSchema9 = {
  type: "object",
  properties: {
    authorization: { type: "string" }
  },
  required: ["authorization"]
};
var outputSchema7 = {
  type: "array",
  items: materialInputIconEntitySchema
};
var listMaterialInputIconsContract = new ApiGatewayContract9({
  id: "listMaterialInputIconsContract",
  path: "/material-inputs/{projectId}/material-input-icons",
  method: "GET",
  integrationType: "httpApi",
  authorizerType: "cognito",
  pathParametersSchema: pathParametersSchema9,
  queryStringParametersSchema,
  headersSchema: headersSchema9,
  outputSchemas: { [HttpStatusCodes7.OK]: outputSchema7 }
});

// contracts/httpApi/listProjectMaterialInputsPicturesContract.ts
import { materialInputPictureEntitySchema } from "@afleya/project-schemas";
import {
  ApiGatewayContract as ApiGatewayContract10,
  HttpStatusCodes as HttpStatusCodes8
} from "@swarmion/serverless-contracts";
var pathParametersSchema10 = {
  type: "object",
  properties: {
    projectId: { type: "string" }
  },
  required: ["projectId"],
  additionalProperties: false
};
var headersSchema10 = {
  type: "object",
  properties: {
    authorization: { type: "string" }
  },
  required: ["authorization"]
};
var queryStringParametersSchema2 = {
  type: "object",
  properties: {
    skip: { type: "string" },
    take: { type: "string" }
  }
};
var outputSchema8 = {
  type: "array",
  items: materialInputPictureEntitySchema
};
var listMaterialInputsPicturesContract = new ApiGatewayContract10({
  id: "listMaterialInputsPicturesContract",
  path: "/material-inputs/{projectId}/pictures",
  method: "GET",
  integrationType: "httpApi",
  authorizerType: "cognito",
  pathParametersSchema: pathParametersSchema10,
  headersSchema: headersSchema10,
  queryStringParametersSchema: queryStringParametersSchema2,
  outputSchemas: { [HttpStatusCodes8.OK]: outputSchema8 }
});

// contracts/httpApi/listScenarioDocumentsUrlContract.ts
import {
  ApiGatewayContract as ApiGatewayContract11,
  HttpStatusCodes as HttpStatusCodes9
} from "@swarmion/serverless-contracts";
var pathParametersSchema11 = {
  type: "object",
  properties: {
    projectId: { type: "string" },
    scenarioId: { type: "string" },
    documentType: { type: "string" }
  },
  required: ["projectId", "scenarioId", "documentType"],
  additionalProperties: false
};
var headersSchema11 = {
  type: "object",
  properties: {
    authorization: { type: "string" }
  },
  required: ["authorization"]
};
var documentNameAndUrl = {
  type: "object",
  properties: {
    scenarioDocumentName: { type: "string" },
    url: { type: "string" }
  },
  required: ["scenarioDocumentName", "url"]
};
var outputSchema9 = {
  type: "array",
  items: documentNameAndUrl
};
var listScenarioDocumentsUrlContract = new ApiGatewayContract11({
  id: "listScenarioDocumentsUrlContract",
  path: "/material-inputs/{projectId}/scenarios/{scenarioId}/pdf-exports/{documentType}",
  method: "GET",
  integrationType: "httpApi",
  authorizerType: "cognito",
  pathParametersSchema: pathParametersSchema11,
  headersSchema: headersSchema11,
  outputSchemas: { [HttpStatusCodes9.OK]: outputSchema9 }
});

// contracts/httpApi/materialInputsGroup/listMaterialInputsGroupsWithNbPictureContract.ts
import {
  ApiGatewayContract as ApiGatewayContract12,
  HttpStatusCodes as HttpStatusCodes10
} from "@swarmion/serverless-contracts";
import { headersSchema as headersSchema12 } from "@afleya/common";
import { materialInputsGroupWithNbPictureOutputSchema } from "@afleya/project-schemas";
var pathParametersSchema12 = {
  type: "object",
  properties: {
    projectId: { type: "string" }
  },
  required: ["projectId"],
  additionalProperties: false
};
var outputsSchema = {
  type: "array",
  items: materialInputsGroupWithNbPictureOutputSchema
};
var listMaterialInputsGroupsWithNbPictureContract = new ApiGatewayContract12({
  id: "listMaterialInputsGroupsWithNbPictureContract",
  path: "/project/{projectId}/material-inputs-groups-with-nb-picture",
  method: "GET",
  integrationType: "httpApi",
  authorizerType: "cognito",
  pathParametersSchema: pathParametersSchema12,
  headersSchema: headersSchema12,
  outputSchemas: { [HttpStatusCodes10.OK]: outputsSchema }
});

// contracts/httpApi/syncProtocolContract.ts
import {
  ApiGatewayContract as ApiGatewayContract13,
  HttpStatusCodes as HttpStatusCodes11
} from "@swarmion/serverless-contracts";
var headersSchema13 = {
  type: "object",
  properties: {
    authorization: { type: "string" }
  },
  required: ["authorization"]
};
var bodySchema = {
  type: "array",
  items: {
    type: "object"
  }
};
var outputSchema10 = {
  type: "number"
};
var syncProtocolContract = new ApiGatewayContract13({
  id: "SyncProtocolContract",
  path: "/material-inputs/sync-protocol",
  method: "POST",
  integrationType: "httpApi",
  authorizerType: "cognito",
  bodySchema,
  headersSchema: headersSchema13,
  outputSchemas: { [HttpStatusCodes11.OK]: outputSchema10 }
});

// contracts/eventBridge/typebridgeBus.ts
var EventSources = /* @__PURE__ */ ((EventSources2) => {
  EventSources2["MATERIAL"] = "akibo-material";
  EventSources2["MATERIAL_INPUTS"] = "akibo-material-inputs";
  EventSources2["REPORT_GENERATION"] = "akibo-report-generation";
  EventSources2["PDF_GENERATION"] = "akibo-pdf-generation";
  return EventSources2;
})(EventSources || {});
var EventTypes = /* @__PURE__ */ ((EventTypes2) => {
  EventTypes2["GENERATE_CATALOG_PHOTO"] = "GENERATE_CATALOG_PHOTO";
  EventTypes2["GENERATE_MATERIAL_INPUTS_GROUP_SHEET"] = "GENERATE_MATERIAL_INPUTS_GROUP_SHEET";
  EventTypes2["GENERATE_QUALITAT_MATERIAL_INPUTS_GROUP_SHEET"] = "GENERATE_QUALITAT_MATERIAL_INPUTS_GROUP_SHEET";
  EventTypes2["GENERATE_OMEGA_MATERIAL_INPUTS_GROUP_SHEET"] = "GENERATE_OMEGA_MATERIAL_INPUTS_GROUP_SHEET";
  EventTypes2["GENERATE_APAVE_MATERIAL_INPUTS_GROUP_SHEET"] = "GENERATE_APAVE_MATERIAL_INPUTS_GROUP_SHEET";
  EventTypes2["UPDATE_SCENARIO_DOCUMENT_STATUS"] = "UPDATE_SCENARIO_DOCUMENT_STATUS";
  EventTypes2["GENERATE_ZIP_PICTURES"] = "GENERATE_ZIP_PICTURES";
  EventTypes2["MATERIAL_WEIGHT_UPDATED"] = "MATERIAL_WEIGHT_UPDATED";
  EventTypes2["GENERATE_ZIP_MATERIAL_INPUTS_GROUP_SHEET"] = "GENERATE_ZIP_MATERIAL_INPUTS_GROUP_SHEET";
  EventTypes2["GENERATE_ALPES_CONTROLES_MATERIAL_INPUTS_GROUP_SHEET"] = "GENERATE_ALPES_CONTROLES_MATERIAL_INPUTS_GROUP_SHEET";
  return EventTypes2;
})(EventTypes || {});

// contracts/eventBridge/materialUpdatedEvent.ts
import { EventBridgeContract } from "@swarmion/serverless-contracts";
var materialUpdatedEventPayload = {
  type: "object",
  properties: {
    materialId: { type: "string" }
  },
  required: ["materialId"],
  additionalProperties: false
};
var materialUpdatedEventContract = new EventBridgeContract({
  id: "MaterialUpdatedEventContract",
  eventType: "MATERIAL_WEIGHT_UPDATED" /* MATERIAL_WEIGHT_UPDATED */,
  sources: ["akibo-material" /* MATERIAL */],
  payloadSchema: materialUpdatedEventPayload
});
export {
  EventSources,
  EventTypes,
  bodySchema,
  deleteBuildingPartMapContract,
  deleteMaterialInputPictureContract,
  downloadImageContract,
  generatePresignedDownloadUrlContract,
  generatePresignedImageUploadUrlContract,
  generatePresignedUploadUrlContract,
  getReuseWeightByWasteCategoryContract,
  listBuildingPartMapsContract,
  listMaterialInputIconsContract,
  listMaterialInputsGroupsWithNbPictureContract,
  listMaterialInputsPicturesContract,
  listScenarioDocumentsUrlContract,
  materialUpdatedEventContract,
  syncProtocolContract
};
