import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  CharacteristicsEntity,
  CharacteristicValuesEntity,
} from '@afleya/material-schemas';
import { useEffect, useState } from 'react';
import PageHeader from 'components/PageHeader';
import { updateCharacteristics } from 'services/networking/requests/traceability/updateCharacteristics';
import { checkIfCharacteristicsExist } from 'services/offline/requests/material/checkIfCharacteristicsExist';
import { CreateOrEditCharacteristicForm } from './components/CreateOrEditCharacteristicForm';
import { Characteristic } from './CharacteristicType';

interface Props {
  setCharacteristics: (characteristics: Characteristic[]) => void;
  setDisplayCreateCharacteristic: (display: boolean) => void;
  materialName: string;
  characteristicNames: CharacteristicsEntity[];
  characteristicValues: CharacteristicValuesEntity[];
  characteristics: Characteristic[];
  materialId?: string;
  projectId?: string;
}

export const CreateOrEditCharacteristic = ({
  setDisplayCreateCharacteristic,
  setCharacteristics,
  materialName,
  characteristicValues,
  characteristicNames,
  characteristics,
  materialId = '',
  projectId = '',
}: Props): JSX.Element => {
  const intl = useIntl();
  const [tempCharacteristics, setTempCharacteristics] =
    useState(characteristics);

  const handleCharacteristicChange = (
    characteristicId: string,
    values: string[],
  ) => {
    setTempCharacteristics(prevState => {
      return updateCharacteristics(prevState, characteristicId, values);
    });
  };
  const [isCharacterized, setIsCharacterized] = useState(false);

  useEffect(() => {
    const checkCharacteristics = async () => {
      const result = await checkIfCharacteristicsExist(
        materialId,
        materialName,
        projectId,
      );
      setIsCharacterized(result);
    };

    void checkCharacteristics();
  }, [materialId, materialName, projectId]);

  return (
    <Dialog fullScreen open={true}>
      <DialogTitle>
        <PageHeader
          title={intl.formatMessage(
            {
              id: 'characteristic-input-page.title',
            },
            {
              materialName: materialName,
            },
          )}
        />
        <Typography>
          <FormattedMessage id={'characteristic-input-page.subtitle'} />
        </Typography>
        {isCharacterized && (
          <Alert severity="warning">
            <FormattedMessage
              id={'characteristic-input-page.isCharacterized'}
            />
          </Alert>
        )}
      </DialogTitle>
      <form>
        <DialogContent>
          <CreateOrEditCharacteristicForm
            characteristics={characteristicNames}
            characteristicValues={characteristicValues}
            tempCharacteristics={tempCharacteristics}
            handleCharacteristicChange={handleCharacteristicChange}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setDisplayCreateCharacteristic(false);
            }}
            style={{
              marginRight: '0.625em',
            }}
          >
            <FormattedMessage id="characteristic-input-page.cancelButton" />
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setCharacteristics(tempCharacteristics);
              setDisplayCreateCharacteristic(false);
            }}
          >
            <FormattedMessage id="characteristic-input-page.submitButton" />
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
