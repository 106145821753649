import BadgeIcon from '@mui/icons-material/Badge';
import BarChartIcon from '@mui/icons-material/BarChart';
import Construction from '@mui/icons-material/Construction';
import ProjectIcon from '@mui/icons-material/Inventory';
import ButtonNavigationIcon from '@mui/icons-material/Menu';
import UserIcon from '@mui/icons-material/Person';
import { Box, Drawer, IconButton, List } from '@mui/material';
import { PageContent } from 'components/PageContent';
import { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { routes } from 'routes';
import { RootState } from 'store';
import { isAkiboAdmin } from '@afleya/common';
import { ListItemNavigation } from './ListItemNavigation';
const Navigation = (): JSX.Element => {
  const [navigationDrawerOpen, setNavigationDrawerOpen] =
    useState<boolean>(false);

  const isUserAdmin = isAkiboAdmin(
    useSelector(
      (state: RootState) => state.userProfileState.userProfile?.groups,
    ) ?? [],
  );

  return (
    <>
      <IconButton
        color="primary"
        data-test="sideMenu"
        onClick={() => setNavigationDrawerOpen(true)}
      >
        <ButtonNavigationIcon />
      </IconButton>
      <Drawer
        anchor="left"
        open={navigationDrawerOpen}
        onClose={() => setNavigationDrawerOpen(false)}
      >
        <PageContent>
          <Box sx={{ width: 200 }}>
            <List data-test="sideMenuItems">
              <ListItemNavigation
                dataTest="menuItemProjects"
                textId="navigation.list-project"
                route={routes.LIST_PROJECTS}
                icon={<ProjectIcon color="primary" />}
              />
              <ListItemNavigation
                dataTest="menuItemMaterials"
                textId="navigation.list-organization-materials"
                route={routes.LIST_ORGANIZATION_MATERIALS}
                icon={<Construction color="primary" />}
              />
              {isUserAdmin && (
                <Fragment>
                  <ListItemNavigation
                    dataTest="menuItemManageOrganizationUsers"
                    textId="navigation.manage-organization-users"
                    route={routes.MANAGE_ORGANIZATION_USERS}
                    icon={<UserIcon color="primary" />}
                  />
                  <ListItemNavigation
                    dataTest="menuItemManageOrganizationProjectAccesses"
                    textId="navigation.manage-organization-project-accesses"
                    route={routes.MANAGE_ORGANIZATION_PROJECT_ACCESSES}
                    icon={<BadgeIcon color="primary" />}
                  />
                </Fragment>
              )}
              {isUserAdmin && (
                <ListItemNavigation
                  dataTest="menuDashboardClient"
                  textId="navigation.dashboard-client"
                  route={routes.DASHBOARD_CLIENT}
                  icon={<BarChartIcon color="primary" />}
                />
              )}
            </List>
          </Box>
        </PageContent>
      </Drawer>
    </>
  );
};

export default Navigation;
