import { MaterialEntity } from '@afleya/material-schemas';
import { BuildingEntity, BuildingPartEntity } from '@afleya/project-schemas';
import PageHeader from 'components/PageHeader';
import { useIntl } from 'react-intl';
import { routes } from 'routes';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { getBuildingAndBuildingPartNames } from '@afleya/common';

interface Props {
  projectId: string;
  building?: BuildingEntity;
  buildingPart?: BuildingPartEntity;
  material: MaterialEntity;
  motherBuildingPartName?: string;
}

export const InputModuleInputPageHeader = ({
  projectId,
  building,
  buildingPart,
  material,
  motherBuildingPartName,
}: Props): JSX.Element => {
  const intl = useIntl();

  const project = useSelector(
    (state: RootState) => state.offlineProject.project,
  );

  return (
    <PageHeader
      title={intl.formatMessage(
        {
          id: 'input-module-input-page.title',
        },
        {
          materialName: material.name,
        },
      )}
      subtitle={intl.formatMessage({
        id: 'input-module-input-page.subtitle',
      })}
      locationTitle={intl.formatMessage(
        {
          id: 'input-module-input-page.locationTitle',
        },
        {
          projectName: project?.projectName,
          buildingAndbuildingPartNames: getBuildingAndBuildingPartNames(
            building?.buildingName ?? '',
            buildingPart?.buildingPartName ?? '',
            motherBuildingPartName,
          ),
        },
      )}
      backLinkTitle={intl.formatMessage({
        id: 'input-module-input-page.backLinkTitle',
      })}
      backLinkPath={routes.INPUT_MODULE_CATEGORIES.replace(
        ':projectId',
        projectId,
      )
        .replace(':buildingId', building?.id ?? '')
        .replace(':buildingPartId', buildingPart?.id ?? '')}
    />
  );
};
