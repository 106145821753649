import { Autocomplete, TextField } from '@mui/material';
import BoxAlert from 'components/BoxAlert';
import LoadingBox from 'components/LoadingBox';
import { Control, Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useAsync } from 'react-use';
import { MaterialInputFormEntity } from 'services/materialInputs';
import { listLocalBuildingParts } from 'services/offline/requests/buildingAndBuildingPart/listLocalBuildingParts';
import { sortBuildingPartsByFloorLevel } from 'services/projects/sortBuildingParts';

interface Props {
  control: Control<MaterialInputFormEntity>;
  buildingId: string;
  motherBuildingPartId: string;
}

export const ChildBuildingPartId = ({
  control,
  buildingId,
  motherBuildingPartId,
}: Props): JSX.Element => {
  const intl = useIntl();

  const {
    loading,
    error,
    value: childBuildingParts,
  } = useAsync(async () => {
    const buildingParts = await listLocalBuildingParts({
      buildingId,
      motherBuildingPartId,
    });

    return sortBuildingPartsByFloorLevel(buildingParts);
  }, [buildingId, motherBuildingPartId]);

  return error ? (
    <BoxAlert contentId="material-input-form.error.childBuildingPartIdLoadingError" />
  ) : loading || childBuildingParts === undefined ? (
    <LoadingBox />
  ) : (
    <Controller
      name="childBuildingPartId"
      control={control}
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          sx={{ marginTop: '16px', marginBottom: '8px' }}
          options={childBuildingParts}
          getOptionLabel={option => option.buildingPartName}
          value={childBuildingParts.find(child => child.id === value) ?? null}
          onChange={(_event, item) => onChange(item?.id ?? null)}
          renderInput={params => (
            <TextField
              {...params}
              data-test="childBuildingPartId"
              variant="outlined"
              color="secondary"
              margin="normal"
              fullWidth
              label={intl.formatMessage({
                id: 'material-input-form.label.childBuildingPartId',
              })}
            />
          )}
        />
      )}
    />
  );
};
