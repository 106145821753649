export interface TargetLocation {
  buildingId: string | null;
  buildingPartId: string | null;
  daugtherBuildingPartId: string | null;
  isValid: boolean;
}

export const updateTargetLocation = (
  targetLocation: TargetLocation,
  update: Partial<TargetLocation>,
): TargetLocation => {
  if (update.buildingId !== undefined) {
    if (targetLocation.buildingId !== update.buildingId) {
      return {
        buildingId: update.buildingId,
        buildingPartId: null,
        daugtherBuildingPartId: null,
        isValid: false,
      };
    }
  } else if (update.buildingPartId !== undefined) {
    if (targetLocation.buildingPartId !== update.buildingPartId) {
      return {
        buildingId: targetLocation.buildingId,
        buildingPartId: update.buildingPartId,
        daugtherBuildingPartId: null,
        isValid: true,
      };
    }
  } else if (update.daugtherBuildingPartId !== undefined) {
    if (
      targetLocation.daugtherBuildingPartId !== update.daugtherBuildingPartId
    ) {
      return {
        buildingId: targetLocation.buildingId,
        buildingPartId: targetLocation.buildingPartId,
        daugtherBuildingPartId: update.daugtherBuildingPartId,
        isValid: true,
      };
    } else {
      return {
        buildingId: targetLocation.buildingId,
        buildingPartId: targetLocation.buildingPartId,
        daugtherBuildingPartId: null,
        isValid: true,
      };
    }
  }

  return targetLocation;
};
