import { LocalMaterialInputPicture } from 'services/materialInputsPictures/type';
import { materialInputsPicturesDatabase } from '../../dexieDB';

export const getMaterialInputPicture = async (
  materialInputPictureId: string,
): Promise<LocalMaterialInputPicture> => {
  const materialInputPicture =
    // @ts-ignore dexie is mistyped
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    (await materialInputsPicturesDatabase.materialInputsPictures.get(
      materialInputPictureId,
    )) as LocalMaterialInputPicture;

  return materialInputPicture;
};
