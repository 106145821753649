import { MaterialCategoryEntity } from '@afleya/material-schemas';
import { Box } from '@mui/material';
import { ListMaterials } from 'components/MaterialsSearch/ListMaterials';
import { ListNicknames } from 'components/MaterialsSearch/ListNicknames';

interface ListNicknamesAndMaterialsProps {
  selectedMotherCategories: MaterialCategoryEntity[];
  selectedDaughterCategories: MaterialCategoryEntity[];
  categories: MaterialCategoryEntity[];
  projectId: string;
  debouncedSearchTerms: string;
  handleNavigate: (
    materialId: string,
    categoryId: string,
    materialInputId?: string,
  ) => void;
  nbNicknamesToFetch: number;
  nbMaterialsToFetch: number;
}

export const ListNicknamesAndMaterials = ({
  selectedMotherCategories,
  selectedDaughterCategories,
  categories,
  projectId,
  debouncedSearchTerms,
  handleNavigate,
  nbNicknamesToFetch,
  nbMaterialsToFetch,
}: ListNicknamesAndMaterialsProps): JSX.Element => {
  return (
    <Box>
      <ListNicknames
        selectedMotherCategories={selectedMotherCategories}
        selectedDaughterCategories={selectedDaughterCategories}
        categories={categories}
        projectId={projectId}
        debouncedSearchTerms={debouncedSearchTerms}
        handleNavigate={handleNavigate}
        nbNicknamesToFetch={nbNicknamesToFetch}
      />
      <ListMaterials
        selectedMotherCategories={selectedMotherCategories}
        selectedDaughterCategories={selectedDaughterCategories}
        categories={categories}
        debouncedSearchTerms={debouncedSearchTerms}
        handleNavigate={handleNavigate}
        nbMaterialsToFetch={nbMaterialsToFetch}
      />
    </Box>
  );
};
