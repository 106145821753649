import { AgeTypes } from '@afleya/common';
import { FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import { Fragment } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { MaterialInputFormEntity } from 'services/materialInputs';

interface Props {
  control: Control<MaterialInputFormEntity>;
}

export const Age = ({ control }: Props): JSX.Element => {
  const intl = useIntl();

  return (
    <Fragment>
      <FormLabel component="legend">
        {intl.formatMessage({
          id: 'material-input-form.label.age.legend',
        })}
      </FormLabel>
      <Controller
        name="age"
        control={control}
        defaultValue={AgeTypes.betweenTenAndFifty}
        render={({ field: { onChange, value } }) => (
          <RadioGroup
            value={value}
            row={true}
            aria-label="age"
            name="age"
            onChange={onChange}
          >
            <FormControlLabel
              value={AgeTypes.twoYears}
              control={<Radio />}
              label={intl.formatMessage({
                id: 'material-input-form.label.age.twoYears',
              })}
            />
            <FormControlLabel
              value={AgeTypes.betweenTwoAndTen}
              control={<Radio />}
              label={intl.formatMessage({
                id: 'material-input-form.label.age.betweenTwoAndTen',
              })}
            />
            <FormControlLabel
              value={AgeTypes.betweenTenAndFifty}
              control={<Radio />}
              label={intl.formatMessage({
                id: 'material-input-form.label.age.betweenTenAndFifty',
              })}
            />
            <FormControlLabel
              value={AgeTypes.greaterThanFifty}
              control={<Radio />}
              label={intl.formatMessage({
                id: 'material-input-form.label.age.greaterThanFifty',
              })}
            />
          </RadioGroup>
        )}
      />
    </Fragment>
  );
};
