import { v4 as uuidV4 } from 'uuid';
import { BuildingPartType } from '@afleya/common';
import { LocalBuildingPart } from './types';

interface Props {
  buildingPartName: string;
  motherBuildingPartId: string | null;
  buildingId: string;
  floorLevel: number;
  buildintPartType?: BuildingPartType;
}
export const formatLocalBuildingPart = ({
  buildingId,
  motherBuildingPartId,
  buildingPartName,
  floorLevel,
  buildintPartType,
}: Props): LocalBuildingPart => {
  const id = uuidV4();

  return {
    id,
    buildingId: buildingId,
    buildingPartName: buildingPartName,
    motherBuildingPartId: motherBuildingPartId,
    buildingPartFloorLevel: floorLevel,
    buildingPartType: buildintPartType ?? BuildingPartType.FLOOR,
  };
};
