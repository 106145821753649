import LoadingBox from 'components/LoadingBox';
import { Control, useFieldArray } from 'react-hook-form';
import { useAsync } from 'react-use';
import { DuplicateBuildingPartFormAnswersSchema } from 'services/buildingPart/validationSchema';
import { getBuildingPart } from 'services/offline/requests/buildingAndBuildingPart/getBuildingPart';
import { NameField } from './NameField';

interface Props {
  numberOfDuplicateBuildingParts: number;
  control: Control<DuplicateBuildingPartFormAnswersSchema>;
  buildingPartId: string;
}

export const DuplicateBuildingPartFields = ({
  numberOfDuplicateBuildingParts,
  control,
  buildingPartId,
}: Props): JSX.Element => {
  const { loading, value: buildingPart } = useAsync(() =>
    getBuildingPart(buildingPartId),
  );
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'buildingPartNames',
  });

  const diff = numberOfDuplicateBuildingParts - fields.length;

  if (diff > 0) {
    for (let i = 0; i < diff; i++) {
      append({
        buildingPartName: `${buildingPart?.buildingPartName} (${++fields.length})`,
      });
    }
  } else if (diff < 0) {
    for (let i = 0; i < Math.abs(diff); i++) {
      remove(fields.length - 1);
    }
  }

  return loading || buildingPart === undefined ? (
    <LoadingBox />
  ) : (
    <>
      {Array.from({ length: numberOfDuplicateBuildingParts }, (_, index) => (
        <div
          key={index}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <NameField
            control={control}
            index={index}
            update={update}
            fields={fields}
          />
        </div>
      ))}
    </>
  );
};
