import { BuildingPartEntity } from '@afleya/project-schemas';

export const sortBuildingPartsByMother = (
  buildingParts: BuildingPartEntity[],
): BuildingPartEntity[] => {
  const sortedBuildingParts: BuildingPartEntity[] = [];
  buildingParts = sortBuildingPartsByFloorLevel(buildingParts);

  const motherBuildingParts = buildingParts.filter(
    buildingPart => buildingPart.motherBuildingPartId === null,
  );

  motherBuildingParts.forEach(mother => {
    sortedBuildingParts.push(mother);
    sortedBuildingParts.push(
      ...buildingParts.filter(
        buildingPart => buildingPart.motherBuildingPartId === mother.id,
      ),
    );
  });

  return sortedBuildingParts;
};

export const sortBuildingPartsByFloorLevel = (
  buildingParts: BuildingPartEntity[],
): BuildingPartEntity[] => {
  const buildingPartsSorted = buildingParts.sort((a, b) => {
    const floorLevelA = a.buildingPartFloorLevel ?? Number.NEGATIVE_INFINITY;
    const floorLevelB = b.buildingPartFloorLevel ?? Number.NEGATIVE_INFINITY;

    const floorLevelComparison = floorLevelA - floorLevelB;

    if (floorLevelComparison === 0) {
      return a.buildingPartName.localeCompare(b.buildingPartName);
    }

    return floorLevelComparison;
  });

  return buildingPartsSorted;
};
