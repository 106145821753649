import { Button, Stack } from '@mui/material';
import { BuildingPartMaps } from 'components/BuildingPartMaps';
import PageFooter from 'components/PageFooter';
import PageHeader from 'components/PageHeader';
import { Fragment, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { useAsync } from 'react-use';
import { LocalMaterialInput } from 'services/materialInputs/types';
import { navigateToModuleInputCategories } from 'services/navigation/navigateToModuleInputCategories';
import { navigateToModuleInputPhotos } from 'services/navigation/navigateToModuleInputPhotos';
import { getMaterialInput } from 'services/offline/requests/materialInput/getMaterialInput';
import { listBuildingPartMapIds } from 'services/offline/requests/buildingAndBuildingPart/listBuildingPartMapIds';
export const InputModuleInputSelectMapPage = (): JSX.Element => {
  const intl = useIntl();
  const navigate = useNavigate();

  const { projectId, buildingId, buildingPartId, materialInputId } = useParams<{
    projectId: string;
    buildingId: string;
    buildingPartId: string;
    materialInputId: string;
  }>();

  const [buildingPartMapIds, setBuildingPartMapIds] = useState<string[]>([]);

  useAsync(async () => {
    const localBuildingPartMapIds: string[] = await listBuildingPartMapIds(
      buildingPartId ?? '',
    );
    setBuildingPartMapIds(localBuildingPartMapIds);
  }, [buildingPartId]);

  const [material, setMaterial] = useState<LocalMaterialInput>();

  useAsync(async () => {
    const localMaterial: LocalMaterialInput = await getMaterialInput(
      materialInputId ?? '',
    );
    setMaterial(localMaterial);
  }, [materialInputId]);

  if (
    materialInputId === undefined ||
    projectId === undefined ||
    buildingPartId === undefined ||
    material === undefined
  ) {
    return <></>;
  }

  return (
    <Fragment>
      <PageHeader
        title={intl.formatMessage({
          id: 'map-module.moduleTitle',
        })}
      />
      <BuildingPartMaps
        buildingPartId={buildingPartId}
        projectId={projectId}
        buildingPartMapIds={buildingPartMapIds}
        setBuildingPartMapIds={setBuildingPartMapIds}
        onlyInputId={materialInputId}
      />
      <PageFooter>
        <Stack direction="row" spacing={1} justifyContent="center">
          <Button
            variant="contained"
            color="secondary"
            onClick={() =>
              navigateToModuleInputCategories({
                projectId,
                buildingId,
                buildingPartId,
                navigate,
              })
            }
          >
            {intl.formatMessage({
              id: 'input-module-input-page.addAnotherMaterial',
            })}
          </Button>{' '}
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              navigateToModuleInputPhotos({
                materialInputId,
                projectId,
                buildingId,
                buildingPartId,
                navigate,
              })
            }
          >
            {intl.formatMessage({ id: 'map-module.photoMaterial' })}
          </Button>
        </Stack>
      </PageFooter>
    </Fragment>
  );
};
