import {
  InputMethod,
  InputUnit,
  MaterialEvaluationMethod,
} from '@afleya/common';
import { Stack } from '@mui/material';
import { Control, UseFormGetValues } from 'react-hook-form';
import { MaterialInputFormEntity } from 'services/materialInputs';
import { DimensionField } from './DimensionField';

interface Props {
  control: Control<MaterialInputFormEntity>;
  getValues: UseFormGetValues<MaterialInputFormEntity>;
  inputMethod: InputMethod;
  inputUnit: InputUnit;
}

export const DimensionFields = ({
  control,
  getValues,
  inputMethod,
  inputUnit,
}: Props): JSX.Element => {
  const evaluationMethod = getValues('evaluationMethod');
  const allEvaluation = [
    MaterialEvaluationMethod.LINEAR,
    MaterialEvaluationMethod.SURFACE,
    MaterialEvaluationMethod.VOLUME,
  ];
  const evaluation2D3D = [
    MaterialEvaluationMethod.SURFACE,
    MaterialEvaluationMethod.VOLUME,
  ];
  const evaluation3D = [MaterialEvaluationMethod.VOLUME];

  return (
    <Stack>
      {allEvaluation.includes(evaluationMethod) && (
        <DimensionField
          control={control}
          fieldName="length"
          inputMethod={inputMethod}
          inputUnit={inputUnit}
        />
      )}
      {evaluation2D3D.includes(evaluationMethod) &&
        inputMethod === InputMethod.DIMENSIONS && (
          <DimensionField
            control={control}
            fieldName="width"
            inputMethod={inputMethod}
            inputUnit={inputUnit}
          />
        )}
      {evaluation3D.includes(evaluationMethod) &&
        inputMethod === InputMethod.DIMENSIONS && (
          <DimensionField
            control={control}
            fieldName="height"
            inputMethod={inputMethod}
            inputUnit={inputUnit}
          />
        )}
    </Stack>
  );
};
