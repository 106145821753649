import { MaterialCategoryEntity } from '@afleya/material-schemas';
import { Backdrop, Box, CircularProgress, Grid2, Stack } from '@mui/material';
import { PageContent } from 'components/PageContent';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useAsync } from 'react-use';
import { navigateToModuleInput } from 'services/navigation/navigateToModuleInput';
import { getBuildingAndBuildingPart } from 'services/offline/requests/buildingAndBuildingPart/getBuildingAndBuildingPart';
import { getBuildingPart } from 'services/offline/requests/buildingAndBuildingPart/getBuildingPart';
import { listCategories } from 'services/offline/requests/material/listCategories';
import { RootState, store } from 'store';
import Filter from 'components/Filter';
import { ImageDisplay } from './components/ImageDisplay';
import { ImageRecognitionPageHeader } from './components/ImageRecognitionPageHeader';
import { ListNicknamesAndMaterials } from './components/ListNicknamesAndMaterials';
import { PredictionsDisplay } from './components/PredictionsDisplay';
import { getLocationTitle } from './getLocationTitle';
import { handleImageInference } from './handleImageInference';
import { handleSelectedPrediction } from './handleSelectedPrediction';

export const InputModuleImageRecognition = (): JSX.Element => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [imageSrc, setImageSrc] = useState<string>('');
  const [inputImage] = useState(store.getState().inputImageRecognition);
  const [selectedPredictions, setSelectedPredictions] = useState<string[]>([]);

  const { projectId, buildingId, buildingPartId } = useParams<{
    projectId: string;
    buildingId: string;
    buildingPartId: string;
  }>();

  const project = useSelector(
    (state: RootState) => state.offlineProject.project,
  );
  const { value: data } = useAsync(async () => {
    const { building, buildingPart } = await getBuildingAndBuildingPart(
      buildingId ?? '',
      buildingPartId ?? '',
    );
    const categories = await listCategories();
    const motherCategories = categories.filter(
      category => category.motherId === null,
    );

    return { building, buildingPart, categories, motherCategories };
  }, [buildingId, buildingPartId]);

  const { value: motherBuildingPartName } = useAsync(async () => {
    const motherBuildingPartId = data?.buildingPart.motherBuildingPartId;

    return motherBuildingPartId === null
      ? undefined
      : (await getBuildingPart(motherBuildingPartId ?? '')).buildingPartName;
  }, [data]);
  const [filter, setFilter] = useState<string>('');
  const [selectedMotherCategories, setSelectedMotherCategories] = useState<
    MaterialCategoryEntity[]
  >([]);
  const [selectedDaughterCategories, setSelectedDaughterCategories] = useState<
    MaterialCategoryEntity[]
  >([]);
  const categories = data ? data.categories : [];
  const locationTitle = getLocationTitle({
    intl,
    projectName: project?.projectName,
    buildingName: data?.building.buildingName,
    buildingPartName: data?.buildingPart.buildingPartName,
    motherBuildingPartName,
  });
  useEffect(() => {
    if (inputImage.image !== '') {
      setImageSrc(inputImage.image);
    }
  }, [inputImage.image]);

  useEffect(() => {
    if (imageSrc) {
      void handleImageInference({
        imageSrc,
        setLoading,
        setSelectedPredictions,
        setSelectedMotherCategories,
        setSelectedDaughterCategories,
      });
    }
  }, [imageSrc]);

  const onSelectedPrediction = (prediction: string): void => {
    void handleSelectedPrediction(
      prediction,
      setSelectedMotherCategories,
      setSelectedDaughterCategories,
    );
  };

  if (
    projectId === undefined ||
    buildingId === undefined ||
    buildingPartId === undefined
  ) {
    navigate(-1);

    return <></>;
  }

  const handleNavigate = (
    materialId: string,
    categoryId: string,
    materialInputId?: string,
  ) => {
    navigateToModuleInput({
      materialInputId,
      projectId,
      buildingId,
      buildingPartId,
      categoryId,
      materialId,
      navigate,
    });
  };

  return (
    <Stack direction="column" height="100vh">
      <ImageRecognitionPageHeader
        locationTitle={locationTitle}
        projectId={projectId}
        buildingId={buildingId}
        buildingPartId={buildingPartId}
      />
      <PageContent overflow="auto">
        <Box sx={{ flexGrow: 1, marginBottom: '50px' }}>
          <Grid2 container style={{ marginTop: '1%' }} spacing={2}>
            <Grid2 size={6}>
              <ImageDisplay imageSrc={imageSrc} />
            </Grid2>
            <Grid2 size={6}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100%"
              >
                {!loading && (
                  <PredictionsDisplay
                    projectId={projectId}
                    buildingId={buildingId}
                    buildingPartId={buildingPartId}
                    selectedPredictions={selectedPredictions}
                    handleSelectedPrediction={onSelectedPrediction}
                  />
                )}
              </Box>
            </Grid2>
            <Grid2 size={11}>
              {selectedMotherCategories.length !== 0 &&
                selectedDaughterCategories.length !== 0 && (
                  <>
                    <Box marginTop={'2rem'}>
                      <Filter
                        label="input-module-categories-page.filterLabel"
                        placeholder="input-module-categories-page.filterPlaceholder"
                        setFilter={setFilter}
                      />
                    </Box>
                    <ListNicknamesAndMaterials
                      selectedMotherCategories={selectedMotherCategories}
                      selectedDaughterCategories={selectedDaughterCategories}
                      categories={categories}
                      projectId={projectId}
                      debouncedSearchTerms={filter}
                      handleNavigate={handleNavigate}
                      nbNicknamesToFetch={3}
                      nbMaterialsToFetch={5}
                    />
                  </>
                )}
            </Grid2>
          </Grid2>
        </Box>
        <Backdrop
          sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </PageContent>
    </Stack>
  );
};
