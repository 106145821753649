import { useIntl } from 'react-intl';

interface Color {
  name: string;
  code: string;
}

export const ColorList = (): Color[] => {
  const intl = useIntl();

  const colors: Color[] = [
    {
      name: intl.formatMessage({
        id: 'characteristic-input-page.colors.white',
      }),
      code: '#FFFFFF',
    },
    {
      name: intl.formatMessage({
        id: 'characteristic-input-page.colors.black',
      }),
      code: '#000000',
    },
    {
      name: intl.formatMessage({ id: 'characteristic-input-page.colors.grey' }),
      code: '#9F9F9F',
    },
    {
      name: intl.formatMessage({ id: 'characteristic-input-page.colors.red' }),
      code: '#FF0B0B',
    },
    {
      name: intl.formatMessage({
        id: 'characteristic-input-page.colors.yellow',
      }),
      code: '#FFDB00',
    },
    {
      name: intl.formatMessage({ id: 'characteristic-input-page.colors.blue' }),
      code: '#058FD9',
    },
    {
      name: intl.formatMessage({
        id: 'characteristic-input-page.colors.green',
      }),
      code: '#00B64E',
    },
    {
      name: intl.formatMessage({
        id: 'characteristic-input-page.colors.brown',
      }),
      code: '#7C481F',
    },
    {
      name: intl.formatMessage({
        id: 'characteristic-input-page.colors.multicolor',
      }),
      code: 'multicolor',
    },
  ];

  return colors;
};
