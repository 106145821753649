/* eslint-disable complexity */
import {
  AgeTypes,
  MaterialEvaluationMethod,
  NumberWithComma,
} from '@afleya/common';
import {
  boolean,
  nativeEnum,
  number,
  object,
  preprocess,
  RefinementCtx,
  string,
  TypeOf,
  ZodIssueCode,
} from 'zod';

export const materialInputFormSchema = object({
  nickname: string().min(1),
  childBuildingPartId: string().uuid().nullable(),
  locationDescription: string().optional(),
  amount: string().min(1),
  materialToDeduce: boolean(),
  hasAsbestos: boolean(),
  hasLead: boolean(),
  hasOtherPollution: boolean(),
  age: nativeEnum(AgeTypes),
  stateNewPercentage: number().min(0).max(100),
  stateGoodPercentage: number().min(0).max(100),
  stateMediumPercentage: number().min(0).max(100),
  stateObsoletePercentage: number().min(0).max(100),
  stateComments: string().optional(),
  assemblyTypeId: string().uuid().optional(),
  comments: string().optional(),
  evaluationMethod: nativeEnum(MaterialEvaluationMethod),
  length: string().optional(),
  width: string().optional(),
  height: string().optional(),
  reusePercentage: number().min(0).max(100),
  weightPerFunctionalUnit: preprocess(
    x => (x === null || x === undefined ? undefined : NumberWithComma(x)),
    number().gt(0).max(999999.99).optional(),
  ),
}).superRefine((data, ctx) => {
  const sumStates =
    data.stateNewPercentage +
    data.stateGoodPercentage +
    data.stateMediumPercentage +
    data.stateObsoletePercentage;
  if (sumStates !== 100) {
    ctx.addIssue({
      path: ['stateNewPercentage'],
      code: ZodIssueCode.custom,
      message: `${sumStates}`,
    });
  }

  const amountNumber = Number(data.amount);
  if (isNaN(amountNumber) || amountNumber < 1) {
    ctx.addIssue({
      path: ['amount'],
      code: ZodIssueCode.custom,
    });
  }
  const length = Number(data.length);
  const width = Number(data.width);
  const height = Number(data.height);

  validateDimesions({
    evaluationMethod: data.evaluationMethod,
    length,
    width,
    height,
    ctx,
  });
});

interface PropsValidateDimensions {
  evaluationMethod: MaterialEvaluationMethod;
  length: number;
  width: number;
  height: number;
  ctx: RefinementCtx;
}

const validateDimesions = ({
  evaluationMethod,
  length,
  width,
  height,
  ctx,
}: PropsValidateDimensions) => {
  const allEvaluation = [
    MaterialEvaluationMethod.LINEAR,
    MaterialEvaluationMethod.SURFACE,
    MaterialEvaluationMethod.VOLUME,
  ];
  const evaluation2D3D = [
    MaterialEvaluationMethod.SURFACE,
    MaterialEvaluationMethod.VOLUME,
  ];
  const evaluation3D = [MaterialEvaluationMethod.VOLUME];
  if (
    (isNaN(length) || length <= 0) &&
    allEvaluation.includes(evaluationMethod)
  ) {
    ctx.addIssue({
      path: ['length'],
      code: ZodIssueCode.custom,
    });
  }
  if (
    (isNaN(width) || width <= 0) &&
    evaluation2D3D.includes(evaluationMethod)
  ) {
    ctx.addIssue({
      path: ['width'],
      code: ZodIssueCode.custom,
    });
  }
  if (
    (isNaN(height) || height <= 0) &&
    evaluation3D.includes(evaluationMethod)
  ) {
    ctx.addIssue({
      path: ['height'],
      code: ZodIssueCode.custom,
    });
  }
};

export type MaterialInputFormEntity = TypeOf<typeof materialInputFormSchema>;
