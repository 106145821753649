import { CameraAlt, Edit } from '@mui/icons-material';
import { Badge, Checkbox, Chip, IconButton, TableCell } from '@mui/material';
import { Fragment } from 'react';
import { useIntl } from 'react-intl';
import { MaterialInputForRecap } from 'services/offline/materialInputsForRecap';

interface Props {
  checked: boolean;
  materialInput: MaterialInputForRecap;
  onClickEdit: (newEditedMaterialInput: MaterialInputForRecap) => void;
  onClickCamera: (newEditedMaterialInput: MaterialInputForRecap) => void;
  onClickCheckbox: (
    isChecked: boolean,
    materialInputForRecap: MaterialInputForRecap,
  ) => void;
}

export const InputModuleSummaryTableContent = ({
  checked,
  materialInput,
  onClickEdit,
  onClickCamera,
  onClickCheckbox,
}: Props): JSX.Element => {
  const intl = useIntl();

  return (
    <Fragment>
      <TableCell
        sx={{
          width: '5%',
          padding: 'checkbox',
        }}
      >
        <Checkbox
          id={materialInput.id}
          checked={checked}
          onChange={(_, isChecked) => onClickCheckbox(isChecked, materialInput)}
        />
      </TableCell>
      <TableCell sx={{ padding: 1 }}>
        {materialInput.nickname}
        {materialInput.toDeduce && (
          <Chip
            variant="outlined"
            size="small"
            color="error"
            label={intl.formatMessage({ id: 'input-module-summary.toDeduce' })}
            sx={{ marginLeft: 1 }}
          />
        )}
        {materialInput.hasCharacteristic && (
          <Chip
            variant="outlined"
            size="small"
            color="primary"
            label={intl.formatMessage({
              id: 'input-module-summary.characteristic',
            })}
            sx={{ marginLeft: 1 }}
          />
        )}
      </TableCell>
      <TableCell sx={{ padding: 1 }}>{materialInput.location}</TableCell>
      <TableCell sx={{ padding: 1 }} align="right">
        {materialInput.amount}
      </TableCell>
      <TableCell sx={{ padding: 1 }} align="right">
        {materialInput.reusePercentage}%
      </TableCell>
      <TableCell sx={{ padding: 1 }} align="right">
        {materialInput.dimensions}
      </TableCell>
      <TableCell sx={{ padding: 1 }} align="right">
        <IconButton onClick={() => onClickCamera(materialInput)}>
          <Badge
            max={3}
            color="secondary"
            badgeContent={materialInput.nbPictures}
          >
            <CameraAlt color="primary" />
          </Badge>
        </IconButton>
        <IconButton onClick={() => onClickEdit(materialInput)}>
          <Edit color="primary" />
        </IconButton>
      </TableCell>
    </Fragment>
  );
};
