import { AssemblyTypeEntity } from '@afleya/material-schemas';
import { materialsDatabase } from '../../dexieDB';

export const listAssemblyTypes = async (): Promise<AssemblyTypeEntity[]> => {
  const assemblyTypes = await materialsDatabase.assemblyTypes
    .reverse()
    .reverse()
    .sortBy('name');

  return assemblyTypes;
};
