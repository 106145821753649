import { Button } from '@mui/material';
import SuccessDrawer from 'components/SuccessDrawer';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes';

interface Props {
  projectId: string;
  buildingId: string;
  buildingPartId: string;
}

export const DuplicateMotherBuildingPartSuccessDrawer = ({
  projectId,
  buildingId,
  buildingPartId,
}: Props): JSX.Element => {
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <SuccessDrawer
      open={true}
      title={intl.formatMessage({
        id: 'input-module-duplicate-mother-building-part.successDrawerMessage',
      })}
    >
      <Button
        color="primary"
        variant="contained"
        onClick={() => {
          if (buildingPartId !== undefined) {
            navigate(
              routes.INPUT_MODULE_BUILDING_PARTS.replace(
                ':projectId',
                projectId,
              ).replace(':buildingId', buildingId),
            );
          }
        }}
      >
        <FormattedMessage id="input-module-duplicate-mother-building-part.validate" />
      </Button>
    </SuccessDrawer>
  );
};
