import {
  MaterialInputTable,
  stringIsMaterialInputTable,
} from '@afleya/sync-protocol-schemas';
import { IDatabaseChange } from 'dexie-observable/api';

export const updateMaterialInputUploadedStatus = (
  changes: IDatabaseChange[],
): IDatabaseChange[] => {
  return changes.reduce((acc, change) => {
    if ((change.type as number) !== 3) {
      if (stringIsMaterialInputTable(change.table)) {
        switch (change.table) {
          case MaterialInputTable.MaterialInput:
            acc.push({
              type: 2,
              table: MaterialInputTable.MaterialInput,
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              key: change.key,
              mods: { loadedFromServer: true },
              obj: undefined,
              oldObj: undefined,
            });
            break;
          case MaterialInputTable.MaterialInputIcon:
            acc.push({
              type: 2,
              table: MaterialInputTable.MaterialInputIcon,
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              key: change.key,
              mods: { uploaded: true },
              obj: undefined,
              oldObj: undefined,
            });
            break;
          case MaterialInputTable.Building:
            acc.push({
              type: 2,
              table: MaterialInputTable.Building,
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              key: change.key,
              mods: { uploaded: true },
              obj: undefined,
              oldObj: undefined,
            });
            break;
          case MaterialInputTable.BuildingPart:
            acc.push({
              type: 2,
              table: MaterialInputTable.BuildingPart,
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              key: change.key,
              mods: { uploaded: true },
              obj: undefined,
              oldObj: undefined,
            });
            break;
          case MaterialInputTable.CharacteristicInput:
            acc.push({
              type: 2,
              table: MaterialInputTable.CharacteristicInput,
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              key: change.key,
              mods: { uploaded: true },
              obj: undefined,
              oldObj: undefined,
            });
            break;
        }
      }
    }

    return acc;
  }, [] as IDatabaseChange[]);
};
