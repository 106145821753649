import { TextField } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { MaterialInputFormEntity } from 'services/materialInputs';

interface Props {
  control: Control<MaterialInputFormEntity>;
}

export const Comments = ({ control }: Props): JSX.Element => {
  const intl = useIntl();

  return (
    <Controller
      name="comments"
      control={control}
      render={({ field: { onChange, value } }) => (
        <TextField
          data-test="comments"
          variant="outlined"
          color="secondary"
          margin="normal"
          minRows={5}
          multiline
          value={value}
          onChange={onChange}
          label={intl.formatMessage({
            id: 'material-input-form.label.comments',
          })}
          placeholder={intl.formatMessage({
            id: 'material-input-form.placeholder.comments',
          })}
        />
      )}
    />
  );
};
