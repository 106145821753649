import { routes } from 'routes';
import { useNavigate } from 'react-router-dom';
import { ReactNode } from 'react';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { FormattedMessage } from 'react-intl';

interface Props {
  textId: string;
  route: routes;
  dataTest: string;
  icon: ReactNode;
}

export const ListItemNavigation = ({
  textId,
  route,
  dataTest,
  icon,
}: Props): JSX.Element => {
  const navigate = useNavigate();

  return (
    <ListItemButton data-test={dataTest} onClick={() => navigate(route)}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText>
        <FormattedMessage id={textId} />
      </ListItemText>
    </ListItemButton>
  );
};
