import { Box } from '@mui/material';

interface ImageDisplayProps {
  imageSrc: string;
}

export const ImageDisplay = ({ imageSrc }: ImageDisplayProps): JSX.Element => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
      width="100%"
      maxHeight="25rem"
      maxWidth="50rem"
    >
      {imageSrc && (
        <img
          src={imageSrc}
          alt="Selected"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'contain',
          }}
        />
      )}
    </Box>
  );
};
