import { MaterialInputForRecap } from './MaterialInputForRecap';
import { deleteLocalMaterialInput } from '../requests/materialInput/deleteLocalMaterialInput';

export const deleteMaterialInputsForRecap = async (
  materialInputsForRecap: Array<MaterialInputForRecap>,
): Promise<void> => {
  for (const materialInputForRecap of materialInputsForRecap) {
    await deleteLocalMaterialInput(materialInputForRecap.id);
  }
};
