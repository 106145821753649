import { sortBuildingPartsByFloorLevel } from 'services/projects/sortBuildingParts';
import { listLocalBuildingParts } from './listLocalBuildingParts';

export const getLastBuildingPartFloorLevel = async (
  buildingId: string,
  motherBuildingPartId: string | null,
): Promise<number | null> => {
  const buildingParts = sortBuildingPartsByFloorLevel(
    await listLocalBuildingParts({ buildingId, motherBuildingPartId }),
  );

  const lastBuildingPart = buildingParts.splice(-1)[0];

  return lastBuildingPart === undefined
    ? -1
    : lastBuildingPart.buildingPartFloorLevel;
};
