import { Fragment, useState } from 'react';
import {
  Control,
  FieldErrors,
  UseFormGetValues,
  UseFormSetValue,
} from 'react-hook-form';
import { MaterialInputFormEntity } from 'services/materialInputs';
import { Divider, Grid2 } from '@mui/material';
import { InputMethod, InputUnit, WasteCategory } from '@afleya/common';
import { BuildingAndBuildingPart } from 'services/offline/requests/buildingAndBuildingPart/getBuildingAndBuildingPart';
import { MaterialEntity } from '@afleya/material-schemas';
import {
  Age,
  Amount,
  AssemblyTypeId,
  CheckboxFields,
  ChildBuildingPartId,
  Comments,
  DimensionFields,
  LocationDescription,
  Nickname,
  ReusePercentage,
  States,
  WeightPerFunctionalUnit,
} from './components';

interface Props {
  projectId: string;
  buildingAndBuildingPart?: BuildingAndBuildingPart;
  control: Control<MaterialInputFormEntity>;
  errors: FieldErrors<MaterialInputFormEntity>;
  getValues: UseFormGetValues<MaterialInputFormEntity>;
  setValue: UseFormSetValue<MaterialInputFormEntity>;
  inputMethod: InputMethod;
  inputUnit: InputUnit;
  material: MaterialEntity;
}

export const MaterialInputForm = ({
  projectId,
  buildingAndBuildingPart,
  control,
  errors,
  getValues,
  setValue,
  inputMethod,
  inputUnit,
  material,
}: Props): JSX.Element => {
  const [ddChecked, setDdChecked] = useState(isDdChecked(getValues));

  return (
    <Fragment>
      <DimensionFields
        control={control}
        getValues={getValues}
        inputMethod={inputMethod}
        inputUnit={inputUnit}
      />
      <CheckboxFields
        control={control}
        getValues={getValues}
        setValue={setValue}
        setDdChecked={setDdChecked}
      />
      <Nickname control={control} projectId={projectId} />
      {buildingAndBuildingPart &&
        shouldDisplayChildBuildingPart(buildingAndBuildingPart) && (
          <ChildBuildingPartId
            buildingId={buildingAndBuildingPart.building.id}
            control={control}
            motherBuildingPartId={buildingAndBuildingPart?.buildingPart.id}
          />
        )}
      {material.generic && <WeightPerFunctionalUnit control={control} />}
      <Grid2 container spacing={{ xs: 0, sm: 1 }}>
        <Grid2 size={{ xs: 12, sm: 4 }}>
          <Grid2 container spacing={1}>
            <Grid2 size={6}>
              <Amount control={control} />
            </Grid2>
            <Grid2 size={6}>
              <ReusePercentage
                control={control}
                disabled={
                  material.defaultWasteCategory === WasteCategory.DD ||
                  ddChecked
                }
              />
            </Grid2>
          </Grid2>
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 8 }}>
          <LocationDescription control={control} projectId={projectId} />
        </Grid2>
      </Grid2>
      <Divider />
      <Age control={control} />
      <Divider />
      <States
        control={control}
        error={errors.stateNewPercentage}
        getValues={getValues}
        setValue={setValue}
      />
      <Divider />
      <AssemblyTypeId control={control} />
      <Comments control={control} />
    </Fragment>
  );
};

const shouldDisplayChildBuildingPart = (
  buildingAndBuildingPart: BuildingAndBuildingPart | undefined,
): boolean => {
  return (
    buildingAndBuildingPart?.buildingPart.motherBuildingPartId === null &&
    buildingAndBuildingPart?.childBuildingPartCount > 0
  );
};

const isDdChecked = (
  getValues: UseFormGetValues<MaterialInputFormEntity>,
): boolean => {
  return (
    getValues('hasAsbestos') ||
    getValues('hasLead') ||
    getValues('hasOtherPollution')
  );
};
