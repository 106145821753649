import { createTheme } from '@mui/material';

const bodyColor = '#14142B';
const primaryColor = '#1A1A70';
const secondaryColor = '#FFDB00';
const white = '#FFFFFF';
const warningColor = '#FF9500';
const lightBlue = '#058FD9';

const muiTheme = createTheme({
  typography: {
    fontFamily: 'Inter, sans-serif',
    h1: {
      fontSize: '18px',
      fontWeight: 'bold',
      color: bodyColor,
      letterSpacing: '1.6%',
      lineHeight: '24px',
    },
    h2: {
      fontSize: '16px',
      fontWeight: 'bold',
      color: bodyColor,
      letterSpacing: '1.6%',
      lineHeight: '22px',
    },
    body1: {
      fontSize: '14px',
      color: bodyColor,
      letterSpacing: '1.6%',
    },
    body2: {
      fontSize: '14px',
      color: bodyColor,
      letterSpacing: '1.6%',
    },
  },
  palette: {
    primary: { main: primaryColor, dark: primaryColor, light: lightBlue },
    secondary: {
      main: secondaryColor,
      light: white,
      dark: secondaryColor,
    },
    warning: {
      main: warningColor,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          overscrollBehaviorY: 'none',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '8px',
        },
        text: {
          textDecoration: 'underline',
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          display: 'flex',
          height: '100%',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paperAnchorBottom: {
          padding: '18px',
          borderRadius: '35px 35px 0 0',
          maxHeight: '90%',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '8px',
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        shrink: true,
      },
      styleOverrides: {
        root: {
          backgroundColor: white,
          paddingLeft: '4px',
          paddingRight: '4px',
        },
      },
    },
  },
});

export default muiTheme;
