import { ProjectEntity } from '@afleya/project-schemas';
import JSZip from 'jszip';
import { IntlShape } from 'react-intl';
import { generateMaterialInputsSqlExport } from './generateMaterialInputsSqlExport';
import {
  generateMaterialInputsCsvExport,
  generateRawMaterialInputsCsvExport,
} from './generateInputCsvExport';
import { generateMaterialInputIconsCsvExport } from './generateMaterialInputIconsCsvExport';
import { generateMaterialInputIconsSqlExport } from './generateMaterialInputIconsSqlExport';
import {
  generateBuildingPartsCsvExport,
  generateRawBuildingPartsCsvExport,
} from './generateBuildingPartsCsvExport';
import { generateBuildingPartsSqlExport } from './generateBuildingPartsSqlExport';

interface File {
  name: string;
  content: string;
}

export const generateAndDownloadExportZipFile = async (
  project: ProjectEntity,
  intl: IntlShape,
  userId: string,
): Promise<void> => {
  const [
    buildingPartCsv,
    rawBuildingPartCsv,
    rawBuildingPartSql,
    inputCsv,
    rawInputCsv,
    rawInputSql,
    rawInputIconCsv,
    rawInputIconSql,
  ] = await Promise.all([
    generateBuildingPartsCsvExport(project.id, intl),
    generateRawBuildingPartsCsvExport(project.id),
    generateBuildingPartsSqlExport(project.id),
    generateMaterialInputsCsvExport(project.id, intl),
    generateRawMaterialInputsCsvExport(project.id),
    generateMaterialInputsSqlExport(project.id, userId),
    generateMaterialInputIconsCsvExport(project.id, intl),
    generateMaterialInputIconsSqlExport(project.id),
  ]);

  const csvString: File[] = createCsvArray({
    project,
    buildingPartCsv,
    rawBuildingPartCsv,
    inputCsv,
    rawInputCsv,
    rawInputIconCsv,
  });
  const sqlString: File[] = createSqlArray({
    project,
    rawBuildingPartSql,
    rawInputSql,
    rawInputIconSql,
  });
  const csvAndSqlString = csvString.concat(sqlString);

  const zip = addZipFiles(csvAndSqlString);
  const a = document.createElement('A') as HTMLAnchorElement;
  await zip.generateAsync({ type: 'base64' }).then(content => {
    a.href = 'data:application/zip;base64,' + content;
  });

  a.download = project.projectName + '-inputs.zip';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

const addZipFiles = (files: File[]): JSZip => {
  const zip = new JSZip();

  for (const file of files) {
    zip.file(file.name, file.content);
  }

  return zip;
};

interface CreateCsvArrayProps {
  project: ProjectEntity;
  buildingPartCsv: string;
  rawBuildingPartCsv: string;
  inputCsv: string;
  rawInputCsv: string;
  rawInputIconCsv: string;
}

const createCsvArray = ({
  project,
  buildingPartCsv,
  rawBuildingPartCsv,
  inputCsv,
  rawInputCsv,
  rawInputIconCsv,
}: CreateCsvArrayProps): File[] => {
  const buildingPartCsvFile: File = {
    name: project.projectName + '-buildingParts.csv',
    content: buildingPartCsv,
  };
  const rawBuildingPartCsvFile: File = {
    name: project.projectName + '-rawBuildingParts.csv',
    content: rawBuildingPartCsv,
  };
  const materialInputCsvFile: File = {
    name: project.projectName + '-inputs.csv',
    content: inputCsv,
  };
  const rawMaterialInputCsvFile: File = {
    name: 'raw' + project.projectName + '-inputs.csv',
    content: rawInputCsv,
  };
  const rawMaterialInputIconsCsvFile: File = {
    name: 'raw' + project.projectName + '-inputsIcon.csv',
    content: rawInputIconCsv,
  };

  return [
    buildingPartCsvFile,
    rawBuildingPartCsvFile,
    materialInputCsvFile,
    rawMaterialInputCsvFile,
    rawMaterialInputIconsCsvFile,
  ];
};

interface CreateSqlArrayProps {
  project: ProjectEntity;
  rawBuildingPartSql: string;
  rawInputSql: string;
  rawInputIconSql: string;
}

const createSqlArray = ({
  project,
  rawBuildingPartSql,
  rawInputSql,
  rawInputIconSql,
}: CreateSqlArrayProps): File[] => {
  const rawBuildingPartSqlFile: File = {
    name: 'raw' + project.projectName + '-buildingParts.sql',
    content: rawBuildingPartSql,
  };
  const rawMaterialInputSqlFile: File = {
    name: 'raw' + project.projectName + '-inputs.sql',
    content: rawInputSql,
  };

  const rawMaterialInputIconSqlFile: File = {
    name: 'raw' + project.projectName + '-inputsIcon.sql',
    content: rawInputIconSql,
  };

  return [
    rawBuildingPartSqlFile,
    rawMaterialInputSqlFile,
    rawMaterialInputIconSqlFile,
  ];
};
