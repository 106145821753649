import { TextField } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { rpn } from 'services/calculator';
import { MaterialInputFormEntity } from 'services/materialInputs';

interface Props {
  control: Control<MaterialInputFormEntity>;
}

export const Amount = ({ control }: Props): JSX.Element => {
  const intl = useIntl();

  return (
    <Controller
      name="amount"
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
          required
          fullWidth
          data-test="amount"
          variant="outlined"
          color="secondary"
          margin="normal"
          label={intl.formatMessage({
            id: 'material-input-form.label.amount',
          })}
          placeholder={intl.formatMessage({
            id: 'material-input-form.placeholder.amount',
          })}
          value={value}
          onChange={onChange}
          error={error !== undefined}
          helperText={
            error !== undefined
              ? error.type === 'too_small'
                ? intl.formatMessage({
                    id: 'material-input-form.error.amountRequired',
                  })
                : intl.formatMessage({
                    id: 'material-input-form.error.amountInvalid',
                  })
              : ''
          }
          onBlur={event => {
            const result = rpn(event.currentTarget.value.replace(',', '.'));
            if (result !== '') {
              onChange(result);
            }
          }}
        />
      )}
    />
  );
};
