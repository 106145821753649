import { CreateMaterialRequestEntity } from '@afleya/material-schemas';
import { MaterialEvaluationMethod } from '@afleya/common';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';

interface Props {
  control: Control<CreateMaterialRequestEntity>;
}

export const EvaluationMethodField = ({ control }: Props): JSX.Element => {
  const intl = useIntl();

  return (
    <Box sx={{ marginTop: '8px', marginBottom: '8px' }}>
      <FormControl component="fieldset">
        <FormLabel component="legend">
          {intl.formatMessage({
            id: 'select-material-evaluation-method.title',
          })}
        </FormLabel>
        <Controller
          control={control}
          name="evaluationMethod"
          render={({ field: { onChange, value } }) => (
            <RadioGroup
              value={value}
              row
              aria-label="evaluationMethod"
              name="evaluationMethod"
              onChange={onChange}
            >
              <FormControlLabel
                data-test="evaluationMethodLinear"
                value={MaterialEvaluationMethod.LINEAR}
                control={<Radio />}
                label={intl.formatMessage({
                  id: 'select-material-evaluation-method.linear',
                })}
              />
              <FormControlLabel
                data-test="evaluationMethodSurface"
                value={MaterialEvaluationMethod.SURFACE}
                control={<Radio />}
                label={intl.formatMessage({
                  id: 'select-material-evaluation-method.surface',
                })}
              />
              <FormControlLabel
                data-test="evaluationMethodVolume"
                value={MaterialEvaluationMethod.VOLUME}
                control={<Radio />}
                label={intl.formatMessage({
                  id: 'select-material-evaluation-method.volume',
                })}
              />
              <FormControlLabel
                data-test="evaluationMethodUnits"
                value={MaterialEvaluationMethod.UNITS}
                control={<Radio />}
                label={intl.formatMessage({
                  id: 'select-material-evaluation-method.units',
                })}
              />
            </RadioGroup>
          )}
        />
      </FormControl>
    </Box>
  );
};
