import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const DivSelectorButtonContainer = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingRight: theme.spacing(1),
}));

export const ButttonSelector = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1),
  minWidth: theme.spacing(7),
}));

export const DivSelectorSubtitle = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(1),
}));

export const DivSelectorTitle = styled('div')(() => ({
  fontWeight: 'bold',
  letterSpacing: '1.6%',
}));
