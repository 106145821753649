import { IntlShape } from 'react-intl';
import { getBuildingPartsForExport } from 'services/offline/requests/buildingAndBuildingPart/getBuildingPartsForExport';
import { listProjectBuildingParts } from 'services/offline/requests/buildingAndBuildingPart/listProjectBuildingParts';
import { generateCsv } from './generateCsv';
import { getBuildingPartCsvFields } from './getBuildingPartCsvFields';

export const generateBuildingPartsCsvExport = async (
  projectId: string,
  intl: IntlShape,
): Promise<string> => {
  const csv = generateCsv(
    await getBuildingPartsForExport(projectId ?? ''),
    getBuildingPartCsvFields(intl),
  );

  return csv;
};

export const generateRawBuildingPartsCsvExport = async (
  projectId: string,
): Promise<string> => {
  return generateCsv(await listProjectBuildingParts(projectId));
};
