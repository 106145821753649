import { CharacteristicsEntity } from '@afleya/material-schemas';

export const groupedByPosition = (
  characteristics: CharacteristicsEntity[],
  color: string,
): { [key: number]: CharacteristicsEntity[] } => {
  return characteristics.reduce(
    (acc, characteristic: CharacteristicsEntity) => {
      const position = characteristic.position;

      if (acc[position] === undefined) {
        acc[position] = [];
      }

      if (characteristic.name !== color) {
        acc[position].push(characteristic);
      }

      return acc;
    },
    {} as { [key: number]: CharacteristicsEntity[] },
  );
};
