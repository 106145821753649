import { BuildingPartEntity } from '@afleya/project-schemas';
import { materialsInputsDatabase } from '../../dexieDB';

export const listAllLocalMotherBuildingParts = async (): Promise<
  BuildingPartEntity[]
> => {
  const buildingParts = await materialsInputsDatabase.buildingParts
    .filter(buildingPart => buildingPart.motherBuildingPartId === null)
    .sortBy('buildingPartFloorLevel');

  return buildingParts;
};
