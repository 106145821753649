import {
  BuildingPartCreateChange,
  BuildingPartDatabaseChange,
  BuildingPartDeleteChange,
  BuildingPartUpdateChange,
  ChangeType,
  MaterialInputTable,
} from '@afleya/sync-protocol-schemas';

import {
  ICreateChange,
  IDatabaseChange,
  IDeleteChange,
  IUpdateChange,
} from 'dexie-observable/api';
import { localBuildingPartToBuildingPartEntity } from 'services/buildingPart/localBuildingPartToBuildingPartEntity';
import { LocalBuildingPart } from 'services/buildingPart/types';

export const transformBuildingPartChangeToDatabaseChange = (
  change: IDatabaseChange,
): BuildingPartDatabaseChange | undefined => {
  switch (change.type as number) {
    case 1: // DatabaseChangeType.Create, but cannot import due to typescript issue
      return transformToBuildingPartCreateChange(change as ICreateChange);
    case 2: // DatabaseChangeType.Update, but cannot import due to typescript issue
      return transformToBuildingPartUpdateChange(change as IUpdateChange);
    default: // DatabaseChangeType.Delete, but cannot import due to typescript issue
      return transformToBuildingPartDeleteChange(change as IDeleteChange);
  }
};

const transformToBuildingPartCreateChange = (
  change: ICreateChange,
): BuildingPartCreateChange | undefined => {
  const localBuildingPart = change.obj as LocalBuildingPart;
  const buildingPartToCreate =
    localBuildingPartToBuildingPartEntity(localBuildingPart);

  return localBuildingPart.uploaded === false ||
    localBuildingPart.uploaded === undefined
    ? {
        type: ChangeType.Create,
        key: change.key as string,
        table: MaterialInputTable.BuildingPart,
        obj: {
          ...buildingPartToCreate,
        },
      }
    : undefined;
};

const transformToBuildingPartUpdateChange = (
  change: IUpdateChange,
): BuildingPartUpdateChange => {
  return {
    type: ChangeType.Update,
    key: change.key as string,
    table: MaterialInputTable.BuildingPart,
    mods: change.mods,
  };
};

const transformToBuildingPartDeleteChange = (
  change: IDeleteChange,
): BuildingPartDeleteChange => {
  return {
    type: ChangeType.Delete,
    key: change.key as string,
    table: MaterialInputTable.BuildingPart,
  };
};
