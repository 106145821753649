import { CharacteristicInputEntity } from '@afleya/project-schemas';
import { LocalCharacteristicInput } from './types';

export const localCharacteristicInputToCharacteristicInputEntity = ({
  id,
  characteristicId,
  characteristicValue,
  materialInputId,
}: LocalCharacteristicInput): CharacteristicInputEntity => ({
  id,
  characteristicId,
  characteristicValue,
  materialInputId,
});
