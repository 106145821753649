import { useEffect } from 'react';
import { ImageOverlay, useMap } from 'react-leaflet';
import { IMapOverlay } from '../interfaces';

export const MapBackground = ({
  backgroundSource,
  mapBounds,
}: IMapOverlay): JSX.Element => {
  const map = useMap();

  useEffect(() => {
    map.setMaxBounds(mapBounds);
  }, [map, mapBounds]);

  return <ImageOverlay url={backgroundSource} bounds={mapBounds} />;
};
