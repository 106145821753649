import { InputMethod, InputUnit } from '@afleya/common';
import { MaterialInputFormEntity } from './MaterialInputFormEntity';
import { formatDimensions } from './formatDimensions';
import { LocalMaterialInput } from './types';

export const formatEditMaterialInput = (
  formAnswers: MaterialInputFormEntity,
  inputMethod: InputMethod,
  inputUnit: InputUnit,
): Partial<LocalMaterialInput> => {
  const {
    nickname,
    locationDescription,
    comments,
    amount,
    stateNewPercentage,
    stateGoodPercentage,
    stateMediumPercentage,
    stateObsoletePercentage,
    stateComments,
    age,
    assemblyTypeId,
    materialToDeduce,
    hasAsbestos,
    hasLead,
    hasOtherPollution,
    length,
    width,
    height,
    evaluationMethod,
    reusePercentage,
    weightPerFunctionalUnit,
  } = formAnswers;

  const dimensions = formatDimensions({
    evaluationMethod,
    inputMethod,
    inputUnit,
    length: Number(length),
    width: Number(width),
    height: Number(height),
  });

  return {
    locationDescription,
    nickname,
    comments,
    amount: Number(amount),
    reusePercentage:
      hasAsbestos || hasLead || hasOtherPollution ? 0 : reusePercentage,
    stateNewPercentage,
    stateGoodPercentage,
    stateMediumPercentage,
    stateObsoletePercentage,
    stateComments,
    age,
    assemblyTypeId,
    materialToDeduce,
    hasAsbestos,
    hasLead,
    hasOtherPollution,
    dimensions,
    inputMethod,
    inputUnit,
    weightPerFunctionalUnit,
  };
};
