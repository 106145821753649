import { materialsInputsDatabase } from '../../dexieDB';

export const checkIfCharacteristicsExist = async (
  materialId: string,
  nickname: string,
  projectId: string,
): Promise<boolean> => {
  const materialInputIds = (
    await materialsInputsDatabase.materialInputs
      .where({ materialId, nickname, projectId })
      .toArray()
  ).map(input => input.id);

  const materialInputIdsWithCharacteristics = (
    await materialsInputsDatabase.characteristicInputs.toArray()
  ).map(input => input.materialInputId);

  const characteristicInputSet = new Set(materialInputIdsWithCharacteristics);

  for (const id of materialInputIds) {
    if (characteristicInputSet.has(id)) {
      return true;
    }
  }

  return false;
};
