import { MaterialEntity } from '@afleya/material-schemas';
import { materialsDatabase } from '../../dexieDB';

export const getMaterial = async (
  materialId: string,
): Promise<MaterialEntity> => {
  const material =
    // @ts-ignore dexie is mistyped
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    (await materialsDatabase.materials.get(materialId)) as MaterialEntity;

  return material;
};
