import { Fragment, useMemo } from 'react';
import { BuildingForRecap } from 'services/offline/materialInputsForRecap';
import { Divider } from '@mui/material';
import { InputModuleSummaryMoveButtonGroup } from './InputModuleSummaryMoveButtonGroup';
import { TargetLocation } from './TargetLocation';

interface Props {
  locationsForRecap: Array<BuildingForRecap>;
  handleBuildingClick: (id: string) => void;
  handleBuildingPartClick: (id: string) => void;
  handleDaughterBuildingPartClick: (id: string) => void;
  targetLocation: TargetLocation;
}

export const InputModuleSummaryMoveDialogContent = ({
  locationsForRecap,
  handleBuildingClick,
  handleBuildingPartClick,
  handleDaughterBuildingPartClick,
  targetLocation,
}: Props): JSX.Element => {
  const buildingParts = useMemo(() => {
    return locationsForRecap.find(
      building => building.id === targetLocation.buildingId,
    )?.buildingParts;
  }, [locationsForRecap, targetLocation]);

  const daugtherBuildingParts = useMemo(() => {
    return buildingParts?.find(
      buildingPart => buildingPart.id === targetLocation.buildingPartId,
    )?.daugtherBuildingParts;
  }, [buildingParts, targetLocation]);

  return (
    <Fragment>
      <InputModuleSummaryMoveButtonGroup
        data={locationsForRecap}
        handleOnClick={handleBuildingClick}
        targetId={targetLocation.buildingId}
      />
      {buildingParts !== undefined && buildingParts.length > 0 && (
        <Divider sx={{ margin: 1 }} />
      )}
      <InputModuleSummaryMoveButtonGroup
        data={buildingParts}
        handleOnClick={handleBuildingPartClick}
        targetId={targetLocation.buildingPartId}
      />
      {daugtherBuildingParts !== undefined &&
        daugtherBuildingParts.length > 0 && <Divider sx={{ margin: 1 }} />}
      <InputModuleSummaryMoveButtonGroup
        data={daugtherBuildingParts}
        handleOnClick={handleDaughterBuildingPartClick}
        targetId={targetLocation.daugtherBuildingPartId}
      />
    </Fragment>
  );
};
