import { deleteBuildingPartMapContract } from '@afleya/material-inputs-contracts';
import { getAxiosRequest } from '@swarmion/serverless-contracts';
import { getJwtToken } from 'services/cognito';
import client from '../client';

export const deleteBuildingPartMap = async (
  buildingPartMapId: string,
): Promise<void> => {
  const jwtToken = await getJwtToken();

  await getAxiosRequest(deleteBuildingPartMapContract, client, {
    headers: { authorization: `Bearer ${jwtToken}` },
    pathParameters: { buildingPartMapId },
  });
};
