import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItemText,
} from '@mui/material';
import { FooterButton } from 'components/Layout';
import {
  duplicateMaterialInputsForRecap,
  MaterialInputForRecap,
} from 'services/offline/materialInputsForRecap';
import { FormattedMessage } from 'react-intl';
import { useAsyncFn } from 'react-use';
import { sortByString } from 'components/InputModuleSummary/libs';
import { DialogTransition } from 'components/DialogTransition';

interface Props {
  open: boolean;
  onClose: () => void;
  selectedMaterialInputs: Array<MaterialInputForRecap>;
  setSelectedMaterialInputs: (
    newSelectedMaterialInputs: Array<MaterialInputForRecap>,
  ) => void;
  materialInputs: Array<MaterialInputForRecap>;
  setMaterialInputs: (newMaterialInputs: Array<MaterialInputForRecap>) => void;
}

export const InputModuleSummaryDuplicateDialog = ({
  open,
  onClose,
  selectedMaterialInputs,
  setSelectedMaterialInputs,
  materialInputs,
  setMaterialInputs,
}: Props): JSX.Element => {
  const [{ loading }, onDuplicate] = useAsyncFn(async () => {
    const duplicatedMaterialInputsForRecap =
      await duplicateMaterialInputsForRecap(selectedMaterialInputs);
    setMaterialInputs(materialInputs.concat(duplicatedMaterialInputsForRecap));
    onClose();
    setSelectedMaterialInputs([]);
  }, [
    materialInputs,
    onClose,
    selectedMaterialInputs,
    setMaterialInputs,
    setSelectedMaterialInputs,
  ]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        if (!loading) {
          onClose();
        }
      }}
      TransitionComponent={DialogTransition}
    >
      <DialogTitle>
        <FormattedMessage
          id="input-module-summary.dialog-duplicate.title"
          values={{ count: selectedMaterialInputs.length }}
        />
      </DialogTitle>
      <DialogContent>
        <List>
          {selectedMaterialInputs
            .sort((a, b) => sortByString(a.nickname, b.nickname))
            .map(selectedMaterialInput => (
              <ListItemText key={selectedMaterialInput.id}>
                - {selectedMaterialInput.nickname}
              </ListItemText>
            ))}
        </List>
      </DialogContent>
      <DialogActions>
        <FooterButton
          variant="contained"
          color="secondary"
          onClick={onClose}
          disabled={loading}
        >
          <FormattedMessage id="input-module-summary.dialog-duplicate.cancelButton" />
        </FooterButton>
        <FooterButton
          variant="contained"
          disabled={loading}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={onDuplicate}
        >
          {loading ? (
            <CircularProgress size={20} />
          ) : (
            <FormattedMessage id="input-module-summary.dialog-duplicate.confirmButton" />
          )}
        </FooterButton>
      </DialogActions>
    </Dialog>
  );
};
