import { duplicateBuildingPartContent } from './duplicateBuildingPartContent';
import { duplicateBuildingPartMaterialInputs } from './duplicateBuildingPartMaterialInputs';

interface Props {
  projectId: string;
  sourceBuildingPartId: string;
  targetBuildingId: string;
  targetBuildingPartId: string;
}
export const duplicateBuildingPart = async ({
  projectId,
  sourceBuildingPartId,
  targetBuildingId,
  targetBuildingPartId,
}: Props): Promise<void> => {
  await duplicateBuildingPartContent({
    projectId,
    sourceBuildingPartId,
    targetBuildingId,
    targetBuildingPartId,
  });

  await duplicateBuildingPartMaterialInputs({
    projectId,
    sourceBuildingPartId,
    targetBuildingId,
    targetBuildingPartId,
  });
};
