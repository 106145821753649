import { IntlShape } from 'react-intl';

interface LabelKeyObject {
  label: string;
  value: string;
}

type Headers = LabelKeyObject[];

export const getBuildingPartCsvFields = (intl: IntlShape): Headers => [
  {
    label: intl.formatMessage({ id: 'export.building-part.building' }),
    value: 'building',
  },
  {
    label: intl.formatMessage({ id: 'export.building-part.name' }),
    value: 'buildingPartName',
  },
  {
    label: intl.formatMessage({ id: 'export.building-part.type' }),
    value: 'buildingPartType',
  },
  {
    label: intl.formatMessage({ id: 'export.building-part.floorLevel' }),
    value: 'buildingPartFloorLevel',
  },
  {
    label: intl.formatMessage({ id: 'export.building-part.mother' }),
    value: 'motherBuildingPart',
  },
  {
    label: intl.formatMessage({
      id: 'export.building-part.uploaded',
    }),
    value: 'uploaded',
  },
];
