import { formatLocalBuildingPart } from 'services/buildingPart/formatLocalBuildingPart';
import { createLocalBuildingPart } from './createLocalBuildingPart';
import { listLocalMotherBuildingParts } from './listLocalMotherBuildingParts';
import { duplicateBuildingPart } from './duplicateBuildingPart';

interface Props {
  projectId: string;
  sourceBuildingId: string;
  targetBuildingId: string;
}
export const duplicateBuildingContent = async ({
  projectId,
  sourceBuildingId,
  targetBuildingId,
}: Props): Promise<void> => {
  const sourceMotherBuildingParts =
    await listLocalMotherBuildingParts(sourceBuildingId);

  await Promise.all(
    sourceMotherBuildingParts.map(async sourceMotherBuildingPart => {
      const localBuildingPartToCreate = formatLocalBuildingPart({
        buildingId: targetBuildingId,
        motherBuildingPartId: null,
        buildingPartName: sourceMotherBuildingPart.buildingPartName,
        floorLevel: sourceMotherBuildingPart.buildingPartFloorLevel ?? -1,
        buildintPartType: sourceMotherBuildingPart.buildingPartType,
      });

      await createLocalBuildingPart(localBuildingPartToCreate);

      await duplicateBuildingPart({
        projectId,
        sourceBuildingPartId: sourceMotherBuildingPart.id,
        targetBuildingId,
        targetBuildingPartId: localBuildingPartToCreate.id,
      });
    }),
  );
};
