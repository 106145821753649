import Dexie, { Table } from 'dexie';
import { LocalBuildingPartMap } from 'services/buildingPartMaps/type';
import { LocalMaterialInputPicture } from 'services/materialInputsPictures/type';
import { SyncableDexie } from './SyncableDexie';
import { updateSynchroStatus } from './updateSynchroStatus';

const baseApiUrl = process.env.REACT_APP_API_URL ?? '';

export class MaterialsInputsPicturesDatabase extends SyncableDexie {
  materialInputsPictures!: Table<LocalMaterialInputPicture>;
  buildingPartMaps!: Table<LocalBuildingPartMap>;

  constructor() {
    super('materialInputsPictures');

    this.version(5).stores({
      materialInputsPictures: 'id,materialInputId,projectId',
      buildingPartMaps: 'id,buildingPartId,projectId',
    });

    this.syncable.on('statusChanged', newStatus => {
      console.log(
        'Material Input Pictures Sync Status changed: ' +
          Dexie.Syncable.StatusTexts[newStatus],
      );
      void updateSynchroStatus();
    });
  }

  startSync = (): Dexie.Promise<void> => {
    return this.syncable.connect(
      'materialInputsPicturesSyncProtocol',
      baseApiUrl,
      { initialUpload: false },
    );
  };

  stopSync = (): Dexie.Promise<void> => {
    return this.syncable.disconnect(baseApiUrl);
  };

  addPicture = async (
    localMaterialInputPictureToStore: LocalMaterialInputPicture,
  ): Promise<void> => {
    await this.materialInputsPictures.add(localMaterialInputPictureToStore);
  };

  deletePicture = async (
    localMaterialInputPictureId: string,
  ): Promise<void> => {
    await this.materialInputsPictures.delete(localMaterialInputPictureId);
  };

  updatePicture = async (
    materialInputPictureToUpdateId: string,
    materialInputPictureToUpdate: Partial<LocalMaterialInputPicture>,
  ): Promise<void> => {
    await this.materialInputsPictures.update(
      materialInputPictureToUpdateId,
      materialInputPictureToUpdate,
    );
  };

  addMap = async (
    localBuildingPartMapToStore: LocalBuildingPartMap,
  ): Promise<void> => {
    await this.buildingPartMaps.add(localBuildingPartMapToStore);
  };

  deleteMap = async (localBuildingPartMapId: string): Promise<void> => {
    await this.buildingPartMaps.delete(localBuildingPartMapId);
  };

  updateMap = async (
    buildingPartMapToUpdateId: string,
    buildingPartMapToUpdate: Partial<LocalBuildingPartMap>,
  ): Promise<void> => {
    await this.buildingPartMaps.update(
      buildingPartMapToUpdateId,
      buildingPartMapToUpdate,
    );
  };

  deletePicturesOfMaterialInput = async (
    materialInputId: string,
  ): Promise<void> => {
    const materialInputPictureIds =
      await this.listMaterialInputPicturesIds(materialInputId);
    await Promise.all(
      materialInputPictureIds.map(async materialInputPictureId => {
        await this.deletePicture(materialInputPictureId);
      }),
    );
  };

  listMaterialInputPicturesIds = async (
    materialInputId: string,
  ): Promise<string[]> => {
    const materialInputPicturesIds = (await this.materialInputsPictures
      .where({ materialInputId })
      .primaryKeys()) as string[];

    return materialInputPicturesIds;
  };
}
