import { Description } from '@mui/icons-material';
import {
  Badge,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
} from '@mui/material';
import BoxAlert from 'components/BoxAlert';
import { BadgeSpan, FooterButton } from 'components/Layout/Layout.style';
import LoadingBox from 'components/LoadingBox';
import { PageContent } from 'components/PageContent';
import PageFooter from 'components/PageFooter';

import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useAsync } from 'react-use';
import { routes } from 'routes';
import { beforeUnload } from 'services/beforeUnload/beforeUnload';
import { generateAndDownloadExportZipFile } from 'services/export/generateAndDownloadExportZipFile';
import { listProjectBuildings } from 'services/offline/requests/buildingAndBuildingPart/listProjectBuildings';
import { listMaterialInputs } from 'services/offline/requests/materialInput/listMaterialInputs';
import { listMaterialInputsForRecap } from 'services/offline/materialInputsForRecap';
import { slugify } from 'services/slugify/slugify';
import { RootState } from 'store';
import { InputModuleSummaryButton } from 'components/InputModuleSummaryButton';
import { InputModuleHomePageHeader } from './InputModuleHomePageHeader';

export const InputModuleHomePage = (): JSX.Element => {
  const userProfile = useSelector(
    (state: RootState) => state.userProfileState.userProfile,
  );

  const intl = useIntl();
  const { projectId } = useParams<{
    projectId: string;
  }>();

  const navigate = useNavigate();

  const project = useSelector(
    (state: RootState) => state.offlineProject.project,
  );

  useEffect(beforeUnload, []);

  const {
    value: buildingsWithMaterialInputs,
    loading,
    error,
  } = useAsync(async () => {
    const buildings = await listProjectBuildings(projectId ?? '');
    const localBuildingsWithMaterialInputs = await Promise.all(
      buildings.map(async building => {
        const materialInputs = await listMaterialInputs({
          projectId: projectId ?? '',
          buildingId: building.id,
        });

        return {
          buildingId: building.id,
          buildingName: building.buildingName,
          buildingMaterialInputsCount: materialInputs.length,
        };
      }),
    );

    return localBuildingsWithMaterialInputs;
  }, [projectId]);

  const { value: nbMaterialInputs } = useAsync(
    async () =>
      (await listMaterialInputsForRecap({ projectId: projectId ?? '' })).length,
  );

  const onClickInputModuleSummaryButton = () =>
    navigate(
      routes.INPUT_MODULE_HOME_PAGE_SUMMARY.replace(
        ':projectId',
        projectId ?? '',
      ),
    );

  if (projectId === undefined) {
    navigate(-1);

    return <></>;
  }

  return (
    <>
      <InputModuleHomePageHeader
        locationTitle={project?.projectName}
        projectId={projectId}
      />
      <PageContent>
        {loading ? (
          <LoadingBox />
        ) : error ? (
          <BoxAlert contentId={'input-module-home-page.loadingError'} />
        ) : (
          <List>
            {buildingsWithMaterialInputs?.map((building, index) => (
              <ListItem key={building.buildingId} divider>
                <ListItemButton
                  data-test={slugify(building.buildingName)}
                  id={`building-${index}`}
                  onClick={() => {
                    navigate({
                      pathname: routes.INPUT_MODULE_BUILDING_PARTS.replace(
                        ':projectId',
                        projectId,
                      ).replace(':buildingId', building.buildingId),
                    });
                  }}
                >
                  <ListItemText>{building.buildingName}</ListItemText>
                  <BadgeSpan sx={{ marginRight: '1em' }}>
                    <Badge
                      badgeContent={building.buildingMaterialInputsCount}
                      color="secondary"
                      max={999}
                    >
                      <Description color="primary" />
                    </Badge>
                  </BadgeSpan>
                </ListItemButton>
              </ListItem>
            )) ?? <></>}
          </List>
        )}
      </PageContent>
      <PageFooter>
        <Stack
          sx={{ width: '100%' }}
          direction="column"
          spacing={{ xs: 0, sm: 1 }}
        >
          <InputModuleSummaryButton
            nbMaterialInputs={nbMaterialInputs ?? 0}
            onClick={onClickInputModuleSummaryButton}
          />
          <FooterButton
            onClick={() => {
              if (project !== undefined && userProfile?.userId !== undefined)
                void generateAndDownloadExportZipFile(
                  project,
                  intl,
                  userProfile.userId,
                );
            }}
          >
            <FormattedMessage id="input-module-home-page.export" />
          </FooterButton>
        </Stack>
      </PageFooter>
    </>
  );
};
