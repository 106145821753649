import { BuildingPartEntity } from '@afleya/project-schemas';
import { materialsInputsDatabase } from '../../dexieDB';

interface ListLocalBuildingPartsArgs {
  buildingId: string;
  motherBuildingPartId?: string | null;
}

export const listLocalBuildingParts = async ({
  buildingId,
  motherBuildingPartId,
}: ListLocalBuildingPartsArgs): Promise<BuildingPartEntity[]> => {
  const buildingParts = await materialsInputsDatabase.buildingParts
    .where({ buildingId })
    .toArray();

  if (motherBuildingPartId === undefined) {
    return buildingParts.sort((a, b) =>
      a.buildingPartName.localeCompare(b.buildingPartName),
    );
  } else {
    return buildingParts
      .filter(bp => bp.motherBuildingPartId === motherBuildingPartId)
      .sort((a, b) => a.buildingPartName.localeCompare(b.buildingPartName));
  }
};
