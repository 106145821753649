import { InputMethod, MaterialEvaluationMethod } from '@afleya/common';
import { Button, ButtonGroup } from '@mui/material';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { MaterialInputFormEntity } from 'services/materialInputs';

interface Props {
  evaluationMethod: MaterialEvaluationMethod;
  inputMethod: InputMethod;
  setInputMethod: (newInputMethod: InputMethod) => void;
  getValues: UseFormGetValues<MaterialInputFormEntity>;
  setValue: UseFormSetValue<MaterialInputFormEntity>;
}

export const InputMethodSelector = ({
  evaluationMethod,
  inputMethod,
  getValues,
  setValue,
  setInputMethod,
}: Props): JSX.Element => {
  const label2D3D =
    evaluationMethod === MaterialEvaluationMethod.SURFACE
      ? 'inputMethodSurface'
      : 'inputMethodVolume';

  const handleClick = () => {
    if (inputMethod !== InputMethod.DIMENSIONS) {
      setInputMethod(InputMethod.DIMENSIONS);
    } else {
      const width = Number(getValues('width'));
      if (isNaN(width) || width <= 0) setValue('width', '1');
      if (evaluationMethod === MaterialEvaluationMethod.SURFACE) {
        setInputMethod(InputMethod.SURFACE);
      } else {
        const height = Number(getValues('height'));
        if (isNaN(height) || height <= 0) setValue('height', '1');
        setInputMethod(InputMethod.VOLUME);
      }
    }
  };

  return (
    <ButtonGroup style={{ justifyContent: 'right' }}>
      <Button
        variant={
          inputMethod === InputMethod.SURFACE ||
          inputMethod === InputMethod.VOLUME
            ? 'contained'
            : 'outlined'
        }
        onClick={handleClick}
      >
        <FormattedMessage id={`material-input-form.label.${label2D3D}`} />
      </Button>
      <Button
        variant={
          inputMethod === InputMethod.DIMENSIONS ? 'contained' : 'outlined'
        }
        onClick={handleClick}
      >
        <FormattedMessage
          id={`material-input-form.label.inputMethodDimensions`}
        />
      </Button>
    </ButtonGroup>
  );
};
