import { CreateMaterialRequestEntity } from '@afleya/material-schemas';
import { TextField } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';

interface Props {
  control: Control<CreateMaterialRequestEntity>;
}

export const CommentsField = ({ control }: Props): JSX.Element => {
  const intl = useIntl();

  return (
    <Controller
      name="comments"
      control={control}
      render={({ field: { onChange, value } }) => (
        <TextField
          data-test="comments"
          id="comments"
          color="secondary"
          variant="outlined"
          margin="normal"
          label={intl.formatMessage({
            id: 'material-form.comments',
          })}
          placeholder={intl.formatMessage({
            id: 'material-form.commentsExample',
          })}
          value={value}
          onChange={onChange}
          multiline
          minRows={5}
        />
      )}
    />
  );
};
