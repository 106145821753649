import { FooterButton, FormContainer } from 'components/Layout';
import { useForm } from 'react-hook-form';
import { PageContent } from 'components/PageContent';
import PageFooter from 'components/PageFooter';
import { FormattedMessage } from 'react-intl';
import { CircularProgress } from '@mui/material';
import { useAsync, useAsyncFn } from 'react-use';
import {
  BuildingPartFormAnswers,
  buildingPartFormAnswersSchema,
} from 'services/buildingPart/validationSchema';
import { zodResolver } from '@hookform/resolvers/zod';
import { editLocalBuildingPart } from 'services/offline/requests/buildingAndBuildingPart/editLocalBuildingPart';
import { getBuildingPart } from 'services/offline/requests/buildingAndBuildingPart/getBuildingPart';
import LoadingBox from 'components/LoadingBox';
import {
  MotherBuildingPartField,
  NameField,
} from 'pages/InputModules/InputModuleCreateBuildingPart/components/CreateBuildingPartForm/Fields';
import { listLocalBuildingParts } from 'services/offline/requests/buildingAndBuildingPart/listLocalBuildingParts';

interface Props {
  setEditState: (editState: boolean) => void;
  buildingId: string;
  buildingPartId: string;
  isMother: boolean;
}

export const EditBuildingPartForm = ({
  setEditState,
  buildingId,
  buildingPartId,
  isMother,
}: Props): JSX.Element => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { isValid },
  } = useForm<BuildingPartFormAnswers>({
    mode: 'onChange',
    resolver: zodResolver(buildingPartFormAnswersSchema),
  });

  const [{ loading: submitLoading }, onSubmit] = useAsyncFn(
    async (formAnswers: BuildingPartFormAnswers) => {
      if (buildingPartId !== undefined) {
        await editLocalBuildingPart(buildingPartId, formAnswers);
        setEditState(true);
      }
    },
  );

  const { loading: loadingBuildingPart, value: buildingPart } = useAsync(() =>
    getBuildingPart(buildingPartId),
  );

  const { loading: loadingBuildingParts, value: buildingParts } = useAsync(() =>
    listLocalBuildingParts({ buildingId }),
  );

  return loadingBuildingPart ||
    loadingBuildingParts ||
    buildingPart === undefined ||
    buildingParts === undefined ? (
    <LoadingBox />
  ) : (
    <>
      <PageContent>
        <FormContainer
          id="edit-buildingPart-form"
          noValidate
          autoComplete="off"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmit={handleSubmit(onSubmit)}
        >
          <NameField
            control={control}
            buildingPartName={buildingPart.buildingPartName}
            setValue={setValue}
            isMother={isMother}
          />

          {!isMother && (
            <MotherBuildingPartField
              control={control}
              buildingId={buildingId}
              buildingPartId={buildingPart.id}
              motherBuildingId={buildingPart.motherBuildingPartId}
            />
          )}
        </FormContainer>
      </PageContent>
      <PageFooter>
        <FooterButton
          form="edit-buildingPart-form"
          type="submit"
          color="primary"
          variant="contained"
          disabled={!isValid || submitLoading}
        >
          {submitLoading ? (
            <CircularProgress size={20} />
          ) : (
            <FormattedMessage
              id={'input-module-edit-building-part.submitButton'}
            />
          )}
        </FooterButton>
      </PageFooter>
    </>
  );
};
