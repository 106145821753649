import { SortDirection } from '../components/InputModuleSummaryTableHeader';

export const sortByNumber = (
  a: number,
  b: number,
  sortDirection: SortDirection,
): number => {
  if (sortDirection === SortDirection.ASC) {
    return a - b;
  } else {
    return b - a;
  }
};
