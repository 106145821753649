import { Alert, AlertColor, AlertTitle, Box } from '@mui/material';
import { FormattedMessage } from 'react-intl';

interface Props {
  contentId?: string;
  contentString?: string;
  title?: string;
  onClose?: () => void;
  severity?: AlertColor;
}

const BoxAlert = ({
  contentId,
  contentString,
  title,
  onClose,
  severity = 'error',
}: Props): JSX.Element => (
  <Box
    height="150px"
    display="flex"
    justifyContent="center"
    alignItems="center"
  >
    <Alert severity={severity} onClose={onClose}>
      {title !== undefined && (
        <AlertTitle>
          <FormattedMessage id={title} />
        </AlertTitle>
      )}
      {contentString ?? <FormattedMessage id={contentId} />}
    </Alert>
  </Box>
);

export default BoxAlert;
