import { MapContainer } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import { CRS, LatLngTuple } from 'leaflet';
import { useCallback } from 'react';
import { MapBackground } from './MapBackground';
import { IMapOverlay, IShowMarkers } from '../interfaces';
import { MarkersDisplay } from './MarkersDisplay';

interface IProps extends IShowMarkers, IMapOverlay {
  centerView: LatLngTuple;
}

export const MapDisplay = ({
  centerView,
  mapStates,
  backgroundSource,
  mapBounds,
  selectedAmount,
  buildingPartMapId,
  filter,
  isEdit,
}: IProps): JSX.Element => {
  const getMarkersDisplay = useCallback(() => {
    return (
      <MarkersDisplay
        mapStates={mapStates}
        selectedAmount={selectedAmount}
        buildingPartMapId={buildingPartMapId}
        filter={filter}
        isEdit={isEdit}
      />
    );
  }, [buildingPartMapId, filter, isEdit, selectedAmount, mapStates]);

  return (
    <MapContainer
      style={{
        height: 'calc(90vh - 2px)',
        backgroundColor: '#dddddd',
      }}
      center={centerView}
      zoom={navigator.userAgent.toLowerCase().includes('mobile') ? 2.25 : 3}
      zoomSnap={0.25}
      zoomDelta={1}
      wheelPxPerZoomLevel={250}
      minZoom={2}
      maxZoom={8}
      crs={CRS.Simple}
      attributionControl={false}
      zoomControl={false}
      maxBoundsViscosity={1}
    >
      <MapBackground
        backgroundSource={backgroundSource}
        mapBounds={mapBounds}
      />
      {isEdit ? (
        <MarkerClusterGroup>{getMarkersDisplay()}</MarkerClusterGroup>
      ) : (
        getMarkersDisplay()
      )}
    </MapContainer>
  );
};
