import { MaterialComponentEntity } from '@afleya/material-schemas';
import { materialsDatabase } from '../../dexieDB';

export const listComponents = async (): Promise<MaterialComponentEntity[]> => {
  const components = await materialsDatabase.components
    .reverse()
    .reverse()
    .sortBy('name');

  return components;
};
