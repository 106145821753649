import { UserGroup } from '@afleya/common';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { routes } from 'routes';
import { RootState } from 'store';

export const RequireAkibo = (): JSX.Element => {
  const userProfile = useSelector(
    (state: RootState) => state.userProfileState.userProfile,
  );

  const authorizedAkiboGroups = [
    UserGroup.ADMIN,
    UserGroup.AKIBO_ADMIN,
    UserGroup.AKIBO_USER,
  ];

  if (userProfile === undefined) {
    return <Navigate to={routes.LOGIN} />;
  } else if (
    userProfile.groups.find(group => authorizedAkiboGroups.includes(group)) ===
    undefined
  ) {
    return <Navigate to={routes.HOME_PAGE} />;
  } else {
    return <Outlet />;
  }
};
