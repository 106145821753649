import { InputModuleSummary } from 'components/InputModuleSummary';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { RootState } from 'store';

export const InputModuleCategoriesSummary = (): JSX.Element => {
  const navigate = useNavigate();

  const project = useSelector(
    (state: RootState) => state.offlineProject.project,
  );

  const { buildingId, buildingPartId } = useParams<{
    projectId: string;
    buildingId: string;
    buildingPartId: string;
  }>();

  if (
    project === undefined ||
    buildingId === undefined ||
    buildingPartId === undefined
  ) {
    navigate(-1);

    return <></>;
  }

  return (
    <InputModuleSummary
      project={project}
      backlinkTitleId="input-module-categories-page.title"
      buildingId={buildingId}
      buildingPartId={buildingPartId}
    />
  );
};
