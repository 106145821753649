// contracts/httpApi/getImageRecognitionResultContract.ts
import {
  ApiGatewayContract,
  HttpStatusCodes
} from "@swarmion/serverless-contracts";
var headersSchema = {
  type: "object",
  properties: {
    authorization: { type: "string" }
  },
  required: ["authorization"]
};
var bodySchema = {
  type: "object",
  properties: {
    image: { type: "string" }
  },
  required: ["image"],
  additionalProperties: false
};
var outputSchema = {
  type: "string"
};
var getImageRecognitionResultContract = new ApiGatewayContract({
  id: "getImageRecognitionResultContract",
  path: "/image-recognition/infer",
  method: "POST",
  integrationType: "httpApi",
  authorizerType: "cognito",
  headersSchema,
  bodySchema,
  outputSchemas: { [HttpStatusCodes.OK]: outputSchema }
});
export {
  getImageRecognitionResultContract
};
