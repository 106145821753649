interface ObjectWithName {
  name: string;
}

export const getNumber = (name: string): number => {
  return parseFloat(name.substring(0, name.indexOf(' ')));
};

export const sortMotherCategories = <T extends ObjectWithName>(
  objects: T[],
): T[] => {
  return objects.sort((a, b) => {
    const numberA = getNumber(a.name);
    const numberB = getNumber(b.name);

    return numberA === numberB ? 0 : numberA < numberB ? -1 : 1;
  });
};
