import { MaterialInputIconEntity } from '@afleya/project-schemas';
import { LocalMaterialInputIcon } from './type';

export const localMaterialInputIconToMaterialInputIconEntity = ({
  id,
  lat,
  lng,
  materialInputId,
  buildingPartMapId,
  amount,
}: LocalMaterialInputIcon): MaterialInputIconEntity => ({
  id,
  lat,
  lng,
  materialInputId,
  buildingPartMapId,
  amount,
});
