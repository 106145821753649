import { useDragLayer, XYCoord } from 'react-dnd';

interface IProps {
  className: string;
}

interface IStyleOuput {
  transform?: string;
  WebkitTransform?: string;
  display?: string;
}

const getStyle = (currentOffset: XYCoord | null): IStyleOuput => {
  if (currentOffset === null) {
    return {
      display: 'none',
    };
  } else {
    const { x, y } = currentOffset;
    const transform = `translate(${x}px, ${y}px)`;

    return {
      transform,
      WebkitTransform: transform,
    };
  }
};

export const CustomDragPreview = ({ className }: IProps): JSX.Element => {
  const { currentOffset, isDragging } = useDragLayer(monitor => ({
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  if (isDragging) {
    return (
      <div
        style={{
          position: 'fixed',
          pointerEvents: 'none',
          zIndex: 400,
          left: 12,
          top: 0,
          width: '100%',
          height: '100%',
        }}
      >
        <div style={getStyle(currentOffset)} className={className}></div>
      </div>
    );
  } else {
    return <></>;
  }
};
