import { v4 as uuidV4 } from 'uuid';
import { getBuildingAndBuildingPartNames } from '@afleya/common';
import { createLocalMaterialInput } from '../materialInput/createLocalMaterialInput';
import { getMaterialInput } from '../materialInput/getMaterialInput';
import { getBuildingAndBuildingPart } from './getBuildingAndBuildingPart';
import { listMaterialInputs } from '../materialInput/listMaterialInputs';
import { getBuildingPart } from './getBuildingPart';

interface Props {
  projectId: string;
  sourceBuildingPartId: string;
  targetBuildingId: string;
  targetBuildingPartId: string;
}

export const duplicateBuildingPartMaterialInputs = async ({
  projectId,
  sourceBuildingPartId,
  targetBuildingId,
  targetBuildingPartId,
}: Props): Promise<void> => {
  const materialInputsToDuplicate = await listMaterialInputs({
    projectId,
    buildingPartId: sourceBuildingPartId,
  });

  const { building, buildingPart } = await getBuildingAndBuildingPart(
    targetBuildingId,
    targetBuildingPartId,
  );

  const motherBuildingPartId = buildingPart.motherBuildingPartId;

  const motherBuildingPartName =
    motherBuildingPartId === null
      ? undefined
      : (await getBuildingPart(motherBuildingPartId)).buildingPartName;

  const buildingAndBuildingPartNames = getBuildingAndBuildingPartNames(
    building.buildingName,
    buildingPart.buildingPartName,
    motherBuildingPartName,
  );

  await Promise.all(
    materialInputsToDuplicate.map(async materialInput => {
      const oldMaterialInput = await getMaterialInput(materialInput.id);
      const newMaterialInput = {
        ...oldMaterialInput,
        id: uuidV4(),
        buildingId: targetBuildingId,
        buildingPartId: targetBuildingPartId,
        buildingAndBuildingPartNames,
        loadedFromServer: false,
      };

      await createLocalMaterialInput(newMaterialInput);
    }),
  );
};
