import { v4 } from 'uuid';
import { LocalMaterialInput } from 'services/materialInputs/types';
import { getMaterialInput } from '../requests/materialInput/getMaterialInput';
import { MaterialInputForRecap } from './MaterialInputForRecap';
import { createLocalMaterialInput } from '../requests/materialInput/createLocalMaterialInput';

export const duplicateMaterialInputsForRecap = async (
  materialInputsForRecap: Array<MaterialInputForRecap>,
): Promise<Array<MaterialInputForRecap>> => {
  const duplicatedMaterialInputsForRecap = new Array<MaterialInputForRecap>();

  for (const materialInputForRecap of materialInputsForRecap) {
    const materialInputToDuplicate = await getMaterialInput(
      materialInputForRecap.id,
    );

    const newId = v4();

    const newMaterialInput: LocalMaterialInput = {
      ...materialInputToDuplicate,
      id: newId,
      loadedFromServer: false,
    };

    await createLocalMaterialInput(newMaterialInput);

    duplicatedMaterialInputsForRecap.push({
      ...materialInputForRecap,
      id: newId,
      nbPictures: 0,
      hasCharacteristic: false,
    });
  }

  return duplicatedMaterialInputsForRecap;
};
