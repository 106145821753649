import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface MaterialInputIdState {
  materialInputId: string | undefined;
}

const initialState: MaterialInputIdState = { materialInputId: '' };

export const materialInputIdSlice = createSlice({
  name: 'materialInputId',
  initialState,
  reducers: {
    setMaterialInputId: (state, action: PayloadAction<string>) => {
      state.materialInputId = action.payload;
    },
    clearMaterialInputId: state => {
      state.materialInputId = undefined;
    },
  },
});

export const { setMaterialInputId, clearMaterialInputId } =
  materialInputIdSlice.actions;

export default materialInputIdSlice.reducer;
