import { Characteristic } from 'components/CreateOrEditCharacteristic/CharacteristicType';

export const characteristicListToCharacteristics = (
  listCharacteristics: {
    characteristicId: string;
    characteristicValue: string;
  }[],
): Characteristic[] => {
  const selectedColumnsCharacteristicInputs = listCharacteristics.map(item => ({
    characteristicId: item.characteristicId,
    characteristicValues: item.characteristicValue,
  }));

  const characteristicInputs = selectedColumnsCharacteristicInputs.reduce<
    Characteristic[]
  >((acc, current) => {
    const item = acc.find(
      value => value.characteristicId === current.characteristicId,
    );

    if (item) {
      if (!item.characteristicValues.includes(current.characteristicValues)) {
        item.characteristicValues.push(current.characteristicValues);
      }
    } else {
      acc.push({
        characteristicId: current.characteristicId,
        characteristicValues: [current.characteristicValues],
      });
    }

    return acc;
  }, []);

  return characteristicInputs;
};
