import { ImageRecognitionLabelEntity } from '@afleya/material-schemas';
import { materialsDatabase } from '../../dexieDB';

export const getImageRecognitionLabel = async (
  id: number,
): Promise<ImageRecognitionLabelEntity | undefined> => {
  const label = await materialsDatabase.imageRecognitionLabels.get({
    labelIndex: id,
  });

  return label;
};
