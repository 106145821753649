import { LocalMaterialInput } from 'services/materialInputs/types';
import { materialsInputsDatabase } from '../../dexieDB';

export const editLocalMaterialInput = async (
  materialInputToUpdateId: string,
  localMaterialInputUpdates: Partial<LocalMaterialInput>,
): Promise<void> => {
  await materialsInputsDatabase.updateMaterialInput(
    materialInputToUpdateId,
    localMaterialInputUpdates,
  );
};
