import { Parser } from '@json2csv/plainjs';

interface LabelKeyObject {
  label: string;
  value: string;
}
type Headers = LabelKeyObject[];

export const generateCsv = (
  data: object | object[],
  fields?: Headers,
): string => {
  const parser = new Parser({
    delimiter: ';',
    withBOM: true,
    fields: fields,
  });

  return parser.parse(data);
};
