import {
  ChangeType,
  MaterialInputIconCreateChange,
  MaterialInputIconDatabaseChange,
  MaterialInputIconDeleteChange,
  MaterialInputIconUpdateChange,
  MaterialInputTable,
} from '@afleya/sync-protocol-schemas';

import {
  ICreateChange,
  IDatabaseChange,
  IDeleteChange,
  IUpdateChange,
} from 'dexie-observable/api';
import { localMaterialInputIconToMaterialInputIconEntity } from 'services/materialInputIcons/localMaterialInputIconToMaterialInputIconEntity';
import { LocalMaterialInputIcon } from 'services/materialInputIcons/type';

export const transformMaterialInputIconChangeToDatabaseChange = (
  change: IDatabaseChange,
): MaterialInputIconDatabaseChange | undefined => {
  switch (change.type as number) {
    case 1: // DatabaseChangeType.Create, but cannot import due to typescript issue
      return transformToMaterialInputIconCreateChange(change as ICreateChange);
    case 2: // DatabaseChangeType.Update, but cannot import due to typescript issue
      return transformToMaterialInputIconUpdateChange(change as IUpdateChange);
    default: //DatabaseChangeType.Delete, but cannot import due to typescript issue
      return transformToMaterialInputIconDeleteChange(change as IDeleteChange);
  }
};

const transformToMaterialInputIconCreateChange = (
  change: ICreateChange,
): MaterialInputIconCreateChange | undefined => {
  const localMaterialInputIcon = change.obj as LocalMaterialInputIcon;
  const materialInputIconToCreate =
    localMaterialInputIconToMaterialInputIconEntity(localMaterialInputIcon);

  return localMaterialInputIcon.uploaded === false ||
    localMaterialInputIcon.uploaded === undefined
    ? {
        type: ChangeType.Create,
        key: change.key as string,
        table: MaterialInputTable.MaterialInputIcon,
        obj: {
          ...materialInputIconToCreate,
        },
      }
    : undefined;
};

const transformToMaterialInputIconUpdateChange = (
  change: IUpdateChange,
): MaterialInputIconUpdateChange => {
  return {
    type: ChangeType.Update,
    key: change.key as string,
    table: MaterialInputTable.MaterialInputIcon,
    mods: change.mods,
  };
};

const transformToMaterialInputIconDeleteChange = (
  change: IDeleteChange,
): MaterialInputIconDeleteChange => {
  return {
    type: ChangeType.Delete,
    key: change.key as string,
    table: MaterialInputTable.MaterialInputIcon,
  };
};
