import { LocalBuildingPartForExport } from 'services/buildingPart/types';
import { listProjectBuildingParts } from './listProjectBuildingParts';
import { listProjectBuildings } from './listProjectBuildings';

export const getBuildingPartsForExport = async (
  projectId: string,
): Promise<LocalBuildingPartForExport[]> => {
  const buildingParts = await listProjectBuildingParts(projectId);
  const buildings = await listProjectBuildings(projectId);

  return buildingParts.map(buildingPart => {
    const building = buildings.find(b => b.id === buildingPart.buildingId);
    const motherBuildingPart =
      buildingPart.motherBuildingPartId !== null
        ? buildingParts.find(bp => bp.id === buildingPart.motherBuildingPartId)
        : undefined;

    return {
      building: building?.buildingName ?? '',
      buildingPartName: buildingPart.buildingPartName,
      buildingPartType: buildingPart.buildingPartType.toString(),
      buildingPartFloorLevel: buildingPart.buildingPartFloorLevel ?? 0,
      motherBuildingPart: motherBuildingPart?.buildingPartName ?? '',
      uploaded: buildingPart.uploaded ?? false,
    };
  });
};
