import { TextField } from '@mui/material';
import { useIntl } from 'react-intl';

interface Props {
  dataTest?: string;
  defaultValue?: string;
  value?: string;
  label: string;
  placeholder: string;
  setFilter: (value: string) => void;
  targetRef?: React.RefObject<HTMLDivElement>;
}

const Filter = ({
  dataTest = 'filter',
  defaultValue,
  value,
  label,
  placeholder,
  setFilter,
  targetRef,
}: Props): JSX.Element => {
  const intl = useIntl();

  const handleToTop = () => {
    if (targetRef?.current) {
      targetRef.current.scrollTop = 0;
    }
  };

  return (
    <TextField
      data-test={dataTest}
      defaultValue={defaultValue}
      fullWidth
      value={value}
      label={intl.formatMessage({
        id: label,
      })}
      onChange={event => {
        handleToTop();
        setFilter(event.target.value);
      }}
      placeholder={intl.formatMessage({
        id: placeholder,
      })}
      type="search"
      variant="outlined"
    />
  );
};

export default Filter;
