import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Popover,
} from '@mui/material';
import { FooterButton } from 'components/Layout';
import { FormattedMessage } from 'react-intl';
import { MaterialInputForRecap } from 'services/offline/materialInputsForRecap';
import { Image } from 'components/Image';
import { useState } from 'react';
import { useAsync } from 'react-use';
import { listMaterialInputPicturesIds } from 'services/offline/requests/materialInput/listMaterialInputPicturesIds';
import {
  CardImage,
  CardNewImage,
  GridImages,
} from 'components/MaterialInputPhoto/MaterialInputPhoto.style';
import { getMaterialInputPicture } from 'services/offline/requests/materialInput/getMaterialInputPicture';
import { deleteLocalMaterialInputPicture } from 'services/offline/requests/materialInput/deleteLocalMaterialInputPicture';
import { ImageTypes } from '@afleya/common';
import PictureDetail from 'components/PictureDetail';
import Camera from 'components/Camera';
import { CameraAlt } from '@mui/icons-material';
import { BrowseImage } from 'components/BrowseImage';
import { createLocalMaterialInputPicture } from 'services/offline/requests/materialInput/createLocalMaterialInputPicture';
import { DialogTransition } from 'components/DialogTransition';

interface Props {
  open: boolean;
  projectId: string;
  materialInput: MaterialInputForRecap;
  onClose: (nbPictures: number) => void;
}

export const InputModuleSummaryPicturesDialog = ({
  open,
  projectId,
  materialInput,
  onClose,
}: Props): JSX.Element => {
  const [materialInputPictureIds, setMaterialInputPictureIds] = useState<
    Array<string>
  >([]);

  const [showCamera, setShowCamera] = useState(false);
  const [showPictureDetail, setShowPictureDetail] = useState(false);
  const [pictureDetailId, setPictureDetailId] = useState<string>();

  useAsync(async () => {
    const localMaterialInputPicturesIds = await listMaterialInputPicturesIds(
      materialInput.id,
    );

    setMaterialInputPictureIds(localMaterialInputPicturesIds);
  }, [materialInput]);

  const addPictureId = (pictureId: string) => {
    if (materialInputPictureIds.length === 3) {
      console.log('Il y a déjà 3 photos');
      // TODO DISPLAY ERROR
    } else {
      setMaterialInputPictureIds(materialInputPictureIds.concat(pictureId));
    }
  };

  const removePictureId = (pictureId: string) =>
    setMaterialInputPictureIds(
      materialInputPictureIds.filter(mipi => mipi !== pictureId),
    );

  const closeCamera = () => setShowCamera(false);

  const openCamera = () => setShowCamera(true);

  const closePictureDetail = () => {
    setShowPictureDetail(false);
    setPictureDetailId(undefined);
  };

  const openPictureDetailWithId = (materialInputPictureId: string) => {
    setPictureDetailId(materialInputPictureId);
    setShowPictureDetail(true);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      keepMounted
      fullScreen
    >
      <DialogTitle>
        <FormattedMessage
          id="input-module-summary.dialog-pictures.title"
          values={{
            nickname: materialInput.nickname,
          }}
        />
      </DialogTitle>
      <DialogContent>
        <GridImages rowGap={2}>
          {materialInputPictureIds.map(mipi => (
            <CardImage key={mipi}>
              <Image
                projectId={projectId}
                hostId={materialInput.id}
                imageId={mipi}
                errorLoadingId="photo.errorLoading"
                dialogContentIds={[
                  'photo.deleteDialogTitle',
                  'photo.deleteDialogContent',
                ]}
                getImage={getMaterialInputPicture}
                imageType={ImageTypes.materialInputPicture}
                deleteImage={deleteLocalMaterialInputPicture}
                removeImageIdFromState={removePictureId}
                onClick={() => openPictureDetailWithId(mipi)}
              />
            </CardImage>
          ))}
          {materialInputPictureIds.length < 3 && (
            <CardNewImage variant="outlined">
              <Button color="primary" onClick={openCamera}>
                <CameraAlt fontSize="large" />
              </Button>
              <FormattedMessage id="input-module-input-photos-page.addPhoto" />
              <BrowseImage
                hostId={materialInput.id}
                projectId={projectId}
                selectFileId="photo.selectFile"
                addImageId={addPictureId}
                requestCreateLocalImage={createLocalMaterialInputPicture}
              />
            </CardNewImage>
          )}
        </GridImages>
        <Popover open={showCamera}>
          <Camera
            onCloseCamera={closeCamera}
            hostId={materialInput.id}
            projectId={projectId}
            addImageId={addPictureId}
            requestCreateLocalImage={createLocalMaterialInputPicture}
          />
        </Popover>
        {pictureDetailId !== undefined && (
          <Popover open={showPictureDetail}>
            <PictureDetail
              onClosePictureDetail={closePictureDetail}
              removeMaterialInputPictureId={removePictureId}
              projectId={projectId}
              materialInputId={materialInput.id}
              pictureId={pictureDetailId}
            />
          </Popover>
        )}
      </DialogContent>
      <DialogActions>
        <FooterButton
          variant="contained"
          onClick={() => onClose(materialInputPictureIds.length)}
        >
          <FormattedMessage id="input-module-summary.dialog-pictures.button" />
        </FooterButton>
      </DialogActions>
    </Dialog>
  );
};
