import { UserGroup } from '@afleya/common';
import LoadingBox from 'components/LoadingBox';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { useAsync } from 'react-use';
import { routes } from 'routes';
import { getLocalUserProfile } from 'services/users/getLocalUserProfile';
import { RootState } from 'store';

export const HomePage = (): JSX.Element => {
  const userProfile = useSelector(
    (state: RootState) => state.userProfileState.userProfile,
  );

  useAsync(async () => {
    if (userProfile === undefined) {
      await getLocalUserProfile();
    }
  });

  const isLogged = useSelector((state: RootState) => state.loginState.isLogged);
  if (isLogged === false) {
    return <Navigate to={routes.LOGIN} />;
  } else if (userProfile === undefined) {
    return <LoadingBox />;
  } else if (userProfile.groups.includes(UserGroup.AKIBO_PROJECT_OWNER)) {
    return <Navigate to={routes.PROJECT_OWNER_LIST_PROJECTS} />;
  } else {
    return <Navigate to={routes.LIST_PROJECTS} />;
  }
};
