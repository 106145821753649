import { Box, Grid2 } from '@mui/material';
import { RootState } from 'store';
import Filter from 'components/Filter';
import { RecognitionCameraButton } from 'components/RecognitionCameraButton';
import { useSelector } from 'react-redux';

interface Props {
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  pageContentRef: React.RefObject<HTMLDivElement>;
  cameraButtonNavigate: () => void;
}

export const FilterWithRecognitionCameraButton = ({
  setSearchTerm,
  pageContentRef,
  cameraButtonNavigate,
}: Props): JSX.Element => {
  const userOrganization = useSelector(
    (state: RootState) => state.userProfileState.userProfile?.organization,
  );
  console.log(userOrganization);

  return (
    <Box>
      <Grid2 container spacing={2}>
        <Grid2
          size={{
            xs: userOrganization === 'Afleya' ? 10 : 12,
            sm: userOrganization === 'Afleya' ? 11 : 12,
          }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
            marginTop={'5px'}
          >
            <Filter
              dataTest="materialFilter"
              label="input-module-categories-page.filterLabel"
              placeholder="input-module-categories-page.filterPlaceholder"
              setFilter={setSearchTerm}
              targetRef={pageContentRef}
            />
          </Box>
        </Grid2>
        <Grid2 size={{ xs: 2, sm: 1 }}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
            marginTop={'5px'}
          >
            <RecognitionCameraButton navigate={cameraButtonNavigate} />
          </Box>
        </Grid2>
      </Grid2>
    </Box>
  );
};
