import { Characteristic } from 'components/CreateOrEditCharacteristic/CharacteristicType';
import { materialsInputsDatabase } from '../../dexieDB';
import { createLocalCharacteristicInput } from './createLocalCharacteristicInput';

export const editLocalCharacteristicInputs = async (
  localMaterialInputId: string,
  characteristics: Characteristic[],
): Promise<void> => {
  await materialsInputsDatabase.deleteCharacteristicInputsOfMaterialInput(
    localMaterialInputId,
  );
  await createLocalCharacteristicInput(characteristics, localMaterialInputId);
};
