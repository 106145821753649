import { IntlShape } from 'react-intl';

interface LabelKeyObject {
  label: string;
  value: string;
}

type Headers = LabelKeyObject[];

export const getMaterialInputCsvFields = (intl: IntlShape): Headers => [
  {
    label: intl.formatMessage({ id: 'export.material-input.category' }),
    value: 'category',
  },
  {
    label: intl.formatMessage({ id: 'export.material-input.material' }),
    value: 'material',
  },
  {
    label: intl.formatMessage({ id: 'export.material-input.nickname' }),
    value: 'nickname',
  },
  {
    label: intl.formatMessage({ id: 'export.material-input.dimensions' }),
    value: 'dimensions',
  },
  {
    label: intl.formatMessage({ id: 'export.material-input.building' }),
    value: 'building',
  },
  {
    label: intl.formatMessage({
      id: 'export.material-input.buildingPart',
    }),
    value: 'buildingPart',
  },
  {
    label: intl.formatMessage({ id: 'export.material-input.location' }),
    value: 'locationDescription',
  },
  {
    label: intl.formatMessage({ id: 'export.material-input.amount' }),
    value: 'amount',
  },
  {
    label: intl.formatMessage({ id: 'export.material-input.age' }),
    value: 'age',
  },
  {
    label: intl.formatMessage({ id: 'export.material-input.assemblyType' }),
    value: 'assemblyType',
  },
  {
    label: intl.formatMessage({ id: 'export.material-input.reusePercentage' }),
    value: 'reusePercentage',
  },
  {
    label: intl.formatMessage({
      id: 'export.material-input.stateNewPercentage',
    }),
    value: 'stateNewPercentage',
  },
  {
    label: intl.formatMessage({
      id: 'export.material-input.stateGoodPercentage',
    }),
    value: 'stateGoodPercentage',
  },
  {
    label: intl.formatMessage({
      id: 'export.material-input.stateMediumPercentage',
    }),
    value: 'stateMediumPercentage',
  },
  {
    label: intl.formatMessage({
      id: 'export.material-input.stateObsoletePercentage',
    }),
    value: 'stateObsoletePercentage',
  },
  {
    label: intl.formatMessage({
      id: 'export.material-input.stateComments',
    }),
    value: 'stateComments',
  },
  {
    label: intl.formatMessage({ id: 'export.material-input.comments' }),
    value: 'comments',
  },
  {
    label: intl.formatMessage({
      id: 'export.material-input.materialToDeduce',
    }),
    value: 'materialToDeduce',
  },
  {
    label: intl.formatMessage({
      id: 'export.material-input.hasAsbestos',
    }),
    value: 'hasAsbestos',
  },
  {
    label: intl.formatMessage({
      id: 'export.material-input.hasLead',
    }),
    value: 'hasLead',
  },
  {
    label: intl.formatMessage({
      id: 'export.material-input.hasOtherPollution',
    }),
    value: 'hasOtherPollution',
  },
  {
    label: intl.formatMessage({
      id: 'export.material-input.weightPerFunctionalUnit',
    }),
    value: 'weightPerFunctionalUnit',
  },
  {
    label: intl.formatMessage({
      id: 'export.material-input.loadedFromServer',
    }),
    value: 'loadedFromServer',
  },
];
