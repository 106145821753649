import { ButtonGroup, ToggleButtonGroup } from '@mui/material';

import {
  ButttonSelector,
  DivSelectorButtonContainer,
  DivSelectorSubtitle,
  DivSelectorTitle,
} from './CustomIntegerSelector.style';

interface Props {
  dataTest: string;
  id: string;
  title: string;
  subtitle: string;
  value?: number;
  setValue: (value?: number) => void;
  defaultMaxShownValue: number;
  minShownValue: number;
}

const CustomIntegerSelector = ({
  dataTest,
  id,
  title,
  subtitle,
  value,
  setValue,
  defaultMaxShownValue,
  minShownValue,
}: Props): JSX.Element => {
  const possibleValues = Array.from(
    { length: defaultMaxShownValue - minShownValue + 1 },
    (_, index) => index + minShownValue,
  );

  return (
    <div data-test={dataTest}>
      <div>
        <DivSelectorTitle>{title}</DivSelectorTitle>
      </div>
      <DivSelectorSubtitle>{subtitle}</DivSelectorSubtitle>
      <div>
        <ToggleButtonGroup exclusive={true}>
          {possibleValues.map(possibleValue => (
            <DivSelectorButtonContainer key={possibleValue}>
              <ButttonSelector
                data-test={possibleValue}
                id={`${id}-${possibleValue}`}
                onClick={() => {
                  setValue(possibleValue);
                }}
                color={value === possibleValue ? 'secondary' : 'primary'}
                variant={value === possibleValue ? 'contained' : 'outlined'}
              >
                {possibleValue}
              </ButttonSelector>
            </DivSelectorButtonContainer>
          ))}
        </ToggleButtonGroup>
      </div>
      <div>
        {value === undefined || value <= defaultMaxShownValue ? (
          <DivSelectorButtonContainer>
            <ButttonSelector
              onClick={() => {
                setValue(defaultMaxShownValue + 1);
              }}
              variant="outlined"
            >
              +
            </ButttonSelector>
          </DivSelectorButtonContainer>
        ) : (
          <div>
            <ButtonGroup>
              {value !== defaultMaxShownValue + 1 ? (
                <DivSelectorButtonContainer>
                  <ButttonSelector
                    variant="outlined"
                    onClick={() => {
                      setValue(value - 1);
                    }}
                  >
                    -
                  </ButttonSelector>
                </DivSelectorButtonContainer>
              ) : (
                <DivSelectorButtonContainer>
                  <ButttonSelector>&nbsp;</ButttonSelector>
                </DivSelectorButtonContainer>
              )}
              <DivSelectorButtonContainer>
                <ButttonSelector variant="contained" color="secondary">
                  {value}
                </ButttonSelector>
              </DivSelectorButtonContainer>
              <DivSelectorButtonContainer>
                <ButttonSelector
                  variant="outlined"
                  onClick={() => {
                    setValue(value + 1);
                  }}
                >
                  +
                </ButttonSelector>
              </DivSelectorButtonContainer>
            </ButtonGroup>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomIntegerSelector;
