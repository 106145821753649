import { unifyString } from '@afleya/common';

const filterService = (values: string[], filter: string): boolean => {
  const filters = filter.split(' ').map(value => unifyString(value));
  for (const aFilter of filters) {
    if (!valuesContain(values, aFilter)) {
      return false;
    }
  }

  return true;
};

const valuesContain = (values: string[], filter: string): boolean => {
  for (const value of values) {
    if (unifyString(value).includes(filter)) {
      return true;
    }
  }

  return false;
};
export default filterService;
