import { ProjectEntity } from '@afleya/project-schemas';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface offlineProject {
  project: ProjectEntity | undefined;
}

const initialState: offlineProject = { project: undefined };

export const offlineProjectSlice = createSlice({
  name: 'offlineProject',
  initialState,
  reducers: {
    setOfflineProject: (
      state,
      action: PayloadAction<ProjectEntity | undefined>,
    ) => {
      state.project = action.payload;
    },
  },
});

export const { setOfflineProject } = offlineProjectSlice.actions;

export default offlineProjectSlice.reducer;
