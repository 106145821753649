import { styled } from '@mui/material/styles';

interface Props {
  colorName: string;
  colorCode: string;
  selected: boolean;
  inputCharacteristicInputs: string[];
}

export const AnalysisModuleColorCircle = styled('div')<Props>(({
  colorName,
  colorCode,
  selected,
  inputCharacteristicInputs,
}) => {
  const isColorInInputCharacteristicInputs =
    inputCharacteristicInputs.includes(colorName);

  return {
    width: 30,
    height: 30,
    borderRadius: '50%',
    background:
      colorCode === 'multicolor'
        ? 'linear-gradient(45deg, black, red, yellow, blue, green, white)'
        : colorCode,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    border: colorCode === '#FFFFFF' ? '0.125em solid black' : '',
    '&::after': {
      content: selected || isColorInInputCharacteristicInputs ? '"✔"' : '""',
      fontSize: 25,
      color: colorCode === '#000000' ? 'white' : 'black',
    },
  };
});
