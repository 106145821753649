import { BuildingEntity, BuildingPartEntity } from '@afleya/project-schemas';
import { materialsInputsDatabase } from '../../dexieDB';

export interface BuildingWithBuildingParts {
  building: BuildingEntity;
  buildingParts: BuildingPartEntity[];
}

export const getBuildingWithBuildingParts = async (
  buildingId: string,
): Promise<BuildingWithBuildingParts> => {
  const building = await materialsInputsDatabase.buildings.get(buildingId);
  if (building === undefined) {
    throw new Error(`building ${buildingId} not found in database`);
  }

  const buildingParts = await materialsInputsDatabase.buildingParts
    .where({ buildingId })
    .reverse()
    .sortBy('buildingPartFloorLevel');

  return { buildingParts, building };
};
