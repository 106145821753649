/* eslint-disable complexity */
import yard from './ShuntingYard';

const Rpn = (expression: string): string => {
  const expr = yard(expression).split(' ');
  const stack: number[] = [];
  if (expr[0] === '') {
    return '';
  }

  for (let i = 0; i < expr.length; i++) {
    const num = parseFloat(expr[i]);
    if (!isNaN(num) && isFinite(num)) {
      stack.push(num);
    } else {
      const a = stack.pop();
      const b = stack.pop();
      if (a === undefined || b === undefined) {
        return '';
      }
      if (expr[i] === '+') {
        stack.push(a + b);
      } else if (expr[i] === '-') {
        stack.push(b - a);
      } else if (expr[i] === '*') {
        stack.push(a * b);
      } else if (expr[i] === '/') {
        stack.push(b / a);
      }
    }
  }

  if (stack.length > 1) {
    return '';
  }

  return (Math.round(stack[0] * 1000) / 1000).toString(); // Fix floating numbers problem
};

export default Rpn;
