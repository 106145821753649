import { MaterialCategoryEntity } from '@afleya/material-schemas';

export const filterCategoriesByMotherCategories = (
  daughterCategories: MaterialCategoryEntity[],
  motherCategories: MaterialCategoryEntity[],
): MaterialCategoryEntity[] => {
  return daughterCategories.filter(daughter => {
    return motherCategories.some(mother => mother.id === daughter.motherId);
  });
};
