/* eslint-disable complexity */
import { InputMethod, InputUnit } from '@afleya/common';

export const computeMaterialInputForRecapDimensions = (
  inputMethod: InputMethod,
  inputUnit: InputUnit,
  materialInputDimensions: {
    [x: string]: unknown;
  },
): string => {
  const dimensions: number[] = [];
  if (typeof materialInputDimensions['length'] === 'number') {
    dimensions.push(materialInputDimensions['length']);
  }
  if (typeof materialInputDimensions['width'] === 'number') {
    dimensions.push(materialInputDimensions['width']);
  }
  if (typeof materialInputDimensions['height'] === 'number') {
    dimensions.push(materialInputDimensions['height']);
  }

  let unit = 'cm';
  if (inputUnit === InputUnit.METER) {
    for (let i = 0; i < dimensions.length; i++) dimensions[i] /= 100;
    unit = 'm';
  }

  if (inputMethod === InputMethod.DIMENSIONS) {
    let dimensionsStr = '';
    if (dimensions.length >= 1) {
      dimensionsStr += `L: ${dimensions[0]}${unit}`;
    }
    if (dimensions.length >= 2) {
      dimensionsStr += ` l: ${dimensions[1]}${unit}`;
    }
    if (dimensions.length === 3) {
      dimensionsStr += ` h: ${dimensions[2]}${unit}`;
    }

    return dimensionsStr;
  } else if (inputMethod === InputMethod.SURFACE) {
    return `${dimensions[0]}${unit}²`;
  } else {
    return `${dimensions[0]}${unit}³`;
  }
};
