import { object, string, TypeOf } from 'zod';

export const buildingPartFormAnswersSchema = object({
  buildingPartName: string().min(1),
  motherBuildingPartId: string().uuid().optional(),
});

export const duplicateBuildingPartFormAnswersSchema = object({
  buildingPartNames: object({
    buildingPartName: string().min(1),
  })
    .array()
    .nonempty(),
});

export type BuildingPartFormAnswers = TypeOf<
  typeof buildingPartFormAnswersSchema
>;

export type DuplicateBuildingPartFormAnswersSchema = TypeOf<
  typeof duplicateBuildingPartFormAnswersSchema
>;
