import { WasteCategory } from '@afleya/common';
import muiTheme from 'services/theme/muiTheme';

const stringToWasteCategory = (str: string): str is WasteCategory =>
  Object.values(WasteCategory).includes(str as WasteCategory);

export const getWasteCategoryColor = (
  wasteCategory: string | WasteCategory,
): string => {
  if (!stringToWasteCategory(wasteCategory)) {
    return muiTheme.palette.error.main;
  }

  switch (wasteCategory) {
    case WasteCategory.DD:
      return '#EC4D34';
    case WasteCategory.DEA:
      return '#C42574';
    case WasteCategory.DEEE:
      return '#FF8042';
    case WasteCategory.DI:
      return '#00C49F';
    case WasteCategory.DNIND:
      return '#FFBB28';
  }
};
