// schemas/entities/ChangeType.ts
var ChangeType = /* @__PURE__ */ ((ChangeType2) => {
  ChangeType2[ChangeType2["Create"] = 1] = "Create";
  ChangeType2[ChangeType2["Update"] = 2] = "Update";
  ChangeType2[ChangeType2["Delete"] = 3] = "Delete";
  return ChangeType2;
})(ChangeType || {});

// schemas/entities/MaterialInputCreateChange.ts
import { materialInputEntityChoreSchema } from "@afleya/project-schemas";

// schemas/entities/MaterialInputTable.ts
var MaterialInputTable = /* @__PURE__ */ ((MaterialInputTable2) => {
  MaterialInputTable2["MaterialInput"] = "materialInputs";
  MaterialInputTable2["MaterialInputIcon"] = "materialInputIcons";
  MaterialInputTable2["Building"] = "buildings";
  MaterialInputTable2["BuildingPart"] = "buildingParts";
  MaterialInputTable2["CharacteristicInput"] = "characteristicInputs";
  return MaterialInputTable2;
})(MaterialInputTable || {});
var stringIsMaterialInputTable = (table) => {
  return Object.values(MaterialInputTable).includes(table);
};

// schemas/entities/MaterialInputCreateChange.ts
var materialInputCreateChangeSchema = {
  type: "object",
  properties: {
    type: { const: 1 /* Create */ },
    table: { const: "materialInputs" /* MaterialInput */ },
    key: { type: "string" },
    obj: materialInputEntityChoreSchema
  },
  required: ["type", "table", "key", "obj"]
};

// schemas/entities/MaterialInputDeleteChange.ts
var materialInputDeleteChangeSchema = {
  type: "object",
  properties: {
    type: { const: 3 /* Delete */ },
    table: { const: "materialInputs" /* MaterialInput */ },
    key: { type: "string" }
  },
  required: ["type", "table", "key"],
  additionalProperties: false
};

// schemas/entities/MaterialInputUpdateChange.ts
import { editMaterialInputSchema } from "@afleya/project-schemas";
var materialInputUpdateChangeSchema = {
  type: "object",
  properties: {
    type: { const: 2 /* Update */ },
    table: { const: "materialInputs" /* MaterialInput */ },
    key: { type: "string" },
    mods: editMaterialInputSchema
  },
  required: ["type", "table", "key", "mods"],
  additionalProperties: false
};

// schemas/entities/MaterialInputDatabaseChange.ts
var materialInputDatabaseChangeSchema = {
  oneOf: [
    materialInputCreateChangeSchema,
    materialInputUpdateChangeSchema,
    materialInputDeleteChangeSchema
  ],
  additionalProperties: false
};

// schemas/entities/MaterialInputIconCreateChange.ts
import { materialInputIconEntitySchema } from "@afleya/project-schemas";
var materialInputIconCreateChangeSchema = {
  type: "object",
  properties: {
    type: { const: 1 /* Create */ },
    table: { const: "materialInputIcons" /* MaterialInputIcon */ },
    key: { type: "string" },
    obj: materialInputIconEntitySchema
  },
  required: ["type", "table", "key", "obj"]
};

// schemas/entities/MaterialInputIconDeleteChange.ts
var materialInputIconDeleteChangeSchema = {
  type: "object",
  properties: {
    type: { const: 3 /* Delete */ },
    table: { const: "materialInputIcons" /* MaterialInputIcon */ },
    key: { type: "string" }
  },
  required: ["type", "table", "key"],
  additionalProperties: false
};

// schemas/entities/MaterialInputIconUpdateChange.ts
import { editMaterialInputIconEntitySchema } from "@afleya/project-schemas";
var materialInputIconUpdateChangeSchema = {
  type: "object",
  properties: {
    type: { const: 2 /* Update */ },
    table: { const: "materialInputIcons" /* MaterialInputIcon */ },
    key: { type: "string" },
    mods: editMaterialInputIconEntitySchema
  },
  required: ["type", "table", "key", "mods"],
  additionalProperties: false
};

// schemas/entities/MaterialInputIconDatabaseChange.ts
var materialInputIconDatabaseChangeSchema = {
  oneOf: [
    materialInputIconCreateChangeSchema,
    materialInputIconDeleteChangeSchema,
    materialInputIconUpdateChangeSchema
  ],
  additionalProperties: false
};

// schemas/entities/BuildingPartCreateChange.ts
import { buildingPartEntitySchema } from "@afleya/project-schemas";
var buildingPartCreateChangeSchema = {
  type: "object",
  properties: {
    type: { const: 1 /* Create */ },
    table: { const: "buildingParts" /* BuildingPart */ },
    key: { type: "string" },
    obj: buildingPartEntitySchema
  },
  required: ["type", "table", "key", "obj"]
};

// schemas/entities/BuildingPartDeleteChange.ts
var buildingPartDeleteChangeSchema = {
  type: "object",
  properties: {
    type: { const: 3 /* Delete */ },
    table: { const: "buildingParts" /* BuildingPart */ },
    key: { type: "string" }
  },
  required: ["type", "table", "key"],
  additionalProperties: false
};

// schemas/entities/BuildingPartUpdateChange.ts
import { editBuildingPartSchema } from "@afleya/project-schemas";
var buildingPartUpdateChangeSchema = {
  type: "object",
  properties: {
    type: { const: 2 /* Update */ },
    table: { const: "buildingParts" /* BuildingPart */ },
    key: { type: "string" },
    mods: editBuildingPartSchema
  },
  required: ["type", "table", "key", "mods"],
  additionalProperties: false
};

// schemas/entities/BuildingPartDatabaseChange.ts
var buildingPartDatabaseChangeSchema = {
  oneOf: [
    buildingPartCreateChangeSchema,
    buildingPartDeleteChangeSchema,
    buildingPartUpdateChangeSchema
  ],
  additionalProperties: false
};

// schemas/entities/BuildingCreateChange.ts
import { BuildingEntitySchema } from "@afleya/project-schemas";
var buildingCreateChangeSchema = {
  type: "object",
  properties: {
    type: { const: 1 /* Create */ },
    table: { const: "buildings" /* Building */ },
    key: { type: "string" },
    obj: BuildingEntitySchema
  },
  required: ["type", "table", "key", "obj"]
};

// schemas/entities/BuildingDeleteChange.ts
var buildingDeleteChangeSchema = {
  type: "object",
  properties: {
    type: { const: 3 /* Delete */ },
    table: { const: "buildings" /* Building */ },
    key: { type: "string" }
  },
  required: ["type", "table", "key"],
  additionalProperties: false
};

// schemas/entities/BuildingUpdateChange.ts
import { editBuildingSchema } from "@afleya/project-schemas";
var buildingUpdateChangeSchema = {
  type: "object",
  properties: {
    type: { const: 2 /* Update */ },
    table: { const: "buildings" /* Building */ },
    key: { type: "string" },
    mods: editBuildingSchema
  },
  required: ["type", "table", "key", "mods"],
  additionalProperties: false
};

// schemas/entities/BuildingDatabaseChange.ts
var buildingDatabaseChangeSchema = {
  oneOf: [
    buildingCreateChangeSchema,
    buildingDeleteChangeSchema,
    buildingUpdateChangeSchema
  ],
  additionalProperties: false
};

// schemas/entities/CharacteristicInputCreateChange.ts
import { CharacteristicInputEntitySchema } from "@afleya/project-schemas";
var characteristicInputCreateChangeSchema = {
  type: "object",
  properties: {
    type: { const: 1 /* Create */ },
    table: { const: "characteristicInputs" /* CharacteristicInput */ },
    key: { type: "string", format: "uuid" },
    obj: CharacteristicInputEntitySchema
  },
  required: ["type", "table", "key", "obj"]
};

// schemas/entities/CharacteristicInputDeleteChange.ts
var characteristicInputDeleteChangeSchema = {
  type: "object",
  properties: {
    type: { const: 3 /* Delete */ },
    table: { const: "characteristicInputs" /* CharacteristicInput */ },
    key: { type: "string", format: "uuid" }
  },
  required: ["type", "table", "key"],
  additionalProperties: false
};

// schemas/entities/CharacteristicInputDatabaseChange.ts
var characteristicInputDatabaseChangeSchema = {
  oneOf: [
    characteristicInputCreateChangeSchema,
    characteristicInputDeleteChangeSchema
  ],
  additionalProperties: false
};
export {
  ChangeType,
  MaterialInputTable,
  buildingCreateChangeSchema,
  buildingDatabaseChangeSchema,
  buildingDeleteChangeSchema,
  buildingPartCreateChangeSchema,
  buildingPartDatabaseChangeSchema,
  buildingPartDeleteChangeSchema,
  buildingPartUpdateChangeSchema,
  buildingUpdateChangeSchema,
  characteristicInputCreateChangeSchema,
  characteristicInputDatabaseChangeSchema,
  characteristicInputDeleteChangeSchema,
  materialInputCreateChangeSchema,
  materialInputDatabaseChangeSchema,
  materialInputDeleteChangeSchema,
  materialInputIconCreateChangeSchema,
  materialInputIconDatabaseChangeSchema,
  materialInputIconDeleteChangeSchema,
  materialInputIconUpdateChangeSchema,
  materialInputUpdateChangeSchema,
  stringIsMaterialInputTable
};
