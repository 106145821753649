import { Button } from '@mui/material';
import SuccessDrawer from 'components/SuccessDrawer';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { navigateToModuleInput } from 'services/navigation/navigateToModuleInput';

interface Props {
  projectId: string;
  buildingId: string;
  buildingPartId: string;
  categoryId: string;
  materialId: string;
}

const CreateMaterialSuccessDrawer = ({
  projectId,
  buildingId,
  buildingPartId,
  categoryId,
  materialId,
}: Props): JSX.Element => {
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <SuccessDrawer
      open={true}
      title={intl.formatMessage({
        id: 'input-module-create-material.successDrawerMessage',
      })}
    >
      <Button
        data-test="addMaterialInput"
        color="primary"
        variant="contained"
        onClick={() => {
          if (materialId !== undefined) {
            navigateToModuleInput({
              projectId,
              buildingId,
              buildingPartId,
              categoryId,
              materialId,
              navigate,
            });
          }
        }}
      >
        <FormattedMessage id="input-module-create-material.toMaterialInput" />
      </Button>
    </SuccessDrawer>
  );
};

export default CreateMaterialSuccessDrawer;
