import { NavigateFunction } from 'react-router-dom';
import { routes } from 'routes';

interface Props {
  projectId: string | undefined;
  buildingId: string | undefined;
  buildingPartId: string | undefined;
  navigate: NavigateFunction;
}

export const navigateToModuleInputCategories = ({
  projectId,
  buildingId,
  buildingPartId,
  navigate,
}: Props): void => {
  if (
    projectId === undefined ||
    buildingId === undefined ||
    buildingPartId === undefined
  ) {
    console.error('projectId or buildingId or buildingPartId is undefined');

    return;
  }
  navigate(
    routes.INPUT_MODULE_CATEGORIES.replace(':projectId', projectId)
      .replace(':buildingId', buildingId)
      .replace(':buildingPartId', buildingPartId),
  );
};
