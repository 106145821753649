/* eslint-disable complexity */
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { FooterButton } from 'components/Layout';
import { FormattedMessage } from 'react-intl';
import {
  duplicateMaterialInputsForRecap,
  listLocationsForRecap,
  MaterialInputForRecap,
  moveMaterialInputsForRecap,
} from 'services/offline/materialInputsForRecap';
import { useAsync, useAsyncFn } from 'react-use';
import { useState } from 'react';
import LoadingBox from 'components/LoadingBox';
import { DialogTransition } from 'components/DialogTransition';
import { InputModuleSummaryMoveDialogContent } from './InputModuleSummaryMoveDialogContent';
import { TargetLocation, updateTargetLocation } from './TargetLocation';

interface Props {
  buildingId?: string;
  buildingPartId?: string;
  onClose: () => void;
  projectId: string;
  selectedMaterialInputs: Array<MaterialInputForRecap>;
  setSelectedMaterialInputs: (
    newSelectedMaterialInputs: Array<MaterialInputForRecap>,
  ) => void;
  materialInputs: Array<MaterialInputForRecap>;
  setMaterialInputs: (newMaterialInputs: Array<MaterialInputForRecap>) => void;
}

export const InputModuleSummaryDuplicateAndMoveDialog = ({
  buildingId,
  buildingPartId,
  projectId,
  onClose,
  selectedMaterialInputs,
  setSelectedMaterialInputs,
  materialInputs,
  setMaterialInputs,
}: Props): JSX.Element => {
  const [targetLocation, setTargetLocation] = useState<TargetLocation>({
    buildingId: null,
    buildingPartId: null,
    daugtherBuildingPartId: null,
    isValid: false,
  });

  const { loading: loadingLocationsForRecap, value: locationsForRecap } =
    useAsync(() => listLocationsForRecap(projectId), [projectId]);

  const handleBuildingClick = (id: string) => {
    setTargetLocation(updateTargetLocation(targetLocation, { buildingId: id }));
  };

  const handleBuildingPartClick = (id: string) => {
    setTargetLocation(
      updateTargetLocation(targetLocation, { buildingPartId: id }),
    );
  };

  const handleDaughterBuildingPartClick = (id: string) => {
    setTargetLocation(
      updateTargetLocation(targetLocation, { daugtherBuildingPartId: id }),
    );
  };

  console.log(targetLocation);

  const [{ loading }, onDuplicateAndMove] = useAsyncFn(async () => {
    if (
      targetLocation.buildingId !== null &&
      targetLocation.buildingPartId !== null &&
      locationsForRecap !== undefined
    ) {
      const duplicatedMaterialInputs = await duplicateMaterialInputsForRecap(
        selectedMaterialInputs,
      );
      const newBuildingId = targetLocation.buildingId;
      const newBuildingPartId =
        targetLocation.daugtherBuildingPartId ?? targetLocation.buildingPartId;
      const building = locationsForRecap.find(l => (l.id = newBuildingId));
      const buildingPart = building?.buildingParts.find(
        b => b.id === targetLocation.buildingPartId,
      );
      const daughterBuildingPart = buildingPart?.daugtherBuildingParts.find(
        d => d.id === targetLocation.daugtherBuildingPartId,
      );

      const newMaterialInputs = await moveMaterialInputsForRecap({
        selectedMaterialInputs: duplicatedMaterialInputs,
        buildingId,
        buildingPartId,
        newBuildingId,
        newBuildingPartId,
        buildingName: building?.name ?? '',
        buildingPartName: buildingPart?.name ?? '',
        daughterBuildingPartName: daughterBuildingPart?.name,
      });

      if (
        buildingId === undefined ||
        (buildingId === newBuildingId && buildingPartId === undefined) ||
        buildingPartId === newBuildingPartId
      ) {
        setMaterialInputs(materialInputs.concat(newMaterialInputs));
      }

      setSelectedMaterialInputs([]);
      onClose();
    }
  }, [
    buildingId,
    buildingPartId,
    locationsForRecap,
    materialInputs,
    onClose,
    selectedMaterialInputs,
    setMaterialInputs,
    setSelectedMaterialInputs,
    targetLocation,
  ]);

  return (
    <Dialog open={true} TransitionComponent={DialogTransition} fullScreen>
      <DialogTitle>
        <FormattedMessage
          id="input-module-summary.dialog-duplicate-move.title"
          values={{ count: selectedMaterialInputs.length }}
        />
      </DialogTitle>
      {loadingLocationsForRecap || locationsForRecap === undefined ? (
        <LoadingBox />
      ) : (
        <DialogContent>
          <InputModuleSummaryMoveDialogContent
            locationsForRecap={locationsForRecap}
            handleBuildingClick={handleBuildingClick}
            handleBuildingPartClick={handleBuildingPartClick}
            handleDaughterBuildingPartClick={handleDaughterBuildingPartClick}
            targetLocation={targetLocation}
          />
        </DialogContent>
      )}
      <DialogActions>
        <FooterButton
          variant="contained"
          color="secondary"
          onClick={() => {
            if (!loading) {
              onClose();
            }
          }}
        >
          <FormattedMessage id="input-module-summary.dialog-duplicate-move.cancelButton" />
        </FooterButton>
        <FooterButton
          variant="contained"
          disabled={!targetLocation.isValid}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={onDuplicateAndMove}
        >
          <FormattedMessage id="input-module-summary.dialog-duplicate-move.confirmButton" />
        </FooterButton>
      </DialogActions>
    </Dialog>
  );
};
