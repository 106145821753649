import { Parser } from '@json2csv/plainjs';
import { IntlShape } from 'react-intl';
import { LocalMaterialInputIcon } from 'services/materialInputIcons/type';
import { listProjectMaterialInputIcons } from 'services/offline/requests/materialInput/listProjectMaterialInputIcons';

interface LabelKeyObject {
  label: string;
  value: string;
  default?: string;
}

type Headers = LabelKeyObject[];

export const generateMaterialInputIconsCsvExport = async (
  projectId: string,
  intl: IntlShape,
): Promise<string> => {
  const csv = rawMaterialInputIconCsvExportEntitiesToCsvOffline(
    await listProjectMaterialInputIcons(projectId),
    intl,
  );

  return csv;
};

const getCsvFields = (intl: IntlShape): Headers => [
  {
    label: intl.formatMessage({ id: 'csv-material-input-icon.id' }),
    value: 'id',
  },
  {
    label: intl.formatMessage({ id: 'csv-material-input-icon.lat' }),
    value: 'lat',
  },
  {
    label: intl.formatMessage({ id: 'csv-material-input-icon.lng' }),
    value: 'lng',
  },
  {
    label: intl.formatMessage({
      id: 'csv-material-input-icon.materialInputId',
    }),
    value: 'materialInputId',
  },
  {
    label: intl.formatMessage({
      id: 'csv-material-input-icon.buildingPartMapId',
    }),
    value: 'buildingPartMapId',
  },
  {
    label: intl.formatMessage({
      id: 'csv-material-input-icon.amount',
    }),
    value: 'amount',
  },
  {
    label: intl.formatMessage({
      id: 'csv-material-input-icon.uploaded',
    }),
    value: 'uploaded',
    default: 'false',
  },
];

const rawMaterialInputIconCsvExportEntitiesToCsvOffline = (
  data: LocalMaterialInputIcon[],
  intl: IntlShape,
): string => {
  const parser = new Parser({
    delimiter: ';',
    withBOM: true,
    fields: getCsvFields(intl),
  });

  const csv = parser.parse(data);

  return csv;
};
