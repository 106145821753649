import { MaterialCategoryEntity } from '@afleya/material-schemas';

const getCategoryName = (
  categories: MaterialCategoryEntity[] | undefined,
  categoryId: string | null,
): string => {
  if (categories !== undefined && categoryId !== null) {
    const obj = categories.find(category => category.id === categoryId);
    if (obj !== undefined) {
      return obj.name;
    } else {
      return '';
    }
  }

  return '';
};

export default getCategoryName;
