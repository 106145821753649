import { MaterialCategoryEntity } from '@afleya/material-schemas';
import { materialsDatabase } from '../../dexieDB';

export const listCategories = async (): Promise<MaterialCategoryEntity[]> => {
  const categories = await materialsDatabase.categories
    .reverse()
    .reverse()
    .sortBy('name');

  return categories;
};
