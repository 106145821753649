import { Description } from '@mui/icons-material';
import { Badge } from '@mui/material';
import { FooterButton } from 'components/Layout';
import { FormattedMessage } from 'react-intl';

interface Props {
  nbMaterialInputs: number;
  onClick: () => void;
}

export const InputModuleSummaryButton = ({
  nbMaterialInputs,
  onClick,
}: Props): JSX.Element => (
  <FooterButton variant="outlined" onClick={onClick}>
    <FormattedMessage id="input-module-summary-button" />
    <Badge max={9999} color="secondary" badgeContent={nbMaterialInputs}>
      <Description color="primary" />
    </Badge>
  </FooterButton>
);
