import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface UnsynchronizedOperationsCounterState {
  value: number;
}

const initialState: UnsynchronizedOperationsCounterState = {
  value: 0,
};

export const unsynchronizedOperationscounterSlice = createSlice({
  name: 'unsynchronizedOperationscounter',
  initialState,
  reducers: {
    increment: state => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1;
    },
    decrement: state => {
      state.value -= 1;
    },
    incrementByAmount: (state, action: PayloadAction<number>) => {
      state.value += action.payload;
    },
    decrementByAmount: (state, action: PayloadAction<number>) => {
      state.value -= action.payload;
    },
    setValue: (state, action: PayloadAction<number>) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  increment,
  decrement,
  decrementByAmount,
  incrementByAmount,
  setValue,
} = unsynchronizedOperationscounterSlice.actions;

export default unsynchronizedOperationscounterSlice.reducer;
