import BoxAlert from 'components/BoxAlert/BoxAlert';
import { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ButtonCamera,
  StackCamera,
  StackCameraFooter,
  VideoCamera,
} from './Camera.style';
import { setCameraStream } from './setCameraSream';

interface Props {
  onCloseCamera: () => void;
  hostId: string;
  projectId: string;
  addImageId: (id: string) => void;
  requestCreateLocalImage: (
    dataUrl: string,
    hostId: string,
    projectId: string,
  ) => Promise<string>;
}

const Camera = ({
  onCloseCamera,
  hostId,
  projectId,
  addImageId,
  requestCreateLocalImage,
}: Props): JSX.Element => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef(document.createElement('canvas'));
  const [disableCloseButton, setDisableCloseButton] = useState(true);
  const [disableTakePictureButton, setDisableTakePictureButton] =
    useState(true);
  const [errorMessageId, setErrorMessageId] = useState('');
  const ratio = 1280 / 720;
  const videoHeightMargin = 150;
  const videoWidthMargin = 10;
  useEffect(() => {
    const stopStream = setCameraStream(
      videoRef,
      setDisableCloseButton,
      setDisableTakePictureButton,
      setErrorMessageId,
    );

    return () => {
      setDisableCloseButton(true);
      setDisableTakePictureButton(true);
      void stopStream.then(value => {
        if (value === undefined) {
          return;
        } else {
          value();
        }
      });
    };
  }, []);

  const onTakePicture = async () => {
    if (videoRef.current === null) {
      return;
    }

    const imageWidth = videoRef.current.videoWidth;
    const imageHeight = videoRef.current.videoHeight;

    canvasRef.current.width = imageWidth;
    canvasRef.current.height = imageHeight;
    const context = canvasRef.current.getContext('2d');

    if (context === null) {
      return;
    }
    context.drawImage(videoRef.current, 0, 0, imageWidth, imageHeight);

    const base64 = canvasRef.current.toDataURL('image/jpeg', 1);

    const id = await requestCreateLocalImage(base64, hostId, projectId);
    addImageId(id);
    onCloseCamera();
  };

  return (
    <StackCamera spacing={2}>
      {errorMessageId === '' ? (
        <VideoCamera
          autoPlay
          playsInline
          muted
          width={
            (window.innerWidth - videoWidthMargin) /
              (window.innerHeight - videoHeightMargin) >
            ratio
              ? (window.innerHeight - videoHeightMargin) * ratio
              : window.innerWidth - videoWidthMargin
          }
          height={
            (window.innerWidth - videoWidthMargin) /
              (window.innerHeight - videoHeightMargin) >
            ratio
              ? window.innerHeight - videoHeightMargin
              : (window.innerWidth - videoWidthMargin) / ratio
          }
          ref={videoRef}
        />
      ) : (
        <BoxAlert contentId={errorMessageId} />
      )}
      <StackCameraFooter direction="row" spacing={2}>
        <ButtonCamera
          variant="contained"
          color="secondary"
          onClick={onCloseCamera}
          disabled={disableCloseButton}
        >
          <FormattedMessage id="camera.back" />
        </ButtonCamera>
        <ButtonCamera
          variant="contained"
          color="primary"
          onClick={() => {
            void onTakePicture();
          }}
          disabled={disableTakePictureButton}
        >
          <FormattedMessage id="camera.validate" />
        </ButtonCamera>
      </StackCameraFooter>
    </StackCamera>
  );
};

export default Camera;
