import { formatLocalBuildingPart } from 'services/buildingPart/formatLocalBuildingPart';
import { createLocalBuildingPart } from './createLocalBuildingPart';
import { duplicateBuildingPartMaterialInputs } from './duplicateBuildingPartMaterialInputs';
import { getLastBuildingPartFloorLevel } from './getLastBuildingPartFloorLevel';

interface Props {
  projectId: string;
  sourceBuildingPartId: string;
  targetBuildingId: string;
  targetBuildingPartId: string;
  buildingPartNames: [
    { buildingPartName: string },
    ...{ buildingPartName: string }[],
  ];
}

export const duplicateBuildingPartMultipleTime = async ({
  projectId,
  sourceBuildingPartId,
  targetBuildingId,
  targetBuildingPartId,
  buildingPartNames,
}: Props): Promise<void> => {
  for (const buildingPart of buildingPartNames) {
    const lastFloorLevel =
      (await getLastBuildingPartFloorLevel(
        targetBuildingId,
        targetBuildingPartId,
      )) ?? -1;

    const localBuildingPartToCreate = formatLocalBuildingPart({
      buildingPartName: buildingPart.buildingPartName,
      motherBuildingPartId: targetBuildingPartId,
      buildingId: targetBuildingId,
      floorLevel: lastFloorLevel + 1,
    });

    await createLocalBuildingPart(localBuildingPartToCreate);
    await duplicateBuildingPartMaterialInputs({
      projectId,
      sourceBuildingPartId,
      targetBuildingId: localBuildingPartToCreate.buildingId,
      targetBuildingPartId: localBuildingPartToCreate.id,
    });
  }
};
