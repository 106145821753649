import { Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { ColorCircle } from './ColorCircle';
import { ColorList } from './ColorList';

interface Props {
  initialColor: string | null;
  label: string;
  onChange: (newColor: string) => void;
}

export const ColorCharacteristic = ({
  initialColor,
  onChange,
  label,
}: Props): JSX.Element => {
  const colors = ColorList();
  const [selectedColor, setSelectedColor] = useState<string | null>(
    initialColor,
  );
  const handleColorClick = useCallback(
    (color: string) => {
      setSelectedColor(prevColor => {
        const newColor = prevColor === color ? null : color;

        setTimeout(() => {
          onChange(newColor ?? '');
        }, 5);

        return newColor;
      });
    },
    [onChange],
  );

  return (
    <div style={{ marginTop: '1em' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          position: 'relative',
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: '-0.7em',
            left: '1.125em',
            backgroundColor: 'white',
            fontSize: '0.7rem',
            color: 'rgba(0, 0, 0, 1)',
            fontWeight: 400,
            fontFamily: 'Roboto, sans-serif',
          }}
        >
          {label}
        </div>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            border: '0.063em solid #c4c4c4',
            borderRadius: '0.25em',
            padding: '1em',
          }}
        >
          {colors.map(color => (
            <div
              key={color.name}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                margin: '1em',
              }}
            >
              <ColorCircle
                color={color.code}
                selected={selectedColor === color.name}
                selectedColor={selectedColor}
                onClick={() => handleColorClick(color.name)}
              />
              <Typography align="center" sx={{ fontSize: '0.7rem' }}>
                {color.name}
              </Typography>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
