import { getDaughterCategoriesFromImageRecognitionLabelIndex } from 'services/offline/requests/material/getDaughterCategoriesFromImageRecognitionLabelIndex';
import { getImageRecognitionLabelIndex } from 'services/offline/requests/material/getImageRecognitionLabelIndex';
import { getMotherCategoriesFromImageRecognitionLabelIndex } from 'services/offline/requests/material/getMotherCategoriesFromImageRecognitionLabelIndex';

export const handleSelectedPrediction = async (
  prediction: string,
  setSelectedMotherCategories: (
    value: React.SetStateAction<
      {
        id: string;
        name: string;
        motherId: string | null;
        imageRecognitionLabelId: string | null;
      }[]
    >,
  ) => void,
  setSelectedDaughterCategories: (
    value: React.SetStateAction<
      {
        id: string;
        name: string;
        motherId: string | null;
        imageRecognitionLabelId: string | null;
      }[]
    >,
  ) => void,
): Promise<void> => {
  try {
    const labelId = await getImageRecognitionLabelIndex(prediction);
    const [motherCategories, daughterCategories] = await Promise.all([
      getMotherCategoriesFromImageRecognitionLabelIndex(labelId),
      getDaughterCategoriesFromImageRecognitionLabelIndex(labelId),
    ]);
    setSelectedMotherCategories(motherCategories);
    setSelectedDaughterCategories(daughterCategories);
  } catch (error) {
    console.error('Error in asynchronous operation:', error);
    setSelectedMotherCategories([]);
    setSelectedDaughterCategories([]);
  }
};
