import { listChildrenOfBuildingPart } from './listChildrenOfBuildingPart';
import { deleteBuildingPart } from './deleteBuildingPart';
import { deleteBuildingPartMaterialInputs } from './deleteBuildingPartMaterialInputs';
import { deleteBuildingPartBuildingPartMaps } from './deleteBuildingPartBuildingPartMaps';

export const deleteBuildingPartContent = async (
  projectId: string,
  buildingPartId: string,
): Promise<void> => {
  await deleteBuildingPartMaterialInputs(projectId, buildingPartId);

  await deleteBuildingPartBuildingPartMaps(buildingPartId);

  const childrenBuildingPart = await listChildrenOfBuildingPart(buildingPartId);

  await Promise.all(
    childrenBuildingPart.map(childBuildingPart =>
      deleteBuildingPart(projectId, childBuildingPart.id),
    ),
  );
};
