import { MaterialInputForRecap } from 'services/offline/materialInputsForRecap';
import { Fragment } from 'react/jsx-runtime';
import { InputModuleSummaryMoveDialog } from './InputModuleSummaryMoveDialog';
import { InputModuleSummaryDuplicateAndMoveDialog } from './InputModuleSummaryDuplicateAndMoveDialog';
import { InputModuleSummaryDuplicateDialog } from './InputModuleSummaryDuplicateDialog';
import { InputModuleSummaryDeleteDialog } from './InputModuleSummaryDeleteDialog';

interface Props {
  projectId: string;
  buildingId?: string;
  buildingPartId?: string;
  selectedMaterialInputs: Array<MaterialInputForRecap>;
  setSelectedMaterialInputs: (
    newSelectedMaterialInputs: Array<MaterialInputForRecap>,
  ) => void;
  materialInputs: Array<MaterialInputForRecap>;
  setMaterialInputs: (newMaterialInputs: Array<MaterialInputForRecap>) => void;
  showDuplicate: boolean;
  setShowDuplicate: (newShowDuplicate: boolean) => void;
  showMove: boolean;
  setShowMove: (newShowMove: boolean) => void;
  showDuplicateAndMove: boolean;
  setShowDuplicateAndMove: (newShowDuplicateAndMove: boolean) => void;
  showDelete: boolean;
  setShowDelete: (newShowDelete: boolean) => void;
}

export const InputModuleSummaryActions = (props: Props): JSX.Element => {
  const onCloseDuplicate = () => props.setShowDuplicate(false);
  const onCloseMove = () => props.setShowMove(false);
  const onCloseDuplicateAndMove = () => props.setShowDuplicateAndMove(false);
  const onCloseDelete = () => props.setShowDelete(false);

  return (
    <Fragment>
      <InputModuleSummaryDuplicateDialog
        {...props}
        open={props.showDuplicate}
        onClose={onCloseDuplicate}
      />
      {props.showMove && (
        <InputModuleSummaryMoveDialog {...props} onClose={onCloseMove} />
      )}
      {props.showDuplicateAndMove && (
        <InputModuleSummaryDuplicateAndMoveDialog
          {...props}
          onClose={onCloseDuplicateAndMove}
        />
      )}
      <InputModuleSummaryDeleteDialog
        {...props}
        open={props.showDelete}
        onClose={onCloseDelete}
      />
    </Fragment>
  );
};
