import { ImageTypes } from '@afleya/common';
import { latLng } from 'leaflet';
import { LocalMaterialInputIcon } from 'services/materialInputIcons/type';
import { LocalMaterialInput } from 'services/materialInputs/types';
import { downloadImage } from 'services/networking/requests/downloadImage';
import { listBuildingPartMapIds } from 'services/offline/requests/buildingAndBuildingPart/listBuildingPartMapIds';
import { getMotherMaterialCategoryId } from 'services/offline/requests/material/getMotherMaterialCategoryId';
import { listMaterialInputIcons } from 'services/offline/requests/materialInput/listMaterialInputIcons';
import { listMotherBuildingPartMaterialInputs } from 'services/offline/requests/materialInput/listMotherBuildingPartMaterialInputs';
import { IMaterial, IMaterialOnMap } from './interfaces';
import {
  listBuildingPartMaterialInputsFromOnline,
  listMaterialInputIconsFromOnline,
} from './listsFromOnline';

export const getBuildingPartMapFromOnline = async (
  projectId: string,
  buildingPartId: string,
  buildingPartMapId: string,
): Promise<string> => {
  const base64 = await downloadImage(
    projectId,
    ImageTypes.buildingPartMap,
    buildingPartId,
    buildingPartMapId,
  );

  return base64;
};

const handleIcons = async (
  materialInputIcons: LocalMaterialInputIcon[],
  buildingPartMaterialInputs: LocalMaterialInput[],
  buildingPartMapId: string,
): Promise<IMaterialOnMap[]> => {
  const materialsOnMap: IMaterialOnMap[] = [];
  await Promise.all(
    materialInputIcons.map(async icon => {
      if (icon.buildingPartMapId === buildingPartMapId) {
        const tempMaterialOnMap = {
          iconId: icon.id,
          coordinates: latLng(icon.lat, icon.lng),
          buildingPartMapId: icon.buildingPartMapId,
          materialInputId: icon.materialInputId,
          amount: icon.amount,
        };
        const correspondingMaterialInput = buildingPartMaterialInputs.find(
          materialInput =>
            materialInput.id === tempMaterialOnMap.materialInputId,
        );
        if (correspondingMaterialInput === undefined) {
          throw new Error(
            `No material input with id ${icon.materialInputId}, but icon ${icon.id} has it`,
          );
        } else {
          const {
            materialCategoryId,
            nickname,
            dimensions,
            locationDescription,
            inputMethod,
            inputUnit,
          } = correspondingMaterialInput;

          materialsOnMap.push({
            ...tempMaterialOnMap,
            materialCategoryId:
              await getMotherMaterialCategoryId(materialCategoryId),
            nickname,
            dimensions: dimensions,
            locationDescription,
            inputMethod,
            inputUnit,
          });
        }
      }
    }),
  );

  return materialsOnMap.sort((a, b) => a.nickname.localeCompare(b.nickname));
};

const handleMaterialInputs = async (
  materialInputIcons: LocalMaterialInputIcon[],
  buildingPartMaterialInputs: LocalMaterialInput[],
): Promise<IMaterial[]> => {
  const materialsOffMap: IMaterial[] = [];
  await Promise.all(
    buildingPartMaterialInputs.map(async materialInput => {
      const tempMaterialOffMap = {
        materialInputId: materialInput.id,
        materialCategoryId: await getMotherMaterialCategoryId(
          materialInput.materialCategoryId,
        ),
        nickname: materialInput.nickname,
        amount: materialInput.amount,
        dimensions: materialInput.dimensions,
        locationDescription: materialInput.locationDescription,
        inputMethod: materialInput.inputMethod,
        inputUnit: materialInput.inputUnit,
      };

      const totalAmountOnMaps = materialInputIcons.reduce((acc, icon) => {
        if (materialInput.id === icon.materialInputId) {
          acc = acc + icon.amount;
        }

        return acc;
      }, 0);
      const amountLeft = materialInput.amount - totalAmountOnMaps;

      if (amountLeft > 0) {
        tempMaterialOffMap.amount = amountLeft;
        materialsOffMap.push(tempMaterialOffMap);
      } else if (amountLeft < 0) {
        console.error(
          `MaterialInput ${materialInput.id} has more occurences on map: ${totalAmountOnMaps}, than in total: ${materialInput.amount}`,
        );
      }
    }),
  );

  return materialsOffMap.sort((a, b) => a.nickname.localeCompare(b.nickname));
};

export const getMapMaterialLists = async (
  projectId: string,
  buildingPartId: string,
  buildingPartMapId: string,
  isEdit: boolean,
): Promise<{
  materialsOnMap: IMaterialOnMap[];
  materialsOffMap: IMaterial[];
}> => {
  let buildingPartMaterialInputs: LocalMaterialInput[];
  let materialInputIcons: LocalMaterialInputIcon[];
  if (isEdit) {
    buildingPartMaterialInputs =
      await listMotherBuildingPartMaterialInputs(buildingPartId);
    materialInputIcons = (
      await Promise.all(
        (await listBuildingPartMapIds(buildingPartId)).map(id =>
          listMaterialInputIcons(id),
        ),
      )
    ).flat();
  } else {
    buildingPartMaterialInputs = await listBuildingPartMaterialInputsFromOnline(
      projectId,
      buildingPartId,
    );
    materialInputIcons = await listMaterialInputIconsFromOnline(
      projectId,
      buildingPartId,
    );
  }

  const materialsOnMap = await handleIcons(
    materialInputIcons,
    buildingPartMaterialInputs,
    buildingPartMapId,
  );

  const materialsOffMap = await handleMaterialInputs(
    materialInputIcons,
    buildingPartMaterialInputs,
  );

  return { materialsOnMap, materialsOffMap };
};
