import { WasteCategory } from '@afleya/common';
import { Chip } from '@mui/material';
import { getWasteCategoryColor } from 'services/getWasteCategoryColor';

interface Props {
  wasteCategory: string | WasteCategory;
}

export const ChipWasteCategory = ({ wasteCategory }: Props): JSX.Element => (
  <Chip
    sx={{
      marginLeft: 1,
      borderColor: getWasteCategoryColor(wasteCategory),
    }}
    variant="outlined"
    label={wasteCategory}
  />
);
