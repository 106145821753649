import { List } from '@mui/material';
import { useEffect, useState } from 'react';
import { MaterialCategoryEntity } from '@afleya/material-schemas';
import { useAsyncFn } from 'react-use';
import { NicknameItem } from 'services/MaterialOrNicknameItems/type';
import LoadingBox from 'components/LoadingBox';
import BoxAlert from 'components/BoxAlert';
import { useIntl } from 'react-intl';
import { getMaterialInputItems } from 'services/MaterialOrNicknameItems/getMaterialInputItems';
import { store } from 'store';
import { setReload } from 'services/store/reloadState';
import { PageContent } from 'components/PageContent';
import { SeeMoreMaterialsButton } from './SeeMoreMaterialsButton';
import { ListMaterialInputItem } from './ListMaterialInputItem';

interface ListNicknamesProps {
  selectedMotherCategories: MaterialCategoryEntity[];
  selectedDaughterCategories: MaterialCategoryEntity[];
  categories: MaterialCategoryEntity[];
  projectId: string;
  debouncedSearchTerms: string;
  handleNavigate: (
    materialId: string,
    categoryId: string,
    materialNickname: string,
  ) => void;
  nbNicknamesToFetch: number;
}

export const ListNicknames = ({
  selectedMotherCategories,
  selectedDaughterCategories,
  categories,
  projectId,
  debouncedSearchTerms,
  handleNavigate,
  nbNicknamesToFetch,
}: ListNicknamesProps): JSX.Element => {
  const intl = useIntl();
  const [skip, setSkip] = useState<number>(0);
  const [canSeeMore, setCanSeeMore] = useState<boolean>(false);
  const [nicknameItems, setNicknameItems] = useState<NicknameItem[]>([]);
  const [reloadState, setLocalReloadState] = useState(
    store.getState().reloadState,
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setLocalReloadState(store.getState().reloadState);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const [{ loading, error }, getNicknames] = useAsyncFn(
    async (
      skipNickname: number,
      filterNickname: string,
    ): Promise<NicknameItem[]> => {
      const materialInputs = await getMaterialInputItems({
        skip: skipNickname,
        take: nbNicknamesToFetch,
        filter: filterNickname,
        projectId: projectId,
        selectedMotherCategories: selectedMotherCategories,
        selectedDaughterCategories: selectedDaughterCategories,
        categories: categories,
      });

      const nicknamesChunks = materialInputs.materialsInputConverted;

      setCanSeeMore(materialInputs.newTake === nbNicknamesToFetch);

      return nicknamesChunks;
    },
    [
      nbNicknamesToFetch,
      categories,
      projectId,
      selectedDaughterCategories,
      selectedMotherCategories,
    ],
  );

  const [{ loading: loadingSeeMore }, fetchMoreNicknames] =
    useAsyncFn(async () => {
      const nicknamesChunks = await getNicknames(
        skip + nbNicknamesToFetch,
        debouncedSearchTerms,
      );
      setNicknameItems(nicknameItems.concat(nicknamesChunks));
      setSkip(skip + nbNicknamesToFetch);
    }, [
      getNicknames,
      skip,
      nbNicknamesToFetch,
      debouncedSearchTerms,
      nicknameItems,
    ]);

  useEffect(() => {
    const fetchData = async () => {
      const nicknamesChunks = await getNicknames(0, debouncedSearchTerms);
      setNicknameItems(nicknamesChunks);
      setSkip(0);
      store.dispatch(setReload(false));
    };

    if (reloadState.reload === true) store.dispatch(setReload(false));
    else void fetchData();
  }, [debouncedSearchTerms, getNicknames, reloadState.reload]);

  return (
    <>
      <PageContent overflow="auto">
        {loading && nicknameItems.length === 0 ? (
          <LoadingBox />
        ) : error ? (
          <BoxAlert contentId={'input-module-materials-page.loadingError'} />
        ) : (
          <div>
            {nicknameItems.length > 0 && (
              <List data-test="nicknames">
                <h2
                  style={{
                    marginBlockStart: '0.40em',
                    marginBlockEnd: '0.20em',
                  }}
                >
                  {intl.formatMessage({
                    id: 'input-module-categories-page.nickname',
                  })}
                </h2>
                {nicknameItems.map(nicknameItem => (
                  <ListMaterialInputItem
                    key={`material-${nicknameItem.name}`}
                    materialInput={nicknameItem}
                    handleNavigate={handleNavigate}
                  />
                ))}
                {canSeeMore && (
                  <SeeMoreMaterialsButton
                    loading={loadingSeeMore}
                    fetchMore={fetchMoreNicknames}
                  />
                )}
              </List>
            )}
          </div>
        )}
      </PageContent>
    </>
  );
};
