import { Checkbox, FormControlLabel } from '@mui/material';
import {
  Control,
  Controller,
  UseFormGetValues,
  UseFormSetValue,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { MaterialInputFormEntity } from 'services/materialInputs';

const DD_SUFFIX = ' (DD)';
const DD_SUFFIX_LENGTH = DD_SUFFIX.length;

interface Props {
  control: Control<MaterialInputFormEntity>;
  fieldName: 'hasAsbestos' | 'hasLead' | 'hasOtherPollution';
  getValues: UseFormGetValues<MaterialInputFormEntity>;
  setValue: UseFormSetValue<MaterialInputFormEntity>;
  setDdChecked: (b: boolean) => void;
}

export const PollutionCheckbox = ({
  control,
  fieldName,
  getValues,
  setValue,
  setDdChecked,
}: Props): JSX.Element => {
  const intl = useIntl();

  const nicknameHasDD = (): boolean => {
    const nickname = getValues('nickname');

    return (
      nickname.length >= DD_SUFFIX_LENGTH &&
      nickname.substring(nickname.length - DD_SUFFIX_LENGTH) === DD_SUFFIX
    );
  };

  const handleOnChange = (checked: boolean) => {
    const nickname = getValues('nickname');
    if (checked) {
      setDdChecked(true);
      if (!nicknameHasDD()) {
        setValue('nickname', `${nickname}${DD_SUFFIX}`);
      }
    } else {
      const pollutions = [
        getValues('hasAsbestos'),
        getValues('hasLead'),
        getValues('hasOtherPollution'),
      ];

      if (!pollutions.includes(true)) {
        setDdChecked(false);
        if (nicknameHasDD()) {
          setValue(
            'nickname',
            `${nickname.substring(0, nickname.length - DD_SUFFIX_LENGTH)}`,
          );
        }
      }
    }
  };

  return (
    <FormControlLabel
      color="secondary"
      label={intl.formatMessage({
        id: `material-input-form.label.${fieldName}`,
      })}
      control={
        <Controller
          name={fieldName}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Checkbox
              checked={value ?? false}
              onChange={event => {
                const checked = event.currentTarget.checked;
                onChange(checked);
                handleOnChange(checked);
              }}
            />
          )}
        />
      }
    />
  );
};
