import { BuildingPartEntity } from '@afleya/project-schemas';
import { LocationOn } from '@mui/icons-material';
import { Badge, Drawer, IconButton, Typography } from '@mui/material';
import { BuildingPartMaps } from 'components/BuildingPartMaps/BuildingPartMaps';
import { listBuildingPartMapIds } from 'services/offline/requests/buildingAndBuildingPart/listBuildingPartMapIds';
import { PageContent } from 'components/PageContent';
import { Fragment, useState } from 'react';
import { useIntl } from 'react-intl';
import { useAsync } from 'react-use';

interface Props {
  buildingPart: BuildingPartEntity;
  projectId: string;
}

export const MapModuleMenu = ({
  buildingPart,
  projectId,
}: Props): JSX.Element => {
  const [mapDrawerOpen, setMapDrawerOpen] = useState(false);

  const intl = useIntl();
  const [mapNumber, setMapNumber] = useState<number>(0);
  const [buildingPartMapIds, setBuildingPartMapIds] = useState<string[]>([]);
  useAsync(async () => {
    const localBuildingPartMapIds: string[] = await listBuildingPartMapIds(
      buildingPart.id ?? '',
    );
    setBuildingPartMapIds(localBuildingPartMapIds);
    setMapNumber(buildingPartMapIds.length);
  }, [buildingPart.id, buildingPartMapIds.length]);

  return (
    <Fragment>
      <IconButton
        onClick={() => {
          setMapDrawerOpen(true);
        }}
        edge="end"
        sx={{ marginRight: '2px' }}
      >
        <Badge color="secondary" badgeContent={mapNumber}>
          <LocationOn />
        </Badge>
      </IconButton>
      <Drawer
        anchor="bottom"
        open={mapDrawerOpen}
        onClose={() => {
          setMapDrawerOpen(false);
        }}
      >
        <Typography variant="h1">
          {intl.formatMessage(
            {
              id: 'map-module.drawerTitle',
            },
            {
              buildingPartName: buildingPart.buildingPartName,
            },
          )}
        </Typography>
        <PageContent>
          <BuildingPartMaps
            buildingPartId={buildingPart.id}
            projectId={projectId}
            buildingPartMapIds={buildingPartMapIds}
            setBuildingPartMapIds={setBuildingPartMapIds}
          />
        </PageContent>
      </Drawer>
    </Fragment>
  );
};
