import { Card, Grid2 } from '@mui/material';
import { styled } from '@mui/material/styles';

export const GridImages = styled(Grid2)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  marginTop: theme.spacing(3),
}));

export const CardImage = styled(Card)(({ theme }) => ({
  width: '48%',
  height: '20vh',
  padding: theme.spacing(1),
}));

export const CardNewImage = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '48%',
  textAlign: 'center',
  padding: theme.spacing(1),
}));
