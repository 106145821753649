import { WasteCategory } from '@afleya/common';
import { CreateMaterialRequestEntity } from '@afleya/material-schemas';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';

interface Props {
  control: Control<CreateMaterialRequestEntity>;
}

export const WasteCategoryField = ({ control }: Props): JSX.Element => {
  const intl = useIntl();

  return (
    <Box sx={{ marginTop: '8px', marginBottom: '8px' }}>
      <FormControl component="fieldset">
        <FormLabel component="legend">
          {intl.formatMessage({
            id: 'select-material-waste-category.title',
          })}
        </FormLabel>
        <Controller
          control={control}
          name="defaultWasteCategory"
          render={({ field: { onChange, value } }) => (
            <RadioGroup
              value={value}
              row
              aria-label="defaultWasteCategory"
              name="defaultWasteCategory"
              onChange={onChange}
            >
              <FormControlLabel
                data-test="wasteCategoryDD"
                value={WasteCategory.DD}
                control={<Radio />}
                label={intl.formatMessage({
                  id: 'select-material-waste-category.DD',
                })}
              />
              <FormControlLabel
                data-test="wasteCategoryDEA"
                value={WasteCategory.DEA}
                control={<Radio />}
                label={intl.formatMessage({
                  id: 'select-material-waste-category.DEA',
                })}
              />
              <FormControlLabel
                data-test="wasteCategoryDEEE"
                value={WasteCategory.DEEE}
                control={<Radio />}
                label={intl.formatMessage({
                  id: 'select-material-waste-category.DEEE',
                })}
              />
              <FormControlLabel
                data-test="wasteCategoryDI"
                value={WasteCategory.DI}
                control={<Radio />}
                label={intl.formatMessage({
                  id: 'select-material-waste-category.DI',
                })}
              />
              <FormControlLabel
                data-test="wasteCategoryDNIND"
                value={WasteCategory.DNIND}
                control={<Radio />}
                label={intl.formatMessage({
                  id: 'select-material-waste-category.DNIND',
                })}
              />
            </RadioGroup>
          )}
        />
      </FormControl>
    </Box>
  );
};
