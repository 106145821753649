import { Button, ToggleButtonGroup } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ToggleButtonGroupContainer = styled(ToggleButtonGroup)(() => ({
  flexWrap: 'wrap',
  justifyContent: 'center',
}));

export const DivSelectorButtonContainer = styled('div')(() => ({
  flexWrap: 'wrap',
  justifyContent: 'center',
}));

export const ButtonSelector = styled(Button)(({ theme }) => ({
  margin: theme.spacing(0.5),
  padding: theme.spacing(1),
  minWidth: theme.spacing(7),
}));
