/* eslint-disable complexity */
import { useIntl } from 'react-intl';
import { routes } from 'routes';
import PageHeader from 'components/PageHeader';
import { useNavigate, useParams } from 'react-router-dom';
import BoxAlert from 'components/BoxAlert';
import { useEffect, useState } from 'react';
import { beforeUnload } from 'services/beforeUnload/beforeUnload';
import CreateMaterialSuccessDrawer from './components/CreateMaterialSuccessDrawer';
import { CreateMaterialForm } from './components';

export const InputModuleCreateMaterial = (): JSX.Element => {
  const { projectId, buildingId, buildingPartId } = useParams<{
    projectId: string;
    buildingId: string;
    buildingPartId: string;
  }>();
  const intl = useIntl();
  const navigate = useNavigate();

  useEffect(beforeUnload, []);

  const [materialId, setMaterialId] = useState<string>();
  const [categoryId, setCategoryId] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();

  if (
    projectId === undefined ||
    buildingId === undefined ||
    buildingPartId === undefined
  ) {
    navigate(-1);

    return <></>;
  }

  return (
    <>
      <PageHeader
        title={intl.formatMessage({ id: 'input-module-create-material.title' })}
        backLinkTitle={intl.formatMessage({
          id: 'input-module-categories-page.title',
        })}
        backLinkPath={routes.INPUT_MODULE_CATEGORIES.replace(
          ':projectId',
          projectId,
        )
          .replace(':buildingId', buildingId)
          .replace(':buildingPartId', buildingPartId)}
      />

      <div>
        {errorMessage !== undefined && (
          <BoxAlert
            title={'input-module-create-material.errorTitle'}
            contentId={errorMessage}
          />
        )}
      </div>
      <CreateMaterialForm
        setCategoryId={setCategoryId}
        setErrorMessage={setErrorMessage}
        setMaterialId={setMaterialId}
      />
      {materialId !== undefined && categoryId !== undefined && (
        <CreateMaterialSuccessDrawer
          materialId={materialId}
          categoryId={categoryId}
          projectId={projectId}
          buildingId={buildingId}
          buildingPartId={buildingPartId}
        />
      )}
    </>
  );
};
