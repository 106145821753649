export const scaleImage = async (
  dataUrl: string,
  dimensions: { width: number; height: number },
): Promise<string> => {
  const image = new Image();
  image.src = dataUrl;

  await new Promise<Event>(res => (image.onload = res));
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d', { alpha: true });

  const scaleFactor =
    image.height > 0 && image.width > 0
      ? Math.min(
          1,
          (dimensions.width * dimensions.height) / (image.height * image.width),
        )
      : 1;
  canvas.width = image.width * scaleFactor;
  canvas.height = image.height * scaleFactor;
  if (scaleFactor < 1) {
    context?.scale(scaleFactor, scaleFactor);
  }
  context?.drawImage(image, 0, 0);

  return new Promise(resolve => resolve(canvas.toDataURL('image/jpeg')));
};
