import { DivFooter } from './PageFooter.style';

interface Props {
  children: JSX.Element | JSX.Element[];
}

const PageFooter = ({ children }: Props): JSX.Element => {
  return <DivFooter>{children}</DivFooter>;
};

export default PageFooter;
