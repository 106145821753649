import { LocalMaterialInputIcon } from 'services/materialInputIcons/type';
import { listBuildingPartMapIds } from '../buildingAndBuildingPart/listBuildingPartMapIds';
import { listMaterialInputIcons } from './listMaterialInputIcons';
import { listProjectBuildingParts } from '../buildingAndBuildingPart/listProjectBuildingParts';

export const listProjectMaterialInputIcons = async (
  projectId: string,
): Promise<LocalMaterialInputIcon[]> => {
  const buildingParts = await listProjectBuildingParts(projectId);
  const buildingPartMapIds = (
    await Promise.all(
      buildingParts.map(buildingPart =>
        listBuildingPartMapIds(buildingPart.id),
      ),
    )
  ).flat();

  return (
    await Promise.all(buildingPartMapIds.map(listMaterialInputIcons))
  ).flat();
};
