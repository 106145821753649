/* eslint-disable complexity */
import { useIntl } from 'react-intl';
import { routes } from 'routes';
import PageHeader from 'components/PageHeader';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { beforeUnload } from 'services/beforeUnload/beforeUnload';
import { CreateBuildingPartForm } from './components/CreateBuildingPartForm/CreateBuildingPartForm';
import { CreateBuildingPartSuccessDrawer } from './components';

export const InputModuleCreateBuildingPart = (): JSX.Element => {
  const { projectId, buildingId } = useParams<{
    projectId: string;
    buildingId: string;
  }>();
  const intl = useIntl();
  const navigate = useNavigate();

  const [buildingPartId, setBuildingPartId] = useState<string>();

  useEffect(beforeUnload, []);
  if (projectId === undefined || buildingId === undefined) {
    navigate(-1);

    return <></>;
  }

  return (
    <>
      <PageHeader
        title={intl.formatMessage({
          id: 'input-module-create-building-part.title',
        })}
        backLinkTitle={intl.formatMessage({
          id: 'input-module-building-parts-page.title',
        })}
        backLinkPath={routes.INPUT_MODULE_BUILDING_PARTS.replace(
          ':projectId',
          projectId,
        ).replace(':buildingId', buildingId)}
      />

      <CreateBuildingPartForm
        setBuildingPartId={setBuildingPartId}
        buildingId={buildingId}
      />
      {buildingPartId !== undefined && (
        <CreateBuildingPartSuccessDrawer
          projectId={projectId}
          buildingId={buildingId}
          buildingPartId={buildingPartId}
        />
      )}
    </>
  );
};
