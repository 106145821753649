import { AgeTypes, MaterialEvaluationMethod } from '@afleya/common';
import { MaterialEntity } from '@afleya/material-schemas';
import { store } from 'store';
import { getMaterialInput } from 'services/offline/requests/materialInput/getMaterialInput';
import { MaterialInputFormEntity } from './MaterialInputFormEntity';

export const getInputMaterialInputFormDefaultValues = async (
  material?: MaterialEntity,
): Promise<MaterialInputFormEntity> => {
  const materialInputId = store.getState().materialInputIdState.materialInputId;
  let materialInput = undefined;
  if (materialInputId !== undefined) {
    materialInput = await getMaterialInput(materialInputId);
  }

  return {
    nickname: (materialInput ? materialInput.nickname : material?.name) ?? '',
    childBuildingPartId: null,
    amount: '1',
    materialToDeduce: false,
    hasAsbestos: materialInput?.hasAsbestos ?? false,
    hasLead: materialInput?.hasLead ?? false,
    hasOtherPollution: materialInput?.hasOtherPollution ?? false,
    age: AgeTypes.betweenTenAndFifty,
    stateNewPercentage: 0,
    stateGoodPercentage: 100,
    stateMediumPercentage: 0,
    stateObsoletePercentage: 0,
    reusePercentage: 0,
    evaluationMethod:
      material?.evaluationMethod ?? MaterialEvaluationMethod.UNITS,
    weightPerFunctionalUnit: getWeightPerFunctionalUnit(
      materialInput?.weightPerFunctionalUnit,
      material?.generic,
    ),
  };
};

const getWeightPerFunctionalUnit = (
  materialInputWeightPerFunctionalUnit: number | undefined,
  materialGeneric: boolean | undefined,
): number | undefined => {
  if (materialInputWeightPerFunctionalUnit !== undefined) {
    return materialInputWeightPerFunctionalUnit;
  }
  if (materialGeneric === true) {
    return 0;
  }

  return undefined;
};
