import { TextField } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { MaterialInputFormEntity } from 'services/materialInputs';

interface Props {
  control: Control<MaterialInputFormEntity>;
}

export const WeightPerFunctionalUnit = ({ control }: Props): JSX.Element => {
  const intl = useIntl();

  return (
    <Controller
      name="weightPerFunctionalUnit"
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
          data-test="weightPerFunctionalUnit"
          variant="outlined"
          color="secondary"
          margin="normal"
          required={true}
          fullWidth
          label={intl.formatMessage({
            id: 'material-input-form.label.weightPerFunctionalUnit',
          })}
          placeholder={intl.formatMessage({
            id: 'material-input-form.placeholder.weightPerFunctionalUnit',
          })}
          value={value}
          error={error !== undefined}
          helperText={
            error !== undefined
              ? intl.formatMessage({
                  id: 'material-input-form.error.weightPerFunctionalUnit',
                })
              : ''
          }
          onChange={onChange}
          slotProps={{
            inputLabel: {
              sx: {
                fontWeight: 'bold',
              },
            },
          }}
        />
      )}
    />
  );
};
