import { CharacteristicsEntity } from '@afleya/material-schemas';
import { materialsDatabase } from '../../dexieDB';

export const listCharacteristics = async (
  categoryId: string,
): Promise<CharacteristicsEntity[]> => {
  const characteristics = await materialsDatabase.characteristics
    .where({
      categoryId,
    })
    .toArray();

  return characteristics;
};
