import { CircularProgress } from '@mui/material';
import { FooterButton } from 'components/Layout';
import { FormattedMessage } from 'react-intl';

interface Props {
  loading: boolean;
  fetchMore: () => Promise<void>;
}

export const SeeMoreMaterialsButton = ({
  loading,
  fetchMore,
}: Props): JSX.Element => {
  return (
    <FooterButton
      key="SeeMore"
      variant="contained"
      disabled={loading}
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onClick={fetchMore}
    >
      {loading ? (
        <CircularProgress size={20} />
      ) : (
        <FormattedMessage id="input-module-categories-page.seeMore" />
      )}
    </FooterButton>
  );
};
