import { WasteCategory } from '@afleya/common';

export enum TypeEnum {
  Material = 'material',
  Nickname = 'nickname',
}

export type MaterialItem = {
  type: TypeEnum.Material;
  name: string;
  tags: string;
  materialId: string;
  categoryId: string;
  defaultWasteCategory: string;
  organizationId: string | null;
  isValidated: boolean;
  generic: boolean;
};

export type NicknameItem = {
  type: TypeEnum.Nickname;
  name: string;
  materialInputId: string;
  materials: {
    materialId: string;
    categoryId: string;
    defaultWasteCategory: WasteCategory;
  }[];
};

export type MaterialOrNicknameItem = MaterialItem | NicknameItem;
