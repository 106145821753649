import { useAsync } from 'react-use';
import { Box, CircularProgress, IconButton } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { Delete, Photo } from '@mui/icons-material';
import { LocalMaterialInputPicture } from 'services/materialInputsPictures/type';
import { LocalBuildingPartMap } from 'services/buildingPartMaps/type';
import { Fragment, useState } from 'react';
import { ConfirmDialog } from 'components/ConfirmDialog';
import { editLocalMaterialInputPicture } from 'services/offline/requests/materialInput/editLocalMaterialInputPicture';
import { editLocalBuildingPartMap } from 'services/offline/requests/buildingAndBuildingPart/editLocalBuildingPartMap';
import { downloadImage } from 'services/networking/requests/downloadImage';
import { ImageTypes } from '@afleya/common';

interface Props {
  projectId: string;
  imageType: ImageTypes;
  hostId: string;
  imageId: string;
  errorLoadingId: string;
  dialogContentIds: [string, string];
  getImage: (
    imageId: string,
  ) => Promise<LocalBuildingPartMap | LocalMaterialInputPicture>;
  deleteImage?: (imageId: string) => Promise<void>;
  removeImageIdFromState?: (imageId: string) => void;
  onClick?: () => void;
}

export const Image = ({
  projectId,
  imageType,
  hostId,
  imageId,
  errorLoadingId,
  dialogContentIds,
  getImage,
  deleteImage,
  removeImageIdFromState,
  onClick,
}: Props): JSX.Element => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const intl = useIntl();
  const {
    value: src,
    loading,
    error,
  } = useAsync(async () => {
    const img = await getImage(imageId);
    if (img.base64.length === 0 && img.uploaded === true) {
      const newBase64 = await downloadImage(
        projectId,
        imageType,
        hostId,
        imageId,
      );
      if (Object.keys(img).includes('buildingPartId')) {
        await editLocalBuildingPartMap(img.id, { base64: newBase64 });
      } else if (Object.keys(img).includes('materialInputId')) {
        await editLocalMaterialInputPicture(img.id, { base64: newBase64 });
      }

      return newBase64;
    }

    return img.base64;
  }, [projectId, imageType, hostId, imageId, getImage]);
  const onDelete = async () => {
    if (deleteImage && removeImageIdFromState) {
      await deleteImage(imageId);
      removeImageIdFromState(imageId);
    }
  };

  if (src !== undefined && src.length > 0) {
    return (
      <Fragment>
        {deleteImage && removeImageIdFromState && (
          <IconButton
            onClick={() => {
              setShowDialog(true);
            }}
            sx={{ position: 'absolute' }}
          >
            <Delete />
          </IconButton>
        )}
        {showDialog && (
          <ConfirmDialog
            title={intl.formatMessage({ id: dialogContentIds[0] })}
            contentText={intl.formatMessage({ id: dialogContentIds[1] })}
            onAgree={() => {
              setShowDialog(false);
              void onDelete();
            }}
            onDisagree={() => {
              setShowDialog(false);
            }}
          />
        )}
        <img
          onClick={onClick}
          src={src}
          width="100%"
          height="100%"
          style={{ objectFit: 'contain' }}
        />
      </Fragment>
    );
  } else {
    return (
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        gap="10px"
        textAlign="center"
      >
        <Photo fontSize="large" />
        {loading && <CircularProgress size={20} />}
        {error && <FormattedMessage id={errorLoadingId} />}
      </Box>
    );
  }
};
