import { Sync } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

export const DivHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  minHeight: theme.spacing(6),
  marginBottom: theme.spacing(2),
  gap: theme.spacing(1),
}));

export const BLocationTitle = styled('b')(() => ({
  textAlign: 'right',
}));

export const AnimatedSync = styled(Sync)(() => ({
  animation: 'spin 1s infinite',
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
}));
