import {
  InputMethod,
  InputUnit,
  MaterialEvaluationMethod,
} from '@afleya/common';
import { Grid2 } from '@mui/material';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { MaterialInputFormEntity } from 'services/materialInputs';
import { InputMethodSelector } from './InputMethodSelector';
import { InputUnitSelector } from './InputUnitSelector';

interface Props {
  getValues: UseFormGetValues<MaterialInputFormEntity>;
  setValue: UseFormSetValue<MaterialInputFormEntity>;
  inputMethod: InputMethod;
  setInputMethod: (newInputMethod: InputMethod) => void;
  inputUnit: InputUnit;
  setInputUnit: (newInputUnit: InputUnit) => void;
}

export const InputSelectors = ({
  getValues,
  setValue,
  inputMethod,
  setInputMethod,
  inputUnit,
  setInputUnit,
}: Props): JSX.Element => {
  const evaluationMethod = getValues('evaluationMethod');

  return evaluationMethod === MaterialEvaluationMethod.UNITS ? (
    <></>
  ) : (
    <Grid2 container direction="row" justifyContent="flex-end" spacing={0.5}>
      {evaluationMethod !== MaterialEvaluationMethod.LINEAR && (
        <Grid2>
          <InputMethodSelector
            evaluationMethod={evaluationMethod}
            inputMethod={inputMethod}
            setInputMethod={setInputMethod}
            getValues={getValues}
            setValue={setValue}
          />
        </Grid2>
      )}
      <Grid2>
        <InputUnitSelector inputUnit={inputUnit} setInputUnit={setInputUnit} />
      </Grid2>
    </Grid2>
  );
};
