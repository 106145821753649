import { Button } from '@mui/material';
import { mapModuleNavConstant } from 'components/BuildingPartMaps';
import { IntlShape } from 'react-intl';
import { useNavigate } from 'react-router-dom';

interface Props {
  onlyInputId?: string;
  onClick: () => void;
  intl: IntlShape;
}

const allInputs = (onlyInputId: string | undefined) => {
  return (
    onlyInputId !== undefined &&
    onlyInputId === (mapModuleNavConstant.allInputs as string)
  );
};

export const GoBackButton = ({
  onlyInputId,
  onClick,
  intl,
}: Props): JSX.Element => {
  return (
    <Button variant="contained" color="secondary" onClick={onClick}>
      {intl.formatMessage({
        id: allInputs(onlyInputId)
          ? 'map-module.goBackToBuildingParts'
          : 'input-module-input-page.addAnotherMaterial',
      })}
    </Button>
  );
};

interface GoBackPlanButtonProps {
  onlyInputId?: string;
  intl: IntlShape;
}

export const GoBackPlanButton = ({
  onlyInputId,
  intl,
}: GoBackPlanButtonProps): JSX.Element => {
  const navigate = useNavigate();

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={() => {
        if (onlyInputId !== undefined) {
          if (allInputs(onlyInputId)) {
            navigate(-1);
          } else {
            navigate(-1);
          }
        }
      }}
    >
      {intl.formatMessage({ id: 'map-module.goBackToPlan' })}
    </Button>
  );
};
