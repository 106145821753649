import {
  AssemblyTypeEntity,
  CharacteristicsEntity,
  CharacteristicValuesEntity,
  ChecksumMaterialsEntity,
  ImageRecognitionLabelEntity,
  MaterialCategoryEntity,
  MaterialComponentEntity,
  MaterialEntity,
} from '@afleya/material-schemas';
import Dexie, { Table } from 'dexie';

export class MaterialsDatabase extends Dexie {
  assemblyTypes!: Table<AssemblyTypeEntity>;
  categories!: Table<MaterialCategoryEntity>;
  materials!: Table<MaterialEntity>;
  imageRecognitionLabels!: Table<ImageRecognitionLabelEntity>;
  components!: Table<MaterialComponentEntity>;
  characteristics!: Table<CharacteristicsEntity>;
  characteristicValues!: Table<CharacteristicValuesEntity>;
  checksums!: Table<ChecksumMaterialsEntity>;
  constructor() {
    super('materials');

    this.version(12).stores({
      assemblyTypes: 'id,name',
      categories: 'id,name,imageRecognitionLabelId',
      materials: 'id,name,categoryId,tags,evaluationMethod,relevance',
      components: 'id,name',
      characteristics: '[id+categoryId],categoryId',
      characteristicValues: '[id+categoryId],categoryId',
      checksums: 'id',
      imageRecognitionLabels: 'id,labelIndex,name',
    });
  }
}
