import { LocalMaterialInputIcon } from 'services/materialInputIcons/type';
import { LocalMaterialInput } from 'services/materialInputs/types';
import listProjectMaterialInputs from 'services/networking/requests/project/listProjectMaterialInputs';
import { listProjectMaterialInputIcons } from 'services/networking/requests/project/listProjectMaterialInputIcons';

export const listBuildingPartMaterialInputsFromOnline = async (
  projectId: string,
  buildingPartId: string,
): Promise<LocalMaterialInput[]> => {
  const materialInputs = await listProjectMaterialInputs(projectId, {
    buildingPartId,
    considerChildBuildingParts: true,
  });

  return materialInputs as LocalMaterialInput[];
};

export const listMaterialInputIconsFromOnline = async (
  projectId: string,
  buildingPartId: string,
): Promise<LocalMaterialInputIcon[]> => {
  const materialInputIcons: LocalMaterialInputIcon[] = [];

  let skip = 0;
  let take = 1000;

  while (take > 0) {
    const materialInputIconsChunk = await listProjectMaterialInputIcons({
      projectId,
      skip,
      take,
      buildingPartId,
      considerChildBuildingParts: true,
    });

    materialInputIconsChunk.forEach(materialInputIcon =>
      materialInputIcons.push({ ...materialInputIcon, uploaded: true }),
    );
    if (materialInputIconsChunk.length === take) {
      skip += take;
    } else {
      take = 0;
    }
  }

  return materialInputIcons;
};
