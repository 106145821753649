import { MaterialCategoryEntity } from '@afleya/material-schemas';
import { getNumber } from 'pages/InputModules/InputModuleCategories/sortMotherCategories';
import getCategoryName from 'services/GetMotherCategory/getCategoryName';

export const sortByMotherCategoryNames = (
  selectedCategories: MaterialCategoryEntity[],
  categories: MaterialCategoryEntity[],
): MaterialCategoryEntity[] => {
  return selectedCategories.sort((a, b) => {
    const numberA = getNumber(getCategoryName(categories, a.motherId));
    const numberB = getNumber(getCategoryName(categories, b.motherId));

    return numberA === numberB ? 0 : numberA < numberB ? -1 : 1;
  });
};
