import { CharacteristicValuesEntity } from '@afleya/material-schemas';
import { materialsDatabase } from '../../dexieDB';

export const listCharacteristicValues = async (
  categoryId: string,
): Promise<CharacteristicValuesEntity[]> => {
  const characteristicValues = await materialsDatabase.characteristicValues
    .where({ categoryId })
    .toArray();

  return characteristicValues;
};
