/* eslint-disable complexity */
import { useIntl } from 'react-intl';
import { routes } from 'routes';
import PageHeader from 'components/PageHeader';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { beforeUnload } from 'services/beforeUnload/beforeUnload';
import { listProjectBuildings } from 'services/offline/requests/buildingAndBuildingPart/listProjectBuildings';
import { useAsync } from 'react-use';
import LoadingBox from 'components/LoadingBox';
import BoxAlert from 'components/BoxAlert';
import { getBuildingPart } from 'services/offline/requests/buildingAndBuildingPart/getBuildingPart';
import { Typography } from '@mui/material';
import { listAllLocalMotherBuildingParts } from 'services/offline/requests/buildingAndBuildingPart/listAllLocalMotherBuildingParts';
import { BuildingEntity, BuildingPartEntity } from '@afleya/project-schemas';
import { DuplicateBuildingSelector } from '../InputModuleDuplicateBuildingPart/components';
import {
  DuplicateMotherBuildingPartForm,
  DuplicateMotherBuildingPartSuccessDrawer,
} from './components';

export const InputModuleDuplicateMotherBuildingPart = (): JSX.Element => {
  const { projectId, buildingId, buildingPartId } = useParams<{
    projectId: string;
    buildingId: string;
    buildingPartId: string;
  }>();
  const intl = useIntl();
  const navigate = useNavigate();

  useEffect(beforeUnload, []);

  const [duplicateState, setDuplicateState] = useState<boolean>(false);

  const {
    loading: loadingBuildings,
    error,
    value: buildings,
  } = useAsync(() => listProjectBuildings(projectId ?? ''), [projectId]);

  const { loading: loadingMotherBuildingParts, value: motherBuildingParts } =
    useAsync(() => listAllLocalMotherBuildingParts());

  const { loading: loadingBuildingPart, value: buildingPart } = useAsync(() =>
    getBuildingPart(buildingPartId ?? ''),
  );

  const [targetBuildingId, setTargetBuildingId] = useState<string>(
    buildingId ?? '',
  );

  const [targetBuildingPartsId, setTargetBuildingPartsId] = useState<string[]>(
    [],
  );

  if (
    projectId === undefined ||
    buildingId === undefined ||
    buildingPartId === undefined
  ) {
    navigate(-1);

    return <></>;
  }

  return error ? (
    <BoxAlert contentId="input-module-duplicate-mother-building.loadingError" />
  ) : isLoading({
      loadingBuildingPart,
      loadingMotherBuildingParts,
      loadingBuildings,
      buildingPart,
      buildings,
      motherBuildingParts,
    }) ? (
    <LoadingBox />
  ) : (
    <>
      <PageHeader
        title={intl.formatMessage(
          {
            id: 'input-module-duplicate-mother-building-part.title',
          },
          {
            buildingName: buildings?.find(
              building => building.id === buildingPart?.buildingId,
            )?.buildingName,
            buildingPartName: buildingPart?.buildingPartName,
          },
        )}
        backLinkTitle={intl.formatMessage({
          id: 'input-module-building-parts-page.title',
        })}
        backLinkPath={routes.INPUT_MODULE_BUILDING_PARTS.replace(
          ':projectId',
          projectId,
        ).replace(':buildingId', buildingId)}
      />
      <Typography variant="h2" sx={{ marginTop: 3 }}>
        {intl.formatMessage({
          id: 'input-module-duplicate-mother-building-part.toBuilding',
        })}
      </Typography>
      <DuplicateBuildingSelector
        targetBuildingId={targetBuildingId}
        setTargetBuildingId={setTargetBuildingId}
        buildings={buildings}
        setTargetBuildingPartsId={setTargetBuildingPartsId}
      />
      <DuplicateMotherBuildingPartForm
        projectId={projectId}
        buildingPart={buildingPart}
        motherBuildingParts={motherBuildingParts ?? []}
        targetBuildingId={targetBuildingId}
        setTargetBuildingPartsId={setTargetBuildingPartsId}
        targetBuildingPartsId={targetBuildingPartsId}
        setDuplicateState={setDuplicateState}
      />
      {duplicateState && (
        <DuplicateMotherBuildingPartSuccessDrawer
          projectId={projectId}
          buildingId={buildingId}
          buildingPartId={buildingPartId}
        />
      )}
    </>
  );
};

interface Prop {
  loadingBuildingPart: boolean;
  loadingMotherBuildingParts: boolean;
  loadingBuildings: boolean;
  buildingPart: BuildingPartEntity | undefined;
  buildings: BuildingEntity[] | undefined;
  motherBuildingParts: BuildingPartEntity[] | undefined;
}

const isLoading = ({
  loadingBuildingPart,
  loadingMotherBuildingParts,
  loadingBuildings,
  buildingPart,
  buildings,
  motherBuildingParts,
}: Prop): boolean => {
  return (
    loadingBuildingPart ||
    buildingPart === undefined ||
    loadingBuildings ||
    buildings === undefined ||
    loadingMotherBuildingParts ||
    motherBuildingParts === undefined
  );
};
