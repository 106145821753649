import { LocalMaterialInputForExport } from 'services/materialInputs/types';
import { materialsDatabase, materialsInputsDatabase } from '../../dexieDB';
import { listMaterialInputs } from './listMaterialInputs';

export const getMaterialInputsForExport = async (
  projectId: string,
): Promise<LocalMaterialInputForExport[]> => {
  const materialInputs = await listMaterialInputs({ projectId });

  const categories = await materialsDatabase.categories.toArray();

  const assemblyTypes = await materialsDatabase.assemblyTypes.toArray();

  const materials = await materialsDatabase.materials.toArray();

  const buildingParts = await materialsInputsDatabase.buildingParts.toArray();

  const buildings = await materialsInputsDatabase.buildings.toArray();

  return materialInputs.map(materialInput => {
    const category = categories.find(
      cat => cat.id === materialInput.materialCategoryId,
    );
    const assemblyType = assemblyTypes.find(
      at => at.id === materialInput.assemblyTypeId,
    );
    const material = materials.find(mat => mat.id === materialInput.materialId);
    const buildingPart = buildingParts.find(
      bp => bp.id === materialInput.buildingPartId,
    );
    const building = buildings.find(b => b.id === materialInput.buildingId);

    return {
      ...materialInput,
      category: category?.name ?? '',
      assemblyType: assemblyType?.name ?? '',
      material: material?.name ?? '',
      buildingPart: buildingPart?.buildingPartName ?? '',
      building: building?.buildingName ?? '',
    } as LocalMaterialInputForExport;
  });
};
