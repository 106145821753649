export const beforeUnload = (): (() => void) => {
  const handleBeforeUnload = (event: {
    preventDefault: () => void;
    returnValue: string;
  }) => {
    // Cancel the event as returning a string will prompt the user with a dialog
    event.preventDefault();
    // Modern browsers require this to be set
    event.returnValue = '';
  };

  window.addEventListener('beforeunload', handleBeforeUnload);

  return () => {
    const removeListener = () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
    removeListener();
  };
};
