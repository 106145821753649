/* eslint-disable complexity */
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { InputMethod, InputUnit } from '@afleya/common';

interface Props {
  materialDimensions: {
    [x: string]: unknown;
  };
  inputUnit: InputUnit;
  inputMethod: InputMethod;
}

const MaterialInputDimensionsSummary = ({
  materialDimensions,
  inputUnit,
  inputMethod,
}: Props): JSX.Element => {
  const dimensions: number[] = [];
  if (typeof materialDimensions['length'] === 'number') {
    dimensions.push(materialDimensions['length']);
  }
  if (typeof materialDimensions['width'] === 'number') {
    dimensions.push(materialDimensions['width']);
  }
  if (typeof materialDimensions['height'] === 'number') {
    dimensions.push(materialDimensions['height']);
  }

  let unit = 'cm';

  if (inputUnit === InputUnit.METER) {
    for (let i = 0; i < dimensions.length; i++) dimensions[i] /= 100;
    unit = 'm';
  }

  if (inputMethod === InputMethod.DIMENSIONS) {
    switch (dimensions.length) {
      case 1:
        return (
          <div>
            <FormattedMessage
              id="input-module-summary.drawerInputDimensions1D"
              values={{
                length: dimensions[0],
                unit,
                b: (chunks: ReactNode) => <strong>{chunks}</strong>,
              }}
            />
          </div>
        );
      case 2:
        return (
          <div>
            <FormattedMessage
              id="input-module-summary.drawerInputDimensions2D"
              values={{
                length: dimensions[0],
                width: dimensions[1],
                unit,
                b: (chunks: ReactNode) => <strong>{chunks}</strong>,
              }}
            />
          </div>
        );
      case 3:
        return (
          <div>
            <FormattedMessage
              id="input-module-summary.drawerInputDimensions3D"
              values={{
                length: dimensions[0],
                width: dimensions[1],
                height: dimensions[2],
                unit,
                b: (chunks: ReactNode) => <strong>{chunks}</strong>,
              }}
            />
          </div>
        );
      default:
        return <></>;
    }
  } else if (inputMethod === InputMethod.SURFACE) {
    return (
      <div>
        <FormattedMessage
          id="input-module-summary.drawerInputDimensionsSurface"
          values={{
            surface: dimensions[0],
            unit,
            b: (chunks: ReactNode) => <strong>{chunks}</strong>,
            u: (chunks: ReactNode) => <sup>{chunks}</sup>,
          }}
        />
      </div>
    );
  } else if (inputMethod === InputMethod.VOLUME) {
    return (
      <div>
        <FormattedMessage
          id="input-module-summary.drawerInputDimensionsVolume"
          values={{
            volume: dimensions[0],
            unit,
            b: (chunks: ReactNode) => <strong>{chunks}</strong>,
            u: (chunks: ReactNode) => <sup>{chunks}</sup>,
          }}
        />
      </div>
    );
  } else {
    return <></>;
  }
};

export default MaterialInputDimensionsSummary;
