import { CreateMaterialRequestEntity } from '@afleya/material-schemas';
import { TextField } from '@mui/material';
import { Fragment } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';

interface Props {
  control: Control<CreateMaterialRequestEntity>;
}

export const ComputeFields = ({ control }: Props): JSX.Element => {
  const intl = useIntl();

  return (
    <Fragment>
      <Controller
        name="weightPerFunctionalUnit"
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextField
            data-test="weightPerFunctionalUnit"
            id="weightPerFunctionalUnit"
            type="tel"
            color="secondary"
            variant="outlined"
            margin="normal"
            label={intl.formatMessage({
              id: 'set-material-compute-fields.weightPerFunctionalUnit',
            })}
            value={value}
            onChange={onChange}
          />
        )}
      />
      <Controller
        name="reuseAvoidedCO2PerFunctionalUnit"
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextField
            data-test="reuseAvoidedCO2PerFunctionalUnit"
            id="reuseAvoidedCO2PerFunctionalUnit"
            type="tel"
            color="secondary"
            variant="outlined"
            margin="normal"
            label={intl.formatMessage({
              id: 'set-material-compute-fields.reuseAvoidedCO2PerFunctionalUnit',
            })}
            value={value}
            onChange={onChange}
          />
        )}
      />
      <Controller
        name="initialCostPerFunctionalUnit"
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextField
            data-test="initialCostPerFunctionalUnit"
            id="initialCostPerFunctionalUnit"
            type="tel"
            color="secondary"
            variant="outlined"
            margin="normal"
            label={intl.formatMessage({
              id: 'set-material-compute-fields.initialCostPerFunctionalUnit',
            })}
            value={value}
            onChange={onChange}
          />
        )}
      />
      <Controller
        name="selectiveRemovalCostPerFunctionalUnit"
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextField
            data-test="selectiveRemovalCostPerFunctionalUnit"
            id="selectiveRemovalCostPerFunctionalUnit"
            type="tel"
            color="secondary"
            variant="outlined"
            margin="normal"
            label={intl.formatMessage({
              id: 'set-material-compute-fields.selectiveRemovalCostPerFunctionalUnit',
            })}
            value={value}
            onChange={onChange}
          />
        )}
      />
    </Fragment>
  );
};
