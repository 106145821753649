import { TextAutoComplete } from 'components/TextAutoComplete';
import { Control } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useAsync } from 'react-use';
import { MaterialInputFormEntity } from 'services/materialInputs';
import {
  MaterialInputFields,
  materialInputSearchTerm,
} from 'services/offline/requests/materialInput/materialInputSearchTerm';

interface Props {
  control: Control<MaterialInputFormEntity>;
  projectId: string;
}

export const LocationDescription = ({
  control,
  projectId,
}: Props): JSX.Element => {
  const intl = useIntl();

  const { value: locationDescriptions, loading: loadingLocations } = useAsync(
    () =>
      materialInputSearchTerm(
        projectId,
        MaterialInputFields.locationDescription,
      ),
    [projectId],
  );

  return (
    <TextAutoComplete
      control={control}
      fieldName="locationDescription"
      isRequired={false}
      values={locationDescriptions}
      loading={loadingLocations}
      label={intl.formatMessage({
        id: 'material-input-form.label.locationDescription',
      })}
      placeholder={intl.formatMessage({
        id: 'material-input-form.placeholder.locationDescription',
      })}
    />
  );
};
