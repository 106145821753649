import { materialsInputsDatabase } from '../../dexieDB';

export enum MaterialInputFields {
  locationDescription = 'locationDescription',
}

export const materialInputSearchTerm = async (
  projectId: string,
  field: MaterialInputFields,
): Promise<string[]> => {
  const materialInputs = await materialsInputsDatabase.materialInputs
    .where('projectId')
    .equals(projectId)
    .toArray();

  const searchTerms = materialInputs
    .map(materialInput => materialInput[field])
    .filter(term => term.trim() !== '');
  const searchTermsUnique = [...new Set(searchTerms)].sort();

  return searchTermsUnique;
};
