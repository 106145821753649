import { sortBuildingPartsByFloorLevel } from 'services/projects/sortBuildingParts';
import { listLocalBuildingParts } from './listLocalBuildingParts';
import { editLocalBuildingPart } from './editLocalBuildingPart';
import { getBuildingPart } from './getBuildingPart';

export enum Direction {
  UP = 'up',
  DOWN = 'down',
}

interface Props {
  buildingId: string;
  sourceBuildingPartId: string;
  direction: Direction;
}
export const swapBuildingPartFloor = async ({
  buildingId,
  sourceBuildingPartId,
  direction,
}: Props): Promise<void> => {
  const sourceBuildingPart = await getBuildingPart(sourceBuildingPartId);

  const buildingParts = sortBuildingPartsByFloorLevel(
    (await listLocalBuildingParts({ buildingId })).filter(
      buildingPart =>
        buildingPart.motherBuildingPartId ===
        sourceBuildingPart.motherBuildingPartId,
    ),
  );

  const sourceIndex = buildingParts.findIndex(
    buildingPart => buildingPart.id === sourceBuildingPartId,
  );

  if (sourceIndex !== -1) {
    let targetBuildingPart = null;
    switch (direction) {
      case Direction.UP:
        if (sourceIndex > 0) {
          targetBuildingPart = buildingParts[sourceIndex - 1];
        }
        break;
      case Direction.DOWN:
        if (sourceIndex < buildingParts.length - 1) {
          targetBuildingPart = buildingParts[sourceIndex + 1];
        }
        break;
    }

    if (targetBuildingPart) {
      await editLocalBuildingPart(sourceBuildingPartId, {
        buildingPartFloorLevel: targetBuildingPart.buildingPartFloorLevel,
      });
      await editLocalBuildingPart(targetBuildingPart.id, {
        buildingPartFloorLevel: sourceBuildingPart.buildingPartFloorLevel,
      });
    }
  }
};
