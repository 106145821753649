import {
  BuildCircle,
  CancelRounded,
  CheckCircle,
  ErrorRounded,
  MaximizeRounded,
} from '@mui/icons-material';
import { Box, Button, Container, IconButton, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

export const FooterButton = styled(Button)(() => ({
  width: '100%',
  height: '100%',
}));

export const FormContainer = styled('form')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const SuccessIcon = styled(CheckCircle)(({ theme }) => ({
  width: theme.spacing(12),
  height: theme.spacing(12),
  color: theme.palette.success.main,
}));

export const SmallSuccessIcon = styled(CheckCircle)(({ theme }) => ({
  width: theme.spacing(4),
  height: theme.spacing(4),
  color: theme.palette.success.main,
}));

export const SmallValidatedIcon = styled(CheckCircle)(({ theme }) => ({
  width: theme.spacing(3),
  height: theme.spacing(3),
  color: theme.palette.success.main,
}));

export const SmallNotValidatedIcon = styled(ErrorRounded)(({ theme }) => ({
  width: theme.spacing(3),
  height: theme.spacing(3),
  color: theme.palette.secondary.main,
}));

export const NotValidatedIcon = styled(ErrorRounded)(({ theme }) => ({
  width: theme.spacing(4),
  height: theme.spacing(4),
  color: theme.palette.secondary.main,
}));

export const SmallBuildIcon = styled(BuildCircle)(({ theme }) => ({
  width: theme.spacing(4),
  height: theme.spacing(4),
  color: theme.palette.secondary.main,
}));

export const TopLine = styled(MaximizeRounded)(({ theme }) => ({
  width: theme.spacing(12),
  height: theme.spacing(12),
}));

export const DrawerText = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  fontSize: '24px',
  justifyContent: 'center',
  textAlign: 'center',
  padding: '42px',
  fontWeight: 'bold',
}));

export const MainContainer = styled(Container)(() => ({
  flexDirection: 'column',
  flexGrow: 1,
  display: 'flex',
}));

export const ParentDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));

export const ScenarioItemIcon = styled(IconButton)(({ theme }) => ({
  width: theme.spacing(4),
  height: theme.spacing(4),
  color: theme.palette.primary.main,
}));

export const BadgeSpan = styled('span')(({ theme }) => ({
  width: theme.spacing(0),
  height: theme.spacing(3),
}));

export const StackFooter = styled(Stack)(({ theme }) => ({
  width: '100%',
  height: '300px',
  backgroundColor: theme.palette.secondary.main,
}));

export const CloseWindow = styled(CancelRounded)(() => ({
  width: '100%',
  height: '20px',
  color: 'red',
}));
