import {
  CreateMaterialRequestEntity,
  createMaterialRequestSchema,
} from '@afleya/material-schemas';
import { FooterButton, FormContainer } from 'components/Layout';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { PageContent } from 'components/PageContent';
import PageFooter from 'components/PageFooter';
import { FormattedMessage } from 'react-intl';
import { CircularProgress, Divider } from '@mui/material';
import { useAsyncFn } from 'react-use';
import createMaterial from 'services/networking/requests/createMaterial';
import { createMaterial as addMaterialOffline } from 'services/offline/requests/material/createMaterial';
import { useEffect } from 'react';
import {
  CategoryIdField,
  CommentsField,
  CompositionField,
  ComputeFields,
  EvaluationMethodField,
  NameField,
  WasteCategoryField,
} from './Fields';

interface Props {
  setCategoryId: (newCategoryId: string) => void;
  setErrorMessage: (newMessage: string) => void;
  setMaterialId: (newMaterialId: string) => void;
}

export const CreateMaterialForm = ({
  setCategoryId,
  setErrorMessage,
  setMaterialId,
}: Props): JSX.Element => {
  const {
    control,
    handleSubmit,
    formState: { isValid },
    setValue,
  } = useForm<CreateMaterialRequestEntity>({
    mode: 'onChange',
    resolver: zodResolver(createMaterialRequestSchema),
    defaultValues: {
      weightPerFunctionalUnit: 0,
      reuseAvoidedCO2PerFunctionalUnit: 0,
      initialCostPerFunctionalUnit: 0,
      selectiveRemovalCostPerFunctionalUnit: 0,
    },
  });

  const [{ loading: submitLoading, error: errorCreating }, onSubmit] =
    useAsyncFn(async (data: CreateMaterialRequestEntity) => {
      const compositionPercentage = data.composition.reduce(
        (acc, current) => (acc += current.percentage),
        0,
      );
      if (compositionPercentage !== 100) {
        setErrorMessage('input-module-create-material.errorPercentage');
        throw new Error();
      }
      if (data.weightPerFunctionalUnit <= 0) {
        setErrorMessage('input-module-create-material.errorWeightZero');
        throw new Error();
      }

      // Set message if create material request fails
      setErrorMessage('input-module-create-material.errorCreateMaterial');
      const material = await createMaterial(data);
      await addMaterialOffline(material);
      setMaterialId(material.id);
      setCategoryId(material.categoryId);
    });

  useEffect(() => {
    if (errorCreating) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [errorCreating]);

  return (
    <>
      <PageContent>
        <FormContainer
          id="create-material-form"
          noValidate
          autoComplete="off"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmit={handleSubmit(onSubmit)}
        >
          <NameField control={control} />
          <CategoryIdField control={control} />
          <CompositionField control={control} setValue={setValue} />
          <Divider />
          <EvaluationMethodField control={control} />
          <Divider />
          <WasteCategoryField control={control} />
          <Divider />
          <ComputeFields control={control} />
          <CommentsField control={control} />
        </FormContainer>
      </PageContent>
      <PageFooter>
        <FooterButton
          data-test="submit"
          form="create-material-form"
          type="submit"
          color="primary"
          variant="contained"
          disabled={!isValid || submitLoading}
        >
          {submitLoading ? (
            <CircularProgress size={20} />
          ) : (
            <FormattedMessage
              id={'input-module-create-material.submitButton'}
            />
          )}
        </FooterButton>
      </PageFooter>
    </>
  );
};
