import { combineReducers, configureStore } from '@reduxjs/toolkit';
import offlineProjectReducer from 'services/store/offlineProject';
import scenarioTabReducer from 'services/store/scenarioTabState';
import materialInputIdReducer from 'services/store/materialInputIdState';
import reloadReducer from 'services/store/reloadState';
import dimensionsReducer from 'services/store/dimensionsState';
import inputImageRecognition from 'services/store/inputImageRecognition';
import { materialsApi } from './services/store/apis/materialsApi';
import loginReducer from './services/store/loginState';
import unsynchronizedOperationsReducer from './services/store/unsynchonizedOperationsCounter';
import userProfileReducer from './services/store/userProfile';

const rootReducer = combineReducers({
  unsynchronizedOperationsCounter: unsynchronizedOperationsReducer,
  loginState: loginReducer,
  offlineProject: offlineProjectReducer,
  userProfileState: userProfileReducer,
  [materialsApi.reducerPath]: materialsApi.reducer,
  scenarioTabState: scenarioTabReducer,
  dimensionsState: dimensionsReducer,
  materialInputIdState: materialInputIdReducer,
  reloadState: reloadReducer,
  inputImageRecognition: inputImageRecognition,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(materialsApi.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
//export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
