import { syncProtocolContract } from '@afleya/material-inputs-contracts';
import {
  BuildingDatabaseChange,
  BuildingPartDatabaseChange,
  CharacteristicInputDatabaseChange,
  MaterialInputDatabaseChange,
  MaterialInputIconDatabaseChange,
} from '@afleya/sync-protocol-schemas';
import { getAxiosRequest } from '@swarmion/serverless-contracts';
import { getJwtToken } from 'services/cognito';
import client from 'services/networking/client';

export const syncProtocol = async (
  changes: (
    | MaterialInputDatabaseChange
    | MaterialInputIconDatabaseChange
    | BuildingDatabaseChange
    | BuildingPartDatabaseChange
    | CharacteristicInputDatabaseChange
  )[],
): Promise<number> => {
  const jwtToken = await getJwtToken();
  const { data: nbChanges } = await getAxiosRequest(
    syncProtocolContract,
    client,
    {
      headers: { authorization: `Bearer ${jwtToken}` },
      body: changes,
    },
  );

  return nbChanges;
};
