import { getBuildingAndBuildingPartNames } from '@afleya/common';
import { IntlShape } from 'react-intl';

interface Props {
  intl: IntlShape;
  projectName: string | undefined;
  buildingName: string | undefined;
  buildingPartName: string | undefined;
  motherBuildingPartName: string | undefined;
}

export const getLocationTitle = ({
  intl,
  projectName = '',
  buildingName = '',
  buildingPartName = '',
  motherBuildingPartName,
}: Props): string => {
  return intl.formatMessage(
    {
      id: 'input-module-image-recognition-page.locationTitle',
    },
    {
      projectName: projectName,
      buildingAndBuildingPartNames: getBuildingAndBuildingPartNames(
        buildingName,
        buildingPartName,
        motherBuildingPartName,
      ),
    },
  );
};
