import { AccountCircle, ArrowBackIos } from '@mui/icons-material';
import { Button, Menu, MenuItem, Typography } from '@mui/material';
import Navigation from 'components/Navigation';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useAsync } from 'react-use';
import muiTheme from 'services/theme/muiTheme';
import { getLocalUserProfile } from 'services/users/getLocalUserProfile';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../store';
import { BLocationTitle, DivHeader } from './PageHeader.style';
import { SyncStatus } from './SyncStatus';

interface Props {
  title: string;
  logout?: () => Promise<void>;
  displaySyncStatus?: boolean;
  onSync?: () => void;
  syncing?: boolean;
  syncingDescription?: string;
  subtitle?: string;
  backLinkPath?: string | -1;
  backLinkTitle?: string;
  locationTitle?: string;
  children?: JSX.Element | JSX.Element[];
  needNavigation?: boolean;
}

const PageHeader = ({
  children,
  title,
  logout,
  displaySyncStatus = true,
  onSync,
  syncingDescription,
  syncing = false,
  subtitle,
  backLinkPath,
  backLinkTitle,
  locationTitle,
  needNavigation,
}: Props): JSX.Element => {
  const unsynchronizedOperations = useSelector(
    (state: RootState) => state.unsynchronizedOperationsCounter.value,
  );

  const intl = useIntl();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | SVGSVGElement>(null);

  const userProfile = useSelector(
    (state: RootState) => state.userProfileState.userProfile,
  );

  useAsync(async () => {
    if (userProfile === undefined) {
      await getLocalUserProfile();
    }
  });

  const handleClick = (event: React.MouseEvent<SVGSVGElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogout = async () => {
    if (logout !== undefined) {
      await logout();
    }
  };

  const onClickBacklink = () => {
    if (backLinkPath === -1) {
      navigate(-1);
    } else if (backLinkPath !== undefined) {
      navigate(backLinkPath);
    }
  };

  return (
    <div
      style={{
        position: 'sticky',
        top: 0,
        zIndex: 100,
        backgroundColor: muiTheme.palette.secondary.light,
      }}
    >
      <DivHeader>
        <div>
          {needNavigation !== undefined && needNavigation && <Navigation />}
          {backLinkPath !== undefined && (
            <>
              <Button
                data-test="backLink"
                onClick={onClickBacklink}
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <ArrowBackIos fontSize="small" />
                {backLinkTitle}
              </Button>
            </>
          )}
        </div>
        {displaySyncStatus === true && (
          <SyncStatus
            unsynchronizedOperations={unsynchronizedOperations}
            syncing={syncing}
            onSync={onSync}
            syncingDescription={syncingDescription}
          />
        )}
        <BLocationTitle>{locationTitle}</BLocationTitle>
        {logout !== undefined && (
          <>
            <AccountCircle
              aria-controls="account-menu"
              aria-haspopup="true"
              onClick={handleClick}
            />
            <Menu
              id="account-menu"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem>{userProfile?.email}</MenuItem>
              <MenuItem
                onClick={() => {
                  void onLogout();
                }}
              >
                {intl.formatMessage({ id: 'account-menu.logout' })}
              </MenuItem>
            </Menu>
          </>
        )}
      </DivHeader>
      <Typography variant="h1">{title}</Typography>
      {subtitle}
      {children}
    </div>
  );
};

export default PageHeader;
