import { ImageTypes } from '@afleya/common';
import { CameraAlt } from '@mui/icons-material';
import { Button, Popover, Stack } from '@mui/material';
import { BrowseImage } from 'components/BrowseImage';
import Camera from 'components/Camera';
import { Image } from 'components/Image';
import { PageContent } from 'components/PageContent';
import PageFooter from 'components/PageFooter';
import PictureDetail from 'components/PictureDetail';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAsync } from 'react-use';
import { createLocalMaterialInputPicture } from 'services/offline/requests/materialInput/createLocalMaterialInputPicture';
import { deleteLocalMaterialInputPicture } from 'services/offline/requests/materialInput/deleteLocalMaterialInputPicture';
import { getMaterialInputPicture } from 'services/offline/requests/materialInput/getMaterialInputPicture';
import { listMaterialInputPicturesIds } from 'services/offline/requests/materialInput/listMaterialInputPicturesIds';
import {
  CardImage,
  CardNewImage,
  GridImages,
} from './MaterialInputPhoto.style';

interface Props {
  materialInputId: string;
  projectId: string;
  onNext: (() => Promise<void>) | (() => void);
  onNavigateToMaps?: () => void;
}

export const MaterialInputPhoto = ({
  materialInputId,
  projectId,
  onNext,
  onNavigateToMaps,
}: Props): JSX.Element => {
  const [materialInputPictureIds, setMaterialInputPictureIds] = useState<
    string[]
  >([]);
  const [showCamera, setShowCamera] = useState(false);
  const [showPictureDetail, setShowPictureDetail] = useState(false);
  const [pictureDetailId, setPictureDetailId] = useState<string>();

  const removeMaterialInputPictureId = (pictureId: string) => {
    if (materialInputPictureIds === undefined) {
      return;
    }
    setMaterialInputPictureIds(
      materialInputPictureIds.filter(
        materialInputPictureId => materialInputPictureId !== pictureId,
      ),
    );
  };
  const closeCamera = () => {
    setShowCamera(false);
  };
  const openCamera = () => {
    setShowCamera(true);
  };
  const closePictureDetail = () => {
    setShowPictureDetail(false);
  };
  const openPictureDetailWithId = (materialInputPictureId: string) => {
    setPictureDetailId(materialInputPictureId);
    setShowPictureDetail(true);
  };
  const initialiseMaterialInputPictureIds = async () => {
    const localMaterialInputPicturesIds = await listMaterialInputPicturesIds(
      materialInputId ?? '',
    );
    setMaterialInputPictureIds(localMaterialInputPicturesIds);
  };
  useAsync(initialiseMaterialInputPictureIds, [materialInputId]);

  const addPictureId = (id: string) => {
    if (materialInputPictureIds.length >= 3) {
      throw new Error('There already are 3 pictures');
    }
    setMaterialInputPictureIds(materialInputPictureIds.concat(id));
  };

  const onSubmit = () => {
    void onNext();
  };

  if (materialInputId === undefined) {
    return <></>;
  }

  return (
    <>
      <PageContent>
        <GridImages rowGap={2}>
          {materialInputPictureIds.map(materialInputPictureId => (
            <CardImage key={materialInputPictureId} variant="outlined">
              <Image
                projectId={projectId}
                hostId={materialInputId}
                imageId={materialInputPictureId}
                errorLoadingId="photo.errorLoading"
                dialogContentIds={[
                  'photo.deleteDialogTitle',
                  'photo.deleteDialogContent',
                ]}
                getImage={getMaterialInputPicture}
                imageType={ImageTypes.materialInputPicture}
                deleteImage={deleteLocalMaterialInputPicture}
                removeImageIdFromState={removeMaterialInputPictureId}
                onClick={() => openPictureDetailWithId(materialInputPictureId)}
              />
            </CardImage>
          ))}
          {materialInputPictureIds.length < 3 && (
            <CardNewImage variant="outlined">
              <Button color="primary" onClick={openCamera}>
                <CameraAlt fontSize="large" />
              </Button>
              <FormattedMessage id="input-module-input-photos-page.addPhoto" />
              <BrowseImage
                hostId={materialInputId}
                projectId={projectId}
                selectFileId="photo.selectFile"
                addImageId={addPictureId}
                requestCreateLocalImage={createLocalMaterialInputPicture}
              />
            </CardNewImage>
          )}
        </GridImages>
      </PageContent>
      <PageFooter>
        <Stack direction="row" spacing={1} justifyContent="center">
          <Button
            data-test="submit"
            color={onNavigateToMaps ? 'secondary' : 'primary'}
            variant="contained"
            onClick={onSubmit}
          >
            <FormattedMessage
              id={
                onNavigateToMaps
                  ? 'input-module-input-page.addAnotherMaterial'
                  : 'camera.back'
              }
            />
          </Button>
          {onNavigateToMaps && (
            <Button
              data-test="navigate-to-map"
              color="primary"
              variant="contained"
              onClick={onNavigateToMaps}
            >
              <FormattedMessage id="map-module.moduleTitle" />
            </Button>
          )}
        </Stack>
      </PageFooter>
      <Popover open={showCamera}>
        <Camera
          onCloseCamera={closeCamera}
          hostId={materialInputId}
          projectId={projectId}
          addImageId={addPictureId}
          requestCreateLocalImage={createLocalMaterialInputPicture}
        />
      </Popover>
      {pictureDetailId !== undefined && (
        <Popover open={showPictureDetail}>
          <PictureDetail
            onClosePictureDetail={closePictureDetail}
            removeMaterialInputPictureId={removeMaterialInputPictureId}
            projectId={projectId}
            materialInputId={materialInputId}
            pictureId={pictureDetailId}
          />
        </Popover>
      )}
    </>
  );
};
