import { Buffer } from 'buffer';
import { generatePresignedImageUploadUrlContract } from '@afleya/material-inputs-contracts';
import { getAxiosRequest } from '@swarmion/serverless-contracts';
import { getJwtToken } from 'services/cognito';
import { LocalMaterialInputPicture } from 'services/materialInputsPictures/type';
import { LocalBuildingPartMap } from 'services/buildingPartMaps/type';
import { ImageTypes } from '@afleya/common';
import client from '../client';

export const createImage = async (
  imageType: ImageTypes,
  hostId: string,
  imageToCreate: LocalMaterialInputPicture | LocalBuildingPartMap,
): Promise<void> => {
  const { projectId, id: imageId, base64 } = imageToCreate;
  const jwtToken = await getJwtToken();

  const presignedUrl = await getAxiosRequest(
    generatePresignedImageUploadUrlContract,
    client,
    {
      headers: { authorization: `Bearer ${jwtToken}` },
      pathParameters: {
        projectId,
        hostId,
        imageId,
        imageType,
      },
    },
  );

  const formData = new FormData();
  for (const key in presignedUrl.data.fields) {
    const value = presignedUrl.data.fields[key];
    if (typeof value !== 'string') {
      console.error(
        `key ${key} doesn't have a string value, but the value :`,
        value,
      );

      return;
    }
    formData.append(key, value);
  }

  // get the type from the base64 data
  const type = base64.split(';')[0].split('/')[1];
  formData.append('Content-Type', `image/${type}`);

  // create a blob from a buffer reading the base64
  const buf = Buffer.from(
    base64.replace(/^data:image\/\w+;base64,/, ''),
    'base64',
  );
  formData.append('file', new Blob([buf]));

  await client.post<FormData>(presignedUrl.data.url, formData);
};
