import 'dexie-observable';
import 'dexie-syncable';
import './materialInputsSyncProtocol/materialInputsSyncProtocol';
import './materialInputsPicturesSyncProtocol';
import { store } from '../../store';
import { setValue } from '../store/unsynchonizedOperationsCounter';
import { SynchroDatabase } from './indexedDb/SynchroDatabase';
import { MaterialsDatabase } from './indexedDb/MaterialsDatabase';
import { MaterialsInputsPicturesDatabase } from './indexedDb/MaterialsInputsPicturesDatabase';
import { MaterialsInputsDatabase } from './indexedDb/MaterialsInputsDatabase';

export const materialsDatabase = new MaterialsDatabase();
export const materialInputsPicturesDatabase =
  new MaterialsInputsPicturesDatabase();
export const materialsInputsDatabase = new MaterialsInputsDatabase();
export const synchroDatabase = new SynchroDatabase();

export const startDatabaseSync = async (): Promise<void> => {
  try {
    await synchroDatabase
      .getUnsynchronizedOperations()
      .then(count => store.dispatch(setValue(count)));

    await materialsInputsDatabase.startSync();
    console.log('material input sync started');
    await materialInputsPicturesDatabase.startSync();
    console.log('material input picture sync started');
  } catch (error) {
    console.log('startDatabaseSync dexie error: ', error);
  }
};

void startDatabaseSync();
