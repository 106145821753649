import { IconButton, InputAdornment, TextField } from '@mui/material';
import { Control, Controller, UseFormSetValue } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { BuildingPartFormAnswers } from 'services/buildingPart/validationSchema';
import ClearIcon from '@mui/icons-material/Clear';

interface Props {
  control: Control<BuildingPartFormAnswers>;
  buildingPartName: string | undefined;
  setValue: UseFormSetValue<BuildingPartFormAnswers>;
  isMother: boolean;
}

export const NameField = ({
  control,
  buildingPartName,
  setValue,
  isMother,
}: Props): JSX.Element => {
  const intl = useIntl();

  return (
    <>
      <Controller
        name={'buildingPartName'}
        control={control}
        defaultValue={buildingPartName}
        render={({ field: { onChange, value } }) => (
          <TextField
            id="buildingPartName"
            color="secondary"
            variant="outlined"
            margin="normal"
            label={intl.formatMessage({
              id: isMother
                ? 'building-part-form.motherName'
                : 'building-part-form.childName',
            })}
            placeholder={intl.formatMessage({
              id: isMother
                ? 'building-part-form.motherNameExample'
                : 'building-part-form.childNameExample',
            })}
            value={value}
            onChange={onChange}
            slotProps={{
              input: {
                endAdornment:
                  value !== '' ? (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        onClick={() => {
                          setValue('buildingPartName', '');
                        }}
                      >
                        <ClearIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  ) : null,
              },
            }}
          />
        )}
      />
    </>
  );
};
