import { Image } from 'components/Image';
import React, { Fragment, useState } from 'react';
import { PageContent } from 'components/PageContent';
import { BrowseImage } from 'components/BrowseImage';
import { createLocalBuildingPartMap } from 'services/offline/requests/buildingAndBuildingPart/createLocalBuildingPartMap';
import { getBuildingPartMap } from 'services/offline/requests/buildingAndBuildingPart/getBuildingPartMap';
import { deleteLocalBuildingPartMap } from 'services/offline/requests/buildingAndBuildingPart/deleteLocalBuildingPartMap';
import { Button, Popover } from '@mui/material';
import { CameraAlt } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import Camera from 'components/Camera/Camera';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes';
import { ImageTypes } from '@afleya/common';
import { LocalBuildingPartMap } from 'services/buildingPartMaps/type';
import { downloadImage } from 'services/networking/requests/downloadImage';
import {
  CardImage,
  CardNewImage,
  GridImages,
} from '../MaterialInputPhoto/MaterialInputPhoto.style';
import { mapModuleNavConstant } from './mapModuleNavConstant';

interface Props {
  buildingPartId: string;
  projectId: string;
  buildingPartMapIds: string[];
  setBuildingPartMapIds?: React.Dispatch<React.SetStateAction<string[]>>;
  onlyInputId?: string;
}
export const BuildingPartMaps = ({
  buildingPartId,
  projectId,
  buildingPartMapIds,
  setBuildingPartMapIds,
  onlyInputId,
}: Props): JSX.Element => {
  const navigate = useNavigate();
  const useOffline = setBuildingPartMapIds !== undefined;
  const [showCamera, setShowCamera] = useState<boolean>(false);
  const addBuildingPartMapId = (id: string) => {
    if (buildingPartMapIds.length >= 3) {
      throw new Error('There already are 3 pictures');
    }
    if (useOffline) {
      setBuildingPartMapIds(buildingPartMapIds.concat(id));
    }
  };
  const openCamera = () => {
    setShowCamera(true);
  };
  const closeCamera = () => {
    setShowCamera(false);
  };
  const downloadMap = async (mapId: string): Promise<LocalBuildingPartMap> => {
    const base64 = await downloadImage(
      projectId,
      ImageTypes.buildingPartMap,
      buildingPartId,
      mapId,
    );

    return {
      base64,
      uploaded: true,
      projectId,
      id: mapId,
      buildingPartId,
    } as LocalBuildingPartMap;
  };

  return (
    <Fragment>
      <PageContent>
        <GridImages rowGap={2}>
          {buildingPartMapIds.map(buildingPartMapId => (
            <CardImage key={buildingPartMapId} variant="outlined">
              <Image
                projectId={projectId}
                hostId={buildingPartId}
                imageId={buildingPartMapId}
                errorLoadingId="map-module.errorLoading"
                dialogContentIds={[
                  'map-module.deleteDialogTitle',
                  'map-module.deleteDialogContent',
                ]}
                getImage={useOffline ? getBuildingPartMap : downloadMap}
                imageType={ImageTypes.buildingPartMap}
                deleteImage={
                  useOffline ? deleteLocalBuildingPartMap : undefined
                }
                removeImageIdFromState={() => {
                  if (useOffline) {
                    setBuildingPartMapIds(
                      buildingPartMapIds.filter(
                        buildingPartMapIdFromList =>
                          buildingPartMapId !== buildingPartMapIdFromList,
                      ),
                    );
                  }
                }}
                onClick={() => {
                  if (useOffline) {
                    navigate(
                      routes.INPUT_MODULE_EDIT_MAP.replace(
                        ':projectId',
                        projectId,
                      )
                        .replace(':buildingPartId', buildingPartId)
                        .replace(':buildingPartMapId', buildingPartMapId)
                        .replace(
                          ':onlyInputId',
                          onlyInputId ?? mapModuleNavConstant.allInputs,
                        ),
                    );
                  } else {
                    navigate(
                      routes.ANALYSIS_MODULE_VIEW_MAP.replace(
                        ':projectId',
                        projectId,
                      )
                        .replace(':buildingPartId', buildingPartId)
                        .replace(':buildingPartMapId', buildingPartMapId),
                    );
                  }
                }}
              />
            </CardImage>
          ))}
          {buildingPartMapIds.length < 3 && useOffline && (
            <CardNewImage variant="outlined">
              <Button color="primary" onClick={openCamera}>
                <CameraAlt fontSize="large" />
              </Button>
              <FormattedMessage id="map-module.takePhoto" />
              <BrowseImage
                hostId={buildingPartId}
                projectId={projectId}
                selectFileId="map-module.selectFile"
                addImageId={addBuildingPartMapId}
                requestCreateLocalImage={createLocalBuildingPartMap}
              />
            </CardNewImage>
          )}
        </GridImages>
      </PageContent>
      <Popover open={showCamera}>
        <Camera
          onCloseCamera={closeCamera}
          hostId={buildingPartId}
          projectId={projectId}
          addImageId={addBuildingPartMapId}
          requestCreateLocalImage={createLocalBuildingPartMap}
        />
      </Popover>
    </Fragment>
  );
};
