import { CreateMaterialRequestEntity } from '@afleya/material-schemas';
import { TextField } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';

interface Props {
  control: Control<CreateMaterialRequestEntity>;
}

export const NameField = ({ control }: Props): JSX.Element => {
  const intl = useIntl();

  return (
    <Controller
      name="name"
      control={control}
      render={({ field: { onChange, value } }) => (
        <TextField
          data-test="materialName"
          id="name"
          color="secondary"
          variant="outlined"
          margin="normal"
          label={intl.formatMessage({ id: 'material-form.name' })}
          placeholder={intl.formatMessage({
            id: 'material-form.nameExample',
          })}
          value={value}
          onChange={onChange}
        />
      )}
    />
  );
};
