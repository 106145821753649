import { InputModuleSummary } from 'components/InputModuleSummary';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'store';

export const InputModuleHomePageSummary = (): JSX.Element => {
  const navigate = useNavigate();
  const project = useSelector(
    (state: RootState) => state.offlineProject.project,
  );

  if (project === undefined) {
    navigate(-1);

    return <></>;
  }

  return (
    <InputModuleSummary
      project={project}
      backlinkTitleId="input-module-home-page.title"
    />
  );
};
