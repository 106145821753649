import { MaterialCategoryEntity } from '@afleya/material-schemas';

export const sortCategories = (
  categories: MaterialCategoryEntity[],
): MaterialCategoryEntity[] => {
  return categories.sort((a, b) => {
    const otherA = a.name.search('Autres');
    const otherB = b.name.search('Autres');

    return (otherA !== -1 && otherB !== -1) || (otherA === -1 && otherB === -1)
      ? a.name.localeCompare(b.name)
      : otherA !== -1
        ? 1
        : -1;
  });
};
