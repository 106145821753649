import { Button } from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { store } from 'store';
import { setInputImageRecognitionImage } from 'services/store/inputImageRecognition';
import { scaleImage } from 'services/images/scaleImage';

interface Props {
  color?: 'primary';
  navigate: () => void;
}

export const RecognitionCameraButton = ({
  color,
  navigate,
}: Props): JSX.Element => {
  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = async e => {
        if (e.target && typeof e.target.result === 'string') {
          const imageString = e.target.result;

          try {
            const resizedImageString = await scaleImage(imageString, {
              width: 1280,
              height: 720,
            });

            store.dispatch(setInputImageRecognitionImage(resizedImageString));
            navigate();
          } catch (error) {
            console.error('Error scaling image:', error);
          }
        } else {
          console.error('Failed to read image.');
        }
      };

      reader.onerror = error => {
        console.error('Error reading image:', error);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleButtonClick = () => {
    const inputElement = document.getElementById(
      'imageInput',
    ) as HTMLInputElement;
    inputElement.click();
  };

  return (
    <>
      <input
        id="imageInput"
        type="file"
        name="image"
        accept="image/*"
        hidden
        onChange={handleImageChange}
      />
      <Button
        variant="contained"
        color={color}
        style={{
          padding: '8px 8px',
          borderRadius: '10px',
          width: '80%',
          height: '90%',
          objectFit: 'contain',
        }}
        onClick={handleButtonClick}
      >
        <CameraAltIcon style={{ fontSize: 30 }} />
      </Button>
    </>
  );
};
