import { BuildingPartEntity } from '@afleya/project-schemas';
import { listLocalBuildingParts } from './listLocalBuildingParts';

export const listLocalMotherBuildingParts = async (
  buildingId: string,
): Promise<BuildingPartEntity[]> => {
  const localBuildingParts = await listLocalBuildingParts({ buildingId });

  const motherBuildingParts = localBuildingParts.filter(
    localBuildingPart => localBuildingPart.motherBuildingPartId === null,
  );

  return motherBuildingParts;
};
