import {
  InputMethod,
  InputUnit,
  MaterialEvaluationMethod,
} from '@afleya/common';

interface FormatDimensionsArgs {
  evaluationMethod: MaterialEvaluationMethod;
  inputMethod: InputMethod;
  inputUnit: InputUnit;
  length: number;
  width: number;
  height: number;
}

export const formatDimensions = ({
  evaluationMethod,
  inputMethod,
  inputUnit,
  length,
  width,
  height,
}: FormatDimensionsArgs): { [x: string]: unknown } => {
  switch (evaluationMethod) {
    case MaterialEvaluationMethod.UNITS:
      return {};
    case MaterialEvaluationMethod.LINEAR:
      if (inputUnit === InputUnit.METER) {
        return { length: length * 100 };
      } else {
        return { length: length };
      }
    case MaterialEvaluationMethod.SURFACE:
      return formatSurface({ inputMethod, inputUnit, length, width });
    default:
      return formatVolume({ inputMethod, inputUnit, length, width, height });
  }
};

interface FormatSurfaceArgs {
  inputMethod: InputMethod;
  inputUnit: InputUnit;
  length: number;
  width: number;
}

const formatSurface = ({
  inputMethod,
  inputUnit,
  length,
  width,
}: FormatSurfaceArgs): { length: number; width: number } => {
  const dimensions = { length: length, width: width };
  if (inputMethod === InputMethod.SURFACE) {
    dimensions.width = 1;
  }
  if (inputUnit === InputUnit.METER) {
    dimensions.length *= 100;
    dimensions.width *= 100;
  }

  return dimensions;
};

interface FormatVolumeArgs {
  inputMethod: InputMethod;
  inputUnit: InputUnit;
  length: number;
  width: number;
  height: number;
}

const formatVolume = ({
  inputMethod,
  inputUnit,
  length,
  width,
  height,
}: FormatVolumeArgs): { length: number; width: number; height: number } => {
  const dimensions = { length: length, width: width, height: height };
  if (inputMethod === InputMethod.VOLUME) {
    dimensions.width = 1;
    dimensions.height = 1;
  }
  if (inputUnit === InputUnit.METER) {
    dimensions.length *= 100;
    dimensions.width *= 100;
    dimensions.height *= 100;
  }

  return dimensions;
};
