import { MaterialCategoryEntity } from '@afleya/material-schemas';
import { listMaterialInputsWithProps } from 'services/offline/requests/materialInput/listMaterialInputsWithProps';
import { getMaterial } from 'services/offline/requests/material/getMaterial';
import { NicknameItem, TypeEnum } from './type';

interface getMaterialItemsProps {
  skip: number;
  take: number;
  filter: string;
  projectId: string;
  selectedMotherCategories: MaterialCategoryEntity[];
  selectedDaughterCategories: MaterialCategoryEntity[];
  categories: MaterialCategoryEntity[];
}

interface getMaterialInputItemsResult {
  materialsInputConverted: NicknameItem[];
  newTake: number;
}

export const getMaterialInputItems = async ({
  skip,
  take,
  filter,
  projectId,
  selectedMotherCategories,
  selectedDaughterCategories,
  categories,
}: getMaterialItemsProps): Promise<getMaterialInputItemsResult> => {
  const materialInputsWithNewTake = await listMaterialInputsWithProps({
    projectId,
    skip,
    take,
    searchTerms: filter,
    selectedMotherCategories,
    selectedDaughterCategories,
    categories,
  });

  const materialsInputConverted = new Array<NicknameItem>();
  for (const materialInput of materialInputsWithNewTake.materialInputs) {
    const existingItemIndex = materialsInputConverted.findIndex(
      item => item.name === materialInput.nickname,
    );

    if (existingItemIndex !== -1) {
      const existingMaterial = materialsInputConverted[
        existingItemIndex
      ].materials.find(
        material => material.materialId === materialInput.materialId,
      );

      if (existingMaterial === undefined) {
        const material = await getMaterial(materialInput.materialId);
        materialsInputConverted[existingItemIndex].materials.push({
          materialId: material.id,
          categoryId: material.categoryId,
          defaultWasteCategory: material.defaultWasteCategory,
        });
      }
    } else {
      const material = await getMaterial(materialInput.materialId);
      materialsInputConverted.push({
        type: TypeEnum.Nickname,
        name: materialInput.nickname,
        materialInputId: materialInput.id,
        materials: [
          {
            materialId: material.id,
            categoryId: material.categoryId,
            defaultWasteCategory: material.defaultWasteCategory,
          },
        ],
      });
    }
  }

  return {
    materialsInputConverted: materialsInputConverted,
    newTake: materialInputsWithNewTake.newTake,
  };
};
