import { listMaterialInputs } from '../materialInput/listMaterialInputs';
import { deleteLocalMaterialInput } from '../materialInput/deleteLocalMaterialInput';

export const deleteBuildingPartMaterialInputs = async (
  projectId: string,
  buildingPartId: string,
): Promise<void> => {
  const buildingPartMaterialInputs = await listMaterialInputs({
    projectId,
    buildingPartId,
  });

  await Promise.all(
    buildingPartMaterialInputs.map(buildingPartMaterialInput =>
      deleteLocalMaterialInput(buildingPartMaterialInput.id),
    ),
  );
};
