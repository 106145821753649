import { MaterialEntity } from '@afleya/material-schemas';
import { materialsDatabase } from '../../dexieDB';

export const createMaterial = async (
  materialToCreate: MaterialEntity,
): Promise<void> => {
  // @ts-ignore dexie is mistyped
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
  await materialsDatabase.materials.add(materialToCreate);
};
