import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableRow,
} from '@mui/material';
import { forwardRef } from 'react';
import { TableComponents } from 'react-virtuoso';
import { MaterialInputForRecap } from 'services/offline/materialInputsForRecap';

export const InputModuleSummaryTableComponents: TableComponents<MaterialInputForRecap> =
  {
    Scroller: forwardRef<HTMLDivElement>((props, ref) => (
      <TableContainer component={Paper} {...props} ref={ref} />
    )),
    Table: props => (
      <Table
        {...props}
        sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }}
      />
    ),
    TableRow: ({ ...props }) => <TableRow {...props} />,
    TableBody: forwardRef<HTMLTableSectionElement>((props, ref) => (
      <TableBody {...props} ref={ref} />
    )),
  };
