import { BuildingPartEntity } from '@afleya/project-schemas';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import { MapModuleMenu } from 'components/MapModuleMenu';
import { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes';
import { BuildingWithBuildingParts } from 'services/offline/requests/buildingAndBuildingPart/getBuildingWithBuildingParts';
import { sortBuildingPartsByFloorLevel } from 'services/projects/sortBuildingParts';
import { slugify } from 'services/slugify/slugify';
import { BuildingPartMenu } from './BuildingPartMenu';
import { ListBuildingParts } from './ListBuildingParts';

interface Props {
  buildingId: string;
  projectId: string;
  buildingParts: BuildingPartEntity[];
  fetchBuildingParts: () => Promise<BuildingWithBuildingParts>;
}

export const ListMotherBuildingParts = ({
  buildingId,
  projectId,
  buildingParts,
  fetchBuildingParts,
}: Props): JSX.Element => {
  const navigate = useNavigate();

  const [openItems, setOpenItems] = useState<string[]>([]);

  const handleToggle = (itemId: string) => {
    setOpenItems(prevOpenItems =>
      prevOpenItems.includes(itemId)
        ? prevOpenItems.filter(id => id !== itemId)
        : [...prevOpenItems, itemId],
    );
  };

  return (
    <List>
      {sortBuildingPartsByFloorLevel(
        buildingParts.filter(
          buildingPart => buildingPart.motherBuildingPartId === null,
        ),
      ).map(buildingPart => {
        const showExpand = buildingParts?.some(
          part => part.motherBuildingPartId === buildingPart.id,
        );

        return (
          <Fragment key={buildingPart.id}>
            <ListItem
              data-test={slugify(buildingPart.buildingPartName)}
              id={`building-floor-level-${
                buildingPart.buildingPartFloorLevel ?? ''
              }`}
              disableGutters
              sx={{ paddingTop: 0, paddingBottom: 0 }}
              secondaryAction={
                <>
                  <MapModuleMenu
                    buildingPart={buildingPart}
                    projectId={projectId}
                  />
                  <BuildingPartMenu
                    projectId={projectId}
                    buildingPart={buildingPart}
                    fetchBuildingParts={fetchBuildingParts}
                  />
                </>
              }
            >
              {showExpand ? (
                <IconButton
                  onClick={() => {
                    handleToggle(buildingPart.id);
                  }}
                >
                  {openItems.includes(buildingPart.id) ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </IconButton>
              ) : (
                <div style={{ width: '40px' }} />
              )}

              <ListItemButton
                alignItems="flex-start"
                onClick={() => {
                  navigate({
                    pathname: routes.INPUT_MODULE_CATEGORIES.replace(
                      ':projectId',
                      projectId,
                    )
                      .replace(':buildingId', buildingId)
                      .replace(':buildingPartId', buildingPart.id),
                  });
                }}
              >
                <ListItemText>
                  <Typography sx={{ fontWeight: 'bold' }}>
                    {buildingPart.buildingPartName}
                  </Typography>
                </ListItemText>
              </ListItemButton>
            </ListItem>
            <Divider component="li" />
            {openItems.includes(buildingPart.id) && (
              <ListBuildingParts
                buildingId={buildingId}
                projectId={projectId}
                buildingParts={buildingParts}
                motherBuildingPartId={buildingPart.id}
                fetchBuildingParts={fetchBuildingParts}
              />
            )}
          </Fragment>
        );
      })}
    </List>
  );
};
