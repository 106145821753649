import { deleteLocalMaterialInputIcons } from '../requests/materialInput/deleteLocalMaterialInputIcons';
import { editLocalMaterialInput } from '../requests/materialInput/editLocalMaterialInput';
import { getMaterialInput } from '../requests/materialInput/getMaterialInput';
import { MaterialInputForRecap } from './MaterialInputForRecap';
import { computeMaterialInputForRecapLocation } from './computeMaterialInputForRecapLocation';

interface Props {
  selectedMaterialInputs: Array<MaterialInputForRecap>;
  buildingId?: string;
  buildingPartId?: string;
  newBuildingId: string;
  newBuildingPartId: string;
  buildingName: string;
  buildingPartName: string;
  daughterBuildingPartName?: string;
}

export const moveMaterialInputsForRecap = async ({
  selectedMaterialInputs,
  buildingId,
  buildingPartId,
  newBuildingId,
  newBuildingPartId,
  buildingName,
  buildingPartName,
  daughterBuildingPartName,
}: Props): Promise<Array<MaterialInputForRecap>> => {
  const newMaterialInputsForRecap = new Array<MaterialInputForRecap>();

  for (const selectedMaterialInput of selectedMaterialInputs) {
    const materialInput = await getMaterialInput(selectedMaterialInput.id);
    await deleteLocalMaterialInputIcons(selectedMaterialInput.id);
    materialInput.buildingId = newBuildingId;
    materialInput.buildingPartId = newBuildingPartId;
    materialInput.buildingAndBuildingPartNames = `${buildingName} - ${buildingPartName}${daughterBuildingPartName !== undefined ? ` - ${daughterBuildingPartName}` : ''}`;

    await editLocalMaterialInput(materialInput.id, materialInput);
    newMaterialInputsForRecap.push({
      ...selectedMaterialInput,
      location: await computeMaterialInputForRecapLocation({
        buildingId,
        buildingPartId,
        materialInputBuildingId: newBuildingId,
        materialInputBuildingPartId: newBuildingPartId,
        materialInputLocationDescription: materialInput.locationDescription,
      }),
    });
  }

  return newMaterialInputsForRecap;
};
