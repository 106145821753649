import { formatLocalBuildingPart } from 'services/buildingPart/formatLocalBuildingPart';
import { createLocalBuildingPart } from './createLocalBuildingPart';
import { listChildrenOfBuildingPart } from './listChildrenOfBuildingPart';
import { duplicateBuildingPart } from './duplicateBuildingPart';

interface Props {
  projectId: string;
  sourceBuildingPartId: string;
  targetBuildingId: string;
  targetBuildingPartId: string;
}

export const duplicateBuildingPartContent = async ({
  projectId,
  sourceBuildingPartId,
  targetBuildingId,
  targetBuildingPartId,
}: Props): Promise<void> => {
  const childrenOfBuildingPart =
    await listChildrenOfBuildingPart(sourceBuildingPartId);

  await Promise.all(
    childrenOfBuildingPart.map(async childOfBuildingPart => {
      const localBuildingPartToCreate = formatLocalBuildingPart({
        buildingId: targetBuildingId,
        motherBuildingPartId: targetBuildingPartId,
        buildingPartName: childOfBuildingPart.buildingPartName,
        floorLevel: childOfBuildingPart.buildingPartFloorLevel ?? -1,
        buildintPartType: childOfBuildingPart.buildingPartType,
      });

      await createLocalBuildingPart(localBuildingPartToCreate);

      await duplicateBuildingPart({
        projectId,
        sourceBuildingPartId: childOfBuildingPart.id,
        targetBuildingId: localBuildingPartToCreate.buildingId,
        targetBuildingPartId: localBuildingPartToCreate.id,
      });
    }),
  );
};
