import { LocalMaterialInputPicture } from 'services/materialInputsPictures/type';
import { materialInputsPicturesDatabase } from '../../dexieDB';

export const editLocalMaterialInputPicture = async (
  materialInputPictureToUpdateId: string,
  localMaterialInputPictureUpdates: Partial<LocalMaterialInputPicture>,
): Promise<void> => {
  await materialInputsPicturesDatabase.updatePicture(
    materialInputPictureToUpdateId,
    localMaterialInputPictureUpdates,
  );
};
