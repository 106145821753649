import { IconButton, InputAdornment, TextField } from '@mui/material';
import {
  Control,
  Controller,
  FieldArrayWithId,
  UseFieldArrayUpdate,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { DuplicateBuildingPartFormAnswersSchema } from 'services/buildingPart/validationSchema';
import ClearIcon from '@mui/icons-material/Clear';
interface Props {
  control: Control<DuplicateBuildingPartFormAnswersSchema>;
  index: number;
  fields: FieldArrayWithId<
    DuplicateBuildingPartFormAnswersSchema,
    'buildingPartNames',
    'id'
  >[];
  update: UseFieldArrayUpdate<
    DuplicateBuildingPartFormAnswersSchema,
    'buildingPartNames'
  >;
}

export const NameField = ({ control, index, update }: Props): JSX.Element => {
  const intl = useIntl();

  return (
    <>
      <Controller
        name="buildingPartNames"
        control={control}
        render={({ field: { value } }) => (
          <TextField
            key={index}
            sx={{ width: '49%' }}
            id={`buildingPartNames.${index}.buildingPartName`}
            color="secondary"
            variant="outlined"
            margin="normal"
            value={value[index].buildingPartName}
            label={intl.formatMessage({ id: 'building-part-form.childName' })}
            placeholder={intl.formatMessage({
              id: 'building-part-form.childNameExample',
            })}
            onChange={e =>
              update(index, {
                buildingPartName: e.target.value,
              })
            }
            onBlur={e =>
              update(index, {
                buildingPartName: e.target.value,
              })
            }
            slotProps={{
              input: {
                endAdornment:
                  value[index].buildingPartName !== '' ? (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        onClick={() => {
                          update(index, {
                            buildingPartName: '',
                          });
                        }}
                      >
                        <ClearIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  ) : null,
              },
            }}
          />
        )}
      />
    </>
  );
};
