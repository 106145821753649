import { LocalMaterialInputIcon } from 'services/materialInputIcons/type';
import { materialsInputsDatabase } from '../../dexieDB';

export const editLocalMaterialInputIcon = async (
  iconToUpdateId: string,
  localMaterialInputIconUpdates: Partial<LocalMaterialInputIcon>,
): Promise<void> => {
  await materialsInputsDatabase.updateIcon(
    iconToUpdateId,
    localMaterialInputIconUpdates,
  );
};
