import { MaterialInputEntity } from '@afleya/project-schemas';
import { LocalMaterialInput } from './types';

export const localMaterialInputToMaterialInputEntity = ({
  id,
  dimensions,
  locationDescription,
  nickname,
  amount,
  comments,
  buildingId,
  buildingPartId,
  projectId,
  materialId,
  materialCategoryId,
  materialEvaluationMethod,
  stateNewPercentage,
  stateGoodPercentage,
  stateMediumPercentage,
  stateObsoletePercentage,
  stateComments,
  age,
  assemblyTypeId,
  materialToDeduce,
  inputUnit,
  inputMethod,
  hasAsbestos,
  hasLead,
  hasOtherPollution,
  reusePercentage,
  weightPerFunctionalUnit,
}: LocalMaterialInput): MaterialInputEntity => ({
  id,
  dimensions,
  locationDescription,
  nickname,
  amount,
  comments,
  buildingId,
  buildingPartId,
  projectId,
  materialId,
  materialCategoryId,
  materialEvaluationMethod,
  stateNewPercentage,
  stateGoodPercentage,
  stateMediumPercentage,
  stateObsoletePercentage,
  stateComments,
  age,
  assemblyTypeId,
  materialToDeduce,
  inputUnit,
  inputMethod,
  hasAsbestos: Boolean(hasAsbestos),
  hasLead: Boolean(hasLead),
  hasOtherPollution: Boolean(hasOtherPollution),
  reusePercentage,
  weightPerFunctionalUnit,
});
