import { styled } from '@mui/material/styles';

export const DivButton = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  display: 'flex',
  justifyContent: 'center',
}));

export const DivContainer = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(5),
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
}));
