import { LocalMaterialInput } from 'services/materialInputs/types';

import { materialsInputsDatabase } from '../../dexieDB';

export const getMaterialInput = async (
  materialInputId: string,
): Promise<LocalMaterialInput> => {
  const materialInput =
    await materialsInputsDatabase.materialInputs.get(materialInputId);
  if (materialInput === undefined) {
    throw new Error(`no materialInput whith id ${materialInputId}`);
  }

  return materialInput;
};
