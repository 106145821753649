import { Autocomplete, TextField } from '@mui/material';
import BoxAlert from 'components/BoxAlert';
import LoadingBox from 'components/LoadingBox';
import { Control, Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useAsync } from 'react-use';
import { MaterialInputFormEntity } from 'services/materialInputs';
import { listAssemblyTypes } from 'services/offline/requests/material/listAssemblyTypes';

interface Props {
  control: Control<MaterialInputFormEntity>;
}

export const AssemblyTypeId = ({ control }: Props): JSX.Element => {
  const intl = useIntl();

  const { loading, error, value: assemblyTypes } = useAsync(listAssemblyTypes);

  return error ? (
    <BoxAlert contentId={'material-input-form.error.assemblyTypes'} />
  ) : loading || assemblyTypes === undefined ? (
    <LoadingBox />
  ) : (
    <Controller
      name="assemblyTypeId"
      control={control}
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          options={assemblyTypes}
          defaultValue={assemblyTypes.find(
            assemblyType => assemblyType.id === value,
          )}
          getOptionLabel={option => option.name}
          onChange={(_event, option) => onChange(option?.id)}
          renderInput={params => (
            <TextField
              {...params}
              variant="outlined"
              color="secondary"
              margin="normal"
              fullWidth
              label={intl.formatMessage({
                id: 'material-input-form.label.assemblyTypeId',
              })}
            />
          )}
        />
      )}
    />
  );
};
