import { setValue } from 'services/store/unsynchonizedOperationsCounter';
import { store } from 'store';
import {
  materialInputsPicturesDatabase,
  materialsInputsDatabase,
} from '../dexieDB';
export const updateSynchroStatus = async (): Promise<void> => {
  const nbUnsynchronizedInputs =
    await materialsInputsDatabase.getNbUnsynchronizedItem();
  const nbUnsynchronizedPictures =
    await materialInputsPicturesDatabase.getNbUnsynchronizedItem();
  store.dispatch(setValue(nbUnsynchronizedInputs + nbUnsynchronizedPictures));
};
