import { deleteBuildingPart } from './deleteBuildingPart';
import { listLocalMotherBuildingParts } from './listLocalMotherBuildingParts';

interface Props {
  projectId: string;
  buildingId: string;
}
export const deleteBuildingContent = async ({
  projectId,
  buildingId,
}: Props): Promise<void> => {
  const motherBuildingParts = await listLocalMotherBuildingParts(buildingId);

  await Promise.all(
    motherBuildingParts.map(motherBuildingPart =>
      deleteBuildingPart(projectId, motherBuildingPart.id),
    ),
  );
};
