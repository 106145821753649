import { BuildingEntity } from '@afleya/project-schemas';
import { materialsInputsDatabase } from '../../dexieDB';

export const getBuilding = async (
  buildingId: string,
): Promise<BuildingEntity> => {
  const building = await materialsInputsDatabase.getBuilding(buildingId);

  return building;
};
