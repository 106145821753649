import { materialsDatabase } from '../../dexieDB';

export const getImageRecognitionLabelIndex = async (
  name: string,
): Promise<number> => {
  const imageRecognition = await materialsDatabase.imageRecognitionLabels.get({
    name: name,
  });

  return imageRecognition?.labelIndex ?? -1;
};
