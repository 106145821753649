import { LatLngBounds, LatLngTuple } from 'leaflet';
import { useEffect, useMemo, useState } from 'react';
import { useDrop, XYCoord } from 'react-dnd';
import { useAsync } from 'react-use';
import { getBuildingPartMap } from 'services/offline/requests/buildingAndBuildingPart/getBuildingPartMap';
import { Grid2 } from '@mui/material';
import Filter from 'components/Filter';
import LoadingBox from 'components/LoadingBox';
import { MapDisplay } from './components/MapDisplay';
import { IMaterial, IMaterialOnMap, IOffMapAmounts } from './interfaces';
import { ScrollingMaterials } from './components/ScrollingMaterials';
import 'leaflet/dist/leaflet.css';
import './icons/icons.css';
import './MapModule.css';
import {
  getBuildingPartMapFromOnline,
  getMapMaterialLists,
} from './dataQueries';
import { CustomDragPreview } from './components/CustomDragPreview';
interface IProps {
  isEdit: boolean;
  projectId: string;
  buildingPartId: string;
  buildingPartMapId: string;
  onlyInputId?: string;
}

export const MapModule = ({
  projectId,
  buildingPartId,
  buildingPartMapId,
  isEdit,
  onlyInputId,
}: IProps): JSX.Element => {
  const [selectedAmount, setSelectedAmount] = useState<IOffMapAmounts>({});
  const [ratio, setRatio] = useState<number>(1);
  const [materialsOnMap, setMaterialsOnMap] = useState<IMaterialOnMap[]>([]);
  const [materialsOffMap, setMaterialsOffMap] = useState<IMaterial[]>([]);
  const [markersInView, setMarkerInView] = useState<boolean[]>([]);
  const [mapBounds, setMapBounds] = useState<LatLngBounds>(
    new LatLngBounds([
      [0, 0],
      [100 * ratio, 100],
    ]),
  );
  const [centerView, setCenterView] = useState<LatLngTuple>([
    (100 * ratio) / 2,
    100 / 2,
  ]);
  const [materialToPlace, setMaterialToPlace] =
    useState<[IMaterial, XYCoord]>();
  const [previewClassName, setPreviewClassName] =
    useState<string>('defaultIcon');
  const [filter, setFilter] = useState<string>('');

  const mapMaterialLists = useAsync(
    async () =>
      await getMapMaterialLists(
        projectId,
        buildingPartId,
        buildingPartMapId,
        isEdit,
      ),
  );

  const [, drop] = useDrop(
    () => ({
      accept: 'material',
      drop: (item: IMaterial, monitor) => {
        const offset = monitor.getClientOffset();
        const container = document.getElementById('mapContainer');
        if (container !== null && offset !== null) {
          const rects = container.getClientRects()[0];
          offset.x = offset.x - rects.left;
          offset.y = offset.y - rects.top;
          setMaterialToPlace([item, offset]);
        }
      },
    }),
    [setMaterialToPlace],
  );
  const background: HTMLImageElement = useMemo(() => new Image(), []);
  const backgroundSrc = useAsync(async () => {
    let image: string;
    if (isEdit) {
      const { base64 } = await getBuildingPartMap(buildingPartMapId);
      image = base64;
    } else {
      image = await getBuildingPartMapFromOnline(
        projectId,
        buildingPartId,
        buildingPartMapId,
      );
    }

    return image;
  });

  useEffect(() => {
    background.onload = () => {
      setRatio(background.height / background.width);
      setMapBounds(
        new LatLngBounds([
          [0, 0],
          [100 * ratio, 100],
        ]),
      );
      setCenterView([(100 * ratio) / 2, 100 / 2]);
    };
    if (!backgroundSrc.loading && backgroundSrc.value !== undefined) {
      background.src = backgroundSrc.value;
    }
  }, [ratio, background, backgroundSrc]);
  useEffect(() => {
    if (!mapMaterialLists.loading && mapMaterialLists.value !== undefined) {
      const {
        materialsOnMap: tempMaterialsOnMap,
        materialsOffMap: tempMaterialsOffMap,
      } = mapMaterialLists.value;
      if (onlyInputId === undefined) {
        setMaterialsOnMap(tempMaterialsOnMap);
        setMaterialsOffMap(tempMaterialsOffMap);
        setMarkerInView(Array(tempMaterialsOnMap.length).fill(true));
      } else {
        setMaterialsOnMap([]);
        setMaterialsOffMap(
          tempMaterialsOffMap.filter(
            mat => mat.materialInputId === onlyInputId,
          ),
        );
        setMarkerInView([true]);
      }
    }
  }, [mapMaterialLists, buildingPartMapId, onlyInputId]);

  return mapMaterialLists.loading ? (
    <LoadingBox />
  ) : (
    <Grid2
      container
      id={isEdit ? undefined : 'mapWithLegend'}
      display="flex"
      border="1px solid grey"
      height="90vh"
      width="100%"
    >
      <Grid2
        size={9}
        id={isEdit ? 'mapContainer' : undefined}
        ref={isEdit ? drop : undefined}
      >
        <MapDisplay
          centerView={centerView}
          mapStates={{
            materialsOnMap,
            materialsOffMap,
            materialToPlace,
            markersInView,
            setMaterialsOnMap,
            setMaterialsOffMap,
            setMaterialToPlace,
            setMarkerInView,
          }}
          backgroundSource={background.src}
          mapBounds={mapBounds}
          selectedAmount={selectedAmount}
          buildingPartMapId={buildingPartMapId}
          filter={filter}
          isEdit={isEdit}
        />
      </Grid2>
      <Grid2 size={3} id="mapModuleSideMenu">
        <CustomDragPreview className={previewClassName} />
        <div id="mapModuleFilterWrapper">
          <Filter
            defaultValue={filter}
            label="map-module.filterLabel"
            placeholder="map-module.filterPlaceholder"
            setFilter={setFilter}
          />
        </div>
        <div id="divContainingScrollingMaterials">
          <ScrollingMaterials
            isEdit={isEdit}
            filter={filter}
            materialsList={isEdit ? materialsOffMap : materialsOnMap}
            selectedAmount={selectedAmount}
            setSelectedAmount={setSelectedAmount}
            setPreviewClassName={setPreviewClassName}
            markersInView={markersInView}
          />
        </div>
      </Grid2>
    </Grid2>
  );
};
