import { BuildingPartEntity } from '@afleya/project-schemas';
import { LocalBuildingPart } from './types';

export const localBuildingPartToBuildingPartEntity = ({
  id,
  buildingId,
  buildingPartName,
  buildingPartType,
  buildingPartFloorLevel,
  motherBuildingPartId,
}: LocalBuildingPart): BuildingPartEntity => ({
  id,
  buildingId,
  buildingPartName,
  buildingPartType,
  buildingPartFloorLevel,
  motherBuildingPartId,
});
