import { MaterialInputPhoto } from 'components/MaterialInputPhoto';
import PageHeader from 'components/PageHeader';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { beforeUnload } from 'services/beforeUnload/beforeUnload';
import { navigateToModuleInputCategories } from 'services/navigation/navigateToModuleInputCategories';
import { navigateToModuleInputMapSelect } from 'services/navigation/navigateToModuleInputMapSelect';

export const InputModuleInputPhotosPage = (): JSX.Element => {
  const intl = useIntl();
  const navigate = useNavigate();

  useEffect(beforeUnload, []);

  const { projectId, buildingId, buildingPartId, materialInputId } = useParams<{
    projectId: string;
    buildingId: string;
    buildingPartId: string;
    materialInputId: string;
  }>();

  if (
    projectId === undefined ||
    materialInputId === undefined ||
    buildingId === undefined ||
    buildingPartId === undefined
  ) {
    return <></>;
  }

  return (
    <>
      <PageHeader
        title={intl.formatMessage({
          id: 'input-module-input-photos-page.title',
        })}
      />
      <MaterialInputPhoto
        materialInputId={materialInputId}
        projectId={projectId}
        onNext={() =>
          navigateToModuleInputCategories({
            projectId,
            buildingId,
            buildingPartId,
            navigate,
          })
        }
        onNavigateToMaps={() =>
          navigateToModuleInputMapSelect({
            materialInputId,
            projectId,
            buildingId,
            buildingPartId,
            navigate,
          })
        }
      />
    </>
  );
};
