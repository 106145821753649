import { Stack } from '@mui/material';
import { mapModuleNavConstant } from 'components/BuildingPartMaps';
import { MapModule } from 'components/MapModule';
import { PageContent } from 'components/PageContent';
import PageFooter from 'components/PageFooter/PageFooter';
import { Fragment } from 'react';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { navigateToModuleInputCategories } from 'services/navigation/navigateToModuleInputCategories';
import { getBuilding } from 'services/offline/requests/buildingAndBuildingPart/getBuilding';
import { getBuildingPart } from 'services/offline/requests/buildingAndBuildingPart/getBuildingPart';
import { useAsync } from 'react-use';
import { GoBackButton, GoBackPlanButton } from './GoBackButtons';

const getOnlyInputId = (onlyInputId: string): string | undefined => {
  if (onlyInputId === (mapModuleNavConstant.allInputs as string)) {
    return undefined;
  } else {
    return onlyInputId;
  }
};

export const InputModuleEditMap = (): JSX.Element => {
  const { projectId, buildingPartId, buildingPartMapId, onlyInputId } =
    useParams<{
      projectId: string;
      buildingPartId: string;
      buildingPartMapId: string;
      onlyInputId: string;
    }>();

  const navigate = useNavigate();
  const intl = useIntl();
  const { value: buildingId } = useAsync(async () => {
    const buildingPart = await getBuildingPart(buildingPartId ?? '');
    const building = await getBuilding(buildingPart.buildingId);

    return building.id;
  });

  const onClickGoBack = () => {
    if (onlyInputId === (mapModuleNavConstant.allInputs as string)) {
      navigate(-1);
    } else {
      navigateToModuleInputCategories({
        projectId,
        buildingId,
        buildingPartId,
        navigate,
      });
    }
  };

  return (
    <Fragment>
      <PageContent display="flex">
        <DndProvider
          backend={TouchBackend}
          options={{ enableMouseEvents: true }}
        >
          {projectId !== undefined &&
            buildingPartId !== undefined &&
            buildingPartMapId !== undefined &&
            onlyInputId !== undefined && (
              <MapModule
                isEdit={true}
                projectId={projectId}
                buildingPartId={buildingPartId}
                buildingPartMapId={buildingPartMapId}
                onlyInputId={getOnlyInputId(onlyInputId)}
              />
            )}
        </DndProvider>
      </PageContent>
      <PageFooter>
        <Stack direction="row" spacing={1} justifyContent="center">
          <GoBackButton
            onlyInputId={onlyInputId}
            onClick={onClickGoBack}
            intl={intl}
          />
          {onlyInputId !== undefined &&
            getOnlyInputId(onlyInputId) !== undefined && (
              <GoBackPlanButton onlyInputId={onlyInputId} intl={intl} />
            )}
        </Stack>
      </PageFooter>
    </Fragment>
  );
};
