import { TextAutoComplete } from 'components/TextAutoComplete';
import { Control } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useAsync } from 'react-use';
import { filterMaterialInputsByMaterialId } from 'services/filter/filterMaterialInputsByMaterialId';
import { MaterialInputFormEntity } from 'services/materialInputs';
import { listMaterialInputs } from 'services/offline/requests/materialInput/listMaterialInputs';

interface Props {
  control: Control<MaterialInputFormEntity>;
  projectId: string;
}

export const Nickname = ({ control, projectId }: Props): JSX.Element => {
  const intl = useIntl();

  const { materialId } = useParams<{
    materialId: string;
  }>();

  const { value: uniqueNicknames, loading: loading } = useAsync(async () => {
    const uniqueNicknamesSet: Set<string> = new Set();
    const materialInputs =
      projectId !== undefined ? await listMaterialInputs({ projectId }) : [];
    if (materialId !== undefined) {
      filterMaterialInputsByMaterialId(materialInputs, materialId).forEach(
        materialInput => uniqueNicknamesSet.add(materialInput.nickname),
      );
    }

    if (uniqueNicknamesSet.size === 0)
      return materialInputs
        .map(materialInput => materialInput.nickname)
        .filter((value, index, self) => self.indexOf(value) === index);
    else return [...uniqueNicknamesSet];
  });

  return (
    <TextAutoComplete
      control={control}
      fieldName="nickname"
      isRequired={true}
      values={uniqueNicknames}
      loading={loading}
      label={intl.formatMessage({
        id: 'material-input-form.label.nickname',
      })}
      placeholder={intl.formatMessage({
        id: 'material-input-form.placeholder.nickname',
      })}
      helperText={intl.formatMessage({
        id: 'material-input-form.error.nicknameRequired',
      })}
    />
  );
};
