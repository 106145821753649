import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface inputImageRecognition {
  image: string;
}

const initialState: inputImageRecognition = { image: '' };

export const inputImageRecognitionSlice = createSlice({
  name: 'inputImageRecognition',
  initialState,
  reducers: {
    setInputImageRecognitionImage: (state, action: PayloadAction<string>) => {
      state.image = action.payload;
    },
    clearInputImageRecognition: state => {
      state.image = '';
    },
  },
});

export const { setInputImageRecognitionImage, clearInputImageRecognition } =
  inputImageRecognitionSlice.actions;

export default inputImageRecognitionSlice.reducer;
