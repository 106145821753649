import { Button } from '@mui/material';
import { ChangeEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import UploadFileIcon from '@mui/icons-material/UploadFile';

interface Props {
  hostId: string;
  projectId: string;
  selectFileId: string;
  addImageId: (id: string) => void;
  requestCreateLocalImage: (
    dataUrl: string,
    hostId: string,
    projectId: string,
  ) => Promise<string>;
}

export const BrowseImage = ({
  hostId,
  projectId,
  selectFileId,
  addImageId,
  requestCreateLocalImage,
}: Props): JSX.Element => {
  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    // ensure the file is an image
    if (files !== null && /image.*/.exec(files[0].type)) {
      try {
        const id = await requestCreateLocalImage(
          URL.createObjectURL(files[0]),
          hostId,
          projectId,
        );
        addImageId(id);
      } catch (error) {
        console.log('createLocalBuildingPartMap error: ', error);
        throw error;
      }
    }
  };

  return (
    <>
      <Button component="label">
        <UploadFileIcon fontSize="large" />
        <input
          data-test="selectPhoto"
          type="file"
          accept="image/*"
          hidden
          onChange={event => {
            void handleChange(event);
          }}
          onClick={event => {
            const chgEvent = event as unknown as ChangeEvent<HTMLInputElement>;
            chgEvent.target.value = '';
          }}
        />
      </Button>
      <FormattedMessage id={selectFileId} />
    </>
  );
};
