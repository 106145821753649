import { getUserProfileContract } from '@afleya/project-contracts';
import { UserProfile } from '@afleya/project-schemas';
import { getAxiosRequest } from '@swarmion/serverless-contracts';
import { getJwtToken } from 'services/cognito';
import client from '../client';

export const getUserProfile = async (): Promise<UserProfile> => {
  const jwtToken = await getJwtToken();

  const { data: userProfile } = await getAxiosRequest(
    getUserProfileContract,
    client,
    {
      headers: { authorization: `Bearer ${jwtToken}` },
    },
  );

  return userProfile;
};
