import TextField from '@mui/material/TextField';
import { useDebounce } from 'react-use';
import { useIntl } from 'react-intl';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import { MaterialCategoryEntity } from '@afleya/material-schemas';
import { Dispatch, SetStateAction, SyntheticEvent, useState } from 'react';
import { Autocomplete } from '@mui/material';
import { filter } from 'services/filter/SearchFilter';
import { filterCategoriesByMotherCategories } from 'services/filter/filterCategoriesByMotherCategories';
import { ExpandMore } from '@mui/icons-material';
import { sortMotherCategories } from './sortMotherCategories';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface Props {
  selectedMotherCategories: MaterialCategoryEntity[];
  setSelectedMotherCategories: Dispatch<
    SetStateAction<MaterialCategoryEntity[]>
  >;
  selectedDaughterCategories: MaterialCategoryEntity[];
  setSelectedDaughterCategories: Dispatch<
    SetStateAction<MaterialCategoryEntity[]>
  >;
  motherCategories: MaterialCategoryEntity[];
}

export const MotherCategoryFilter = ({
  selectedMotherCategories,
  setSelectedMotherCategories,
  selectedDaughterCategories,
  setSelectedDaughterCategories,
  motherCategories,
}: Props): JSX.Element => {
  const intl = useIntl();

  const [debouncedSearchTerms, setDebouncedSearchTerms] = useState<string[]>(
    [],
  );
  const [searchTerm, setSearchTerm] = useState('');
  useDebounce(
    () => {
      setDebouncedSearchTerms(searchTerm.split(' '));
    },
    500,
    [searchTerm],
  );

  const handleCategoryChange = (
    _event: SyntheticEvent<Element, Event>,
    newValue: MaterialCategoryEntity[] | null,
  ) => {
    setSelectedMotherCategories(newValue ?? []);

    setSelectedDaughterCategories(
      filterCategoriesByMotherCategories(
        selectedDaughterCategories,
        newValue ?? [],
      ),
    );
  };

  return (
    <>
      <Autocomplete
        multiple
        limitTags={3}
        id="motherCategoryFilterBarID"
        value={selectedMotherCategories}
        onChange={handleCategoryChange}
        options={sortMotherCategories(motherCategories)}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        disableCloseOnSelect
        getOptionLabel={(option: MaterialCategoryEntity) => option.name}
        filterOptions={options => filter(options, debouncedSearchTerms)}
        renderOption={(props, option: MaterialCategoryEntity, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.name}
          </li>
        )}
        renderInput={params => (
          <TextField
            {...params}
            label={intl.formatMessage({
              id: 'select-mother-category-filter.filterBox',
            })}
            onChange={event => setSearchTerm(event.target.value)}
            onBlur={() => setDebouncedSearchTerms([])}
          />
        )}
        popupIcon={<ExpandMore color="primary" />}
      />
    </>
  );
};
