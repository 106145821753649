import MaterialInputDimensionsSummary from 'components/MaterialInputSummaryItem/MaterialInputDimensionsSummary';
import { LatLng, Point } from 'leaflet';
import { Fragment, useCallback, useEffect } from 'react';
import { Marker, Popup, useMap } from 'react-leaflet';
import { createLocalMaterialInputIcon } from 'services/offline/requests/materialInput/createLocalMaterialInputIcon';
import { deleteLocalMaterialInputIcon } from 'services/offline/requests/materialInput/deleteLocalMaterialInputIcon';
import { editLocalMaterialInputIcon } from 'services/offline/requests/materialInput/editLocalMaterialInputIcon';
import { v4 as uuidv4 } from 'uuid';
import filterService from 'services/filterService/filterService';
import { IMaterial, IShowMarkers } from '../interfaces';
import { handleInBounds, handleOutOfBounds } from './handleDragend';
import { getDivIcon } from '../icons/getIcon';

export const MarkersDisplay = ({
  mapStates,
  selectedAmount,
  buildingPartMapId,
  filter,
  isEdit,
}: IShowMarkers): JSX.Element => {
  const {
    materialsOnMap,
    materialsOffMap,
    materialToPlace,
    markersInView,
    setMaterialsOnMap,
    setMaterialsOffMap,
    setMaterialToPlace,
    setMarkerInView,
  } = mapStates;

  const map = useMap();

  const handleEndEvents = useCallback(() => {
    setMarkerInView(
      materialsOnMap.map(material =>
        map.getBounds().contains(material.coordinates),
      ),
    );
  }, [materialsOnMap, map, setMarkerInView]);
  map.on('zoomend', handleEndEvents);
  map.on('moveend', handleEndEvents);

  useEffect(() => {
    if (materialToPlace !== undefined) {
      const material = materialToPlace[0];
      const coordinates = map.containerPointToLatLng(
        new Point(materialToPlace[1].x, materialToPlace[1].y),
      );
      setMaterialToPlace(undefined);
      const amountOut = selectedAmount[material.materialInputId];
      const onMap = {
        ...material,
        amount: amountOut,
        coordinates: coordinates,
        iconId: uuidv4(),
        buildingPartMapId: buildingPartMapId,
      };
      setMaterialsOnMap([...materialsOnMap, onMap]);
      const amountLeft = material.amount - amountOut;
      if (amountLeft > 0) {
        setMaterialsOffMap(
          materialsOffMap.map(mat => {
            if (mat.materialInputId === material.materialInputId) {
              const offMap: IMaterial = {
                ...material,
                amount: amountLeft,
              };

              return offMap;
            } else {
              return mat;
            }
          }),
        );
      } else {
        setMaterialsOffMap(
          materialsOffMap.filter(
            mat => mat.materialInputId !== material.materialInputId,
          ),
        );
      }
      void createLocalMaterialInputIcon({
        id: onMap.iconId,
        materialInputId: onMap.materialInputId,
        buildingPartMapId: onMap.buildingPartMapId,
        amount: onMap.amount,
        lat: onMap.coordinates.lat,
        lng: onMap.coordinates.lng,
      });
    }
  }, [
    materialsOffMap,
    setMaterialsOffMap,
    materialsOnMap,
    setMaterialsOnMap,
    materialToPlace,
    setMaterialToPlace,
    map,
    selectedAmount,
    buildingPartMapId,
  ]);

  return (
    <Fragment>
      {materialsOnMap
        .filter(
          (material, index) =>
            filterService(
              [material.nickname, material.locationDescription],
              filter,
            ) && (isEdit ? true : markersInView[index]),
        )
        .map((material, index) => {
          return (
            <Marker
              eventHandlers={{
                dragend: event => {
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                  const position: LatLng = event.target.getLatLng();
                  if (map.getBounds().contains(position)) {
                    handleInBounds(
                      materialsOnMap,
                      setMaterialsOnMap,
                      material,
                      position,
                    );
                    void editLocalMaterialInputIcon(material.iconId, {
                      lat: position.lat,
                      lng: position.lng,
                    });
                  } else {
                    handleOutOfBounds({
                      materialsOnMap,
                      setMaterialsOnMap,
                      materialsOffMap,
                      setMaterialsOffMap,
                      material,
                    });
                    void deleteLocalMaterialInputIcon(material.iconId);
                  }
                },
              }}
              key={material.iconId}
              icon={getDivIcon(isEdit, material.materialCategoryId, index)}
              draggable={isEdit}
              position={material.coordinates}
            >
              {isEdit ? (
                <Popup>
                  <b>{material.nickname}</b>
                  <div>{`x${material.amount}`}</div>
                  <MaterialInputDimensionsSummary
                    materialDimensions={material.dimensions}
                    inputUnit={material.inputUnit}
                    inputMethod={material.inputMethod}
                  />
                </Popup>
              ) : (
                <></>
              )}
            </Marker>
          );
        })}
    </Fragment>
  );
};
