import { MaterialCategoryEntity } from '@afleya/material-schemas';
import { materialsDatabase } from '../../dexieDB';
import { getImageRecognitionLabel } from './getImageRecognitionLabel';

export const getDaughterCategoriesFromImageRecognitionLabelIndex = async (
  labelIndex: number,
): Promise<MaterialCategoryEntity[]> => {
  const imageRecognitionLabelId = await getImageRecognitionLabel(labelIndex);

  if (!imageRecognitionLabelId) {
    throw new Error(
      'Image recognition label not found for index ' + labelIndex,
    );
  }

  const daughterCategories = await materialsDatabase.categories
    .where('imageRecognitionLabelId')
    .equals(imageRecognitionLabelId.id)
    .toArray();

  return daughterCategories;
};
