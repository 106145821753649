import { MaterialInputForRecap } from './MaterialInputForRecap';
import { computeMaterialInputForRecapLocation } from './computeMaterialInputForRecapLocation';
import { computeMaterialInputForRecapDimensions } from './computeMaterialnputForRecapDimensions';
import { computeMaterialInputForRecapNbPictures } from './computeMaterialInputForRecapNbPictures';
import { listMaterialInputs } from '../requests/materialInput/listMaterialInputs';
import { listChildrenOfBuildingPart } from '../requests/buildingAndBuildingPart/listChildrenOfBuildingPart';
import { listCharacteristicInputs } from '../requests/materialInput/listCharacteristicInputs';

interface Props {
  projectId: string;
  buildingId?: string;
  buildingPartId?: string;
}

export const listMaterialInputsForRecap = async (
  props: Props,
): Promise<Array<MaterialInputForRecap>> => {
  const materialInputs = await listMaterialInputs(props);

  if (props.buildingPartId !== undefined) {
    const daughterBuildingParts = await listChildrenOfBuildingPart(
      props.buildingPartId,
    );

    for (const daughterBuildingPart of daughterBuildingParts) {
      const materialInputsTmp = await listMaterialInputs({
        ...props,
        buildingPartId: daughterBuildingPart.id,
      });

      materialInputs.push(...materialInputsTmp);
    }
  }

  return await Promise.all(
    materialInputs.map(async materialInput => {
      const materialInputBuildingId = materialInput.buildingId;
      const materialInputBuildingPartId = materialInput.buildingPartId;
      const materialInputLocationDescription =
        materialInput.locationDescription;
      const hasCharacteristic =
        (await listCharacteristicInputs(materialInput.id)).length > 0;

      return {
        id: materialInput.id,
        nickname: materialInput.nickname,
        location: await computeMaterialInputForRecapLocation({
          buildingId: props.buildingId,
          buildingPartId: props.buildingPartId,
          materialInputBuildingId,
          materialInputBuildingPartId,
          materialInputLocationDescription,
        }),
        dimensions: computeMaterialInputForRecapDimensions(
          materialInput.inputMethod,
          materialInput.inputUnit,
          materialInput.dimensions,
        ),
        amount: materialInput.amount,
        reusePercentage: materialInput.reusePercentage,
        nbPictures: await computeMaterialInputForRecapNbPictures(
          materialInput.id,
        ),
        toDeduce: Boolean(materialInput.materialToDeduce),
        hasCharacteristic,
      };
    }),
  );
};
