import { LocalBuildingPartMap } from 'services/buildingPartMaps/type';
import { materialInputsPicturesDatabase } from '../../dexieDB';

export const editLocalBuildingPartMap = async (
  buildingPartMapToUpdateId: string,
  localBuildingPartMapUpdates: Partial<LocalBuildingPartMap>,
): Promise<void> => {
  await materialInputsPicturesDatabase.updateMap(
    buildingPartMapToUpdateId,
    localBuildingPartMapUpdates,
  );
};
