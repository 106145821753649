import { Button, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StackCamera = styled(Stack)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  height: '100%',
}));

export const StackCameraFooter = styled(Stack)(() => ({
  width: '100%',
}));

export const ButtonCamera = styled(Button)(() => ({
  width: '100%',
  height: '100%',
}));

export const VideoCamera = styled('video')(() => ({
  objectFit: 'contain',
}));
