import { Autocomplete, TextField } from '@mui/material';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { sortNameASC } from 'services/sort/sortName';

export interface Props<T extends FieldValues> {
  control: Control<T>;
  fieldName: Path<T>;
  values: string[] | undefined;
  loading: boolean;
  label: string;
  placeholder: string;
  helperText?: string;
  isRequired: boolean;
}
export const TextAutoComplete = <T extends FieldValues>({
  control,
  fieldName,
  values,
  loading,
  label,
  placeholder,
  helperText = '',
  isRequired,
}: Props<T>): JSX.Element => {
  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <Autocomplete
            id={fieldName}
            loading={loading}
            freeSolo
            options={values?.sort(sortNameASC) ?? []}
            onInputChange={(_e, v) => {
              onChange(v);
            }}
            value={value ?? ''}
            renderInput={params => (
              <TextField
                {...params}
                color="secondary"
                label={label}
                placeholder={placeholder}
                required={isRequired}
                margin="normal"
                variant="outlined"
                error={error !== undefined}
                helperText={error !== undefined ? helperText : ''}
                slotProps={{
                  htmlInput: params.inputProps,
                }}
              />
            )}
          />
        );
      }}
    />
  );
};
