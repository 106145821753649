/* eslint-disable complexity */
import { Box, Stack } from '@mui/material';
import BoxAlert from 'components/BoxAlert';
import { PageContent } from 'components/PageContent';
import PageHeader from 'components/PageHeader';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { useAsync } from 'react-use';
import { routes } from 'routes';
import { getBuildingAndBuildingPart } from 'services/offline/requests/buildingAndBuildingPart/getBuildingAndBuildingPart';
import { useEffect, useRef, useState } from 'react';
import PageFooter from 'components/PageFooter';
import LoadingBox from 'components/LoadingBox';
import { FooterButton } from 'components/Layout';
import { useSelector } from 'react-redux';
import { RootState, store } from 'store';
import { beforeUnload } from 'services/beforeUnload/beforeUnload';
import { BuildingEntity, BuildingPartEntity } from '@afleya/project-schemas';
import { clearDimensions } from 'services/store/dimensionsState';
import { getBuildingAndBuildingPartNames } from '@afleya/common';
import { getBuildingPart } from 'services/offline/requests/buildingAndBuildingPart/getBuildingPart';
import { MaterialsSearch } from 'components/MaterialsSearch';
import { listMaterialInputsForRecap } from 'services/offline/materialInputsForRecap';
import { InputModuleSummaryButton } from 'components/InputModuleSummaryButton';
import { ScrollTopButton } from './ScrollTopButton';
import { FilterWithRecognitionCameraButton } from './FilterWithRecognitionCameraButton';

export interface Data {
  building: BuildingEntity;
  buildingPart: BuildingPartEntity;
}

export const InputModuleCategories = (): JSX.Element => {
  store.dispatch(clearDimensions());
  const { projectId, buildingId, buildingPartId } = useParams<{
    projectId: string;
    buildingId: string;
    buildingPartId: string;
  }>();
  const navigate = useNavigate();
  const intl = useIntl();

  useEffect(beforeUnload, []);

  const project = useSelector(
    (state: RootState) => state.offlineProject.project,
  );

  const {
    loading,
    error,
    value: data,
  } = useAsync(async (): Promise<Data> => {
    const { building, buildingPart } = await getBuildingAndBuildingPart(
      buildingId ?? '',
      buildingPartId ?? '',
    );

    return { building, buildingPart };
  }, [buildingId, buildingPartId]);

  const { value: motherBuildingPartName } = useAsync(async () => {
    const motherBuildingPartId = data?.buildingPart.motherBuildingPartId;

    return motherBuildingPartId === null
      ? undefined
      : (await getBuildingPart(motherBuildingPartId ?? '')).buildingPartName;
  }, [data]);

  const { value: nbMaterialInputs } = useAsync(
    async () =>
      (
        await listMaterialInputsForRecap({
          projectId: projectId ?? '',
          buildingId,
          buildingPartId,
        })
      ).length,
    [projectId, buildingId, buildingPartId],
  );

  const locationTitle = intl.formatMessage(
    {
      id: 'input-module-categories-page.locationTitle',
    },
    {
      projectName: project?.projectName,
      buildingAndBuildingPartNames: getBuildingAndBuildingPartNames(
        data?.building.buildingName ?? '',
        data?.buildingPart.buildingPartName ?? '',
        motherBuildingPartName,
      ),
    },
  );

  const [searchTerm, setSearchTerm] = useState('');
  const pageContentRef = useRef<HTMLDivElement>(null);

  const onClickInputModuleSummaryButton = () =>
    navigate(
      routes.INPUT_MODULE_CATEGORIES_SUMMARY.replace(
        ':projectId',
        projectId ?? '',
      )
        .replace(':buildingId', buildingId ?? '')
        .replace(':buildingPartId', buildingPartId ?? ''),
    );

  if (
    projectId === undefined ||
    buildingId === undefined ||
    buildingPartId === undefined
  ) {
    navigate(-1);

    return <></>;
  }

  const cameraButtonNavigate = () =>
    navigate(
      routes.INPUT_MODULE_IMAGE_RECOGNITION.replace(':projectId', projectId)
        .replace(':buildingId', buildingId)
        .replace(':buildingPartId', buildingPartId),
    );

  return error ? (
    <BoxAlert contentId="input-module-categories-page.loadingError" />
  ) : loading || data === undefined ? (
    <LoadingBox />
  ) : (
    <Stack direction="column" height="100vh">
      <PageHeader
        title={intl.formatMessage({ id: 'input-module-categories-page.title' })}
        locationTitle={locationTitle}
        backLinkTitle={intl.formatMessage({
          id: 'input-module-building-parts-page.title',
        })}
        backLinkPath={routes.INPUT_MODULE_BUILDING_PARTS.replace(
          ':projectId',
          projectId,
        ).replace(':buildingId', buildingId)}
      >
        <FilterWithRecognitionCameraButton
          setSearchTerm={setSearchTerm}
          pageContentRef={pageContentRef}
          cameraButtonNavigate={cameraButtonNavigate}
        />
      </PageHeader>
      <div ref={pageContentRef} style={{ overflow: 'auto', flexGrow: 1 }}>
        <PageContent overflow="auto">
          <Box marginBlockStart="0.80em" marginBlockEnd="0.80em">
            <MaterialsSearch searchTerm={searchTerm} />
            <FooterButton
              data-test="createMaterial"
              color="primary"
              variant="text"
              onClick={() =>
                navigate(
                  routes.INPUT_MODULE_CREATE_MATERIAL.replace(
                    ':projectId',
                    projectId,
                  )
                    .replace(':buildingId', buildingId)
                    .replace(':buildingPartId', buildingPartId),
                )
              }
            >
              <FormattedMessage id="input-module-categories-page.createMaterial" />
            </FooterButton>
          </Box>
        </PageContent>
      </div>
      <PageFooter>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <div style={{ flex: 1 }}>
            <InputModuleSummaryButton
              nbMaterialInputs={nbMaterialInputs ?? 0}
              onClick={onClickInputModuleSummaryButton}
            />
          </div>
          <div style={{ marginLeft: 'auto' }}>
            <ScrollTopButton targetRef={pageContentRef} />
          </div>
        </div>
      </PageFooter>
    </Stack>
  );
};
