import { ListItem, ListItemButton } from '@mui/material';
import { MaterialItem } from 'services/MaterialOrNicknameItems/type';
import { slugify } from 'services/slugify/slugify';
import { ListMaterialItemText } from './ListMaterialItemText';

interface Props {
  material: MaterialItem;
  handleNavigate: (materialId: string, categoryId: string) => void;
}

export const ListMaterialItem = ({
  material,
  handleNavigate,
}: Props): JSX.Element => {
  return (
    <>
      <ListItem data-test={slugify(`material-${material.name}`)} disablePadding>
        <ListItemButton
          alignItems="flex-start"
          disableGutters
          divider={true}
          onClick={() =>
            handleNavigate(material.materialId, material.categoryId)
          }
        >
          <ListMaterialItemText
            materialName={material.name}
            defaultWasteCategory={material.defaultWasteCategory}
            isOrganizationMaterial={typeof material.organizationId === 'string'}
            isValidated={material.isValidated}
            isGeneric={material.generic}
          />
        </ListItemButton>
      </ListItem>
    </>
  );
};
