import { BuildingEntity } from '@afleya/project-schemas';
import {
  ButtonSelector,
  DivSelectorButtonContainer,
  ToggleButtonGroupContainer,
} from 'services/buildingPart/DuplicateSelector.style';

interface Props {
  sourceBuildingId: string;
  targetBuildingId?: string;
  setTargetBuildingId: (targetBuildingId?: string) => void;
  buildings: BuildingEntity[] | undefined;
}

const DuplicateBuildingSelector = ({
  sourceBuildingId,
  targetBuildingId,
  setTargetBuildingId,
  buildings,
}: Props): JSX.Element => {
  return (
    <ToggleButtonGroupContainer exclusive={true}>
      {buildings
        ?.filter(building => building.id !== sourceBuildingId)
        .map(building => (
          <DivSelectorButtonContainer key={building.id}>
            <ButtonSelector
              onClick={() => {
                setTargetBuildingId(building.id);
              }}
              color={building.id === targetBuildingId ? 'secondary' : 'primary'}
              variant={
                building.id === targetBuildingId ? 'contained' : 'outlined'
              }
            >
              {building.buildingName}
            </ButtonSelector>
          </DivSelectorButtonContainer>
        ))}
    </ToggleButtonGroupContainer>
  );
};

export default DuplicateBuildingSelector;
