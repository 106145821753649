import { IntlShape } from 'react-intl';
import { getMaterialInputsForExport } from 'services/offline/requests/materialInput/getMaterialInputsForExport';
import { listMaterialInputs } from 'services/offline/requests/materialInput/listMaterialInputs';
import { generateCsv } from './generateCsv';
import { getMaterialInputCsvFields } from './getMaterialInputCsvFields';

export const generateMaterialInputsCsvExport = async (
  projectId: string,
  intl: IntlShape,
): Promise<string> => {
  return generateCsv(
    await getMaterialInputsForExport(projectId ?? ''),
    getMaterialInputCsvFields(intl),
  );
};

export const generateRawMaterialInputsCsvExport = async (
  projectId: string,
): Promise<string> => {
  const csv = generateCsv(
    await listMaterialInputs({ projectId: projectId ?? '' }),
  );

  return csv;
};
