import { sanitizeString, unifyString } from '@afleya/common';
import {
  MaterialCategoryEntity,
  MaterialEntity,
} from '@afleya/material-schemas';
import { getNewSelectedDaughterCategories } from '../materialInput/getNewSelectedDaughterCategories';
import { fetchMaterials } from './levenshteinForMaterialsSearchEngine';

interface Props {
  skip: number;
  take: number;
  searchTerms: string;
  selectedMotherCategories: MaterialCategoryEntity[];
  selectedDaughterCategories: MaterialCategoryEntity[];
  categories: MaterialCategoryEntity[];
}

export const listMaterialsSearchEngine = async ({
  skip,
  take,
  searchTerms,
  selectedMotherCategories,
  selectedDaughterCategories,
  categories,
}: Props): Promise<MaterialEntity[]> => {
  let materials: MaterialEntity[] = [];
  let maxDistance = 0;

  const unifiedSearchTerms = sanitizeString(searchTerms)
    .split(' ')
    .map(searchTerm => unifyString(searchTerm));

  const newSelectedDaughterCategories = getNewSelectedDaughterCategories(
    selectedMotherCategories,
    selectedDaughterCategories,
    categories,
  );

  while (materials.length < take && maxDistance <= 1) {
    materials = await fetchMaterials({
      materials,
      newSelectedDaughterCategories,
      maxDistance,
      unifiedSearchTerms,
      skip,
      take: take - materials.length,
    });
    maxDistance++;
  }

  return materials;
};
