import Dexie, { Table } from 'dexie';
import {
  decrement,
  decrementByAmount,
  increment,
  incrementByAmount,
  setValue,
} from 'services/store/unsynchonizedOperationsCounter';
import { store } from 'store';

export interface SynchroStatus {
  id: number;
  unsynchronizedOperations: number;
}

const SYNCHROSTATUS_ID = 1;

export class SynchroDatabase extends Dexie {
  synchrosStatus!: Table<SynchroStatus>;
  refreshTimeout = 0;
  constructor() {
    super('synchrosStatus');
    this.version(1).stores({
      synchrosStatus: 'id, unsynchronizedOperations',
    });
  }
  setRefreshTimeout = (): void => {
    if (this.refreshTimeout !== 0) {
      clearTimeout(this.refreshTimeout);
      this.refreshTimeout = 0;
    }
    this.refreshTimeout = setTimeout(() => {
      const synchroStatus = {
        id: SYNCHROSTATUS_ID,
        unsynchronizedOperations:
          store.getState().unsynchronizedOperationsCounter.value,
      };
      void this.synchrosStatus.put(synchroStatus);
    }, 1000) as unknown as number;
  };

  incrementUnsynchronizedOperations = (): void => {
    store.dispatch(increment());
    this.setRefreshTimeout();
  };
  incrementUnsynchronizedOperationsByAmount = (amount: number): void => {
    store.dispatch(incrementByAmount(amount));
    this.setRefreshTimeout();
  };
  decrementUnsynchronizedOperations = (): void => {
    store.dispatch(decrement());
    this.setRefreshTimeout();
  };
  decrementUnsynchronizedOperationsByAmount = (amount: number): void => {
    store.dispatch(decrementByAmount(amount));
    this.setRefreshTimeout();
  };
  resetUnsynchronizedOperations = (): void => {
    store.dispatch(setValue(0));
    this.setRefreshTimeout();
  };

  getUnsynchronizedOperations = async (): Promise<number> => {
    const synchroStatus = await this.synchrosStatus.get(SYNCHROSTATUS_ID);

    return synchroStatus !== undefined
      ? synchroStatus.unsynchronizedOperations
      : 0;
  };
}
