import { v4 as uuidV4 } from 'uuid';
import { Characteristic } from 'components/CreateOrEditCharacteristic/CharacteristicType';
import { materialsInputsDatabase } from '../../dexieDB';

export const createLocalCharacteristicInput = async (
  characteristics: Characteristic[],
  localMaterialInputId: string,
): Promise<void> => {
  await Promise.all(
    characteristics.map(async characteristic => {
      return Promise.all(
        characteristic.characteristicValues.map(async value => {
          await materialsInputsDatabase.addCharacteristicInput({
            id: uuidV4(),
            characteristicId: characteristic.characteristicId,
            characteristicValue: value,
            materialInputId: localMaterialInputId,
          });
        }),
      );
    }),
  );
};
