import ConstructionIcon from '@mui/icons-material/Construction';
import { Chip, ListItemText, Stack } from '@mui/material';
import { ChipWasteCategory } from 'components/ChipWasteCategory';
import { SmallNotValidatedIcon, SmallValidatedIcon } from 'components/Layout';
import { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

interface Props {
  materialName: string;
  defaultWasteCategory: string;
  isOrganizationMaterial: boolean;
  isValidated: boolean;
  isGeneric?: boolean;
}

export const ListMaterialItemText = ({
  materialName,
  defaultWasteCategory,
  isOrganizationMaterial,
  isValidated,
  isGeneric = false,
}: Props): JSX.Element => {
  const intl = useIntl();

  return (
    <ListItemText>
      <Stack direction="row" spacing={2} alignItems="center">
        <div>
          <FormattedMessage
            id="input-module-categories-page.materialName"
            values={{
              materialName,
              b: (chunks: ReactNode) => <strong>{chunks}</strong>,
            }}
          />
          <ChipWasteCategory wasteCategory={defaultWasteCategory} />
        </div>
        {isOrganizationMaterial && (
          <Stack direction="row" alignItems="center">
            <ConstructionIcon fontSize="small" />
            {isValidated === true ? (
              <SmallValidatedIcon fontSize="small" />
            ) : (
              <SmallNotValidatedIcon fontSize="small" />
            )}
          </Stack>
        )}
        {isGeneric && (
          <Chip
            label={intl.formatMessage({
              id: 'input-module-categories-page.generic',
            })}
          />
        )}
      </Stack>
    </ListItemText>
  );
};
