import { MaterialCategoryEntity } from '@afleya/material-schemas';
import { materialsDatabase } from '../../dexieDB';
import { getDaughterCategoriesFromImageRecognitionLabelIndex } from './getDaughterCategoriesFromImageRecognitionLabelIndex';

export const getMotherCategoriesFromImageRecognitionLabelIndex = async (
  labelIndex: number,
): Promise<MaterialCategoryEntity[]> => {
  const daughterCategories =
    await getDaughterCategoriesFromImageRecognitionLabelIndex(labelIndex);

  const motherCategoryIds = daughterCategories
    .map(category => category.motherId)
    .filter(
      (motherId, index, self) =>
        typeof motherId === 'string' && self.indexOf(motherId) === index,
    ) as string[];

  const motherCategories = await materialsDatabase.categories
    .where('id')
    .anyOf(motherCategoryIds)
    .toArray();

  return motherCategories;
};
