import { InputMethod, InputUnit } from '@afleya/common';

export const getUnitToDisplay = (
  inputUnit: InputUnit,
  inputMethod: InputMethod,
): string => {
  if (inputMethod === InputMethod.DIMENSIONS) {
    return inputUnit === InputUnit.METER ? 'meter' : 'centimeter';
  } else if (inputMethod === InputMethod.SURFACE) {
    return inputUnit === InputUnit.METER ? 'squareMeter' : 'squareCentimeter';
  } else {
    return inputUnit === InputUnit.METER ? 'cubicMeter' : 'cubicCentimeter';
  }
};
