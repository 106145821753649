import { NumberWithComma } from '@afleya/common';
import { CreateMaterialRequestEntity } from '@afleya/material-schemas';
import { Add, Delete } from '@mui/icons-material';
import { Autocomplete, Box, IconButton, Stack, TextField } from '@mui/material';
import BoxAlert from 'components/BoxAlert';
import LoadingBox from 'components/LoadingBox';
import { Fragment } from 'react';
import { Control, useFieldArray, UseFormSetValue } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAsync } from 'react-use';
import { listComponents } from 'services/offline/requests/material/listComponents';

interface Props {
  control: Control<CreateMaterialRequestEntity>;
  setValue: UseFormSetValue<CreateMaterialRequestEntity>;
}

export const CompositionField = ({ control, setValue }: Props): JSX.Element => {
  const intl = useIntl();

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'composition',
  });

  const {
    loading,
    error,
    value: components,
  } = useAsync(async () => {
    return await listComponents();
  }, []);

  return error ? (
    <BoxAlert contentId={'select-material-component-form.loadingError'} />
  ) : loading || components === undefined ? (
    <LoadingBox />
  ) : (
    <Box sx={{ marginTop: '2px', marginBottom: '18px' }}>
      <Box sx={{ margin: '6px', marginBottom: '10px' }}>
        <FormattedMessage id="set-material-composition-form.composition" />
      </Box>
      <Stack spacing={2}>
        {fields.map((_fields, index) => (
          <Fragment key={_fields.id}>
            <Stack direction="row" spacing={2}>
              <Autocomplete
                id={`composition.${index}.componentId`}
                fullWidth
                options={components}
                getOptionLabel={option => option.name}
                value={components.find(
                  component => component.id === _fields.componentId,
                )}
                onChange={(_event, item) =>
                  update(index, {
                    componentId: item?.id ?? '',
                    percentage: _fields.percentage,
                  })
                }
                renderInput={params => (
                  <TextField
                    data-test={`materialComponent-${index}`}
                    {...params}
                    label={intl.formatMessage({
                      id: 'select-material-component-form.component',
                    })}
                    variant="outlined"
                    slotProps={{
                      htmlInput: {
                        ...params.inputProps,
                      },
                    }}
                  />
                )}
              />
              <TextField
                data-test={`materialComponentPercentage-${index}`}
                id={`composition.${index}.percentage`}
                color="secondary"
                label={intl.formatMessage({
                  id: 'set-material-component.percentage',
                })}
                margin="normal"
                variant="outlined"
                defaultValue={_fields.percentage}
                onBlur={event => {
                  const input = event.currentTarget.value;
                  const inputNumber = NumberWithComma(input);
                  if (!Number.isNaN(inputNumber))
                    update(index, {
                      componentId: _fields.componentId,
                      percentage: inputNumber,
                    });
                }}
                onChange={event =>
                  setValue(
                    `composition.${index}.percentage`,
                    NumberWithComma(event.target.value),
                    { shouldValidate: true },
                  )
                }
              />
              <IconButton
                data-test={`deleteComponent-${index}`}
                aria-label="delete"
                onClick={() => remove(index)}
              >
                <Delete />
              </IconButton>
            </Stack>
          </Fragment>
        ))}
      </Stack>
      <IconButton
        data-test="addComponent"
        aria-label="add"
        onClick={() => append({ componentId: '', percentage: 0 })}
      >
        <Add />
      </IconButton>
    </Box>
  );
};
