import { LocalBuildingPart } from 'services/buildingPart/types';
import { materialsInputsDatabase } from '../../dexieDB';

export const editLocalBuildingPart = async (
  buildingPartToUpdateId: string,
  localBuildingPartUpdates: Partial<LocalBuildingPart>,
): Promise<void> => {
  await materialsInputsDatabase.updateBuildingPart(
    buildingPartToUpdateId,
    localBuildingPartUpdates,
  );
};
