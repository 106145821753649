import { LocalBuildingPartMap } from 'services/buildingPartMaps/type';
import { materialInputsPicturesDatabase } from '../../dexieDB';

export const getBuildingPartMap = async (
  buildingPartMapId: string,
): Promise<LocalBuildingPartMap> => {
  const buildingPartMap =
    // @ts-ignore dexie is mistyped
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    (await materialInputsPicturesDatabase.buildingPartMaps.get(
      buildingPartMapId,
    )) as LocalBuildingPartMap;

  return buildingPartMap;
};
