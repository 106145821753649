import { Checkbox, FormControlLabel } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { MaterialInputFormEntity } from 'services/materialInputs';

interface Props {
  control: Control<MaterialInputFormEntity>;
}

export const MaterialToDeduce = ({ control }: Props): JSX.Element => {
  const intl = useIntl();

  return (
    <FormControlLabel
      color="secondary"
      label={intl.formatMessage({
        id: 'material-input-form.label.materialToDeduce',
      })}
      control={
        <Controller
          name="materialToDeduce"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Checkbox checked={value} onChange={onChange} />
          )}
        />
      }
    />
  );
};
