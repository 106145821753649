import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp,
} from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { CSSProperties, useCallback, useEffect, useState } from 'react';

interface IProps {
  startValue: number;
  max: number;
  min: number;
  onChange: (value: number) => void;
}

const buttonStyle: CSSProperties = {
  padding: 0,
  margin: 0,
  height: '0.9rem',
};

export const NumberPicker = ({
  startValue,
  max,
  min,
  onChange,
}: IProps): JSX.Element => {
  const [value, setValue] = useState<number>(startValue);

  const keepValueInBounds = useCallback(
    (val: number): number => {
      let newVal: number = val;
      if (newVal > max) {
        newVal = max;
      } else if (newVal < min) {
        newVal = min;
      }

      return newVal;
    },
    [max, min],
  );

  const handleInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      setValue(keepValueInBounds(Number(event.target.value)));
    },
    [keepValueInBounds],
  );

  const handleButtons = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {
      event.preventDefault();
      switch (id) {
        case 'max': {
          setValue(max);
          break;
        }
        case 'min': {
          setValue(min);
          break;
        }
        case 'inc': {
          setValue(keepValueInBounds(value + 1));
          break;
        }
        case 'dec': {
          setValue(keepValueInBounds(value - 1));
          break;
        }
      }
    },
    [keepValueInBounds, value, min, max],
  );

  useEffect(() => {
    onChange(value);
  }, [onChange, value]);

  useEffect(() => {
    setValue(keepValueInBounds(startValue));
  }, [setValue, startValue, keepValueInBounds]);

  return (
    <div
      style={{
        flex: 0.25,
        width: '1.5rem',
        height: '100%',
        display: 'flex',
        flexFlow: 'column',
        margin: 0,
        padding: 0,
        justifyContent: 'left',
      }}
    >
      {min === max ? (
        <div style={{ height: '4.6rem' }} />
      ) : (
        <>
          <IconButton
            sx={buttonStyle}
            size="small"
            onClick={event => {
              handleButtons(event, 'max');
            }}
          >
            <KeyboardDoubleArrowUp fontSize="inherit" />
          </IconButton>
          <IconButton
            sx={buttonStyle}
            size="small"
            onClick={event => {
              handleButtons(event, 'inc');
            }}
          >
            <KeyboardArrowUp fontSize="inherit" />
          </IconButton>
          <input
            style={{
              display: 'block',
              padding: 0,
              margin: '0 auto',
              border: 0,
              width: '1.5rem',
              height: '1rem',
              textAlign: 'center',
              backgroundColor: 'transparent',
            }}
            onChange={handleInput}
            value={value}
            type="tel"
            pattern="[0-9]+"
          />
          <IconButton
            sx={buttonStyle}
            size="small"
            onClick={event => {
              handleButtons(event, 'dec');
            }}
          >
            <KeyboardArrowDown fontSize="inherit" />
          </IconButton>
          <IconButton
            sx={buttonStyle}
            size="small"
            onClick={event => {
              handleButtons(event, 'min');
            }}
          >
            <KeyboardDoubleArrowDown fontSize="inherit" />
          </IconButton>
        </>
      )}
    </div>
  );
};
