import { MaterialEntity } from '@afleya/material-schemas';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { NicknameItem } from 'services/MaterialOrNicknameItems/type';
import { getMaterial } from 'services/offline/requests/material/getMaterial';
import { slugify } from 'services/slugify/slugify';
import { ChipWasteCategory } from 'components/ChipWasteCategory';
import { ListMaterialItemText } from './ListMaterialItemText';

interface Props {
  materialInput: NicknameItem;
  handleNavigate: (
    materialId: string,
    categoryId: string,
    materialInputId: string,
  ) => void;
}

export const ListMaterialInputItem = ({
  materialInput,
  handleNavigate,
}: Props): JSX.Element => {
  const [open, setOpen] = useState(false);
  const [materialEntities, setMaterialEntities] = useState<MaterialEntity[]>(
    [],
  );
  const [fetchingNames, setFetchingNames] = useState(false);

  const handleOpenMenu = () => {
    if (!open && !fetchingNames) {
      if (materialEntities.length === 0) {
        setFetchingNames(true);
      } else {
        setOpen(true);
      }
    } else if (open && !fetchingNames) {
      setOpen(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (fetchingNames) {
        const fetchedMaterialEntity = await Promise.all(
          materialInput.materials.map(
            async item => await getMaterial(item.materialId),
          ),
        );

        setMaterialEntities(
          fetchedMaterialEntity.sort((a, b) =>
            a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
          ),
        );
        setFetchingNames(false);
        setOpen(true);
      }
    };

    void fetchData();
  }, [fetchingNames, materialInput.materials]);

  if (materialInput.materials.length === 0) return <></>;
  else if (materialInput.materials.length === 1)
    return (
      <ListItem data-test={slugify(materialInput.name)} disablePadding>
        <ListItemButton
          alignItems="flex-start"
          disableGutters
          divider={true}
          onClick={() =>
            handleNavigate(
              materialInput.materials[0].materialId,
              materialInput.materials[0].categoryId,
              materialInput.materialInputId,
            )
          }
        >
          <ListItemText>
            <div>
              <FormattedMessage
                id="input-module-categories-page.materialNickname"
                values={{
                  materialNickname: materialInput.name,
                  b: (chunks: ReactNode) => <strong>{chunks}</strong>,
                }}
              />
              <ChipWasteCategory
                wasteCategory={materialInput.materials[0].defaultWasteCategory}
              />
            </div>
          </ListItemText>
        </ListItemButton>
      </ListItem>
    );
  else
    return (
      <>
        <ListItem data-test={slugify(materialInput.name)} disablePadding>
          <ListItemButton
            alignItems="flex-start"
            disableGutters
            divider={true}
            onClick={handleOpenMenu}
          >
            <ListItemText>
              <div>
                <FormattedMessage
                  id="input-module-categories-page.materialNickname"
                  values={{
                    materialNickname: materialInput.name,
                    b: (chunks: ReactNode) => <strong>{chunks}</strong>,
                  }}
                />
              </div>
            </ListItemText>
            {open ? (
              <ExpandLess color="primary" />
            ) : (
              <ExpandMore color="primary" />
            )}
          </ListItemButton>
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {materialEntities.map(item => (
              <ListItemButton
                alignItems="flex-start"
                disableGutters
                divider={true}
                key={item.id}
                onClick={() =>
                  handleNavigate(
                    item.id,
                    item.categoryId,
                    materialInput.materialInputId,
                  )
                }
              >
                <span
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '3px',
                    marginLeft: '30px',
                  }}
                >
                  <ListMaterialItemText
                    materialName={item.name}
                    defaultWasteCategory={item.defaultWasteCategory}
                    isOrganizationMaterial={
                      typeof item.organizationId === 'string'
                    }
                    isValidated={item.isValidated}
                  />
                </span>
              </ListItemButton>
            ))}
          </List>
        </Collapse>
      </>
    );
};
